import { useEffect, useMemo } from 'react';

import { Loader, Select } from '@mantine/core';
import { useInfiniteQuery } from '@tanstack/react-query';

import { AssignableUserRole } from 'features/user/models';

import { usersOptions } from 'features/user/queryOptions';

interface SelectOption {
  value: string;
  label: string;
}

interface Props {
  selectedOption: SelectOption;
  onChange: (value: string, usename?: string) => void;
}

const OrderProcessAssigneeSelect = ({ selectedOption, onChange }: Props) => {
  const { data, isFetching } = useInfiniteQuery({
    ...usersOptions(),
    select: (data_) => ({
      ...data_,
      pages: data_.pages.map((page) => ({
        ...page,
        result: page.result.filter((user) => !user.roles.includes(AssignableUserRole.AIAssistant)),
      })),
    }),
    enabled: true,
  });

  const selectData = useMemo(() => data?.pages.flatMap((page) => page.result.map((user) => ({
    value: user.id,
    label: `${user.firstName} ${user.lastName}`,
  }))), [data]);

  useEffect(() => {
    if (!selectedOption?.value || selectedOption?.label) return;

    const user = data?.pages.flatMap((page) => page.result).find((u) => u.id === selectedOption.value);
    if (user) {
      onChange(user.id, `${user.firstName} ${user.lastName}`);
    }
  }, [selectedOption, data, onChange]);

  return (
    <Select
      data={selectData}
      placeholder="Assignee"
      value={selectedOption?.value}
      size="xs"
      onChange={(v, option) => onChange(v, option.label)}
      rightSection={isFetching ? <Loader size={10} /> : null}
    />
  );
};

export default OrderProcessAssigneeSelect;
