import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { LoadingOverlay } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';

import { ROUTES } from 'config/routes';

import { useDeleteUserById } from 'features/user/hooks';

import { Page } from 'components/layout/Page/Page';
import { ConfirmModalWrapperBase } from 'components/wrapper';
import { LeftSide, RightSide } from 'features/user/components';

import { genericErrorFeedback } from 'utils/errors';

import { userByIdOptions } from '../queryOptions';

interface Props {
  userId: string;
}

interface ContentProps extends Props {
  isDeletingUser: boolean;
  setConfirmationModalOpen?: (isOpen: boolean) => void;
}

const Content = ConfirmModalWrapperBase(({ userId, setConfirmationModalOpen, isDeletingUser }: ContentProps) => {
  const { t } = useTranslation();

  const { data: user, isFetching: isFetchingUser, error } = useQuery({
    ...userByIdOptions(userId),
  });

  const handleDeleteUser = () => {
    setConfirmationModalOpen(true);
  };

  useEffect(() => {
    if (error) {
      genericErrorFeedback(t('error.fetchingUser'))(error);
    }
  }, [error, t]);

  return (
    <Page
      pageTitle={t('teammember.details.title')}
      className="overflow-y-auto"
    >
      <LoadingOverlay
        visible={isFetchingUser || isDeletingUser}
        loaderProps={{ type: 'dots' }}
        overlayProps={{ blur: 2 }}
      />
      <div className="w-full flex-1 flex space-x-6">
        <div className="w-[20%]">
          <LeftSide user={user} />
        </div>
        <div className="flex-1 flex-col overflow-hidden">
          <RightSide handleDeleteUser={handleDeleteUser} />
        </div>
      </div>
    </Page>
  );
});

const TeamMemberDetailsPage = ({ userId }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isLoading: isDeletingUser, deleteUserById } = useDeleteUserById();

  const onConfirmDeleteUser = () => {
    deleteUserById(userId)
      .then(() => {
        navigate(ROUTES.TEAM_MEMBERS);
      });
  };

  return (
    <Content
      userId={userId}
      title={t('teammember.details.delete.title')}
      description={t('teammember.details.delete.description')}
      onConfirm={onConfirmDeleteUser}
      isDeletingUser={isDeletingUser}
    />
  );
};

export default TeamMemberDetailsPage;
