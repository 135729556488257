import { ProtectedScreen } from 'components/ui/ProtectedScreen';

import { TeamMemberListPage } from '../pages';

const TeamMemberListScreen = () => (
  <ProtectedScreen>
    <TeamMemberListPage />
  </ProtectedScreen>
);

export default TeamMemberListScreen;
