import {
  useCallback, useEffect, useMemo,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';

import { isBefore, max, startOfToday } from 'date-fns';
import Dayjs from 'dayjs';

import { Tooltip } from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';

import { RequestedDeliveryTimePickerProps } from './types';

import LabelWrapper from '../../ProcessView/components/LabelWrapper';

import stars from 'assets/stars-svgrepo-com.svg';

import { isZeroTime } from 'utils/dateTime';

const RequestedDeliveryTimePicker = ({
  requestedDeliveryTime,
  autoMatchedRequestedDeliveryTime,
  onDeliveryDateTimeChange,
  setError,
  label = 'Delivery date and time',
}: RequestedDeliveryTimePickerProps) => {
  const { t } = useTranslation();

  const openRef = useRef<boolean>(false);

  const requestedDeliveryDatetime: Date | null = useMemo(() => {
    if (requestedDeliveryTime) return Dayjs(requestedDeliveryTime).toDate();

    return null;
  }, [requestedDeliveryTime]);

  const datetimeError: string = useMemo(() => {
    if (isZeroTime(requestedDeliveryTime)) return t('order.processOrderDrafts.rightSide.requestedDeliveryDateWasNotExtracted');

    if (
      !requestedDeliveryTime
      || (requestedDeliveryDatetime
          && isBefore(requestedDeliveryDatetime, startOfToday()))
    ) return t('order.processOrderDrafts.rightSide.selectAValidDeliveryDate');

    return '';
  }, [requestedDeliveryTime, requestedDeliveryDatetime, t]);

  const isRequestedDeliveryTimeAutoMatched = useMemo(() => {
    if (
      !requestedDeliveryTime
          || !autoMatchedRequestedDeliveryTime
    ) return false;

    return (
      requestedDeliveryTime === autoMatchedRequestedDeliveryTime
    );
  }, [requestedDeliveryTime, autoMatchedRequestedDeliveryTime]);

  const showAutoMatched = useMemo(
    () => isRequestedDeliveryTimeAutoMatched && datetimeError === '',
    [isRequestedDeliveryTimeAutoMatched, datetimeError],
  );

  const minDate = useMemo(() => startOfToday(), []);

  const rightSection = useMemo(() => {
    if (showAutoMatched) {
      return (
        <img
          className="relative h-[25.4px] w-[20px]"
          src={stars}
          alt=""
          style={{
            filter: 'invert(54%) sepia(62%) saturate(6540%) hue-rotate(237deg) brightness(99%) contrast(94%)',
          }}
        />
      );
    }

    return null;
  }, [showAutoMatched]);

  const onDeliveryDateTimeClick = useCallback(() => {
    if (openRef.current) {
      openRef.current = false;
      return;
    }

    openRef.current = true;
    if (!requestedDeliveryDatetime) {
      onDeliveryDateTimeChange(Dayjs(startOfToday()).toDate());
      return;
    }

    const maxDate = Dayjs(
      max([requestedDeliveryDatetime, startOfToday()]),
    ).toDate();

    if (maxDate.getTime() !== requestedDeliveryDatetime.getTime()) {
      onDeliveryDateTimeChange(maxDate);
    }
  }, [onDeliveryDateTimeChange, requestedDeliveryDatetime]);

  useEffect(() => {
    setError(datetimeError);
  }, [datetimeError, setError]);

  return (
    <LabelWrapper label={label} errorMsg={datetimeError}>
      <Tooltip
        disabled={!showAutoMatched}
        label={t('order.processOrderDrafts.rightSide.adamHasMatchedTheDeliveryTimeForThisOrder')}
        bg="#a78bfa"
        withArrow
        position="top-start"
      >
        <DateTimePicker
          value={requestedDeliveryDatetime}
          onChange={onDeliveryDateTimeChange}
          onClick={onDeliveryDateTimeClick}
          minDate={minDate}
          error={datetimeError}
          rightSection={rightSection}
          styles={{
            input: {
              color: showAutoMatched ? '#8b5cf6' : '',
            },
            error: {
              position: 'absolute',
            },
          }}
          size="xs"
        />
      </Tooltip>
    </LabelWrapper>
  );
};

export default RequestedDeliveryTimePicker;
