enum OAuthProvider {
  GOOGLE = 'google',
  MICROSOFT = 'microsoft',
}

enum OAuthFlow {
  BUSINESS_EMAIL_CONNECTION = 'business_email_connection',
}

type OAuthAppState = {
  origin_uri: string;
  flow: OAuthFlow;
  business_id?: string;
  user_id?: string;
};

enum OAuthBindingPurpose {
  BUSINESS_EMAIL_CONNECTION = 'business_email_connection',
}

type OAuthBinding = {
  provider: OAuthProvider;
  purposes: OAuthBindingPurpose[];
  email: string;
};

export {
  OAuthAppState,
  OAuthBinding,
  OAuthBindingPurpose,
  OAuthFlow,
  OAuthProvider,
};
