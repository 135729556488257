import { ReactNode } from 'react';

export type SelectOptionDescription = {
  label: string;
  value: string;
  customRender?: () => ReactNode;
};

export type SelectOption = {
  label: string;
  value: string;

  // Optional
  visibleId?: string;
  customLabelTooltip?: string;
  additionalInfo?: ReactNode;
  contentTheme?: ContentTheme;
  flag?: string;

  descriptions?: SelectOptionDescription[];

  // TODO: remove this
  meta?: string;
  metaDescription?: string;

};

export enum ContentTheme {
  STANDARD = 'standard',
  WARNING = 'warning',
  ERROR = 'error',
  AIImproved = 'ai-improved',
  AIInfo = 'ai-info',
  AISafe = 'ai-safe',
  AIWarning = 'ai-warning',
  AIError = 'ai-error',
}
