import { forwardRef, Ref, SVGProps } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props extends SVGProps<SVGSVGElement> {
  className?: string;
}

const LearningIcon = ({ className, ...props }: Props, ref: Ref<SVGSVGElement>) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    className={twMerge('w-6 h-6', className)}
    {...props}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M13.7377 5.24578C12.8198 4.73872 11.7098 4.72166 10.7768 5.20027L4.73578 8.29913C3.57288 8.89566 3.53216 10.5431 4.66416 11.1963L4.97732 11.3771V15.6887C4.97732 16.4695 5.40064 17.1889 6.08319 17.5681L6.83454 17.9855V19.1712C6.83454 19.5302 7.12555 19.8212 7.48454 19.8212C7.84352 19.8212 8.13454 19.5302 8.13454 19.1712V18.7077L10.7086 20.1378C11.7106 20.6944 12.9359 20.6629 13.9079 20.0554L17.8956 17.5631C18.5242 17.1702 18.9061 16.4812 18.9061 15.7399V11.4619L19.3794 11.1677C20.4498 10.5025 20.4094 8.93143 19.3063 8.32205L13.7377 5.24578ZM8.13454 17.2206L11.34 19.0014C11.9284 19.3283 12.648 19.3098 13.2189 18.953L17.2066 16.4607C17.4551 16.3054 17.6061 16.033 17.6061 15.7399V12.2698L13.8732 14.5898C12.8864 15.203 11.6422 15.2233 10.636 14.6426L8.13454 13.1991V13.5514C8.41571 13.7536 8.59881 14.0837 8.59881 14.4565C8.59881 14.8293 8.41571 15.1594 8.13454 15.3617V17.2206ZM6.83454 13.5513C6.55333 13.7536 6.37021 14.0837 6.37021 14.4565C6.37021 14.8294 6.55333 15.1594 6.83454 15.3617V16.4984L6.71453 16.4317C6.44468 16.2818 6.27732 15.9973 6.27732 15.6887V12.1273L6.91787 12.4969C6.86327 12.6872 6.83454 12.8865 6.83454 13.0899V13.5513ZM8.91316 12.1475L12.2693 10.1897C12.5793 10.0089 12.6841 9.61085 12.5032 9.30077C12.3223 8.99068 11.9243 8.88594 11.6142 9.06683L7.90122 11.2327C7.80889 11.2866 7.72153 11.3468 7.63955 11.4125L5.31394 10.0704C5.07382 9.9318 5.08246 9.58235 5.32913 9.45582L11.3701 6.35697C11.9181 6.07588 12.57 6.0859 13.1091 6.38369L18.6777 9.45996C18.9117 9.58922 18.9202 9.92248 18.6932 10.0636L13.187 13.4856C12.6074 13.8458 11.8767 13.8577 11.2858 13.5167L8.91316 12.1475Z" />
  </svg>
);

export default forwardRef(LearningIcon);
