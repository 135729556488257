import { action, makeObservable, observable } from 'mobx';

import { createAlert } from 'utils/sse';

const TOPICS = ['messages', 'chats', 'orders', 'lros', 'erp_sync_logs'];

const EVENT_TYPES = [
  // Messages
  'new_message',
  'classified_message',
  'processed_message',
  'failed_message',
  'smtp_message_sent',
  'smtp_message_failed',
  // Chats
  'new_chat',
  // Orders
  'new_order',
  'eager_sync_success',
  'eager_sync_failure',
  // LROs
  'lro_done',
  'lro_started',
  // ERP Sync Logs
  'failed_order_sync',
];

class SseSources {
  areConnected: boolean = false;

  userSource: EventSource = null;

  businessSource: EventSource = null;

  receivedEvents: Record<string, boolean> = {};

  handlers: {
    key: string;
    eventTypes: typeof EVENT_TYPES;
    handler: (_: MessageEvent) => void;
  }[] = [];

  constructor() {
    makeObservable(this, {
      areConnected: observable,
      connectSources: action,
      disconnectSources: action,
    });
  }

  connectSources = () => {
    this.disconnectSources();

    const topicsParams = TOPICS.map((t) => `topics[]=${t}`).join('&');
    const authParams = { withCredentials: true };

    this.userSource = new EventSource(
      `${HOSHII_API_URL}/v1/events/users/me/stream?${topicsParams}`,
      authParams,
    );
    this.businessSource = new EventSource(
      `${HOSHII_API_URL}/v1/events/businesses/me/stream?${topicsParams}`,
      authParams,
    );

    EVENT_TYPES.forEach((type) => {
      this.userSource.addEventListener(type, this._baseEventHandler.bind(this));
      this.businessSource.addEventListener(
        type,
        this._baseEventHandler.bind(this),
      );
    });

    this.areConnected = true;
  };

  disconnectSources = () => {
    this.userSource?.close();
    this.businessSource?.close();
    this.areConnected = false;
  };

  _baseEventHandler = (event: MessageEvent) => {
    try {
      const eventId = JSON.parse(event.data)?.id;

      if (eventId) {
        if (this.receivedEvents[eventId]) return;
        this.receivedEvents[eventId] = true;

        setTimeout(() => {
          delete this.receivedEvents[eventId];
        }, 1000);
      }
    } catch (e) {
      console.error('[SSE] Error parsing event data:', e);
    }

    createAlert(event);
    let handled = false;
    this.handlers.forEach((h) => {
      // Handle the event with the handler if it is listening to the event type
      // or if the handler is listening to all events
      if (h.eventTypes.includes(event.type) || h.eventTypes.length === 0) {
        console.log('[SSE] Event received:', event.type);
        h.handler(event);
        handled = true;
      }
    });
    if (!handled) {
      console.groupCollapsed('[SSE] Event not handled:', event.type);
      console.log(event);
      console.groupEnd();
    }
  };

  addSourcesHandler = (
    key: string,
    eventTypes: typeof EVENT_TYPES,
    handler: (_: MessageEvent) => void,
  ) => {
    // console.log('[SSE] Sources handler added:', key);
    this.handlers.push({ key, eventTypes, handler });
  };

  removeSourcesHandler = (key: string) => {
    // console.log('[SSE] Sources handler removed:', key);
    this.handlers = this.handlers.filter((h) => h.key !== key);
  };
}

export { SseSources };
