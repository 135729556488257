import { AlertData } from 'state/classes/AlertDataList';

import Alert from './Alert';
import ErrorModal from './ErrorModal';

interface Props {
  alert: AlertData;
}

const AlertListItem = ({ alert }: Props) => {
  if (alert.theme === 'error') {
    return (
      <ErrorModal
        alertId={alert.id}
        title={alert.title}
        message={alert.description}
      />
    );
  }

  return (
    <Alert
      alertId={alert.id}
      theme={alert.theme}
      title={alert.title}
      message={alert.description}
    />
  );
};

export default AlertListItem;
