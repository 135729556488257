import _, { camelCase } from 'lodash';

import {
  FieldSchema, FieldSpec, FieldType as SchemaFieldType,
  InputType,
} from '../models';

import { Model } from '../types';
import { GroupedOption } from 'features/settings/components/BusinessSettings/setting-card/ui/GroupedCombobox/types';

import { FieldType } from 'components/common/UniversalField';

export const fieldSchemasToGropedOptions = (fieldSchemas: FieldSchema[], parentLabel: string = ''): GroupedOption[] => {
  const options: GroupedOption[] = [];

  const leafFieldSchemas = fieldSchemas.filter((fieldSchema) => !fieldSchema.typeSchema);
  const nestedFieldSchemas = fieldSchemas.filter((fieldSchema) => fieldSchema.typeSchema);

  if (leafFieldSchemas.length > 0) {
    options.push({
      label: parentLabel,
      options: leafFieldSchemas.map((fieldSchema) => ({
        label: fieldSchema.name,
        value: fieldSchema.path,
        meta: {
          inputType: fieldSchema.inputType,
          isErpBindable: fieldSchema.isErpBindable,
          type: fieldSchema.type,
        },
      })),
    });
  }

  if (nestedFieldSchemas.length > 0) {
    nestedFieldSchemas.forEach((fieldSchema) => {
      options.push(...fieldSchemasToGropedOptions(fieldSchema.typeSchema.fields, fieldSchema.name));
    });
  }
  return options;
};

export const inputTypeToUniversalFieldType = (inputType: InputType, fieldType: SchemaFieldType): FieldType => {
  switch (inputType) {
    case 'standard':
      if (fieldType === 'numeric') {
        return 'number input';
      }
      return 'input';
    case 'queryable':
    case 'multivalue':
      return 'dropdown';
    case 'datetime':
      return 'datetime';
    default:
      return 'text';
  }
};

export const getFieldByPath = (fields: FieldSchema[], path: string): FieldSchema => {
  if (!fields || !path) return null;

  let field = fields?.find((_field) => _field.path === path);
  if (field) return field;

  for (let i = 0; i < fields.length; i += 1) {
    const _field = fields[i];
    field = getFieldByPath(_field?.typeSchema?.fields, path);
    if (field) return field;
  }

  return null;
};

const getReplacedPath = (path: string, indices: number[] = []) => {
  let index = -1;
  // replace *s by indices
  return path.replace(/\*/g, (match) => {
    index += 1;
    return indices[index]?.toString() || match;
  }).split('.').map(camelCase).join('.');
};

export const getValueByPath = (obj: any, path: string, indices: number[] = []) => {
  if (!obj || !path) return null;
  const replacedPath = getReplacedPath(path, indices);
  // separate by "." and convert all to camel case
  return _.get(obj, replacedPath);
};

export const setValueByPath = (obj: any, value: any, path: string, indices: number[] = []): any => {
  const newObj = { ...obj };

  const replacedPath = getReplacedPath(path, indices);

  _.set(newObj, replacedPath, value);

  return newObj;
};

export const isListFieldSpec = (fieldSpec: FieldSpec): boolean => fieldSpec?.modelPath?.split('.')?.[1] === '*';

export const isProductSchema = (schema: FieldSchema) => schema.name === 'Product';

export const getProductNameField = (schema: FieldSchema): FieldSchema => schema.typeSchema.fields.filter((field) => (
  field.standardFieldType === 'product_name'
))?.[0];

export const fieldSpecToModel = (fieldSpec: FieldSpec): Model => {
  switch (fieldSpec?.modelPath?.split('.')[0]) {
    case 'products':
      return Model.PRODUCTS;
    case 'customer':
      return Model.CUSTOMER;
    default:
      return Model.OTHER;
  }
};

export const pathToModel = (path: string): Model => {
  if (!path) return Model.OTHER;

  if (path.includes('products')) {
    return Model.PRODUCTS;
  }
  if (path.includes('customer')) {
    return Model.CUSTOMER;
  }
  return Model.OTHER;
};
