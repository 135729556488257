class BaseHeaderMatcher {
  id: string;

  matcherName: string;

  isMatched: boolean = false;
}

type ModelFieldType = {
  title: string;
  key: string;
  type: 'string' | 'list';
};

class ModelConfig {
  required: ModelFieldType[];

  optional: ModelFieldType[];
}

type MatchingLogic = {
  [key: string]: any;
  additional_features: string[];
  matcherName: string;
};

type MatchStatus = {
  title: string;
  key: string;
  matched: boolean;
};

export {
  BaseHeaderMatcher,
  MatchingLogic,
  MatchStatus,
  ModelConfig,
  ModelFieldType,
};
