import { useCallback, useState } from 'react';

import { globalAlertDataList } from '../state/globalAlertDataList';
import { AlertTheme } from 'state/classes/AlertDataList';

import { Message } from 'features/message/models/Message';
import { SMTPMessage } from 'models/Mail';

import { genericErrorFeedback } from '../utils/errors';
import { httpPostV2 } from '../utils/xhr';

const useCreateMessageV2 = () => {
  const [isLoading, setIsLoading] = useState(false);

  const createMailMessage = useCallback(
    async (
      smtpMessage: SMTPMessage,
      createAlertData: boolean = false,
      giveErrorFeedback: boolean = true,
    ): Promise<Message> => {
      if (isLoading) {
        return Promise.reject(
          new Error('Already creating message'),
        );
      }

      setIsLoading(true);

      return httpPostV2('/chat/messages', {
        smtpMessage,
      })
        .then((response) => {
          if (createAlertData) {
            globalAlertDataList.create('Message created successfully', AlertTheme.SUCCESS);
          }
          return response.data as Message;
        })
        .catch((error) => {
          if (giveErrorFeedback) {
            genericErrorFeedback('Create message failed')(error);
          }
          return Promise.reject(error);
        })
        .finally(() => setIsLoading(false));
    },
    [isLoading],
  );

  return { isLoading, createMailMessage };
};

export { useCreateMessageV2 };
