import { DatePicker } from '@mantine/dates';

interface DatePickersRightSectionProps {
  fromDate: Date | null;
  toDate: Date | null;
  setFromDate: (date: Date | null) => void;
  setToDate: (date: Date | null) => void;
}

const DatePickersRightSection = ({
  fromDate, toDate, setFromDate, setToDate,
}: DatePickersRightSectionProps) => (
  <div className="inline-flex items-center gap-4 pl-4 pr-0 pt-0 pb-4 relative flex-[0_0_auto]">
    <DatePicker
      date={fromDate}
      value={fromDate}
      onChange={setFromDate}
      unselectable="on"
    />
    <DatePicker
      date={toDate}
      value={toDate}
      onChange={setToDate}
      unselectable="on"
    />
  </div>
);

export default DatePickersRightSection;
