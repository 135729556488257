import { MessageIntent } from 'features/message/models/Message';
import { Thread } from 'models/Thread';

import { parsePaginatedApiResponse } from 'utils';
import { httpGetV1 } from 'utils/xhr';

export interface FetchThreadsProps {
  exclude_threads_without_participation?: boolean;
  intents?: MessageIntent[];
  unread_only?: boolean;
  inbox_id?: string;
  cursor?: string;
  limit?: number;
}

export const fetchThreads = (params: FetchThreadsProps) => httpGetV1('/threads', {
  params,
  classType: Thread,
}).then((response) => parsePaginatedApiResponse<Thread>(response));
