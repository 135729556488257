import { Threads } from './classes/Threads';

let globalThreads: Threads = null;

const initGlobalThreads = async () => {
  globalThreads = new Threads();
  await globalThreads.initPersistence();
};

export { globalThreads, initGlobalThreads };
