import { ProtectedScreen } from 'components/ui/ProtectedScreen';

import { ProcessNewOrderDraftPage } from 'features/order/pages';

const ProcessNewOrderDraftScreen = () => (
  <ProtectedScreen>
    <ProcessNewOrderDraftPage />
  </ProtectedScreen>
);

export default ProcessNewOrderDraftScreen;
