import { TagVariant } from 'types/tag';

import { Tag } from 'components/common/Tag';

interface Props {
  flag: string;
}

const Flag = ({ flag }: Props) => {
  if (!flag) return null;
  return (
    <Tag
      className="static whitespace-nowrap"
      tagTitle={flag}
      tagVariant={TagVariant.GRAY}
      hideCircle
    />
  );
};

export default Flag;
