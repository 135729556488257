import {
  memo,
  useCallback,
  useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Dayjs from 'dayjs';

import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { LoadingOverlay } from '@mantine/core';

import { ROUTES } from 'config/routes';

import { Order } from 'features/order/models';

import { DetailPanelContentContext, DetailPanelContentType } from 'contexts/useDetailPanelContentContext';
import { MessageProviderType, MessagesProvider, useMessagesContext } from 'contexts/useMessagesContext';
import { PlayRecordingProvider } from 'contexts/usePlayRecordingIndex';
import { OrderProvider, useOrderContext } from 'features/order/contexts/useOrderContext';
import { ProcessOrderProvider } from 'features/order/contexts/useProcessOrderContext';

import { Card } from 'components/common/Card';
import { Page } from 'components/layout/Page/Page';
import {
  Customer, Details, Events, Products,
} from 'features/order/components/order-detail';
import { OrderStatusBadge } from 'features/order/components/order-list/OrderStatusBadge';
import { OrderMessages } from 'features/order/components/process-order-draft/OrderMessages';

import { isZeroId } from 'utils/objectId';

import { useFetchOrder } from 'features/order/api/useFetchOrder';
import { useFetchOrderDrafts } from 'features/order/api/useFetchOrderDrafts';

interface Props {
  orderId: string;
}

const PageContent = memo(({ order }: { order: Order }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { loadOrderDrafts } = useFetchOrderDrafts({
    preventInitialFetch: true,
  });

  const {
    groupOrders, setGroupOrders, setSelectedIndex, selectedIndex,
  } = useOrderContext();
  const { messages, isLoading: isMessageLoading } = useMessagesContext();

  const selectedOrder = useMemo(() => groupOrders[selectedIndex], [groupOrders, selectedIndex]);

  const onBackButtonClick = useCallback(() => {
    navigate(ROUTES.ORDERS);
  }, [navigate]);

  useEffect(() => {
    if (order && setGroupOrders && setSelectedIndex && loadOrderDrafts) {
      if (order.groupId && !isZeroId(order.groupId)) {
        // Get order groups
        loadOrderDrafts(false, true, order.groupId).then((orders) => {
          setGroupOrders([order, ...(orders || [])]);
        });
      } else {
        setGroupOrders([{
          ...order,
          products: (order.products || []).map((product) => ({ ...product })),
        }]);
      }

      setSelectedIndex(0);
    }
  }, [loadOrderDrafts, order, setGroupOrders, setSelectedIndex]);

  return (
    <div className="h-full flex-1 overflow-hidden flex flex-col gap-lg">
      <div className="flex items-center space-x-4">
        <div>
          <button type="button" onClick={onBackButtonClick}>
            <ArrowLeftIcon className="w-5 h-5" />
          </button>
        </div>
        <div className="space-y-2">
          <div className="flex gap-lg">
            <h1 className="text-2xl font-semibold leading-none text-gray-900">
              {t('order.details.header.orderId')}
              {' '}
              {selectedOrder?.externalId}
            </h1>

            {/* Tags */}
            <div className="h-4">
              <OrderStatusBadge status={selectedOrder?.status} />
            </div>
          </div>
          <div className="text-sm">
            {Dayjs(selectedOrder?.createdAt).format('MMM D, YYYY')}
            {' '}
            from
            {' '}
            {selectedOrder?.customer?.name}
          </div>
        </div>
      </div>
      <div className="flex-1 grid grid-cols-6 w-full gap-lg overflow-hidden relative">
        <LoadingOverlay
          visible={isMessageLoading}
          loaderProps={{ type: 'dots' }}
          overlayProps={{ blur: 2 }}
        />
        <div className="col-span-3 overflow-y-auto h-full hidden-scrollbar space-y-3">
          <Card openAccordion accordion className="col-span-3">
            <Card.Header>{t('order.details.card.details.title')}</Card.Header>
            <Card.Body className="block">
              <Details order={selectedOrder} />
            </Card.Body>
          </Card>

          <Card accordion className="col-span-3">
            <Card.Header>{t('order.details.card.customer.title')}</Card.Header>
            <Card.Body className="block">
              <Customer order={selectedOrder} />
            </Card.Body>
          </Card>

          <Card accordion className="col-span-3">
            <Card.Header>{t('order.details.card.messages.title')}</Card.Header>
            <Card.Body className="block">
              <div className="h-[40vh] flex flex-col w-full">
                <OrderMessages
                  messages={messages}
                  customer={groupOrders[0]?.customer}
                  subject={groupOrders[0]?.createdByUserInfo}
                />
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="col-span-3 overflow-y-auto hidden-scrollbar h-full space-y-3">
          <Card openAccordion accordion className="col-span-2">
            <Card.Header>{t('order.details.card.products.title')}</Card.Header>
            <Card.Body className="block">
              <Products order={selectedOrder} />
            </Card.Body>
          </Card>

          <Card openAccordion accordion className="col-span-2">
            <Card.Header>{t('order.details.card.events.title')}</Card.Header>
            <Card.Body className="block">
              <Events order={selectedOrder} />
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
});

const OrderDetailsPage = ({ orderId }: Props) => {
  const { t } = useTranslation();

  const { order } = useFetchOrder({
    orderId,
    preventFetch: false,
  });

  const referenceId = useMemo(() => {
    if (!order) {
      return null;
    }

    if (order.groupId && !isZeroId(order.groupId)) {
      return order.groupId;
    }

    return order.id;
  }, [order]);

  const [detailPanelContent, setDetailPanelContent] = useState<DetailPanelContentType>(
    DetailPanelContentType.ORDER_DETAIL,
  );

  const detailPanelContentContextValue = useMemo(
    () => ({
      detailPanelContent,
      setDetailPanelContent,
    }),
    [detailPanelContent],
  );

  return (
    <Page
      pageTitle={t('order.details.title')}
    >
      <MessagesProvider
        providerType={MessageProviderType.BY_REFERENCE_ID}
        referenceId={referenceId}
      >
        <PlayRecordingProvider>
          <DetailPanelContentContext.Provider
            value={detailPanelContentContextValue}
          >
            <OrderProvider>
              {/* TODO: remove this dependency (rn it is needed for the message component) */}
              <ProcessOrderProvider onOrderDraftProcessed={() => {}}>
                <PageContent order={order} />
              </ProcessOrderProvider>
            </OrderProvider>
          </DetailPanelContentContext.Provider>
        </PlayRecordingProvider>
      </MessagesProvider>
    </Page>
  );
};

export default OrderDetailsPage;
