import { useEffect, useRef, useState } from 'react';

import { useInfiniteQuery } from '@tanstack/react-query';

import { Message } from 'features/message/models/Message';

import { useChatMessageWs, useLLMChatSender } from 'features/chat/hooks';

import LandingView from './LandingView';
import MainView from './MainView';
import { chatMessagesOptions } from 'features/chat/options';
import { useAiChatStore } from 'features/chat/stores';

interface Props {
  chatId: string;
}

const scrollToMessage = (messageId: string, timeout = 300) => {
  setTimeout(() => {
    const element = document.getElementById(messageId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, timeout);
};

const RightSide = ({ chatId }: Props) => {
  const currentChat = useAiChatStore((state) => state.currentChat);
  const setCurrentChat = useAiChatStore((state) => state.setCurrentChat);

  const addChat = useAiChatStore((state) => state.addChat);

  const chats = useAiChatStore((state) => state.chats);
  const setChats = useAiChatStore((state) => state.setChats);

  const loadingChatById = useAiChatStore((state) => state.loadingChatById);
  const setLoadingChatById = useAiChatStore((state) => state.setLoadingChatById);

  const mainViewRef = useRef(null);

  const [tempMessage, setTempMessage] = useState<Message | null>(null);
  const [messages, setMessages] = useState([]);

  useChatMessageWs({
    chatId: currentChat?.id,
    onMessageReceived: (message) => {
      // Update the chat title in sidebar if not set
      setChats(chats.map((chat) => {
        if (chat.id === message.chatId && !chat.title) {
          return {
            ...chat,
            lastMessage: message.message,
          };
        }

        return chat;
      }));

      if (message.chatId === currentChat?.id) {
        mainViewRef.current?.deactivateScrollToCurrentPosition();
        scrollToMessage(message.id);
        setMessages([...messages, message]);
      }
    },
    stopAdamThinking: (id: string) => {
      setLoadingChatById(id, false);
    },
  });

  const { sendMessage, isLoading } = useLLMChatSender({
    chat: currentChat,
    setTempMessage: (message) => {
      mainViewRef.current?.deactivateScrollToCurrentPosition();
      scrollToMessage(message.id);

      setTempMessage(message);
      setLoadingChatById(currentChat?.id, true);
    },
    updateTempMessage: (updatedMessage) => {
      // If temp message is in messages, update it
      // Otherwise, add it to the messages ensuring that messages are ordered by date
      mainViewRef.current?.deactivateScrollToCurrentPosition();
      setTempMessage(null);
      setMessages((messages_) => [
        ...messages_,
        updatedMessage,
      ].sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()));
      setLoadingChatById(updatedMessage.chatId, true);
    },
    setChat: (chat) => {
      addChat(chat);
      setCurrentChat(chat);
    },
  });

  const {
    data, isFetching, hasNextPage, fetchNextPage,
  } = useInfiniteQuery({
    ...chatMessagesOptions(currentChat?.id),
    enabled: !!currentChat?.id,
  });

  useEffect(() => {
    if (data) {
      mainViewRef.current?.activateScrollToCurrentPosition();
      // We inverse the messages to display them in the correct order
      setMessages(data.pages.flatMap((page) => page.result).reverse());
    }
  }, [data]);

  if (chatId || currentChat) {
    return (
      <MainView
        ref={mainViewRef}
        messages={tempMessage ? [...messages, tempMessage] : messages}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isAdamThinking={loadingChatById[currentChat?.id]}
        isFetchingMessages={isFetching}
        onSendMessage={sendMessage}
      />
    );
  }

  return <LandingView onSendMessage={sendMessage} isLoading={isLoading} />;
};

export default RightSide;
