import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { v4 as uuidv4 } from 'uuid';

import { globalUser } from '../state/globalUser';

import { Chat } from '../features/chat/models';
import {
  Message,
  MessageChannel,
  MessageStatus,
  MessageType,
} from '../features/message/models/Message';

import { MessageDirection } from '../features/message/types/message';

import { useSendLLMMessage } from '../hooks/useSendLLMMessage';

import { isAiChat as isAiChatHelper } from '../features/chat/utils/chat';
import { getMessageDirection } from '../features/message/utils/message';
import { genericErrorFeedback } from '../utils/errors';
import { convertKeysToCamelCase } from '../utils/mapping';
import { openAuthWebSocket } from '../utils/ws';

type ChatMessageContextType = {
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;

  isSendingLLMMessage: boolean;
  isAdamThinking: boolean;
  isAiChat: boolean;

  userInput: string;
  setUserInput: React.Dispatch<React.SetStateAction<string>>;

  onMessageSubmit: (pipeline?: string) => void;
};

const ChatMessagesContext = createContext<ChatMessageContextType | undefined>(undefined);

interface ChatMessagesProviderProps {
  children: React.ReactNode;

  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  chat: Chat;
  setChat: (chat: Chat) => void;
  isAdamTemporaryChat?: boolean;
  isNewAdamChat?: boolean;
}

const ChatMessagesProvider: React.FC<ChatMessagesProviderProps> = ({
  children,
  setMessages,
  chat,
  setChat,
  isAdamTemporaryChat,
  isNewAdamChat,
}: ChatMessagesProviderProps) => {
  const { sendLLMMessage, isLoading: isSendingLLMMessage } = useSendLLMMessage();

  const [isAdamThinking, setIsAdamThinking] = useState<boolean>(false);
  const [userInput, setUserInput] = useState<string>('');

  const isAiChat = useMemo(() => isAiChatHelper(chat), [chat]);

  const messagesWs = useRef<WebSocket | null>();

  const onMessageSubmit = useCallback((pipeline?: string) => {
    // Currently we only support LLM messages
    if (!isAiChat && !isAdamTemporaryChat) return;

    const id = uuidv4();

    const msg = {
      id,
      chatId: chat?.id,
      businessSentBy: globalUser.business.id,
      businessSentTo: chat?.user?.id,
      userSentBy: globalUser.id,
      userSentTo: chat?.user?.id,
      message: userInput,
      messageType: MessageType.TEXT,
      source: MessageChannel.INTERNAL,
      createdAt: new Date().toISOString(),
      messageStatus: MessageStatus.SENDING,
      isAdded: true,
    } as Message;

    setMessages((prevMessages) => [
      ...prevMessages,
      msg,
    ]);

    setUserInput('');

    if (isAiChat || isAdamTemporaryChat) {
      setIsAdamThinking(true);
    }

    setTimeout(() => {
      const messageElement = document.getElementById(id);
      if (messageElement) {
        messageElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);

    let chatId = chat?.id;

    if (isNewAdamChat) {
      chatId = null;
    }

    sendLLMMessage(userInput, chatId, isAdamTemporaryChat, pipeline)
      .then((message) => {
        setMessages((prevMessages) => prevMessages.map((_message) => {
          // Update chat id
          setChat({
            ...chat,
            id: message.chatId,
          });

          if (_message.id === id) {
            return {
              ...message,
              messageStatus: MessageStatus.SENT,
            };
          }

          return _message;
        }));
      });
  }, [isAiChat, chat, userInput, setMessages, sendLLMMessage,
    isAdamTemporaryChat, isNewAdamChat, setChat]);

  const onWsMessageReceived = useCallback((event: MessageEvent) => {
    const message = convertKeysToCamelCase(JSON.parse(event.data)) as Message;

    if (message.chatId !== chat?.id) return;

    const direction = getMessageDirection(message, chat?.business);

    if (direction === MessageDirection.Received) {
      setMessages((m) => [...m, { ...message, isAdded: true }]);

      if ((isAiChat || isAdamTemporaryChat) && isAdamThinking) {
        setIsAdamThinking(false);
      }
    }
  }, [chat?.id, chat?.business, setMessages, isAiChat, isAdamThinking, isAdamTemporaryChat]);

  const onWsError = useCallback((error: unknown) => {
    genericErrorFeedback('Error loading live messages')(error);
  }, []);

  useEffect(() => {
    setUserInput('');
  }, [chat?.id]);

  useEffect(() => {
    messagesWs.current = openAuthWebSocket('/v1/chat/ws');
    messagesWs.current.addEventListener('message', onWsMessageReceived);
    messagesWs.current.addEventListener('error', onWsError);

    return () => {
      messagesWs.current?.removeEventListener('message', onWsMessageReceived);
      messagesWs.current?.removeEventListener('error', onWsError);
      messagesWs.current?.close();
      messagesWs.current = null;
    };
  }, [onWsMessageReceived, onWsError, chat]);

  const contextValue = useMemo(() => ({
    isSendingLLMMessage,
    isAdamThinking,
    isAiChat,
    userInput,
    setUserInput,
    onMessageSubmit,
    setMessages,
  }), [
    isSendingLLMMessage,
    isAdamThinking,
    userInput,
    setUserInput,
    onMessageSubmit,
    isAiChat,
    setMessages,
  ]);

  return (
    <ChatMessagesContext.Provider value={contextValue}>
      {children}
    </ChatMessagesContext.Provider>
  );
};

const useChatMessagesContext = () => {
  const context = useContext(ChatMessagesContext);

  if (context === undefined) {
    throw new Error('Must be wrapped by BusinessSettingsContext provider.');
  }

  return context;
};

export {
  ChatMessagesProvider,
  useChatMessagesContext,
};
