import { Expose, Type } from 'class-transformer';

import {
  MessageChannel, MessageIntent, MessageRefAction, MessageRefType, MessageType,
} from '../features/message/models/Message';
import { User } from '../features/user/models';

import { Business } from './Business';

enum ThreadType {
  Chat = 'chat',
  Email = 'email',
}

class Thread {
  @Expose({ name: 'id' })
    id: string;

  @Expose({ name: 'type' })
    type: ThreadType;

  @Expose({ name: 'external_id' })
    externalId?: string;

  @Expose({ name: 'external_message_id_chain' })
    externalMessageIdChain?: string[];

  @Expose({ name: 'inbox_id' })
    inboxId: string;

  @Expose({ name: 'participant_business_ids' })
    participantBusinessIds: string[];

  @Expose({ name: 'participant_user_ids' })
    participantUserIds: string[];

  @Expose({ name: 'participant_businesses' })
  @Type(() => Business)
    participantBusinesses: Business[];

  @Expose({ name: 'participant_users' })
  @Type(() => User)
    participantUsers: User[];

  @Expose({ name: 'first_message_id' })
    firstMessageId: string;

  @Expose({ name: 'first_message_text' })
    firstMessageText: string;

  @Expose({ name: 'first_message_type' })
    firstMessageType: MessageType;

  @Expose({ name: 'first_message_channel' })
    firstMessageChannel: MessageChannel;

  @Expose({ name: 'first_message_ref_type' })
    firstMessageRefType: MessageRefType;

  @Expose({ name: 'first_message_ref_action' })
    firstMessageRefAction: MessageRefAction;

  @Expose({ name: 'first_message_created_at' })
    firstMessageCreatedAt: string;

  @Expose({ name: 'first_message_subject' })
    firstMessageSubject: string;

  @Expose({ name: 'last_message_id' })
    lastMessageId: string;

  @Expose({ name: 'last_message_text' })
    lastMessageText: string;

  @Expose({ name: 'last_message_type' })
    lastMessageType: MessageType;

  @Expose({ name: 'last_message_channel' })
    lastMessageChannel: MessageChannel;

  @Expose({ name: 'last_message_ref_type' })
    lastMessageRefType: MessageRefType;

  @Expose({ name: 'last_message_ref_action' })
    lastMessageRefAction: MessageRefAction;

  @Expose({ name: 'last_message_created_at' })
    lastMessageCreatedAt: string;

  @Expose({ name: 'last_message_subject' })
    lastMessageSubject: string;

  @Expose({ name: 'length' })
    length: number;

  @Expose({ name: 'intents' })
    intents: MessageIntent[];

  @Expose({ name: 'read_at' })
    readAt: string;

  @Expose({ name: 'created_at' })
    createdAt: string;

  @Expose({ name: 'updated_at' })
    updatedAt: string;
}

export { Thread };
