import {
  forwardRef, useCallback,
  useEffect, useImperativeHandle, useMemo, useState,
} from 'react';

import { useQuery } from '@tanstack/react-query';

import { Select, SelectOption } from 'components/ui/Select';

import { languagesOptions } from 'features/order/queryOptions';

type Ref = {
  selectedLanguage: SelectOption;
  isLoading: boolean;
};

interface Props {
  transcriptionLanguage?: string;
}

const TranscriptionLanguageSelect = forwardRef<Ref, Props>(({ transcriptionLanguage }, ref) => {
  const { data: languages, isLoading: isLanguagesLoading } = useQuery({
    ...languagesOptions(),
  });

  const [selectedLanguage, setSelectedLanguage] = useState<SelectOption>(null);

  const languageOptions = useMemo(() => languages?.map((language) => ({
    label: language.name,
    value: language.code,
  })) || [],
  [languages],
  );

  const onSelectedLanguageSelectChange = useCallback(
    (selected: { value: string; label: string }) => {
      setSelectedLanguage(selected);
    },
    [],
  );

  useEffect(() => {
    const languageOption = languageOptions.find((option) => option.value === transcriptionLanguage);
    if (languageOption) {
      setSelectedLanguage(languageOption);
    }
  }, [languageOptions, transcriptionLanguage]);

  useImperativeHandle(ref, () => ({
    selectedLanguage,
    isLoading: isLanguagesLoading,
  }));

  return (
    <Select
      isLoading={isLanguagesLoading}
      options={languageOptions}
      selectedOption={selectedLanguage}
      onSelectionChange={onSelectedLanguageSelectChange}
      label="Audio language"
    />
  );
});

export default TranscriptionLanguageSelect;
