/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-closing-tag-location */
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { observer } from 'mobx-react-lite';

import { MessageIntents } from 'state/classes/MessageIntents';
import { OrderDrafts } from 'state/classes/OrderDrafts';

import { Analytics, DashboardInbox, UnprocessedOrders } from '../components';
import TestInbox from '../components/dashboard/TestInbox';
import { Page } from 'components/layout/Page/Page';
import { FinalizationTimeAndCorrectionsLinechart } from 'features/analytics/components';

interface Props {
  orderDrafts: OrderDrafts;
  messageIntents: MessageIntents;
  navigateToOrderDraftsProcessing: () => void;
}

const HomePage = observer(
  ({
    orderDrafts,
    messageIntents,
    navigateToOrderDraftsProcessing,
  }: Props) => {
    const { t } = useTranslation();

    const onProcessOrdersButtonClick = useCallback(() => {
      navigateToOrderDraftsProcessing();
    }, [navigateToOrderDraftsProcessing]);

    return (
      <Page
        pageTitle={t('dashboard.title')}
        pageDescription={t('dashboard.description')}
        className="overflow-y-auto hidden-scrollbar"
      >
        <div className="flex gap-3 desktop:gap-4">
          <div className="w-[300px] desktop:w-[350px] h-full">
            <DashboardInbox />
          </div>
          <div className="flex-1 space-y-3 desktop:space-y-4">
            <div className="h-[288px] w-full">
              <UnprocessedOrders />
            </div>
            <div className="h-[400px] w-full">
              <FinalizationTimeAndCorrectionsLinechart chartDivClassName="h-[260px]" />
            </div>
          </div>
        </div>
        <Analytics />
      </Page>
    );
  },
);

export default HomePage;
