import { StateCreator } from 'zustand';

import { Group } from 'features/instruction/constants';

import { ErrorsAndWarningsSlice, GroupErrorsAndWarningsState, ProcessOrderDraftsStore } from './types';

export const DefaultGroupErrorsAndWarningsState: GroupErrorsAndWarningsState = {
  [Group.Customer]: {},
  [Group.Product]: {},
  [Group.StandardFields]: {},
};

type SetState = (partial: (state: ProcessOrderDraftsStore) => Partial<ProcessOrderDraftsStore>, replace?: boolean) => void;

const add = (
  set: SetState, stateKey: 'errors' | 'warnings',
) => (orderId: string, group: Group, key: string, value: string) => {
  set((state) => {
    const prevStateByOrderId = state[stateKey][orderId] || DefaultGroupErrorsAndWarningsState;
    const newGroupState = { ...prevStateByOrderId[group] };
    newGroupState[key] = value;
    return {
      [stateKey]: {
        ...state[stateKey],
        [orderId]: {
          ...prevStateByOrderId,
          [group]: newGroupState,
        },
      },
    };
  });
};

const remove = (
  set: SetState, stateKey: 'errors' | 'warnings',
) => (orderId: string, group: Group, key: string) => {
  set((state) => {
    const prevStateByOrderId = state[stateKey][orderId] || DefaultGroupErrorsAndWarningsState;
    const newGroupState = { ...prevStateByOrderId[group] };
    delete newGroupState[key];
    return {
      [stateKey]: {
        ...state[stateKey],
        [orderId]: {
          ...prevStateByOrderId,
          [group]: newGroupState,
        },
      },
    };
  });
};

const deleteProduct = (
  set: SetState, stateKey: 'errors' | 'warnings',
) => (orderId: string, uiId: string) => {
  set((state) => {
    const prevStateByOrderId = state[stateKey][orderId] || DefaultGroupErrorsAndWarningsState;
    const updatedProductGroup = Object.keys(prevStateByOrderId[Group.Product]).reduce((acc, key) => {
      if (!key.startsWith(uiId)) {
        acc[key] = prevStateByOrderId[Group.Product][key];
      }
      return acc;
    }, {} as Record<string, string>);
    return {
      [stateKey]: {
        ...state[stateKey],
        [orderId]: {
          ...prevStateByOrderId,
          [Group.Product]: updatedProductGroup,
        },
      },
    };
  });
};

export const createErrorsAndWarningsSlice: StateCreator<
ProcessOrderDraftsStore, [], [], ErrorsAndWarningsSlice
> = (set, get) => ({
  errors: {},
  warnings: {},
  addError: (group: Group, key: string, error: string, _orderId?: string) => {
    const orderId = _orderId || get().selectedOrderId;
    add(set, 'errors')(orderId, group, key, error);
  },
  removeError: (group: Group, key: string, _orderId?: string) => {
    const orderId = _orderId || get().selectedOrderId;
    remove(set, 'errors')(orderId, group, key);
  },
  deleteProductErrorsByUiId: (uiId: string, _orderId?: string) => {
    const orderId = _orderId || get().selectedOrderId;
    deleteProduct(set, 'errors')(orderId, uiId);
  },

  addWarning: (group: Group, key: string, warning: string, _orderId?: string) => {
    const orderId = _orderId || get().selectedOrderId;
    add(set, 'warnings')(orderId, group, key, warning);
  },
  removeWarning: (group: Group, key: string, _orderId?: string) => {
    const orderId = _orderId || get().selectedOrderId;
    remove(set, 'warnings')(orderId, group, key);
  },
  deleteProductWarningsByUiId: (uiId: string, _orderId?: string) => {
    const orderId = _orderId || get().selectedOrderId;
    deleteProduct(set, 'warnings')(orderId, uiId);
  },

  resetGroupErrorsAndWarnings: (group: Group, _orderId?: string) => {
    const orderId = _orderId || get().selectedOrderId;
    // reset errors
    set((state) => {
      const prevStateByOrderId = state.errors[orderId] || DefaultGroupErrorsAndWarningsState;
      prevStateByOrderId[group] = {};
      return {
        ...state,
        errors: {
          ...state.errors,
          [orderId]: prevStateByOrderId,
        },
      };
    });

    // reset warnings
    set((state) => {
      const prevStateByOrderId = state.warnings[orderId] || DefaultGroupErrorsAndWarningsState;
      prevStateByOrderId[group] = {};
      return {
        ...state,
        warnings: {
          ...state.warnings,
          [orderId]: prevStateByOrderId,
        },
      };
    });
  },
  getFilteredErrors: () => (
    Object.values(get().errors)
      .map((groupErrsRec) => Object.values(groupErrsRec))
      .flat()
      .map((errsRec) => Object.values(errsRec))
      .flat()
      .filter((e) => e !== '')
  ),
});
