import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ProtectedScreen } from 'components/ui/ProtectedScreen';

import { CustomerListPage } from 'features/customer/pages';

const CustomerListScreen = () => {
  const navigate = useNavigate();

  const navigateToCustomersImport = useCallback(() => {
    navigate('/customers/import');
  }, [navigate]);

  return (
    <ProtectedScreen>
      <CustomerListPage navigateToCustomersImport={navigateToCustomersImport} />
    </ProtectedScreen>
  );
};

export default CustomerListScreen;
