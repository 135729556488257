import { NavLink, useLocation } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';

import { isSidebarNavItemMultiple, SidebarNavItem } from '../Sidebar.types';

import { isActive } from '../Sidebar.utils';

import LinksGroup from './LinksGroup';

interface Props {
  item: SidebarNavItem;
  isSidebarExpanded: boolean;
}

const SidebarContentItem = ({ item, isSidebarExpanded }: Props) => {
  const { pathname } = useLocation();

  if (isSidebarNavItemMultiple(item)) {
    return (
      <LinksGroup
        item={item}
        isSidebarExpanded={isSidebarExpanded}
        pathname={pathname}
      />
    );
  }

  return (
    <NavLink
      className={twMerge(
        'items-center gap-2 relative flex-[0_0_auto] rounded-full text-black-menu-80 hover:bg-light-blue-hue-box transition-all duration-300 ease-in-out',
        isSidebarExpanded ? 'pl-2 pr-3 desktop:pl-3 desktop:pr-4 py-1.5 desktop:py-2 self-stretch w-full flex' : 'inline-flex p-1',
        isActive(pathname, item.href) && 'bg-gradient-to-r from-[#8249F2] to-[#BE99F0] text-white',
      )}
      to={item.href}
    >
      <item.icon className="size-[22px] desktop:size-6" />
      {
          isSidebarExpanded && (
          <div
            className={twMerge(
              'relative w-fit font-font-screen-nav-bar-medium font-[number:var(--font-screen-nav-bar-medium-font-weight)] text-[length:var(--font-screen-nav-bar-medium-font-size)] tracking-[var(--font-screen-nav-bar-medium-letter-spacing)] leading-[var(--font-screen-nav-bar-medium-line-height)] whitespace-nowrap [font-style:var(--font-screen-nav-bar-medium-font-style)]',
              'desktop:font-font-screen-nav-bar-large-medium desktop:font-[number:var(--font-screen-nav-bar-large-medium-font-weight)] desktop:text-[length:var(--font-screen-nav-bar-large-medium-font-size)] desktop:tracking-[var(--font-screen-nav-bar-large-medium-letter-spacing)] desktop:leading-[var(--font-screen-nav-bar-large-medium-line-height)] desktop:[font-style:var(--font-screen-nav-bar-large-medium-font-style)]',
            )}
          >
              {item.label}
          </div>
          )
      }
    </NavLink>
  );
};

export default SidebarContentItem;
