import {
  useCallback,
} from 'react';

import { globalLROs } from 'state/globalLROs';

import { OperationGroup } from 'models/Lro';
import { WorkflowRun } from 'models/Workflow';

import { httpPostV1 } from '../../../utils/xhr';
import { genericErrorFeedback } from 'utils/errors';

type RetryWorkflowParams = {
  workflow: string;
  transcription_language?: string;
};

const useRetryWorkflow = () => {
  const retryWorkflow = useCallback(
    async (
      selectedWorkflow: string,
      workflowRun: WorkflowRun,
      pendingId: string,

      lroTitle: string,
      lroDescription: string,

      transcriptionLanguage?: string,
    ): Promise<void> => {
      if (!workflowRun) return;

      const params: RetryWorkflowParams = {
        workflow: selectedWorkflow,
      };
      if (transcriptionLanguage) {
        params.transcription_language = transcriptionLanguage;
      }

      httpPostV1(`/workflows/runs/${workflowRun.id}/retry`, params)
        .then((response) => {
          const lroId: string = response.data.lroId;

          globalLROs.addPendingLRO(lroId, OperationGroup.WORKFLOW, lroTitle, lroDescription, pendingId);
        })
        .catch(genericErrorFeedback('Error retrying workflow'))
        .finally(() => {});
    },
    [],
  );

  return {
    retryWorkflow,
  };
};

export { useRetryWorkflow };
