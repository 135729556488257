import { uniq } from 'lodash';

import { globalUser } from 'state/globalUser';

import {
  convertibleUnits,
  LlmScore, Order, ProductWithQuantity,
} from '../models';

import { AssignedStatus } from 'features/order/types/order';
import { SortExtractedProductsBy } from 'features/product/types/product';

import { isZeroId } from 'utils/objectId';

const getAssignedStatus = (assigneeId: string | null) => {
  if (!assigneeId || isZeroId(assigneeId)) return AssignedStatus.UNASSIGNED;

  if (assigneeId === globalUser.id) return AssignedStatus.ME;

  return AssignedStatus.OTHER;
};

const calcTotalPrice = (order: Order) => {
  const currencies = uniq(
    order.products
      .map((_product) => _product?.price?.currency)
      .filter((_currency) => _currency),
  );
  return {
    ...order,
    totalPrice: {
      amount:
        currencies.length === 1
          ? order.products.reduce(
            (total, product) => total
                + (product?.price?.amount || 0) * (product?.quantity || 0),
            0,
          )
          : 0,
      currency:
        currencies.length === 1 ? currencies[0] : order.totalPrice?.currency,
    },
  };
};

const isUnitConvertible = (unit: string) => convertibleUnits.includes(unit?.toUpperCase());

const llmScoreToNumber = (score: LlmScore) => {
  switch (score) {
    case LlmScore.ALMOST_SURE:
      return 4;
    case LlmScore.SURE:
      return 3;
    case LlmScore.CONFIDENT:
      return 2;
    case LlmScore.NOT_CONFIDENT:
      return 1;
    case LlmScore.NOT_SURE:
      return 0;
    default:
      return 0;
  }
};

const getErrorStatusScore = (uiId: string, errorsKeys: string[], warningsKeys: string[]) => {
  if (errorsKeys.some((key) => key.startsWith(uiId))) return 1;
  if (warningsKeys.some((key) => key.startsWith(uiId))) return 2;
  return 3;
};

const sortExtractedProducts = (
  products: ProductWithQuantity[],
  sortExtractedProductsBy: SortExtractedProductsBy,
  errorsKeys: string[],
  warningsKeys: string[],
) => {
  if (!products || products?.length === 0) return [];

  if (sortExtractedProductsBy === SortExtractedProductsBy.SCORE) {
    return [...products].sort((a, b) => llmScoreToNumber(a.llmScore) - llmScoreToNumber(b.llmScore));
  }

  if (sortExtractedProductsBy === SortExtractedProductsBy.ErrorStatus) {
    return [...products].sort((a, b) => (
      getErrorStatusScore(a.uiId, errorsKeys, warningsKeys) - getErrorStatusScore(b.uiId, errorsKeys, warningsKeys)
    ));
  }

  return products;
};

export {
  calcTotalPrice, getAssignedStatus, isUnitConvertible, sortExtractedProducts,
};
