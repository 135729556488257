import { twMerge } from 'tailwind-merge';

import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover } from '@mantine/core';
import { Editor } from '@tiptap/react';

import TextSizeIcon from 'components/icon/TextSizeIcon';

interface Props {
  editor: Editor;
}

const TextSize = ({ editor }: Props) => {
  if (!editor) {
    return null;
  }

  return (
    <Popover>
      <Popover.Target>
        <div className="cursor-pointer flex items-center gap-2 hover:bg-gray-100 rounded-sm p-1 transition-colors duration-300">
          <TextSizeIcon className="w-5 h-5" />
          <FontAwesomeIcon icon={faCaretDown} />
        </div>
      </Popover.Target>
      <Popover.Dropdown className="flex flex-col gap-1">
        <button
          type="button"
          onClick={() => editor.chain().focus().setParagraph().run()}
          className={twMerge(
            'items-center flex gap-2 hover:bg-gray-100 rounded-sm px-2 py-1 transition-colors duration-300',
            editor.isActive('paragraph') && 'bg-gray-100',
          )}
        >
          Normal
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
          className={twMerge(
            'items-center flex gap-2 hover:bg-gray-100 rounded-sm px-2 py-1 transition-colors duration-300',
            editor.isActive('heading', { level: 1 }) && 'bg-gray-100',
          )}
        >
          H1
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
          className={twMerge(
            'items-center flex gap-2 hover:bg-gray-100 rounded-sm px-2 py-1 transition-colors duration-300',
            editor.isActive('heading', { level: 2 }) && 'bg-gray-100',
          )}
        >
          H2
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
          className={twMerge(
            'items-center flex gap-2 hover:bg-gray-100 rounded-sm px-2 py-1 transition-colors duration-300',
            editor.isActive('heading', { level: 3 }) && 'bg-gray-100',
          )}
        >
          H3
        </button>
      </Popover.Dropdown>
    </Popover>
  );
};

export default TextSize;
