import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MapPinIcon } from '@heroicons/react/24/outline';

import { Business } from 'models/Business';

import {
  MailLetterIcon, MenuParagraphIcon, PhoneIcon, QRCodeScanIcon,
} from 'components/icon/outline';
import { InfiniteScrollTable, InfiniteScrollTableContextProps } from 'components/ui';

import CustomerListTableRow from './CustomerListTableRow';

interface Props extends InfiniteScrollTableContextProps {
  customers: Business[];
  openDialog: (customerId: string) => void;
}

const CustomerListTable = ({
  customers,
  openDialog,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState<string[]>([]);

  const checkbox = useRef<HTMLInputElement>();

  const onDetailsButtonClick = useCallback(
    (customerId: string) => {
      openDialog(customerId);
    },
    [openDialog],
  );

  const toggleAll = useCallback(() => {
    if (selectedCustomerIds.length === customers.length) {
      setSelectedCustomerIds([]);
    } else {
      setSelectedCustomerIds(customers.map((customer) => customer.id));
    }
  }, [customers, selectedCustomerIds]);

  const onSelectionChange = useCallback((customer: Business) => {
    setSelectedCustomerIds(
      (prev) => (prev.includes(customer.id) ? prev.filter((p) => p !== customer.id) : [...prev, customer.id]));
  }, []);

  return (
    <InfiniteScrollTable
      {...props}
    >
      <InfiniteScrollTable.Thead className="bg-white">
        <tr>
          <InfiniteScrollTable.Th>
            <input
              type="checkbox"
              className="absolute left-1/2 -translate-x-1/2 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary-500"
              ref={checkbox}
              checked={selectedCustomerIds.length === customers.length}
              onChange={toggleAll}
            />
          </InfiniteScrollTable.Th>

          <InfiniteScrollTable.Th
            text={t('customer.list.header.externalID')}
            Icon={QRCodeScanIcon}
          />

          <InfiniteScrollTable.Th
            text={t('customer.list.header.name')}
            Icon={MenuParagraphIcon}
          />

          <InfiniteScrollTable.Th
            text={t('customer.list.header.phone')}
            Icon={PhoneIcon}
          />

          <InfiniteScrollTable.Th
            text={t('customer.list.header.email')}
            Icon={MailLetterIcon}
          />

          <InfiniteScrollTable.Th
            text={t('customer.list.header.address')}
            Icon={MapPinIcon}
            isLast
          />
        </tr>
      </InfiniteScrollTable.Thead>
      <InfiniteScrollTable.Tbody>
        {customers.map((customer, idx) => (
          <CustomerListTableRow
            key={customer.id}
            idx={idx}
            customer={customer}
            isRowSelected={selectedCustomerIds.includes(customer.id)}
            onRowSelected={onSelectionChange}
            onDetailsButtonClick={onDetailsButtonClick}
          />
        ))}
      </InfiniteScrollTable.Tbody>
    </InfiniteScrollTable>
  );
};

export default CustomerListTable;
