import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useInfiniteQuery } from '@tanstack/react-query';

import { GetSyncLogsParams } from 'features/erp/apis';

import { Page } from 'components/layout/Page/Page';
import { SyncLogFilters, SyncLogListTable } from 'features/erp/components';

import { syncLogsOptions } from '../queryOptions/syncLogsOptions';

const SyncLogListPage = () => {
  const { t } = useTranslation();

  const [params, setParams] = useState<GetSyncLogsParams>({});

  const {
    data,
    isFetching,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    ...syncLogsOptions(params),
  });

  const onTableScrolledToEnd = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  const onResetFilters = useCallback(() => {
    setParams({});
  }, []);

  return (
    <Page
      pageTitle={t('audit.erpSyncLogs.title')}
      pageDescription={t('audit.erpSyncLogs.description')}
      className="p-4"
      contentWithBorder
    >
      <div className="flex justify-between gap-lg">
        <SyncLogFilters
          params={params}
          setParams={setParams}
          onResetFilters={onResetFilters}
        />
      </div>

      <SyncLogListTable
        logs={data?.pages.flatMap((page) => page.result) ?? []}
        isLoading={isFetching}
        onScrolledToEnd={onTableScrolledToEnd}
        hasNextPage={hasNextPage}
      />
    </Page>
  );
};

export default SyncLogListPage;
