import { useCallback, useState } from 'react';

import { Button, Popover } from '@mantine/core';

import { CalendarIcon } from 'components/icon/outline';

import buttonStyles from './DatePickersButton.module.css';
import popoverStyles from './DatePickersPopover.module.css';

import DatePickersButtons from './DatePickersButtons';
import { DatePickersLeftSection } from './DatePickersLeftSection';
import { DatePickersRightSection } from './DatePickersRightSection';

interface Props {
  fromDate: Date | null;
  toDate: Date | null;
  onFromDateChange: (date: Date | null) => void;
  onToDateChange: (date: Date | null) => void;
}

const formatLabel = (fromDate: Date | null, toDate: Date | null) => {
  if (fromDate && toDate) {
    return `${fromDate.toLocaleDateString()} - ${toDate.toLocaleDateString()}`;
  }

  if (fromDate) {
    return `${fromDate.toLocaleDateString()} - `;
  }

  if (toDate) {
    return `- ${toDate.toLocaleDateString()}`;
  }
  return 'Datetime';
};

const DatePickers = ({
  fromDate: paramsFromDate,
  toDate: paramsToDate,
  onFromDateChange,
  onToDateChange,
}: Props) => {
  const [opened, setOpened] = useState(false);

  const [fromDate, setFromDate] = useState<Date | null>(paramsFromDate);
  const [toDate, setToDate] = useState<Date | null>(paramsToDate);

  const onCancelClick = useCallback(() => {
    setOpened(false);
  }, []);

  const onApplyClick = useCallback(() => {
    if (fromDate) {
      onFromDateChange(fromDate);
    }
    if (toDate) {
      onToDateChange(toDate);
    }
  }, [fromDate, onFromDateChange, toDate, onToDateChange]);

  return (
    <Popover
      classNames={popoverStyles}
      opened={opened}
      onClose={() => setOpened(false)}
    >
      <Popover.Target>
        <Button
          onClick={() => setOpened(true)}
          size="xs"
          c={(paramsFromDate || paramsToDate) ? 'black' : 'gray'}
          variant="outline"
          classNames={buttonStyles}
          leftSection={<CalendarIcon className="size-4 desktop:size-5" />}
        >
          {formatLabel(paramsFromDate, paramsToDate)}
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <div className="inline-flex items-start relative flex-[0_0_auto]">
          <DatePickersLeftSection setFromDate={setFromDate} setToDate={setToDate} />
          <DatePickersRightSection
            fromDate={fromDate}
            toDate={toDate}
            setFromDate={setFromDate}
            setToDate={setToDate}
          />
        </div>

        <DatePickersButtons
          onCancelClick={onCancelClick}
          onApplyClick={onApplyClick}
        />
      </Popover.Dropdown>
    </Popover>
  );
};

export default DatePickers;
