import {
  useCallback, useRef, useState,
} from 'react';

import { Subject } from 'features/user/models';

import { MailLetterIcon, MenuParagraphIcon, PhoneIcon } from 'components/icon/outline';
import { InfiniteScrollTable, InfiniteScrollTableContextProps } from 'components/ui';

import SubjectsTableRow from './SubjectsTableRow';

interface Props extends InfiniteScrollTableContextProps {
  subjects: Subject[];
}

const SubjectsTable = ({
  subjects,
  ...props
}: Props) => {
  const [selectedSubjectIds, setSelectedSubjectIds] = useState<string[]>([]);

  const checkbox = useRef<HTMLInputElement>();

  const toggleAll = useCallback(() => {
    if (selectedSubjectIds.length === subjects.length) {
      setSelectedSubjectIds([]);
    } else {
      setSelectedSubjectIds(subjects.map((subject) => subject.id));
    }
  }, [subjects, selectedSubjectIds]);

  const onSelectionChange = useCallback((subject: Subject) => {
    setSelectedSubjectIds(
      (prev) => (prev.includes(subject.id) ? prev.filter((p) => p !== subject.id) : [...prev, subject.id]));
  }, []);

  return (
    <InfiniteScrollTable
      {...props}
    >
      <InfiniteScrollTable.Thead>
        <tr>
          <InfiniteScrollTable.Th>
            <input
              type="checkbox"
              className="absolute left-1/2 -translate-x-1/2 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary-500"
              ref={checkbox}
              checked={selectedSubjectIds.length === subjects.length}
              onChange={toggleAll}
            />
          </InfiniteScrollTable.Th>

          <InfiniteScrollTable.Th
            text="Name"
            Icon={MenuParagraphIcon}
          />
          <InfiniteScrollTable.Th
            text="Phone"
            Icon={PhoneIcon}
          />
          <InfiniteScrollTable.Th
            text="Email"
            Icon={MailLetterIcon}
            isLast
          />
        </tr>
      </InfiniteScrollTable.Thead>
      <InfiniteScrollTable.Tbody>
        {subjects.map((subject, idx) => (
          <SubjectsTableRow
            key={subject.id}
            idx={idx}
            subject={subject}
            isRowSelected={selectedSubjectIds.includes(subject.id)}
            onRowSelected={onSelectionChange}
          />
        ))}
      </InfiniteScrollTable.Tbody>
    </InfiniteScrollTable>
  );
};

export default SubjectsTable;
