/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import { Header } from './PageHeader';
import { Sidebar } from './Sidebar';

interface Props {
  showChrome?: boolean;
  contentWithBorder?: boolean;

  secondarySidebar?: ReactNode;

  pageTitle?: string;
  pageDescription?: string | ReactNode;

  className?: string;

  children?: ReactNode;
}

// TODO: centralize navigation
const Page = ({
  showChrome = true,
  contentWithBorder,
  secondarySidebar,
  pageTitle,
  pageDescription,
  className,
  children,
}: Props) => {
  if (!showChrome) {
    return (
      <div className="h-screen w-screen flex flex-col overflow-hidden">
        {children}
      </div>
    );
  }

  return (
    <div className="flex h-screen w-screen overflow-hidden bg-off-white-purple-hue">
      <Sidebar />

      {
        secondarySidebar && secondarySidebar
      }

      <div className="flex flex-col items-start justify-center gap-3 desktop:gap-4 p-4 desktop:p-6 relative flex-1 self-stretch grow">
        <Header title={pageTitle} description={pageDescription} />

        <div
          className={twMerge(
            'flex flex-col gap-4 relative flex-1 self-stretch w-full grow overflow-y-hidden',
            contentWithBorder && 'border border-solid bg-white border-light-grey shadow-blue-light-tint-drop-shadow rounded-[20px]',
            className,
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export { Page };
