import { Business } from '../../../models/Business';

enum Currency {
  Chf = 'CHF',
  Eur = 'EUR',
}

class Price {
  amount: number;

  currency: Currency;
}

class BaseProduct {
  idOrSku: string;

  name: string;

  minOrderQty: number;

  price: Price;

  category: string;

  supplier: string;

  units: string[];
}

class Unit {
  symbol: string;

  baseConversionFactor: number;

  constructor(data: Partial<Unit>) {
    this.symbol = data.symbol;
    this.baseConversionFactor = data.baseConversionFactor;
  }
}

class Product {
  id: string;

  idOrSku: string;

  minOrderQty: number;

  name: string;

  shortName: string;

  price: Price;

  provenanceCode: string;

  category: string;

  supplier: Business;

  supplierId: string;

  baseUnit: Unit;

  salesUnits: Unit[];

  units: string[]; // DEPRECATED

  createdBy: string;

  createdAt: string;

  updatedAt: string;

  disabled: boolean;

  score?: number;

  orderCount?: number;

  defaultUnitConversionFactor?: number;

  additionalNotes?: Record<string, AdditionalNote>;
}

class AdditionalNote {
  note: string;
}

export {
  BaseProduct, Currency, Price, Product, Unit,
};
