import { twMerge } from 'tailwind-merge';

import {
  CSSProperties,
  Tooltip as MantineTooltip,
  TooltipProps,
} from '@mantine/core';

import styles from './Tooltip.module.css';

interface Props extends TooltipProps {
  children: React.ReactNode;

  tooltipStyles?: CSSProperties;
}

const Tooltip = ({
  children,
  tooltipStyles,
  ...props
}: Props) => (
  <MantineTooltip
    classNames={styles}
    className={twMerge(
      'whitespace-normal',
      props.className,
    )}
    {...props}
    transitionProps={{ transition: 'pop', duration: 200, ...props.transitionProps }}
    bg={props.bg || 'white'}
    styles={{
      tooltip: {
        color: 'black',
        ...tooltipStyles,
      },
    }}
  >
    {children}
  </MantineTooltip>
);

export default Tooltip;
