import { createContext, useContext } from 'react';

export interface InfiniteScrollTableContextProps {
  isLoading: boolean;
  hasNextPage: boolean;
  onScrolledToEnd: () => void;
}

export const InfiniteScrollTableContext = createContext<InfiniteScrollTableContextProps>({
  isLoading: false,
  hasNextPage: false,
  onScrolledToEnd: () => {},
});

export const useInfiniteScrollTableContext = () => useContext(InfiniteScrollTableContext);
