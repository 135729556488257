import { infiniteQueryOptions } from '@tanstack/react-query';

import { AssignableUserRole } from '../models';

import { fetchUsers } from '../apis';

interface UsersOptionsParams {
  query?: string;
  roles?: AssignableUserRole[];
}

export const usersOptions = (params?: UsersOptionsParams) => infiniteQueryOptions({
  queryKey: ['users', params?.query ?? '', params?.roles ?? []],
  queryFn: ({ pageParam = '' }) => fetchUsers({
    query: params?.query ?? '',
    roles: params?.roles ?? [],
    cursor: pageParam,
    limit: 10,
  }),
  initialPageParam: '',
  getNextPageParam: (lastPage) => lastPage.cursor || null,
  gcTime: 0,
});
