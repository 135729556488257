import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface SidebarStore {
  isExpanded: boolean;
  toggle: () => void;
  close: () => void;
}

export const useSidebarStore = create<SidebarStore>()(
  persist(
    (set) => ({
      isExpanded: false,
      toggle: () => set((state) => ({ isExpanded: !state.isExpanded })),
      close: () => set({ isExpanded: false }),
    }),
    {
      name: 'sidebar-store',
    },
  ),
);
