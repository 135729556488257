import { Message, MessageIntent } from '../models/Message';
import { WorkflowRunFSMState } from 'models/Workflow';

export const hasPendingWorkflow = (message: Message, intent: MessageIntent) => (
  Object.values(message.state.details.workflowsByIntent?.[intent] || {}).includes(WorkflowRunFSMState.Ready)
   || Object.values(message.state.details.workflowsByIntent?.[intent] || {}).includes(WorkflowRunFSMState.Retry)
);

export const hasFailedWorkflow = (message: Message, intent: MessageIntent) => (
  Object.values(message.state.details.workflowsByIntent?.[intent] || {}).includes(WorkflowRunFSMState.Failed)
);
