import { ReactNode } from 'react';

import { InformationCircleIcon } from 'components/icon/outline';
import { Tooltip } from 'components/ui';

interface Props {
  title: string;
  description?: string | ReactNode;
}

const HeaderTitle = ({ title, description }: Props) => (
  <div className="flex w-48 items-center gap-1 relative">
    <div
      className="relative w-fit font-font-screen-2xl-medium font-[number:var(--font-screen-2xl-medium-font-weight)] text-black-menu-80 text-[length:var(--font-screen-2xl-medium-font-size)] tracking-[var(--font-screen-2xl-medium-letter-spacing)] leading-[var(--font-screen-2xl-medium-line-height)] whitespace-nowrap [font-style:var(--font-screen-2xl-medium-font-style)]"
    >
      {title}
    </div>

    {
      description && (
        <Tooltip label={description} withArrow>
          <div
            className="inline-flex items-center justify-center p-1 relative flex-[0_0_auto] rounded-full overflow-hidden"
          >
            <InformationCircleIcon className="!w-5 !h-5 text-dark-midnight-blue-hue-box" />
          </div>
        </Tooltip>
      )
    }
  </div>
);

export default HeaderTitle;
