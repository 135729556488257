import { useEffect, useRef } from 'react';

import { Formik } from 'formik';
import { object, string } from 'yup';

import { FormInput } from '../FormInput';
import { LoadingIndicator } from '../LoadingIndicator';
import { Button } from '../ui/Button';

interface InvitedUserDetails {
  firstName: string;
  lastName: string;
}

const formInitialValues = {
  firstName: '',
  lastName: '',
};

// const formInitialValues = {
//   firstName: 'Chihiro',
//   lastName: 'Okuyama',
// };

const formValidationSchema = object({
  firstName: string().required(),
  lastName: string().required(),
});

interface Props {
  onCompletion: (invetedUserDetails: InvitedUserDetails) => void;
  isLoading: boolean;
}

const InvitedUserDetailsStep = ({ onCompletion, isLoading }: Props) => {
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (buttonRef.current && event.key === 'Enter') {
        buttonRef.current.click();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <>
      <LoadingIndicator isLoading={isLoading} />

      <div className="flex flex-col">
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={onCompletion}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            errors,
            touched,
          }) => (
            <div className="flex flex-col gap-lg">
              <div className="felx-1 flex flex-col gap-lg">
                <FormInput
                  field="firstName"
                  required
                  disabled={isLoading}
                  label="First name"
                  value={values.firstName}
                  error={errors.firstName}
                  touched={touched.firstName}
                  onChange={handleChange('firstName')}
                  onBlur={handleBlur('firstName')}
                />
                <FormInput
                  field="lastName"
                  required
                  disabled={isLoading}
                  label="Last name"
                  value={values.lastName}
                  error={errors.lastName}
                  touched={touched.lastName}
                  onChange={handleChange('lastName')}
                  onBlur={handleBlur('lastName')}
                />
              </div>

              <div className="mt-lg flex w-full justify-end border-t pt-lg">
                <Button
                  title="Next"
                  theme="indigo"
                  radius="md"
                  ref={buttonRef}
                  onClick={handleSubmit}
                />
              </div>
            </div>
          )}
        </Formik>
      </div>
    </>
  );
};

export { InvitedUserDetailsStep };
