import {
  useCallback,
  useEffect, useRef, useState,
} from 'react';

import {
  isNil, omitBy, uniqBy,
} from 'lodash';

import { globalInboxes } from 'state/globalInboxes';

import { MessageIntent } from 'features/message/models/Message';
import { GetDocumentsQueryType } from 'models/GetDocumentsQueryType';
import { Thread } from 'models/Thread';

import { genericErrorFeedback } from 'utils/errors';
import { convertKeysToSnakeCase } from 'utils/mapping';
import { httpGetV1 } from 'utils/xhr';

enum FetchThreadsType {
  COUNT = 'count',
  DATA = 'data',
  DATA_AND_COUNT = 'data_and_count',
}

interface FetchThreadsProps {
  fetchType?: FetchThreadsType;
  preventInitialFetch?: boolean;
  defaultFilter?: ThreadsFilter;
}

type ThreadsFilter = {
  excludeThreadsWithoutParticipation?: boolean;
  intents?: MessageIntent[];
  unreadOnly?: boolean;
};

const useFetchThreads = ({
  fetchType = FetchThreadsType.DATA,
  preventInitialFetch = false,
  defaultFilter,
}: FetchThreadsProps) => {
  const [threads, setThreads] = useState<Thread[]>([]);
  const [threadsCount, setThreadsCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);

  const initialFetchRef = useRef(true);
  const endReachedRef = useRef(false);
  const cursor = useRef<string | null>(null);
  const currentAbortController = useRef<AbortController | null>(null);

  const [threadsFilter, setThreadsFilter] = useState<ThreadsFilter>(defaultFilter || {});

  const resetRefs = () => {
    endReachedRef.current = false;
    cursor.current = null;
  };

  const loadThreads = useCallback(
    async (reset: boolean = false, giveErrorFeedback: boolean = true) => {
      if (reset) {
        setThreads([]);
        resetRefs();
      } else if (endReachedRef.current) return;

      if (currentAbortController.current) {
        currentAbortController.current.abort();
      }

      const controller = new AbortController();
      currentAbortController.current = controller;

      if (!globalInboxes.selectedInboxId) return;

      setIsLoading(true);

      const threadsFilter_ = {
        ...threadsFilter,
        inbox_id: globalInboxes.selectedInboxId,
      };

      try {
        if (fetchType === FetchThreadsType.COUNT || fetchType === FetchThreadsType.DATA_AND_COUNT) {
          const responseCount = await httpGetV1('/threads', {
            params: {
              query_type: GetDocumentsQueryType.count,
              ...convertKeysToSnakeCase(omitBy(threadsFilter_, isNil)), // exclude all null values
            },
            signal: controller.signal,
          });

          setThreadsCount(responseCount?.data?.count);
        }

        if (fetchType === FetchThreadsType.DATA || fetchType === FetchThreadsType.DATA_AND_COUNT) {
          const response = await httpGetV1('/threads', {
            params: {
              cursor: cursor.current,
              limit: 20,
              ...convertKeysToSnakeCase(omitBy(threadsFilter_, isNil)), // exclude all null values
            },
            signal: controller.signal,
          });

          const entries = response?.data?.result || [];
          if (reset) {
            setThreads(entries);
          } else {
            setThreads((_threads) => uniqBy([..._threads, ...entries], 'id'));
          }
          cursor.current = response.data.cursor;

          if (!response.data.cursor || entries.length === 0) {
            endReachedRef.current = true;
          }
        }
      } catch (error) {
        console.error('Failed to fetch threads:', error.name);
        if (giveErrorFeedback && error.name !== 'AbortError' && error.name !== 'CanceledError') {
          genericErrorFeedback('An error has occured while fetching threads')(error);
        }
      } finally {
        if (!controller.signal.aborted) {
          setIsLoading(false);
        }
        currentAbortController.current = null;
      }
    },
    [threadsFilter, fetchType],
  );

  useEffect(() => {
    if (preventInitialFetch) {
      initialFetchRef.current = false;
      return () => {};
    }

    if (initialFetchRef.current) {
      initialFetchRef.current = false;
      loadThreads();
    }

    return () => {
      if (currentAbortController.current) {
        currentAbortController.current.abort();
      }
    };
  }, [loadThreads, preventInitialFetch]);

  useEffect(() => {
    if (initialFetchRef.current) {
      initialFetchRef.current = false;
      return;
    }

    loadThreads(true);
  }, [threadsFilter, loadThreads]);

  return {
    threadsCount,
    setThreadsCount,
    threads,
    setThreads,
    threadsFilter,
    setThreadsFilter,
    isLoading,
    loadThreads,
  };
};

export { FetchThreadsType, ThreadsFilter, useFetchThreads };
