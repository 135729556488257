import { useSidebarStore } from 'stores';
import { twMerge } from 'tailwind-merge';

import SidebarContent from './SidebarContent';
import SidebarHeader from './SidebarHeader';

const Sidebar = () => {
  const isExpanded = useSidebarStore((state) => state.isExpanded);
  const toggleSidebar = useSidebarStore((state) => state.toggle);

  return (
    <div
      className={twMerge(
        'inline-flex flex-col gap-8 px-3 desktop:px-4 py-6 relative bg-white rounded-[0px_20px_20px_0px] border border-solid border-light-grey shadow-blue-light-tint-drop-shadow',
        isExpanded ? 'items-start self-stretch flex-[0_0_auto]' : 'items-center ',
        'transition-all duration-300 ease-in-out',
      )}
    >
      <SidebarHeader
        isOpen={isExpanded}
        toggleSidebar={toggleSidebar}
      />

      <SidebarContent
        isSidebarExpanded={isExpanded}
      />
    </div>
  );
};

export default Sidebar;
