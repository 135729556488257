import {
  forwardRef, useCallback, useImperativeHandle,
} from 'react';

import { faPencil, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mantine/core';

import { FieldSpec } from 'features/instruction/models';
import { ProductWithQuantity } from 'features/order/models';
import { Product, Unit } from 'features/product/models/Product';

import { FieldPathWrapper } from '../utils';

import { ProductQuantityInputWithPopup } from '../../../form-elemts';
import { ProductSelect } from '../../../form-elemts/ProductSelect';
import { UnitSelect } from '../../../form-elemts/UnitSelect';

const WrappedProductSelect = FieldPathWrapper(ProductSelect);
const WrappedUnitSelect = FieldPathWrapper(UnitSelect);

interface Props {
  product: ProductWithQuantity;
  customerId: string;
  productNameField: FieldSpec | null;
  productQuantityField: FieldSpec | null;
  productUnitField: FieldSpec | null;

  onProductSelectionChange: (p: Product) => void;
  onProductQuantityChange: (val: number) => void;
  onProductUnitChange: (newUnit: Unit) => void;

  setError: (error: string, path: string) => void;

  quantityPopupContent: {
    prevQuantity: number;
    newQuantity: number;
  } | null;
  onQuantityPopupConfirmed: () => void;
  onQuantityPopupCanceled: () => void;
}

export type CollapsedContentRef = {
  openUnitSelect: () => void;
  closeUnitSelect: () => void;
};

const CollapsedContent = forwardRef(({
  product, customerId, productNameField, productQuantityField, productUnitField,
  onProductSelectionChange, onProductQuantityChange, onProductUnitChange,
  setError,
  quantityPopupContent,
  onQuantityPopupConfirmed,
  onQuantityPopupCanceled,
}: Props, ref: React.Ref<CollapsedContentRef>) => {
  const setUnitError = useCallback((error: string) => {
    setError(error, productUnitField?.path);
  }, [setError, productUnitField?.path]);

  const setError_ = useCallback((error: string) => {
    setError(error, 'product-name');
  }, [setError]);

  const onCommentButtonClick = useCallback(() => {
    const interval = setInterval(() => {
      const commentId = `${product.uiId}-comment`;
      const commentElement: HTMLTextAreaElement | null = document.querySelector(`[data-comment-id="${commentId}"]`);

      if (commentElement) {
        commentElement.focus();
        clearInterval(interval);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [product.uiId]);

  useImperativeHandle(ref, () => ({
    openUnitSelect: () => {
      const unitSelect: HTMLInputElement | null = (
        document.querySelector(`[data-select-id="${product.uiId}-unit-select"]`)
      );
      if (unitSelect) {
        // I want to prevent propagation of the event
        const event = new MouseEvent('click', { bubbles: true, cancelable: true });
        unitSelect.dispatchEvent(event);
      }
    },
    closeUnitSelect: () => {
      const unitSelect: HTMLInputElement | null = (
        document.querySelector(`[data-dropdown-id="${product.uiId}-unit-select-dropdown"]`)
      );
      if (unitSelect) {
        unitSelect.style.display = 'none';
      }
    },
  }));

  return (
    <div className="flex gap-2 pt-2 items-center">
      <div className="w-[380px] 2xl:w-[500px] 4xl:w-[700px]">
        <WrappedProductSelect
          isProductField
          fieldPath={productNameField?.path}
          fieldId={product.positionId}
          businessId={customerId}
          productWithQuantity={product}
          onProductChange={onProductSelectionChange}
          setError={setError_}
        />
      </div>

      <div className="flex">
        <ProductQuantityInputWithPopup
          productQuantityField={productQuantityField}
          product={product}
          onProductQuantityChange={onProductQuantityChange}
          quantityPopupContent={quantityPopupContent}
          onQuantityPopupCanceled={onQuantityPopupCanceled}
          onQuantityPopupConfirmed={onQuantityPopupConfirmed}
          isInCollapsedContent
        />
        <div className="w-[120px]">
          <WrappedUnitSelect
            fieldPath={productUnitField?.path}
            fieldId={product.positionId}
            isProductField
            productWithQuantity={product}
            onUnitChange={onProductUnitChange}
            inputStyles={{
              error: {
                position: 'absolute',
              },
              input: {
                borderRadius: '0 var(--mantine-radius-md) var(--mantine-radius-md) 0',
              },
            }}
            setError={setUnitError}
          />
        </div>
      </div>

      <Button
        variant="outline"
        radius="xl"
        size="xs"
        color={product.comment ? 'green' : 'blue'}
        leftSection={product.comment ? (
          <FontAwesomeIcon icon={faPencil} className="-mr-2" />
        ) : (
          <FontAwesomeIcon icon={faPlus} className="-mr-2" />
        )}
        onClick={onCommentButtonClick}
      >
        Comment
      </Button>
    </div>
  );
});

export default CollapsedContent;
