import {
  useCallback, useEffect, useState,
} from 'react';

import {
  ArrowsPointingOutIcon,
} from '@heroicons/react/24/outline';
import { Carousel, Embla } from '@mantine/carousel';
import {
  Badge,
  Image,
} from '@mantine/core';

import { Message, MessageContextUtils } from '../../../../../features/message/models/Message';

import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';

import { ImageOverlay } from './ImageOverlay';

interface Props {
  message: Message;
}

const ImageMessage = ({ message }: Props) => {
  const {
    selectedDocIndex,
    setSelectedDocIndex,
    selectedDocPageIndex,
    setSelectedDocPageIndex,
  } = useProcessOrderContext();

  const [showOverlay, setShow] = useState(false);

  // TODO(ntauth): Proper handling of documents with multiple pages
  const docs = MessageContextUtils.documentAttachments(message.context);
  const docNames = docs?.map((doc) => doc.name);
  const docImages = docs?.map((doc, docIndex) => doc.imagifiedPages?.map((page, pageIndex) => ({
    ...page,
    docIndex,
    pageIndex,
  })) || []).reduce(
    (acc, curr) => [...acc, ...curr],
    [],
  );

  const selectedDocImgIndex = MessageContextUtils.docAndPageIndexToImgIndex(docs, selectedDocIndex, selectedDocPageIndex);

  const slides = docImages.map((item) => (
    <Carousel.Slide key={item.url}>
      <Image radius="md" src={item.url} fit="contain" w="full" h={400} />
    </Carousel.Slide>
  ));

  const [carouselEmbla, setCarouselEmbla] = useState<Embla>(null);

  const onPreviousButtonClick = useCallback(() => {
    const nextSelectedDocImgIndex = Math.max(selectedDocImgIndex - 1, 0);
    setSelectedDocIndex(docImages[nextSelectedDocImgIndex].docIndex);
    setSelectedDocPageIndex(docImages[nextSelectedDocImgIndex].pageIndex);
    // setSelectedDocImgIndex(nextSelectedDocImgIndex);
    carouselEmbla.scrollPrev();
  }, [carouselEmbla, docImages, selectedDocImgIndex, setSelectedDocIndex, setSelectedDocPageIndex]);

  const onNextButtonClick = useCallback(() => {
    const nextSelectedDocImgIndex = Math.min(selectedDocImgIndex + 1, docImages.length - 1);
    setSelectedDocIndex(docImages[nextSelectedDocImgIndex].docIndex);
    setSelectedDocPageIndex(docImages[nextSelectedDocImgIndex].pageIndex);
    // setSelectedDocImgIndex(nextSelectedDocImgIndex);
    carouselEmbla.scrollNext();
  }, [carouselEmbla, docImages, selectedDocImgIndex, setSelectedDocIndex, setSelectedDocPageIndex]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        onPreviousButtonClick();
      } else if (event.key === 'ArrowRight') {
        onNextButtonClick();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedDocImgIndex, docImages, onPreviousButtonClick, onNextButtonClick]);

  return (
    <>
      <div className="relative">
        <Carousel
          getEmblaApi={setCarouselEmbla}
          withIndicators
          withControls={!showOverlay}
          withKeyboardEvents={false} // We handle keyboard events ourselves
          className="mb-sm rounded-lg border border-blue-gray-100 bg-white p-lg"
          align="center"
          styles={{
            indicator: {
              backgroundColor: '#8CBBFA',
            },
          }}
          speed={25}
          onSlideChange={(index) => {
            setSelectedDocIndex(docImages[index].docIndex);
            setSelectedDocPageIndex(docImages[index].pageIndex);
            // setSelectedDocImgIndex(index);
          }}
        >
          {slides}
        </Carousel>

        <span className="absolute inset-0 h-fit w-full flex justify-between p-md">
          <Badge variant="outline" color="blue" size="xs">
            {message.context?.attachments?.[selectedDocIndex]?.name}
          </Badge>

          <button
            type="button"
            onClick={() => setShow(true)}
          >
            <ArrowsPointingOutIcon className="aspect-square w-5" />
          </button>
        </span>
      </div>

      {showOverlay && (
        <ImageOverlay
          message={message}
          selectedDocIndex={selectedDocIndex}
          selectedDocPageIndex={selectedDocPageIndex}
          selectedDocImgIndex={selectedDocImgIndex}
          docImages={docImages}
          docNames={docNames}
          setShow={setShow}
          onPreviousButtonClick={onPreviousButtonClick}
          onNextButtonClick={onNextButtonClick}
        />
      )}
    </>
  );
};

export default ImageMessage;
