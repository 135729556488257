import { forwardRef, Ref, SVGProps } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props extends SVGProps<SVGSVGElement> {
  className?: string;
}

const UserCircleIcon = ({ className, ...props }: Props, ref: Ref<SVGSVGElement>) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 24"
    fill="currentColor"
    className={twMerge('w-6 h-6', className)}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3338 4.3C8.08105 4.3 4.6335 7.74756 4.6335 12.0003C4.6335 16.2531 8.08105 19.7007 12.3338 19.7007C16.5866 19.7007 20.0342 16.2531 20.0342 12.0003C20.0342 7.74756 16.5866 4.3 12.3338 4.3ZM3.3335 12.0003C3.3335 7.02959 7.36308 3 12.3338 3C17.3046 3 21.3342 7.02959 21.3342 12.0003C21.3342 16.9711 17.3046 21.0007 12.3338 21.0007C7.36308 21.0007 3.3335 16.9711 3.3335 12.0003ZM12.3349 7.43217C11.541 7.43217 10.8973 8.0758 10.8973 8.86975C10.8973 9.66371 11.541 10.3073 12.3349 10.3073C13.1289 10.3073 13.7725 9.66371 13.7725 8.86975C13.7725 8.0758 13.1289 7.43217 12.3349 7.43217ZM9.59735 8.86975C9.59735 7.35783 10.823 6.13217 12.3349 6.13217C13.8469 6.13217 15.0725 7.35783 15.0725 8.86975C15.0725 10.3817 13.8469 11.6073 12.3349 11.6073C10.823 11.6073 9.59735 10.3817 9.59735 8.86975ZM8.76241 15.2401C8.56369 15.048 8.51223 14.8861 8.50613 14.7712C8.49964 14.6489 8.54068 14.4991 8.6641 14.3352C8.92087 13.9942 9.46762 13.6946 10.1152 13.6946H14.5531C15.2007 13.6946 15.7474 13.9942 16.0042 14.3352C16.1276 14.4991 16.1686 14.6489 16.1621 14.7712C16.1561 14.8861 16.1046 15.048 15.9059 15.2401C15.0823 16.0362 13.8037 16.5697 12.3341 16.5697C10.8646 16.5697 9.58599 16.0362 8.76241 15.2401ZM10.1152 12.3946C9.10588 12.3946 8.15472 12.8506 7.62562 13.5532C7.35611 13.9111 7.18222 14.355 7.20795 14.84C7.23407 15.3324 7.46198 15.7912 7.8589 16.1748C8.94803 17.2276 10.5611 17.8697 12.3341 17.8697C14.1072 17.8697 15.7202 17.2276 16.8094 16.1748C17.2063 15.7912 17.4342 15.3324 17.4603 14.84C17.4861 14.355 17.3122 13.9111 17.0427 13.5532C16.5136 12.8506 15.5624 12.3946 14.5531 12.3946H10.1152Z"
    />
  </svg>
);

export default forwardRef(UserCircleIcon);
