import {
  useCallback,
  useMemo,
} from 'react';

import { Overlay } from '@mantine/core';

import { Chat as ChatModel } from '../../../features/chat/models';

import { useChatMessagesContext } from '../../../contexts/useChatMessagesContext';
import { useChatsContext } from '../../../contexts/useChatsContext';

import { ChatHeading } from './ChatHeading';
import { ChatInput } from './ChatInput';
import { ChatMessages } from './ChatMessages';

interface Props {
  chatId: string;
}

const Chat = ({
  chatId,
}: Props) => {
  const { chats } = useChatsContext();

  const chat: ChatModel | undefined = useMemo(
    () => chats.find((c) => c.id === chatId),
    [chats, chatId],
  );

  const {
    userInput,
    setUserInput,
    isAdamThinking,
    isAiChat,
    onMessageSubmit: _onMessageSubmit,
    isSendingLLMMessage,
  } = useChatMessagesContext();

  const headerTitle: string = useMemo(() => {
    if (!chat) return '';

    if (isAiChat) {
      return 'Adam';
    }

    // if is a user chat
    if (chat.user) {
      return `${chat.user.firstName} ${chat.user.lastName}`;
    }

    return chat.business.name;
  }, [chat, isAiChat]);

  const onMessageSubmit = useCallback(
    () => {
      _onMessageSubmit('adam_assistant');
    },
    [_onMessageSubmit],
  );

  return (
    <div className="flex h-full w-full flex-col overflow-hidden">
      <ChatHeading title={headerTitle} isAiChat={isAiChat} />
      <div className="flex flex-grow overflow-hidden flex-col p-md">
        <ChatMessages business={chat?.business} isAdamThinking={isAdamThinking} />
      </div>
      <div className="relative border-t border-blue-gray-50 bg-white">
        {!isAiChat && <Overlay opacity={0.03} blur={2} />}
        <ChatInput
          userInput={userInput}
          setUserInput={setUserInput}
          onMessageSubmit={onMessageSubmit}
          disabled={isSendingLLMMessage}
        />
      </div>
    </div>
  );
};

export { Chat };
