import { queryOptions } from '@tanstack/react-query';

import { fetchProductCategories } from '../api';

export const productCategoriesOptions = () => queryOptions({
  queryKey: ['product-categories'],
  queryFn: () => fetchProductCategories(),
  gcTime: 0,
  meta: {
    errorMessage: 'Error fetching product categories',
  },
});
