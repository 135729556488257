import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { observer } from 'mobx-react-lite';

import { useInfiniteQuery } from '@tanstack/react-query';

import { globalInboxes } from 'state/globalInboxes';

import { MessageIntent } from 'features/message/models/Message';
import { Thread } from 'models/Thread';

import { FetchThreadsProps } from '../apis';

import { InboxList, ThreadListTable } from '../components';
import { Page } from 'components/layout/Page/Page';

import { threadsOptions } from '../options';

interface Props {
  defaultUnreadOnly?: boolean;
  defaultIntent?: MessageIntent;
}

const InboxPage = ({ defaultUnreadOnly, defaultIntent }: Props) => {
  const { t } = useTranslation();

  const [threads, setThreads] = useState<Thread[]>([]);
  const [params, setParams] = useState<FetchThreadsProps>({
    unread_only: defaultUnreadOnly,
    intents: defaultIntent ? [defaultIntent] : [],
  });
  const {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery({
    ...threadsOptions(globalInboxes.selectedInboxId, params),
  });

  useEffect(() => {
    if (data) {
      setThreads(data.pages.flatMap((page) => page.result));
    }
  }, [data]);

  return (
    <Page
      pageTitle={t('inbox.title')}
    >
      <div className="flex-1 overflow-hidden flex gap-4">
        <div className="h-full w-[15%] 4xl:w-[10%] bg-white py-4 px-2 rounded-md 4xl:py-5 4xl:px-4 4xl:rounded-lg border border-light-grey shadow-blue-light-tint-drop-shadow">
          <InboxList
            filter={params}
            setFilter={setParams}
          />
        </div>
        <div className="flex-1 bg-white flex flex-col overflow-hidden gap-3 border border-light-grey shadow-blue-light-tint-drop-shadow rounded-md">
          {/* <MessageListTable /> */}
          <ThreadListTable
            threads={threads}
            setThreads={setThreads}
            loadMoreThreads={fetchNextPage}
            hasNextPage={hasNextPage}
            isThreadsLoading={isFetching}
            threadsFilter={params}
            setThreadsFilter={setParams}
            reload={refetch}
          />
        </div>
      </div>
    </Page>
  );
};

export default observer(InboxPage);
