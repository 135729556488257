import { useEffect, useRef, useState } from 'react';

import { twMerge } from 'tailwind-merge';

import * as Sentry from '@sentry/react';

import { MegaphoneIcon } from 'components/icon/outline';

const FeedbackButton = () => {
  const [feedback, setFeedback] = useState<ReturnType<typeof Sentry.getFeedback>>();
  // Read `getFeedback` on the client only, to avoid hydration errors during server rendering
  useEffect(() => {
    setFeedback(Sentry.getFeedback());
  }, []);

  const buttonRef = useRef<HTMLButtonElement>();
  const [isButtonRefAcquired, setIsButtonRefAcquired] = useState(false);
  useEffect(() => {
    setIsButtonRefAcquired(!!buttonRef.current);
  }, []);

  useEffect(() => {
    if (feedback && isButtonRefAcquired) {
      const unsubscribe = feedback.attachTo(buttonRef.current);
      return unsubscribe;
    }
    return () => {};
  }, [feedback, isButtonRefAcquired]);

  return (
    <button
      type="button"
      className={twMerge(
        'inline-flex items-center justify-center gap-2 relative flex-[0_0_auto] bg-white rounded-full border border-solid border-light-grey shadow-blue-light-tint-drop-shadow hover:shadow-blue-tint-drop-shadow',
        'pl-1.5 desktop:pl-2 pr-3 desktop:pr-4 py-1.5 desktop:py-2',
      )}
      ref={(el) => {
        buttonRef.current = el;
      }}
      onClick={() => {}}
    >
      <MegaphoneIcon
        className="size-[18px] desktop:size-5"
      />
      <div className={twMerge(
        'relative w-fit whitespace-nowrap',
        'font-font-screen-sm-regular font-[number:var(--font-screen-sm-regular-font-weight)] text-[length:var(--font-screen-sm-regular-font-size)] tracking-[var(--font-screen-sm-regular-letter-spacing)] leading-[var(--font-screen-sm-regular-line-height)] [font-style:var(--font-screen-sm-regular-font-style)]',
        'desktop:font-font-screen-nav-bar-medium desktop:font-[number:var(--font-screen-nav-bar-medium-font-weight)] desktop:text-[length:var(--font-screen-nav-bar-medium-font-size)] desktop:tracking-[var(--font-screen-nav-bar-medium-letter-spacing)] desktop:leading-[var(--font-screen-nav-bar-medium-line-height)] desktop:[font-style:var(--font-screen-nav-bar-medium-font-style)]',
      )}
      >
        Feedback
      </div>
    </button>
  );
};

export default FeedbackButton;
