import { ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import { ContentTheme } from '../types';

import { InformationCircleIcon } from 'components/icon/outline';
import { OverflowEllipsisParagraph } from 'components/ui/OverflowEllipsisParagraph';

import { contentThemeToTextColor } from '../utils';

interface Props {
  additionalInfo: ReactNode;
  contentTheme: ContentTheme;
}

const AdditionalInformation = ({ additionalInfo, contentTheme }: Props) => {
  if (!additionalInfo) return null;

  return (
    <OverflowEllipsisParagraph
      maxLines={1}
      isTooltipEnabled
      customTooltipContent={additionalInfo}
    >
      <InformationCircleIcon className={twMerge('aspect-square w-5', contentThemeToTextColor(contentTheme))} />
    </OverflowEllipsisParagraph>
  );
};

export default AdditionalInformation;
