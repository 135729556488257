import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { Metric } from '../types/metric';
import { TimePeriod } from '../types/timePeriod';

interface OrderMetricsStore {
  metric: Metric;
  setMetric: (metric: Metric) => void;

  timePeriod: TimePeriod;
  setTimePeriod: (timePeriod: TimePeriod) => void;
}

export const useOrderMetricsStore = create<OrderMetricsStore>()(
  persist(
    (set) => ({
      metric: Metric.FinalizationTime,
      setMetric: (metric) => set({ metric }),

      timePeriod: TimePeriod.Monthly,
      setTimePeriod: (timePeriod) => set({ timePeriod }),
    }),
    {
      name: 'order-metrics-storage',
    },
  ),
);
