import { debounce } from 'lodash';
import {
  action, makeObservable, observable, runInAction,
} from 'mobx';
import { makePersistable } from 'mobx-persist-store';

import { MessageChannel, MessageIntent } from '../../features/message/models/Message';
import { GetDocumentsQueryType } from '../../models/GetDocumentsQueryType';

import { genericErrorFeedback } from 'utils/errors';
import { httpGetV1 } from 'utils/xhr';

import { globalSseSources } from '../globalSseSources';

export class MessageIntents {
  unreadCounts: Record<MessageIntent, number> = {
    [MessageIntent.ORDER]: 0,
    [MessageIntent.OTHER]: 0,
  };

  totalUnreadCount: number = 0;

  isLoading?: boolean = false;

  constructor() {
    makeObservable(this, {
      unreadCounts: observable,
      totalUnreadCount: observable,
      isLoading: observable,
      load: action,
      _onNewMessageSseReceived: action,
    });
  }

  initPersistence = async () => {
    await makePersistable(this, {
      name: 'unread_counts',
      properties: [],
      storage: window.localStorage,
    });
  };

  _reset = () => {
    runInAction(() => {
      this.unreadCounts = {
        [MessageIntent.ORDER]: 0,
        [MessageIntent.OTHER]: 0,
      };
      this.totalUnreadCount = 0;
      this.isLoading = false;
    });
  };

  refresh = () => {
    this._reset();
    this.load()
      .catch(genericErrorFeedback('Error loading message intents'));
  };

  load = async () => {
    runInAction(() => {
      this.isLoading = true;
    });

    const totalUnreadCount = await httpGetV1('/chat/messages', {
      params: {
        unread_only: true,
        query_type: GetDocumentsQueryType.count,
        sources: [MessageChannel.IMAP],
      },
    }).then((response) => response.data.count);

    const intents = Object.values(MessageIntent);
    const counts = await Promise.all(intents.map((intent) => httpGetV1('/chat/messages', {
      params: {
        intent,
        unread_only: true,
        query_type: GetDocumentsQueryType.count,
      },
    }).then((response) => ({ intent, count: response.data.count })),
    ));

    runInAction(() => {
      this.totalUnreadCount = totalUnreadCount;

      this.unreadCounts = counts.reduce((acc, { intent, count }) => {
        acc[intent] = count;
        return acc;
      }, {} as Record<MessageIntent, number>);

      this.isLoading = false;
    });
  };

  _debouncedLoad = debounce(this.load, 2000);

  addSseSourcesHandler = () => {
    globalSseSources.addSourcesHandler(
      'messages',
      ['new_message'],
      this._onNewMessageSseReceived,
    );
  };

  // eslint-disable-next-line class-methods-use-this
  removeSseSourcesHandler = () => {
    globalSseSources.removeSourcesHandler('messages');
  };

  _onNewMessageSseReceived = () => {
    this._debouncedLoad();
  };
}
