import { memo } from 'react';

import { twMerge } from 'tailwind-merge';

import { TextInput } from '@mantine/core';

interface Props {
  label: string;
  value: string;
  readOnly?: boolean;
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
}

const TextWithLabel = memo(({
  label,
  value,
  readOnly = true,
  className,
  labelClassName,
  inputClassName,
}: Props) => (
  <TextInput
    label={label}
    value={value || ''}
    readOnly={readOnly}
    variant="unstyled"
    className={className}
    classNames={{
      label: twMerge('text-gray-400', labelClassName),
      input: twMerge('text-gray-900', inputClassName),
    }}
  />
));

export default TextWithLabel;
