import { twMerge } from 'tailwind-merge';

interface Props extends React.HTMLAttributes<HTMLTableSectionElement> {
  children: React.ReactNode;
}

const InfiniteScrollTableThead = ({ children, ...props }: Props) => (
  <thead
    {...props}
    className={twMerge(
      'sticky top-0 z-10 bg-white',
      props.className,
    )}
  >
    {children}
  </thead>
);

export default InfiniteScrollTableThead;
