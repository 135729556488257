import { useTranslation } from 'react-i18next';

import { TFunction } from 'i18next';
import { twMerge } from 'tailwind-merge';

import { ROUTES } from 'config/routes';

import { SidebarNavItem } from './Sidebar.types';

import {
  CommandLineIcon,
  ContactsIcon,
  HomeIcon, InboxesIcon, LearningIcon, ShopIcon, ShoppingBagIcon, ShoppingCartIcon,
  TeamsIcon,
} from 'components/icon/outline';

import SidebarContentItem from './SidebarContentItem/SidebarContentItem';

interface Props {
  isSidebarExpanded: boolean;
}

const getPages = (t: TFunction): SidebarNavItem[] => ([
  {
    icon: HomeIcon,
    label: t('sidebar.home'),
    href: ROUTES.HOME,
  },
  {
    icon: InboxesIcon,
    label: t('sidebar.inbox'),
    href: ROUTES.INBOX({}),
  },
  {
    icon: ShoppingCartIcon,
    label: t('sidebar.orders'),
    href: ROUTES.ORDERS,
  },
  {
    icon: ShoppingBagIcon,
    label: t('sidebar.products'),
    href: ROUTES.PRODUCTS,
  },
  {
    icon: ShopIcon,
    label: t('sidebar.customers'),
    href: ROUTES.CUSTOMERS,
  },
  {
    icon: ContactsIcon,
    label: t('sidebar.contacts'),
    href: ROUTES.SUBJECTS,
  },
  {
    icon: TeamsIcon,
    label: t('sidebar.teamsMembers'),
    href: ROUTES.TEAM_MEMBERS,
  },
  {
    icon: LearningIcon,
    label: t('sidebar.instructions'),
    href: ROUTES.INSTRUCTIONS,
  },
  {
    icon: CommandLineIcon,
    label: t('sidebar.logs.title'),
    basePath: ROUTES.AUDIT,
    children: [
      {
        label: t('sidebar.logs.erpSyncLogs'),
        href: ROUTES.AUDIT_ERP_SYNC_LOGS,
      },
    ],
  },
]);

const SidebarContent = ({ isSidebarExpanded }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={twMerge(
      'inline-flex flex-col gap-2 relative flex-[0_0_auto] w-full',
      isSidebarExpanded ? 'items-start gap-2' : 'items-center',
    )}
    >
      {getPages(t).map((page) => (
        <SidebarContentItem
          key={page.label}
          item={page}
          isSidebarExpanded={isSidebarExpanded}
        />
      ))}
    </div>
  );
};

export default SidebarContent;
