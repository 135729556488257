import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Prompt } from 'features/instruction/models';

import { Action, TypeRef, Workflow } from 'features/instruction/types';

import { InstructionContext } from 'contexts/useInstructionContext';

import { useCreatePrompt } from 'hooks/useCreatePrompt';

import { Page } from 'components/layout/Page/Page';
import {
  ChooseInstructionType,
  InstructionDetail,
} from 'components/steps/instructions';

import { workflowToPipeline } from 'features/instruction/utils';

const steps = [
  { component: ChooseInstructionType },
  { component: InstructionDetail },
];

const CreateInstructionPage = () => {
  const { t } = useTranslation();

  const { isLoading: isCreatePromptLoading, createPrompt } = useCreatePrompt();

  const [currentStep, setCurrentStep] = useState(1);
  const [selectedWorkflow, setSelectedWorkflow] = useState<
  Workflow | null>(Workflow.ORDER); // TODO: reset to null
  const [selectedAction, setSelectedAction] = useState<Action | null>(Action.CREATE);
  const [instructionName, setInstructionName] = useState('Test');

  const [prompt, setPrompt] = useState<Prompt | null>({
    id: '',
    name: instructionName,
    boundTypeSpecs: [{
      // TODO: support multiple workflows
      typeRef: TypeRef.ORDER_DRAFT,
      fields: [],
    }],
    customerIds: [],
  });

  const handleNextStep = () => setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1));
  const handlePreviousStep = () => setCurrentStep((prev) => Math.max(prev - 1, 0));

  const StepComponent = steps[currentStep].component;

  const stepProps = {
    onPrevious: handlePreviousStep,
    ...(currentStep === 0 && {
      onNext: handleNextStep,
    }),
    ...(currentStep === 1 && {
      buttonTitle: 'Create',
      onButtonClick: () => {
        createPrompt(prompt, instructionName, workflowToPipeline(selectedWorkflow));
      },
      isOuterLoading: isCreatePromptLoading,
    }),
  };

  const contextValue = useMemo(
    () => ({
      selectedAction,
      setSelectedAction,
      selectedWorkflow,
      setSelectedWorkflow,
      instructionName,
      setInstructionName,
      prompt,
      setPrompt,
    }),
    [selectedAction, selectedWorkflow, instructionName, prompt],
  );

  return (
    <InstructionContext.Provider value={contextValue}>
      <Page
        pageTitle={t('instruction.create.title')}
        pageDescription={t('instruction.create.description')}
      >
        <StepComponent {...stepProps} />
      </Page>
    </InstructionContext.Provider>
  );
};

export default CreateInstructionPage;
