import { queryOptions } from '@tanstack/react-query';

import { MINUTE } from 'constants/miliseconds';

import { SalesMetric } from '../types/metric';
import { TimePeriod } from '../types/timePeriod';

import { fetchTopTierCustomers } from '../apis';

export const topTierCustomersOptions = (
  date: Date,
  period: TimePeriod,
  metric: SalesMetric,
  limit: number = 10,
) => queryOptions({
  queryKey: ['top-tier-customers', date, period, metric, limit],
  queryFn: () => fetchTopTierCustomers(date, period, metric, limit),
  staleTime: 30 * MINUTE, // cache for 30 minutes
  meta: {
    errorMessage: 'Error fetching top tier customers',
  },
});
