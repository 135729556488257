import { startOfToday, startOfWeek, subWeeks } from 'date-fns';

import { queryOptions } from '@tanstack/react-query';

import { MINUTE } from 'constants/miliseconds';

import { fetchOrderCounts } from '../apis';

export const orderCountsOptions = (assigneeId?: string) => queryOptions({
  queryKey: ['orderCounts', assigneeId],
  queryFn: () => {
    const today = startOfToday();

    // set fromTime to monday of the last week
    const fromTime = startOfWeek(subWeeks(today, 1));

    return fetchOrderCounts({
      from_time: fromTime.toISOString(),
      granularity: '24h',
      assignee_user_id: assigneeId,
    });
  },
  staleTime: 5 * MINUTE, // cache for 5 minutes
  meta: {
    errorMessage: 'Error fetching order counts',
  },
});
