import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { toJS } from 'mobx';

import { globalChats } from '../state/globalChats';

import { Chat } from '../features/chat/models';

import { genericErrorFeedback } from '../utils/errors';

type ChatsContextType = {
  chats: Chat[];
  setChats: React.Dispatch<React.SetStateAction<Chat[]>>;
  loadChats: () => Promise<void>;
  updateDataFilter: (filter: 'private' | 'adam') => Promise<void>;
  isLoading: boolean;
  isInitialLoading: boolean;
};
const ChatsContext = createContext<ChatsContextType | undefined>(undefined);

type ChatProviderProps = {
  children: ReactNode;
};

const ChatsProvider: React.FC<ChatProviderProps> = ({
  children,
}: ChatProviderProps) => {
  const [chats, setChats] = useState<Chat[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);

  const loadChats = useCallback(async (reset?: boolean) => {
    setIsLoading(true);
    if (reset) {
      setIsInitialLoading(true);
    }
    globalChats
      .loadChats(reset)
      .then(() => setChats(toJS(globalChats.chats)))
      .catch(genericErrorFeedback('Failed to load chats'))
      .finally(() => {
        setIsLoading(false);
        setIsInitialLoading(false);
      });
  }, []);

  const updateDataFilter = useCallback(async (filter: 'private' | 'adam') => {
    globalChats.updateDataFilter(filter);
    await loadChats(true);
  }, [loadChats]);

  useEffect(() => {
    setIsInitialLoading(true);
    loadChats().finally(() => setIsInitialLoading(false));
  }, [loadChats]);

  const contextValue = useMemo(
    () => ({
      chats,
      loadChats,
      setChats,
      isLoading,
      isInitialLoading,
      updateDataFilter,
    }),
    [chats, isInitialLoading, isLoading, loadChats, updateDataFilter],
  );

  return (
    <ChatsContext.Provider value={contextValue}>
      {children}
    </ChatsContext.Provider>
  );
};

const useChatsContext = () => {
  const context = useContext(ChatsContext);

  if (context === undefined) {
    throw new Error('Must be wrapped by ChatsContext provider.');
  }

  return context;
};

export { ChatsContext, ChatsProvider, useChatsContext };
