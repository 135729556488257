import { infiniteQueryOptions } from '@tanstack/react-query';

import { fetchSubjects, FetchSubjectsParams } from '../apis';

export const subjectsOptions = (params?: FetchSubjectsParams) => infiniteQueryOptions({
  queryKey: ['subjects', params?.query ?? ''],
  queryFn: ({ pageParam = '' }) => fetchSubjects({
    ...params,
    cursor: pageParam,
    limit: params?.limit ?? 20,
  }),
  initialPageParam: '',
  getNextPageParam: (lastPage) => lastPage.cursor || null,
  gcTime: 0,
  meta: {
    errorMessage: 'Error fetching subjects',
  },
});
