import { Input as MantineInput } from '@mantine/core';

import LabelWrapper from '../LabelWrapper';

interface Props {
  label: string;
  value: string;
  setValue?: React.Dispatch<React.SetStateAction<string>>;
  readOnly?: boolean;
}

const Input = ({
  label, value, setValue, readOnly = true,
}: Props) => (
  <LabelWrapper label={label}>
    <MantineInput
      value={value}
      onChange={(e) => setValue?.(e.target.value)}
      readOnly={readOnly}
      w="100%"
    />
  </LabelWrapper>
);

export default Input;
