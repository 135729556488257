import { useLocation, useParams } from 'react-router-dom';

import { ThreadByIdScreenState } from '../types';

import { ThreadProviderType, ThreadsProvider } from 'contexts/useThreadsContext';

import { ProtectedScreen } from 'components/ui/ProtectedScreen';

import { ThreadByIdPage } from '../pages';

const ThreadByIdScreen = () => {
  const { threadId } = useParams();
  const location = useLocation();

  const hasState = (state: unknown): state is ThreadByIdScreenState => state !== null && typeof state === 'object'
      && 'threads' in state && 'currentIndex' in state;

  const state = hasState(location.state) ? location.state : null;

  return (
    <ProtectedScreen>
      <ThreadsProvider
        providerType={ThreadProviderType.BY_THREAD_ID_WITH_COUNT}
        threadId={threadId}
      >
        <ThreadByIdPage state={state} />
      </ThreadsProvider>
    </ProtectedScreen>
  );
};

export default ThreadByIdScreen;
