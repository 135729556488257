import { useCallback, useState } from 'react';

import { httpPatchV1 } from '../utils/xhr';

const useMarkThreadsAsUnread = () => {
  const [isLoading, setIsLoading] = useState(false);

  const markThreadsAsUnread = useCallback((ids: string[]): Promise<void> => {
    setIsLoading(true);
    return httpPatchV1('/threads/unread', { ids })
      .then(() => {})
      .catch((error) => {
        console.error('failed to mark threads as unread', error);
        return Promise.reject(error);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return { isLoading, markThreadsAsUnread };
};

export { useMarkThreadsAsUnread };
