import { Avatar, AvatarProps } from '@mantine/core';

import { User } from 'features/user/models';

import { getUserInitials } from 'features/user/utils/user';

interface Props extends AvatarProps {
  user: User;
}

const UserInitialsAvatar = ({ user, ...props }: Props) => (
  <Avatar
    name={getUserInitials(user)}
    color="initials"
    {...props}
  />
);

export default UserInitialsAvatar;
