import { Expose } from 'class-transformer';

enum SyncModel {
  Product = 'product',
  Customer = 'customer',
  Order = 'order',
}

enum SyncRunStatus {
  Ok = 'ok',
  Failed = 'failed',
}

enum SyncDirection {
  FromERP = 'from_erp',
  ToERP = 'to_erp',
  FromToERP = 'from_to_erp',
}

class SyncLog {
  @Expose({ name: 'id' })
    id: string;

  @Expose({ name: 'run_id' })
    runId: string;

  @Expose({ name: 'lro_id' })
    lroId: string;

  @Expose({ name: 'business_id' })
    businessId: string;

  @Expose({ name: 'model' })
    model: SyncModel;

  @Expose({ name: 'sync_direction' })
    syncDirection: SyncDirection;

  @Expose({ name: 'status' })
    status: SyncRunStatus;

  @Expose({ name: 'failed_index' })
    failedIndex?: number;

  @Expose({ name: 'failed_id' })
    failedId?: string;

  @Expose({ name: 'failed_external_id' })
    failedExternalId?: string;

  @Expose({ name: 'failed_message' })
    failedMessage?: string;

  @Expose({ name: 'additional_fields' })
    additionalFields: Record<string, any>;

  @Expose({ name: 'created_at' })
    createdAt: Date;
}

export {
  SyncDirection, SyncLog, SyncModel, SyncRunStatus,
};
