import { memo, useEffect, useMemo } from 'react';

import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  isSameDay,
} from 'date-fns';

import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';

import { Message, MessageContextUtils } from 'features/message/models/Message';
import { Subject } from 'features/user/models';
import { Organization } from 'models/Business';

import { useMessagesContext } from 'contexts/useMessagesContext';
import { usePlayRecordingContext } from 'contexts/usePlayRecordingIndex';

import { useMarkMessageAsRead } from 'hooks/useMarkMessageAsRead';

import { AttachmentList } from 'components/chat/Chat/ChatMessage/AttachmentList/AttachmentList';
import { ImageMessage } from 'components/chat/Chat/ChatMessage/ImageMessage';
import { OrderBody } from 'components/chat/Chat/ChatMessage/MessageBody/OrderBody';
import { RecordingMessage } from 'components/chat/Chat/ChatMessage/RecordingMessage';

import { decodeEntities } from 'features/message/utils/message';
import { formatDate, isZeroTime } from 'utils/dateTime';
import { isZeroId } from 'utils/objectId';

interface Props {
  messages: Message[];
  customer?: Organization;
  subject: Subject;
  onManageSubjectButtonClick?: () => void;
}

const OrderMessages = memo(({
  messages,
  customer,
  subject,

  onManageSubjectButtonClick,
}: Props) => {
  const { setSelectedMessageId } = useMessagesContext();
  const { setIsRecordingAvailable } = usePlayRecordingContext();
  const message = useMemo(() => messages[0], [messages]);

  const { markMessagesAsRead } = useMarkMessageAsRead();

  useEffect(() => {
    if (message && isZeroTime(message.readAt)) {
      markMessagesAsRead([message.id]);
    }
  }, [message, markMessagesAsRead]);

  const customerOrSubjectName = useMemo(() => {
    if (customer && customer?.name) return customer?.name;

    if (subject) {
      const name = `${subject.firstName || ''} ${subject.lastName || ''}`;

      if (name.replace(/\s/g, '').length > 0) return name;
    }

    return '';
  }, [customer, subject]);

  const customerOrSubjectContactMedium = useMemo(() => {
    const customerContactMedium = (
      customer?.email
      || (customer?.emails || [])[0]
      || (customer?.phones || [])[0]?.number
    );

    if (customerContactMedium) return customerContactMedium;

    const subjectContactMedium = subject?.GetEmails()?.join(', ') || subject?.GetPhones()?.join(', ');

    if (subjectContactMedium) return subjectContactMedium;

    return '';
  }, [customer, subject]);

  const subjectNeedsCustomerAssignment = useMemo(() => isZeroId(subject?.subjectData?.organizationId), [subject]);

  const showRecording = useMemo(
    () => MessageContextUtils.audioAttachments(message?.context).length > 0,
    [message],
  );
  const showImage = useMemo(
    () => MessageContextUtils.documentAttachments(message?.context).length > 0,
    [message],
  );

  const showAttachments = useMemo(
    () => showRecording || showImage,
    [showRecording, showImage],
  );

  const messageDatetimeText: string = useMemo(() => {
    if (!message?.createdAt) return '';

    const messageCreatedAt = new Date(message?.createdAt);
    const now = new Date();

    const diffInSeconds = differenceInSeconds(now, messageCreatedAt);
    const diffInMinutes = differenceInMinutes(now, messageCreatedAt);
    const diffInHours = differenceInHours(now, messageCreatedAt);
    const diffInDays = differenceInDays(now, messageCreatedAt);

    // Generate relative time text if enabled
    let relativeText = '';
    if (diffInSeconds < 60) {
      relativeText = 'Just now';
    } else if (diffInMinutes < 60) {
      relativeText = `${diffInMinutes}m ago`;
    } else if (diffInHours < 24 && isSameDay(now, messageCreatedAt)) {
      relativeText = `${diffInHours}h ago`;
    } else if (diffInDays < 8) {
      relativeText = `${diffInDays}d ago`;
    }

    // Format the full date
    const formattedDate = formatDate(messageCreatedAt);

    // Combine date with relative time if available
    return relativeText
      ? `${formattedDate} (${relativeText})`
      : formattedDate;
  }, [message]);

  useEffect(() => {
    if (message) {
      setIsRecordingAvailable(MessageContextUtils.audioAttachments(message?.context).length > 0);
    }
  }, [setIsRecordingAvailable, message]);

  useEffect(() => {
    if (message) {
      setSelectedMessageId(message.id);
    }
  }, [setSelectedMessageId, message]);
  return (
    <div className="flex-1 overflow-y-auto w-full px-2xl py-xl">
      <div className="flex justify-between">
        <div className="grid grid-cols-[auto_1fr] gap-3 items-start">
          <p className="text-gray-400 text-sm">From</p>

          {
              customerOrSubjectName ? (
                <div className="flex items-center gap-1">
                  <p className="font-semibold text-sm">{customerOrSubjectName}</p>
                  <p className="text-gray-400 text-xs">
                    {'<'}
                    {customerOrSubjectContactMedium}
                    {'>'}
                  </p>
                </div>
              ) : (
                <div>
                  <p className="font-semibold text-sm">{customerOrSubjectContactMedium}</p>
                </div>
              )
            }

          <p className="text-gray-400 text-sm">Subject</p>
          <div className="space-y-1 font-semibold text-sm">
            {message?.context?.subject || 'No subject'}
          </div>
        </div>

        <div className="flex">
          <p className="text-xs text-neutral-200 underline">
            {messageDatetimeText}
          </p>
        </div>
      </div>

      {subjectNeedsCustomerAssignment && onManageSubjectButtonClick && (
      <div className="mt-lg flex h-10 w-full items-center justify-between border-l-2 border-warning-border bg-warning-bg px-lg text-warning-title">
        <div className="flex items-center gap-x-md">
          <ExclamationTriangleIcon className="aspect-square h-5 text-warning-button" />
          Unknown contact detected
        </div>
        <div>
          <button
            type="button"
            className="px-1 text-warning-description"
            onClick={onManageSubjectButtonClick}
          >
            Manage
          </button>
        </div>
      </div>
      )}

      {message?.context?.subject && (
        <div className="pt-4 text-title-md font-semibold lg:text-title-lg hidden">
          {decodeEntities(message?.context?.subject)}
        </div>
      )}

      <div className="divide-y-4 pt-4 text-content-smd text-lg lg:text-content-md">
        {message && <OrderBody message={message} />}
      </div>

      {showAttachments && (
        <>
          <div className="mt-8 border-t border-blue-gray-100 pt-2 font-semibold">
            Attachments
          </div>
          {showRecording && (
            <div className="pt-3">
              {message && <RecordingMessage message={message} />}
            </div>
          )}

          {showImage && (
            <>
              <div className="pt-3 flex justify-center">
                <p className="text-[0.6rem] italic text-gray-400 text-center max-w-[400px]">
                  The document scanning feature is still in its alpha version. Please double check the
                  output and report issues to
                  {' '}
                  <a href="mailto:support@hoshii.ai">
                    support@hoshii.ai
                  </a>
                  .
                </p>
              </div>
              <div className="pt-3">
                {message && <ImageMessage message={message} />}
              </div>
            </>
          )}

          <div className="pt-3">
            <AttachmentList attachments={message?.context?.attachments || []} />
          </div>
        </>
      )}
    </div>
  );
});

export default OrderMessages;
