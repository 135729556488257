import { create } from 'zustand';

import { ProcessOrderDraftsStore } from './types';

import { createAnnotationSlice } from './annotationSlice';
import { createBusinessSettingsSlice } from './businessSettingsSlice';
import { createErrorsAndWarningsSlice } from './errorsAndWarningsSlice';
import { createImageSlice } from './imageSlice';
import { createKeywordSlice } from './keywordSlice';
import { createMagicPenSlice } from './magicPenSlice';
import { createMessageSlice } from './messageSlice';
import { createNavigationSlice } from './navigationSlice';
import { createOrderPricesSlice } from './orderPricesSlice';
import { createOrderSlice } from './orderSlice';
import { createProductSlice } from './productSlice';
import { createSchemaSlice } from './schemaSlice';

export const useProcessOrderDraftsStore = create<ProcessOrderDraftsStore>((...args) => ({
  ...createAnnotationSlice(...args),
  ...createBusinessSettingsSlice(...args),
  ...createErrorsAndWarningsSlice(...args),
  ...createImageSlice(...args),
  ...createKeywordSlice(...args),
  ...createMagicPenSlice(...args),
  ...createMessageSlice(...args),
  ...createNavigationSlice(...args),
  ...createOrderSlice(...args),
  ...createOrderPricesSlice(...args),
  ...createProductSlice(...args),
  ...createSchemaSlice(...args),
}));
