import { observer } from 'mobx-react-lite';
import { twMerge } from 'tailwind-merge';

import { globalAlertDataList } from 'state/globalAlertDataList';

import AlertListItem from './AlertListItem';

const AlertList = () => (
  <div
    className={twMerge(
      'absolute right-0 top-[70px] z-[500] space-y-2 h-fit max-h-[40vh] overflow-y-auto',
      globalAlertDataList.alerts.length === 0 && 'hidden',
    )}
  >
    {globalAlertDataList.alerts.map((alert) => (
      <AlertListItem
        key={alert.id}
        alert={alert}
      />
    ))}
  </div>
);

export default observer(AlertList);
