import { ComponentType, useState } from 'react';

import { ConfirmModalBase } from 'components/modal';

interface InjectedProps {
  title: string;
  description: string;

  onConfirm: () => void;
}

const ConfirmModalWrapperBase = <TProps extends object>(
  Component: ComponentType<TProps>,
): ComponentType<TProps & InjectedProps> => {
  function WrappedComponent({
    title, description, onConfirm,
    ...props
  }: InjectedProps & TProps) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    return (
      <>
        <Component
          {...props as TProps}
          setConfirmationModalOpen={setIsModalOpen}
        />

        <ConfirmModalBase
          open={isModalOpen}
          setOpen={setIsModalOpen}
          title={title}
          description={description}
          onConfirm={onConfirm}
        />
      </>
    );
  }

  return WrappedComponent;
};

export default ConfirmModalWrapperBase;
