import { observer } from 'mobx-react-lite';

import {
  ArrowLeftStartOnRectangleIcon,
} from '@heroicons/react/24/outline';
import { Menu } from '@mantine/core';

import { globalUser } from 'state/globalUser';

import { Menu as MenuComponent } from 'components/ui';

const UserButton = () => (
  <MenuComponent withArrow arrowSize={14}>
    <Menu.Target>
      <div className="size-[30px] desktop:size-9 gap-0.5 bg-purple-highlight-2 rounded-[50px] shadow-[1px_3px_10px_#68adff1a] flex items-center justify-center relative cursor-pointer">
        <div className="w-10 h-10 gap-[11px] mt-[-2.00px] mb-[-2.00px] ml-[-2.00px] mr-[-2.00px] flex items-center justify-center relative">
          <div className="flex-col w-[31px] h-[31px] gap-2.5 p-1 rounded-full flex items-center justify-center relative">
            <div className="relative w-fit font-font-screen-nav-bar-medium font-[number:var(--font-screen-nav-bar-medium-font-weight)] text-white text-[length:var(--font-screen-nav-bar-medium-font-size)] text-center tracking-[var(--font-screen-nav-bar-medium-letter-spacing)] leading-[var(--font-screen-nav-bar-medium-line-height)] whitespace-nowrap [font-style:var(--font-screen-nav-bar-medium-font-style)]">
              {globalUser.firstName?.charAt(0) || globalUser.lastName?.charAt(0)}
            </div>
          </div>
        </div>
      </div>
    </Menu.Target>
    <Menu.Dropdown>
      <Menu.Item
        onClick={globalUser.signOut}
        leftSection={<ArrowLeftStartOnRectangleIcon width={22} height={22} style={{ stroke: 'rgba(107, 114, 128, 1)' }} />}
      >
        Sign out
      </Menu.Item>
    </Menu.Dropdown>
  </MenuComponent>
);

export default observer(UserButton);
