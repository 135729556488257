import { useCallback, useState } from 'react';

import { faDisplay, faGear, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, Select, Switch } from '@mantine/core';

import { ProductSortingColumn, SortExtractedProductsBy } from 'features/product/types/product';

import { Button } from 'components/ui/Button';

import { useSettingsStore } from 'features/order/store/settingsStore';

const ProductDetailsSettingsPopover = () => {
  const sortingColumn = useSettingsStore((state) => state.sortingColumn);
  const filterEnabledProducts = useSettingsStore((state) => state.filterEnabledProducts);
  const sortExtractedProductsBy = useSettingsStore((state) => state.sortExtractedProductsBy);
  const setSortingColumn = useSettingsStore((state) => state.setSortingColumn);
  const setFilterEnabledProducts = useSettingsStore((state) => state.setFilterEnabledProducts);
  const setSortExtractedProductsBy = useSettingsStore((state) => state.setSortExtractedProductsBy);

  const [opened, setOpened] = useState<boolean>(false);
  const [sortingColumn_, setSortingColumn_] = useState<string>(sortingColumn || 'created_at');
  const [filterEnabledProducts_, setFilterEnabledProducts_] = useState<boolean>(filterEnabledProducts);
  const [sortExtractedProductsBy_, setSortExtractedProductsBy_] = useState<string>(sortExtractedProductsBy || 'default');

  const onFilterEnabledProductsSwitchChange_ = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterEnabledProducts_(event.currentTarget.checked);
  }, []);

  const onApplyClick = useCallback(() => {
    setSortingColumn(sortingColumn_ as ProductSortingColumn);
    setFilterEnabledProducts(filterEnabledProducts_);
    setSortExtractedProductsBy(sortExtractedProductsBy_ as SortExtractedProductsBy);
    setOpened(false);
  }, [sortingColumn_, filterEnabledProducts_, sortExtractedProductsBy_, setSortingColumn,
    setFilterEnabledProducts, setSortExtractedProductsBy]);

  return (
    <Popover
      position="bottom-start"
      withArrow
      shadow="md"
      clickOutsideEvents={['mouseup', 'touchend']}
      transitionProps={{ transition: 'pop', duration: 200 }}
      opened={opened}
      onChange={setOpened}
    >
      <Popover.Target>
        <button
          type="button"
          className="flex items-center gap-1 text-sm bg-gray-200 hover:bg-gray-300/80 px-3 py-1 rounded"
          onClick={() => setOpened(true)}
        >
          <FontAwesomeIcon icon={faGear} />
          Settings
        </button>
      </Popover.Target>
      <Popover.Dropdown className="">
        <div className="flex items-center space-x-2 text-sm text-muted-foreground text-gray-400 pb-2">
          <FontAwesomeIcon icon={faSearch} className="w-4 h-4" />
          <span className="font-medium">Search Options</span>
        </div>
        <div className="space-y-3">
          <Select
            label="Sort by"
            data={[{ label: 'ID or SKU', value: 'id_or_sku' },
              { label: 'Name', value: 'name' }, { label: 'Creation date', value: 'created_at' }]}
            value={sortingColumn_}
            onChange={setSortingColumn_}
            comboboxProps={{ withinPortal: false }}
            size="xs"
          />
          <Switch
            checked={filterEnabledProducts_}
            onChange={onFilterEnabledProductsSwitchChange_}
            labelPosition="left"
            label="Show only enabled products"
            size="xs"
          />
        </div>

        <hr className="my-3" />

        <div className="flex items-center space-x-2 text-sm text-muted-foreground text-gray-400 pb-2">
          <FontAwesomeIcon icon={faDisplay} className="w-4 h-4" />
          <span className="font-medium">Display Options</span>
        </div>

        <Select
          label="Sort extracted products by"
          data={[{ label: 'Default', value: 'default' },
            { label: 'Score', value: 'score' }, { label: 'Error status', value: 'error_status' }]}
          value={sortExtractedProductsBy_}
          onChange={setSortExtractedProductsBy_}
          comboboxProps={{ withinPortal: false }}
          size="xs"
        />

        <Button
          title="Apply"
          onClick={onApplyClick}
          className="w-full mt-3"
        />
      </Popover.Dropdown>
    </Popover>
  );
};

export default ProductDetailsSettingsPopover;
