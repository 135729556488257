import { useTranslation } from 'react-i18next';

import { format } from 'date-fns';
import { TFunction } from 'i18next';

import { useQuery } from '@tanstack/react-query';

import { BusinessSales } from 'models/Analytics';

import { SalesMetric } from 'features/analytics/types/metric';
import { TimePeriod } from 'features/analytics/types/timePeriod';

import { AreaChart } from 'components/charts';
import { InformationIconWithTooltip } from 'components/ui';

import { capitalizeFirstLetter } from 'utils/strings';

import { businessSalesOptions } from 'features/analytics/queryOptions';
import { useAnalyticsStore } from 'features/analytics/stores';

const formatData = (
  data: BusinessSales[],
  period: TimePeriod,
  metric: SalesMetric,
) => {
  if (!data) return [];

  // Sort data by saleDate in ascending order
  const sortedData = data.sort((a, b) => new Date(a.saleDate).getTime() - new Date(b.saleDate).getTime());

  if (period === TimePeriod.Monthly) {
    // Format to month name
    return sortedData.map((item) => ({
      x: format(item.saleDate, 'MMM'),
      y: metric === SalesMetric.TotalProductsSold ? item.totalQuantitySum : item.totalRevenueSum,
    }));
  }

  // Format to Month-Day
  return sortedData.map((item) => ({
    x: format(item.saleDate, 'MMM-dd'),
    y: metric === SalesMetric.TotalProductsSold ? item.totalQuantitySum : item.totalRevenueSum,
  }));
};

const title = (metric: SalesMetric, t: TFunction) => (
  <div className="flex items-center gap-2">
    <p>
      {t('dashboard.salesTrends.title')}
    </p>
    <InformationIconWithTooltip
      iconClassName="w-5 h-5 text-gray-500 hover:text-gray-700"
      label={(
        <div className="max-w-[300px] whitespace-normal">
          {metric === SalesMetric.TotalProductsSold ? (
            <div className="space-y-3">
              <div className="grow shrink basis-0 text-[#191919]/75 text-sm font-normal font-['Inter']">
                {t('dashboard.salesTrends.productsSold.description')}
              </div>
              <div className="self-stretch text-[#232d42]/60 text-[13px] font-normal font-['Inter']">
                {t('dashboard.salesTrends.productsSold.note')}
              </div>
            </div>
          ) : (
            <div className="space-y-3">
              <div className="grow shrink basis-0 text-[#191919]/75 text-sm font-normal font-['Inter']">
                {t('dashboard.salesTrends.productsSold.note')}
              </div>
            </div>
          )}
        </div>
      )}
    />
  </div>
);

const RevenueLineChart = () => {
  const { t } = useTranslation();

  const timePeriod = useAnalyticsStore((state) => state.salesTrendsTimePeriod);
  const setTimePeriod = useAnalyticsStore((state) => state.setSalesTrendsTimePeriod);
  const metric = useAnalyticsStore((state) => state.salesTrendsMetric);
  const setMetric = useAnalyticsStore((state) => state.setSalesTrendsMetric);

  const {
    data: businessSales,
    isFetching,
  } = useQuery({
    ...businessSalesOptions(timePeriod),
    select: (data) => formatData(data, timePeriod, metric),
  });

  const subtitle = () => (
    <span className="flex items-center w-fit">
      {capitalizeFirstLetter(timePeriod)}
      {' '}
      {capitalizeFirstLetter(metric === SalesMetric.TotalProductsSold ? t('dashboard.salesTrends.metric.productsSold') : t('dashboard.salesTrends.metric.revenue'))}
      {' '}
      trends
    </span>
  );

  const tooltipFormatter = (value: any) => {
    if (metric === SalesMetric.TotalProductsSold) {
      return [`${value.toLocaleString()} products`];
    }

    return [`${value.toLocaleString()} CHF`];
  };

  return (
    <AreaChart
      data={businessSales}
      xDataKey="x"
      yDataKey="y"
      isLoading={isFetching}
      title={title(metric, t)}
      subtitle={subtitle()}
      chartDivClassName="h-[350px]"
      rightSelectOptions={[
        { value: TimePeriod.Daily, label: t('timePeriod.daily') },
        { value: TimePeriod.Weekly, label: t('timePeriod.weekly') },
        { value: TimePeriod.Monthly, label: t('timePeriod.monthly') },
      ]}
      selectedRightOptionValue={timePeriod}
      setRightOption={setTimePeriod}
      leftSelectOptions={[
        { value: SalesMetric.TotalProductsSold, label: t('dashboard.salesTrends.metric.productsSold') },
        { value: SalesMetric.TotalRevenue, label: t('dashboard.salesTrends.metric.revenue') },
      ]}
      selectedLeftOptionValue={metric}
      setLeftOption={setMetric}
      toolTipFormatter={tooltipFormatter}
    />
  );
};

export default RevenueLineChart;
