import { isZeroId } from 'utils/objectId';
import { httpPostV1 } from 'utils/xhr';

export const confirmOrderDrafts = async (id: string, groupId?: string) => {
  let timeToFinalize = localStorage.getItem(`${id}/timeToFinalize`);
  console.log('timeToFinalize', timeToFinalize);
  if (!timeToFinalize) {
    timeToFinalize = '0';
  }
  if (groupId && !isZeroId(groupId)) {
    return httpPostV1(`/orders/drafts/group/${groupId}/confirm`, { groupId, timeToFinalize });
  }
  return httpPostV1(`/orders/drafts/${id}/confirm`, { id, timeToFinalize });
};
