import { Fragment, useMemo } from 'react';

import { uniq } from 'lodash';
import { twMerge } from 'tailwind-merge';

import { AudioKeyword, TextKeyword } from 'models/Workflow';

import { Keyword as ContextKeyword } from 'features/order/types/keyword';

import { isValidKeyword } from 'features/order/utils/keyword';

import { useProcessOrderDraftsStore } from 'features/order/store/process-order-drafts';

type Keyword = AudioKeyword | TextKeyword;

type Position = {
  position: number;
  type: 'start' | 'end';
  keyword: Keyword;
};

type Segment = {
  text: string;
  keywords: Keyword[];
  isNewline: boolean;
};

interface Props {
  fullText: string;
  keywords: Keyword[];
  messageId: string;
}

const isHoveredKeyword = (segment: Segment, hoveredKeyword: ContextKeyword) => (
  (hoveredKeyword !== null && (hoveredKeyword.fieldIds.length > 0 || hoveredKeyword.fieldPath))
        && (segment.keywords || []).some((kw) => (
          (hoveredKeyword.fieldIds.length > 0 ? hoveredKeyword.fieldIds.includes(kw.fieldId) : true)
            && (hoveredKeyword.fieldPath ? hoveredKeyword.fieldPath === kw.path : true)
        ))
);

const TextHighlighter = ({ fullText = '', keywords = [], messageId }: Props) => {
  const scrollToKeyword = useProcessOrderDraftsStore((state) => state.scrollToKeyword);
  const leftHoveredKeyword = useProcessOrderDraftsStore((state) => state.leftHoveredKeyword);
  const rightHoveredKeyword = useProcessOrderDraftsStore((state) => state.rightHoveredKeyword);
  const setLeftHoveredKeyword = useProcessOrderDraftsStore((state) => state.setLeftHoveredKeyword);
  const setPlayRecordingKeywordAndMessageId = useProcessOrderDraftsStore(
    (state) => state.setPlayRecordingKeywordAndMessageId);

  const hoveredKeyword = useMemo(() => (
    isValidKeyword(leftHoveredKeyword) ? leftHoveredKeyword : rightHoveredKeyword
  ), [leftHoveredKeyword, rightHoveredKeyword]);

  const createSegments = (): Segment[] => {
    if (!fullText || !Array.isArray(keywords) || keywords.length === 0) {
      return [{ text: fullText, keywords: [], isNewline: false }];
    }

    const positions: Position[] = [];
    keywords
      .forEach((kw) => {
        positions.push({
          position: kw.offset,
          type: 'start',
          keyword: kw,
        });
        positions.push({
          position: kw.offset + kw.word.length,
          type: 'end',
          keyword: kw,
        });
      });

    positions.sort((a, b) => {
      if (a.position !== b.position) return a.position - b.position;
      return a.type === 'end' ? -1 : 1;
    });

    const segments: Segment[] = [];
    let currentPos = 0;
    let activeKeywords: Keyword[] = [];

    positions.forEach((pos) => {
      if (pos.position > currentPos) {
        const textSegment = fullText.slice(currentPos, pos.position);
        // Split segment by newlines and create separate segments
        const lines = textSegment.split(/(\n)/);
        lines.forEach((line) => {
          if (line !== '') {
            segments.push({
              text: line,
              keywords: [...activeKeywords],
              isNewline: line === '\n',
            });
          }
        });
      }

      if (pos.type === 'start') {
        activeKeywords.push(pos.keyword);
      } else {
        activeKeywords = activeKeywords.filter((k) => k !== pos.keyword);
      }

      currentPos = pos.position;
    });

    if (currentPos < fullText.length) {
      const remainingText = fullText.slice(currentPos);
      const lines = remainingText.split(/(\n)/);
      lines.forEach((line) => {
        if (line !== '') {
          segments.push({
            text: line,
            keywords: [],
            isNewline: line === '\n',
          });
        }
      });
    }

    return segments;
  };

  const segments = createSegments();

  const onClick = (segment: Segment) => {
    if (segment.keywords.length > 0) {
      const kw = segment.keywords[0];
      const scrollToId = kw.fieldId ? kw.fieldId : kw.path;
      scrollToKeyword(scrollToId);

      setPlayRecordingKeywordAndMessageId({
        keyword: scrollToId,
        messageId,
      });
    }
  };

  const onMouseEnter = (segment: Segment) => {
    if (segment.keywords.length > 0) {
      const fieldIds = uniq(segment.keywords.map((kw) => kw.fieldId));
      if (segment.keywords.length > 1 && fieldIds.length === 1) {
        setLeftHoveredKeyword({
          fieldIds,
        });
      } else {
        const kw = segment.keywords[0];
        setLeftHoveredKeyword({
          fieldIds: [kw.fieldId],
          fieldPath: kw.path,
        });
      }
    }
  };

  const onMouseLeave = () => {
    setLeftHoveredKeyword(null);
  };

  return (
    <div className="relative">
      <div className="relative inline-block whitespace-pre-wrap break-words">
        {segments.map((segment, index) => (
          <Fragment key={`segment-fragment-${index.toString()}`}>
            {segment.isNewline ? (
              <br />
            ) : (
              <span
                key={`segment-span-${index.toString()}`}
                data-keyword-id={(
                  `${segment.keywords?.[0]?.path}${segment.keywords?.[0]?.fieldId && `-${segment.keywords?.[0]?.fieldId}`}`
                )}
                className={`relative ${
                  segment.keywords.length > 0
                    ? twMerge(
                      'bg-violet-200 hover:bg-violet-300 cursor-pointer transition-colors duration-200 px-1 py-0.5 rounded-sm',
                      isHoveredKeyword(segment, hoveredKeyword) && 'bg-violet-300',
                      isValidKeyword(hoveredKeyword) && !isHoveredKeyword(segment, hoveredKeyword) && 'bg-violet-200/70',
                    )
                    : ''
                }`}
                onClick={() => onClick(segment)}
                onMouseEnter={() => onMouseEnter(segment)}
                onMouseLeave={onMouseLeave}
                role="button"
                tabIndex={0}
                onKeyDown={null}
              >
                {segment.text}
              </span>
            )}
          </Fragment>
        ))}
      </div>

      {/* {hoveredKeyword && hoveredKeyword.length > 0 && (
        <div
          className="absolute bottom-full left-0 whitespace-nowrap bg-gray-800 text-white px-2 py-1 rounded shadow-lg z-10"
        >
          {hoveredKeyword.map((kw, index) => (
            <span key={index} className="mr-2">
              {kw.word}
              {index < hoveredKeyword.length - 1 ? ', ' : ''}
            </span>
          ))}
        </div>
      )} */}
    </div>
  );
};

export default TextHighlighter;
