import { useEffect, useState } from 'react';

import { Subject } from 'features/user/models';

import { ActionFlow } from './_Initial/types';

import { SidebarBase } from '../SidebarBase';
import { _Body as MergeBody } from './_Assign/_Body';
import { _Footer as MergeFooter } from './_Assign/_Footer';
import { _Header as MergeHeader } from './_Assign/_Header';
import { _Body as InitialBody } from './_Initial/_Body';
import { _Footer as InitialFooter } from './_Initial/_Footer';
import { _Header as InitialHeader } from './_Initial/_Header';

interface Props {
  subject?: Subject;
  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;
  onComplete: (subject: Subject) => void;
}

const ManageSubjectSidebar = ({
  subject,
  sidebarOpen,
  setSidebarOpen,
  onComplete: onManageSubjectComplete,
}: Props) => {
  const [actionFlow, setSelectedActionFlow] = useState<ActionFlow | null>(null);

  const onBack = () => {
    setSelectedActionFlow(null);
  };

  const onNext = (next: ActionFlow) => {
    setSelectedActionFlow(next);
  };

  const onComplete = (_subject: Subject) => {
    setSidebarOpen(false);
    onManageSubjectComplete(_subject);
  };

  useEffect(() => {
    // reset on close
    if (!sidebarOpen) {
      setSelectedActionFlow(null);
    }
  }, [sidebarOpen]);

  if (actionFlow === null) {
    return (
      <SidebarBase
        header={<InitialHeader />}
        body={<InitialBody onNext={onNext} />}
        footer={<InitialFooter />}
        sidebarWidth="max-w-[30vw]"
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
    );
  }

  return (
    <SidebarBase
      header={<MergeHeader onBack={onBack} />}
      body={<MergeBody subject={subject} onComplete={onComplete} />}
      footer={<MergeFooter />}
      sidebarWidth="max-w-[30vw]"
      sidebarOpen={sidebarOpen}
      setSidebarOpen={setSidebarOpen}
    />
  );
};

export { ManageSubjectSidebar };
