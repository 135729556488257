import { SMTPMessage } from 'models/Mail';

import { InlineEmailPanel } from 'features/inbox/components';

interface ThreadReplyMessageProps {
  subject: string;
  fromValues: string[];
  from: string;
  to: string[];
  senderName: string;
  businessName: string;

  onSend: (smtpMessage: SMTPMessage) => void;
  onDiscard: () => void;
}

export const ThreadReplyMessage = ({
  subject,
  fromValues,
  from,
  to,
  senderName,
  businessName,
  onSend,
  onDiscard,
}: ThreadReplyMessageProps) => {
  const _onSend = async (smtpMessage: SMTPMessage) => {
    onSend(smtpMessage);
  };

  const _onDiscard = () => {
    onDiscard();
  };

  return (
    <div className="px-4 py-3 group">
      <div className="flex items-start">
        <div className="flex-shrink-0 mr-4">
          <div className="relative">
            <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center z-10 relative border border-white">
              <span className="text-blue-700 font-medium">
                {(businessName || senderName)?.charAt(0).toLocaleUpperCase()}
              </span>
            </div>
          </div>
        </div>

        <div className="flex-1 min-w-0">
          <InlineEmailPanel
            subject={subject}
            fromValues={fromValues}
            from={from}
            to={to}
            onDiscard={_onDiscard}
            onSendSmtpMessage={_onSend}
          />
        </div>
      </div>
    </div>
  );
};
