import { twMerge } from 'tailwind-merge';

import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { LRO, LRO_STATUSES } from 'state/classes/LROs';

interface Props {
  lro: LRO;
}

const LroListItem = ({ lro }: Props) => {
  if (lro.status === LRO_STATUSES.ERROR) {
    return (
      <div
        key={lro.lroId}
        className={twMerge(
          'group relative flex cursor-pointer gap-4 rounded-sm py-3 bg-gray-50',
        )}
      >
        <div className="flex items-start">
          <FontAwesomeIcon icon={faExclamationCircle} className="text-red-500 w-5 h-5" />
        </div>
        <div className="flex-1 overflow-hidden">
          {/* <p className='text-neutral-900 font-semibold'>{getTitle(event)}</p> */}
          <p className="line-clamp-2 w-full max-w-full overflow-hidden text-ellipsis leading-tight text-red-500">
            {lro.title}
          </p>
          <p className="text-xs text-neutral-300">
            {lro.description}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div
      key={lro.lroId}
      className={twMerge(
        'group relative flex cursor-pointer gap-4 rounded-sm py-3 bg-gray-50',
      )}
    >
      <div className="flex items-start">
        <div className="w-6 h-6 border-2 border-blue-200 rounded-full animate-spin border-t-blue-500" />
      </div>
      <div className="flex-1 overflow-hidden">
        {/* <p className='text-neutral-900 font-semibold'>{getTitle(event)}</p> */}
        <p className="line-clamp-2 w-full max-w-full overflow-hidden text-ellipsis leading-tight text-neutral-800">
          {lro.title}
        </p>
        <p className="text-xs text-neutral-300">
          {lro.description}
        </p>
      </div>
    </div>
  );
};

export default LroListItem;
