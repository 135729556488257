import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ROUTES } from 'config/routes';

import { ProtectedScreen } from 'components/ui/ProtectedScreen';

import { ProductDetailsPage } from '../pages';

const ProductDetailsScreen = () => {
  const { productId } = useParams();
  const navigate = useNavigate();

  const navigateToProductsList = useCallback(() => {
    navigate(ROUTES.PRODUCTS);
  }, [navigate]);

  return (
    <ProtectedScreen>
      <ProductDetailsPage
        productId={productId}
        navigateToProductsList={navigateToProductsList}
      />
    </ProtectedScreen>
  );
};

export default ProductDetailsScreen;
