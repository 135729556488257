import { ContentTheme } from '../types';

import { OverflowEllipsisParagraph } from 'components/ui/OverflowEllipsisParagraph';

import { contentThemeToTooltipTheme } from '../utils';

interface Props {
  label: string;
  customLabelTooltip?: string;
  contentTheme: ContentTheme;
}

const Label = ({ label, customLabelTooltip, contentTheme }: Props) => (
  <OverflowEllipsisParagraph
    customTooltipContent={customLabelTooltip || label}
    theme={contentThemeToTooltipTheme(contentTheme)}
    maxLines={1}
    isTooltipEnabled
    openDelay={0}
  >
    {label}
  </OverflowEllipsisParagraph>
);

export default Label;
