import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'config/routes';

import { ProtectedScreen } from 'components/ui/ProtectedScreen';

import { ProductsImportPage } from '../pages';

const ProductsImportScreen = () => {
  const navigate = useNavigate();

  const navigateToProductsList = useCallback(() => {
    navigate(ROUTES.PRODUCTS);
  }, [navigate]);

  return (
    <ProtectedScreen>
      <ProductsImportPage navigateToProductsList={navigateToProductsList} />
    </ProtectedScreen>
  );
};

export default ProductsImportScreen;
