import {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'config/routes';

import { ThreadByIdScreenState } from '../types';

import { useThreadsContext } from 'contexts/useThreadsContext';

import { useMarkThreadsAsRead } from 'hooks/useMarkThreadsAsRead';

import { Thread } from '../components/threads';
import { Page } from 'components/layout/Page/Page';

import { isZeroTime } from 'utils/dateTime';

type Props = {
  state?: ThreadByIdScreenState;
};

const ThreadByIdPage = ({ state }: Props) => {
  const { threads, threadsCount, isLoading: isThreadLoading } = useThreadsContext();
  const thread = useMemo(() => threads[0], [threads]);
  const { markThreadsAsRead } = useMarkThreadsAsRead();

  const navigate = useNavigate();

  const navigateToNextThread = useCallback(() => {
    if (!state) {
      return;
    }
    if (state.currentIndex === threadsCount - 1) {
      return;
    }

    const nextThread = state.threads[state.currentIndex + 1];
    navigate(ROUTES.THREAD_BY_ID(nextThread.id), {
      state: {
        threads: state.threads,
        currentIndex: state.currentIndex + 1,
      },
    });
  }, [navigate, state, threadsCount]);

  const navigateToPreviousThread = useCallback(() => {
    if (!state) {
      return;
    }
    if (state.currentIndex === 0) {
      return;
    }

    const previousThread = state.threads[state.currentIndex - 1];
    navigate(ROUTES.THREAD_BY_ID(previousThread.id), {
      state: {
        threads: state.threads,
        currentIndex: state.currentIndex - 1,
      },
    });
  }, [navigate, state]);

  useEffect(() => {
    if (threads.length > 0) {
      if (isZeroTime(thread.readAt)) {
        markThreadsAsRead([thread.id]);
      }
    }
  }, [thread, threads.length, markThreadsAsRead]);

  return (
    <Page
      contentWithBorder
    >
      <Thread
        thread={thread}
        threadsCount={threadsCount}
        currentIndex={state?.currentIndex ?? 0}
        isThreadLoading={isThreadLoading}
        state={state}
        onNextThread={navigateToNextThread}
        onPreviousThread={navigateToPreviousThread}
      />
    </Page>
  );
};

export default ThreadByIdPage;
