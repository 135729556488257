import { ComponentType, useCallback } from 'react';

import { twMerge } from 'tailwind-merge';

import { isValidKeyword } from 'features/order/utils/keyword';

import { useProcessOrderDraftsStore } from 'features/order/store/process-order-drafts';

interface InjectedProps {
  positionId?: string;
}

const ProductPositionIdWrapper = <TProps extends object>(
  Component: ComponentType<TProps>,
): ComponentType<TProps & InjectedProps> => {
  function WrappedComponent({
    positionId,
    ...props
  }: InjectedProps & TProps) {
    const toZoomKeyword = useProcessOrderDraftsStore((state) => state.toZoomKeyword);
    const leftHoveredKeyword = useProcessOrderDraftsStore((state) => state.leftHoveredKeyword);
    const setRightHoveredKeyword = useProcessOrderDraftsStore((state) => state.setRightHoveredKeyword);

    const isProductCardSelected = (leftHoveredKeyword?.fieldIds || []).includes(positionId);

    const onMouseEnter = useCallback(() => {
      setRightHoveredKeyword({
        fieldIds: [positionId],
        fieldPath: '',
      });
    }, [positionId, setRightHoveredKeyword]);

    const onMouseLeave = useCallback(() => {
      setRightHoveredKeyword(null);
    }, [setRightHoveredKeyword]);

    return (
      <div
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={twMerge(
          'cursor-pointer',
          (isValidKeyword(leftHoveredKeyword) && !isValidKeyword(toZoomKeyword) && !isProductCardSelected) && 'opacity-50',
        )}
      >
        <Component {...props as TProps} />
      </div>
    );
  }

  return WrappedComponent;
};

export default ProductPositionIdWrapper;
