import { XMarkIcon } from '@heroicons/react/24/outline';

import { Product } from 'features/product/models/Product';

import { getUnits } from 'features/product/utils/product';

const ProductDetailsModal = ({
  opened,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onClose,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  product,
}: {
  opened: boolean;
  onClose: () => void;
  product: Product;
}) => {
  if (!opened || !product) return null;
  return (
    <div
      className="absolute z-[1000] top-0 left-0 w-full h-full bg-dark-blue-hue-box p-10 backdrop-blur-[5px] flex justify-end transition-all duration-300"
      role="button"
      tabIndex={0}
      onKeyDown={null}
      onClick={onClose}
    >
      <div
        className="flex flex-col w-[500px] items-start p-6 relative self-stretch bg-white rounded-[10px] border border-solid border-light-grey shadow-blue-tint-drop-shadow transition-all duration-300"
        role="button"
        tabIndex={0}
        onKeyDown={null}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex flex-col items-end justify-end gap-4 relative self-stretch w-full flex-[0_0_auto]">
          <div className="flex flex-col h-[702px] items-start gap-6 relative self-stretch w-full">
            <div className="flex items-start gap-6 relative self-stretch w-full flex-[0_0_auto]">
              <div className="flex flex-col items-start gap-1 relative flex-1 grow">
                <div className="font-font-screen-xl-medium font-[number:var(--font-screen-xl-medium-font-weight)] text-[length:var(--font-screen-xl-medium-font-size)] tracking-[var(--font-screen-xl-medium-letter-spacing)] leading-[var(--font-screen-xl-medium-line-height)] relative self-stretch mt-[-1.00px] text-black [font-style:var(--font-screen-xl-medium-font-style)]">
                  Product Information
                </div>

                <p className="relative self-stretch font-font-screen-xs-regular font-[number:var(--font-screen-xs-regular-font-weight)] text-black-lighter-text text-[length:var(--font-screen-xs-regular-font-size)] tracking-[var(--font-screen-xs-regular-letter-spacing)] leading-[var(--font-screen-xs-regular-line-height)] [font-style:var(--font-screen-xs-regular-font-style)]">
                  Edit and push the save button.
                </p>
              </div>

              <button
                type="button"
                className="relative w-6 h-6 hover:bg-gray-50"
                onClick={onClose}
              >
                <XMarkIcon className="!relative !w-6 !h-6" />
              </button>
            </div>

            <div className="flex flex-col items-start gap-3 relative self-stretch w-full flex-[0_0_auto]">
              <div className="flex flex-col items-start gap-1 relative self-stretch w-full flex-[0_0_auto]">
                <div className="font-font-screen-sm-regular font-[number:var(--font-screen-sm-regular-font-weight)] text-[length:var(--font-screen-sm-regular-font-size)] tracking-[var(--font-screen-sm-regular-letter-spacing)] leading-[var(--font-screen-sm-regular-line-height)] relative self-stretch mt-[-1.00px] text-black [font-style:var(--font-screen-sm-regular-font-style)]">
                  Product Name
                </div>

                <div className="flex items-center gap-2.5 px-4 py-2 relative self-stretch w-full flex-[0_0_auto] bg-light-purple-body rounded-[10px] opacity-90">
                  <div className="relative w-fit mt-[-1.00px] font-font-screen-xs-regular font-[number:var(--font-screen-xs-regular-font-weight)] text-black text-[length:var(--font-screen-xs-regular-font-size)] tracking-[var(--font-screen-xs-regular-letter-spacing)] leading-[var(--font-screen-xs-regular-line-height)] whitespace-nowrap [font-style:var(--font-screen-xs-regular-font-style)]">
                    {product.name}
                  </div>
                </div>
              </div>

              <div className="flex flex-col items-start gap-1 relative self-stretch w-full flex-[0_0_auto]">
                <div className="relative self-stretch mt-[-1.00px] font-font-screen-sm-regular font-[number:var(--font-screen-sm-regular-font-weight)] text-black text-[length:var(--font-screen-sm-regular-font-size)] tracking-[var(--font-screen-sm-regular-letter-spacing)] leading-[var(--font-screen-sm-regular-line-height)] [font-style:var(--font-screen-sm-regular-font-style)]">
                  ID or SKU
                </div>

                <div className="flex items-center gap-2.5 px-4 py-2 relative self-stretch w-full flex-[0_0_auto] bg-light-purple-body rounded-[10px] opacity-90">
                  <div className="relative w-fit mt-[-1.00px] font-font-screen-xs-regular font-[number:var(--font-screen-xs-regular-font-weight)] text-black text-[length:var(--font-screen-xs-regular-font-size)] tracking-[var(--font-screen-xs-regular-letter-spacing)] leading-[var(--font-screen-xs-regular-line-height)] whitespace-nowrap [font-style:var(--font-screen-xs-regular-font-style)]">
                    {product.idOrSku}
                  </div>
                </div>
              </div>

              <div className="flex items-start gap-3 relative self-stretch w-full flex-[0_0_auto]">
                <div className="flex flex-col items-start gap-1 relative flex-1 grow">
                  <div className="relative self-stretch mt-[-1.00px] font-font-screen-sm-regular font-[number:var(--font-screen-sm-regular-font-weight)] text-black text-[length:var(--font-screen-sm-regular-font-size)] tracking-[var(--font-screen-sm-regular-letter-spacing)] leading-[var(--font-screen-sm-regular-line-height)] [font-style:var(--font-screen-sm-regular-font-style)]">
                    Price
                  </div>

                  <div className="flex items-center justify-between pl-4 pr-3 py-2 relative self-stretch w-full flex-[0_0_auto] bg-light-purple-body rounded-[10px] opacity-90">
                    <div className="relative w-fit mt-[-1.00px] font-font-screen-xs-regular font-[number:var(--font-screen-xs-regular-font-weight)] text-black text-[length:var(--font-screen-xs-regular-font-size)] tracking-[var(--font-screen-xs-regular-letter-spacing)] leading-[var(--font-screen-xs-regular-line-height)] whitespace-nowrap [font-style:var(--font-screen-xs-regular-font-style)]">
                      {product.price?.amount}
                    </div>
                  </div>
                </div>

                <div className="flex flex-col items-start gap-1 relative flex-1 grow">
                  <div className="relative self-stretch mt-[-1.00px] font-font-screen-sm-regular font-[number:var(--font-screen-sm-regular-font-weight)] text-black text-[length:var(--font-screen-sm-regular-font-size)] tracking-[var(--font-screen-sm-regular-letter-spacing)] leading-[var(--font-screen-sm-regular-line-height)] [font-style:var(--font-screen-sm-regular-font-style)]">
                    Currency
                  </div>

                  <div className="flex items-center justify-between pl-4 pr-3 py-2 relative self-stretch w-full flex-[0_0_auto] bg-light-purple-body rounded-[10px] opacity-90">
                    <div className="relative w-fit mt-[-1.00px] font-font-screen-xs-regular font-[number:var(--font-screen-xs-regular-font-weight)] text-black text-[length:var(--font-screen-xs-regular-font-size)] tracking-[var(--font-screen-xs-regular-letter-spacing)] leading-[var(--font-screen-xs-regular-line-height)] whitespace-nowrap [font-style:var(--font-screen-xs-regular-font-style)]">
                      {product.price?.currency.toUpperCase()}
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-start gap-3 relative self-stretch w-full flex-[0_0_auto]">
                <div className="flex flex-col items-start gap-1 relative flex-1 grow">
                  <div className="relative self-stretch mt-[-1.00px] font-font-screen-sm-regular font-[number:var(--font-screen-sm-regular-font-weight)] text-black text-[length:var(--font-screen-sm-regular-font-size)] tracking-[var(--font-screen-sm-regular-letter-spacing)] leading-[var(--font-screen-sm-regular-line-height)] [font-style:var(--font-screen-sm-regular-font-style)]">
                    Min Order Qty
                  </div>

                  <div className="flex items-center justify-between pl-4 pr-3 py-2 relative self-stretch w-full flex-[0_0_auto] bg-light-purple-body rounded-[10px] opacity-90">
                    <div className="relative w-fit mt-[-1.00px] font-font-screen-xs-regular font-[number:var(--font-screen-xs-regular-font-weight)] text-black text-[length:var(--font-screen-xs-regular-font-size)] tracking-[var(--font-screen-xs-regular-letter-spacing)] leading-[var(--font-screen-xs-regular-line-height)] whitespace-nowrap [font-style:var(--font-screen-xs-regular-font-style)]">
                      {product.minOrderQty}
                    </div>
                  </div>
                </div>

                <div className="flex flex-col items-start gap-1 relative flex-1 grow">
                  <div className="relative self-stretch mt-[-1.00px] font-font-screen-sm-regular font-[number:var(--font-screen-sm-regular-font-weight)] text-black text-[length:var(--font-screen-sm-regular-font-size)] tracking-[var(--font-screen-sm-regular-letter-spacing)] leading-[var(--font-screen-sm-regular-line-height)] [font-style:var(--font-screen-sm-regular-font-style)]">
                    Unit(s)
                  </div>

                  <div className="flex items-center gap-2.5 px-4 py-2 relative self-stretch w-full flex-[0_0_auto] bg-light-purple-body rounded-[10px] opacity-90">
                    <div className="relative w-fit mt-[-1.00px] font-font-screen-xs-regular font-[number:var(--font-screen-xs-regular-font-weight)] text-black text-[length:var(--font-screen-xs-regular-font-size)] tracking-[var(--font-screen-xs-regular-letter-spacing)] leading-[var(--font-screen-xs-regular-line-height)] whitespace-nowrap [font-style:var(--font-screen-xs-regular-font-style)]">
                      {getUnits(product).join(', ')}
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-start gap-3 relative self-stretch w-full flex-[0_0_auto]">
                <div className="flex flex-col items-start gap-1 relative flex-1 grow">
                  <div className="relative self-stretch mt-[-1.00px] font-font-screen-sm-regular font-[number:var(--font-screen-sm-regular-font-weight)] text-black text-[length:var(--font-screen-sm-regular-font-size)] tracking-[var(--font-screen-sm-regular-letter-spacing)] leading-[var(--font-screen-sm-regular-line-height)] [font-style:var(--font-screen-sm-regular-font-style)]">
                    Category
                  </div>

                  <div className="flex items-center gap-2.5 px-4 py-2 relative self-stretch w-full flex-[0_0_auto] bg-light-purple-body rounded-[10px] opacity-90">
                    <div className="relative w-fit mt-[-1.00px] font-font-screen-xs-regular font-[number:var(--font-screen-xs-regular-font-weight)] text-black text-[length:var(--font-screen-xs-regular-font-size)] tracking-[var(--font-screen-xs-regular-letter-spacing)] leading-[var(--font-screen-xs-regular-line-height)] whitespace-nowrap [font-style:var(--font-screen-xs-regular-font-style)]">
                      {product.category}
                      <p className="invisible">
                        Hello
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col items-start gap-1 relative flex-1 grow">
                  <div className="relative self-stretch mt-[-1.00px] font-font-screen-sm-regular font-[number:var(--font-screen-sm-regular-font-weight)] text-black text-[length:var(--font-screen-sm-regular-font-size)] tracking-[var(--font-screen-sm-regular-letter-spacing)] leading-[var(--font-screen-sm-regular-line-height)] [font-style:var(--font-screen-sm-regular-font-style)]">
                    Disabled
                  </div>

                  <div className="flex items-center justify-between pl-4 pr-3 py-2 relative self-stretch w-full flex-[0_0_auto] bg-light-purple-body rounded-[10px] opacity-90">
                    <div className="relative w-fit mt-[-1.00px] font-font-screen-xs-regular font-[number:var(--font-screen-xs-regular-font-weight)] text-black text-[length:var(--font-screen-xs-regular-font-size)] tracking-[var(--font-screen-xs-regular-letter-spacing)] leading-[var(--font-screen-xs-regular-line-height)] whitespace-nowrap [font-style:var(--font-screen-xs-regular-font-style)]">
                      {product.disabled ? 'True' : 'False'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailsModal;
