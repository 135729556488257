import { AvgCorrections } from '../models';

import { httpGetV1 } from 'utils/xhr';

// TODO(chihirokuya): expose once it's needed
enum CorrectionType {
  Added = 'added',
  Removed = 'removed',
  Corrected = 'corrected',
  TotalCorrections = 'total_corrections',
  CorrectlyAutomatched = 'correctly_automatched',
  ChangedAutomatched = 'changed_automatched',
}

interface GetAvgCorrectionsRequest {
  from_time: Date;
  to_time?: Date;
  granularity?: string;
  product_number_from?: number;
  product_number_to?: number;
}

export const fetchAvgCorrections = (
  request: GetAvgCorrectionsRequest,
  type: CorrectionType = CorrectionType.TotalCorrections,
): Promise<AvgCorrections> => httpGetV1(`/orders/metrics/processed_orders/corrections/${type}`, {
  params: {
    ...request,
    aggregation: 'avg',
  },
  classType: AvgCorrections,
})
  .then((response) => response.data);
