import { LroType, OperationGroup } from 'models/Lro';

const getWorkflowTitle = (type: LroType, subGroup?: string) => {
  if (type === LroType.Retry) {
    if (subGroup === 'order_bridge') {
      return 'Retrying order parsing...';
    }
    return 'Retrying workflow run...';
  }
  if (subGroup === 'order_bridge') {
    return 'Processing purchase order...';
  }
  return 'Running workflow...';
};

export const getTitleByGroup = (group: OperationGroup, type?: LroType, subGroup?: string) => {
  switch (group) {
    case OperationGroup.WORKFLOW:
      return getWorkflowTitle(type, subGroup);
    case OperationGroup.ORDER_SYNC:
      return 'Syncing order...';
    case OperationGroup.RECONCILIATION:
      return 'Reconciling contact...';
    default:
      return 'Running operation...';
  }
};

const getWorkflowDescription = (type: LroType, subGroup?: string) => {
  if (type === LroType.Retry) {
    if (subGroup === 'order_bridge') {
      return 'The order parsing is being retried in the background';
    }
    return 'The workflow run is being retried in the background';
  }
  if (subGroup === 'order_bridge') {
    return 'An order draft is being generated by Adam';
  }
  return 'A workflow was started and is now running in the background';
};

export const getDescriptionByGroup = (
  group: OperationGroup,
  type?: LroType,
  subGroup?: string,
  isError?: boolean,
  isCompleted?: boolean,
) => {
  switch (group) {
    case OperationGroup.WORKFLOW:
      return getWorkflowDescription(type, subGroup);
    case OperationGroup.ORDER_SYNC:
      if (isError) {
        return 'The order synchronization failed';
      }
      if (isCompleted) {
        return 'The order has been successfully synchronized to the configured ERP system';
      }
      return 'The order is being synchronized to the configured ERP system';
    case OperationGroup.RECONCILIATION:
      return 'The contact is being reconciled with the assigned customer or organization';
    default:
      return '';
  }
};
