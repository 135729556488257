import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Select } from '@mantine/core';

import { MagnifyGlassIcon } from 'components/icon/outline';

import { FetchProductsParams } from 'features/product/api';

interface CategoryProps {
  categories: string[];
  selectedCategory: string;
  setSelectedCategory: React.Dispatch<string>;
}

const Categories = ({
  categories,
  selectedCategory,
  setSelectedCategory,
}: CategoryProps) => {
  const { t } = useTranslation();

  return (
    <Select
      data={categories}
      value={selectedCategory}
      onChange={(value) => setSelectedCategory(value)}
      placeholder={t('product.list.searchBar.category')}
      rightSection={<ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
      styles={{
        input: {
          backgroundColor: 'var(--violet-hue-selection-button-2)',
          border: '0',
          fontFamily: 'var(--font-screen-sm-regular-font-family)',
          fontSize: 'var(--font-screen-sm-regular-font-size)',
          fontStyle: 'var(--font-screen-sm-regular-font-style)',
          fontWeight: 'var(--font-screen-sm-regular-font-weight)',
          letterSpacing: 'var(--font-screen-sm-regular-letter-spacing)',
          lineHeight: 'var(--font-screen-sm-regular-line-height)',
        },
      }}
      maxDropdownHeight="50vh"
    />
  );
};

interface SearchBarProp {
  categories: string[];
  setParams: (params: FetchProductsParams) => void;
}

function ProductSearchBar({
  categories,
  setParams,
}: SearchBarProp) {
  const { t } = useTranslation();

  const [selectedCategory, setSelectedCategory] = useState<string>('All Categories');

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const params: FetchProductsParams = {};

    if (selectedCategory && selectedCategory !== 'All Categories') {
      params.category = selectedCategory;
    }

    const formData = new FormData(e.currentTarget);
    const query = formData.get('search_query');

    if (query?.toString()) {
      params.query = query.toString();
    }

    setParams(params);
  };

  return (
    <form onSubmit={onSubmit} className="flex items-center space-x-3">
      <div className="flex gap-2">
        <Categories
          categories={categories}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />

        <div className="flex bg-violet-hue-selection-button-3 rounded-full w-[300px] desktop:w-[400px]">
          <input
            type="text"
            name="search_query"
            id="search_query"
            className="relative flex-1 font-font-screen-sm-regular font-[number:var(--font-screen-sm-regular-font-weight)] text-placeholder-text text-[length:var(--font-screen-sm-regular-font-size)] tracking-[var(--font-screen-sm-regular-letter-spacing)] leading-[var(--font-screen-sm-regular-line-height)] whitespace-nowrap [font-style:var(--font-screen-sm-regular-font-style)] bg-transparent border-none focus:outline-none focus:ring-0"
            placeholder={t('product.list.searchBar.placeholder')}
          />
          <div className="inline-flex justify-center gap-1 px-3 py-2 flex-[0_0_auto] bg-black rounded-full items-center relative">
            <MagnifyGlassIcon className="!h-4 !w-4 text-white" />
            <button
              type="submit"
              className="relative w-fit mt-[-1.50px] font-font-screen-sm-regular font-[number:var(--font-screen-sm-regular-font-weight)] text-[#ffffff] text-[length:var(--font-screen-sm-regular-font-size)] tracking-[var(--font-screen-sm-regular-letter-spacing)] leading-[var(--font-screen-sm-regular-line-height)] whitespace-nowrap [font-style:var(--font-screen-sm-regular-font-style)]"
            >
              {t('product.list.searchBar.searchButton')}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

export default ProductSearchBar;
