import { scan } from 'react-scan';
import 'reflect-metadata';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/react';

import { Routing } from './routing/Routing';

import { initGlobalAlertDataList } from './state/globalAlertDataList';
import { initGlobalChats } from './state/globalChats';
import { initGlobalEffects } from './state/globalEffects';
import { initGlobalMessageIntents } from './state/globalMessageIntents';
import { initGlobalNotifications } from './state/globalNotifications';
import { initGlobalOrderDrafts } from './state/globalOrderDrafts';
import { initGlobalSseSources } from './state/globalSseSources';
import { initGlobalThreads } from './state/globalThreads';
import { globalUser, initGlobalUser } from './state/globalUser';
import { initGlobalInboxes } from 'state/globalInboxes';
import { initGlobalLROs } from 'state/globalLROs';
import { refreshGlobalStates } from 'state/refresh';

import { LoadingIndicator } from './components/LoadingIndicator';

import { initDateTimeHelper } from './utils/dateTime';

import '@mantine/carousel/styles.css';
import '@mantine/charts/styles.css';

import { AppGlobalProviders } from './AppGlobalProviders';

const App = () => {
  const [appIsReady, setAppIsReady] = useState(false);

  useEffect(() => {
    // Make sure to run react-scan only after hydration
    scan({
      enabled: true,
    });
  }, []);

  useEffect(() => {
    // Indipendent helpers and logics init
    initDateTimeHelper();

    // Persisted mobx states and other async inits
    const asyncInits = [initGlobalUser(), initGlobalChats()];

    Promise.all(asyncInits).then(() => {
      initGlobalOrderDrafts();
      initGlobalLROs();
      initGlobalMessageIntents();
      initGlobalThreads();
      initGlobalNotifications();
      initGlobalInboxes();

      if (globalUser.isSignedIn) {
        refreshGlobalStates();
      }

      initGlobalAlertDataList();
      initGlobalSseSources();

      initGlobalEffects();

      setAppIsReady(true);
    });

    // Init Sentry
    Sentry.init({
      environment: ENVIRONMENT,
      dsn: 'https://6eebf3e818d26259bb43478267c302d7@o4508245135065088.ingest.de.sentry.io/4508245137752144',
      integrations: [
        Sentry.feedbackIntegration({
          autoInject: false,
          colorScheme: 'system',
          triggerLabel: 'Issue or Feature Request?',
          formTitle: 'Give Feedback',
          messagePlaceholder: 'What is the issue you are facing or feature you would like to see on Hoshii?',
          submitButtonLabel: 'Submit',
          isNameRequired: true,
          isEmailRequired: true,
        }),
        Sentry.replayIntegration({
          maskAllText: SENTRY_REPLAYS_MASK_ALL_TEXT,
          blockAllMedia: SENTRY_REPLAYS_BLOCK_ALL_MEDIA,
        }),
      ],
      // Session replay
      replaysSessionSampleRate: SENTRY_REPLAYS_SAMPLE_RATE,
      replaysOnErrorSampleRate: SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE, // Sample rate when errors occur
    });
  }, []);

  if (!appIsReady) {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <LoadingIndicator isLoading />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <script src="https://hoshii-dev.statuspage.io/embed/script.js" />
      </Helmet>

      <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
        <AppGlobalProviders>
          <Routing />
        </AppGlobalProviders>
      </GoogleOAuthProvider>
    </>
  );
};

export { App };
