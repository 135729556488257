import { ComponentType, useState } from 'react';

import { Subject } from 'features/user/models';

import { ManageSubjectSidebar } from '../sidebars/ManageSubject/ManageSubjectSidebar';

interface InjectedProps {
  subject: Subject;
  onComplete: (subject: Subject) => void;
}

const ManageSubjectSidebarWrapper = <TProps extends object>(
  Component: ComponentType<TProps>,
): ComponentType<TProps & InjectedProps> => {
  function WrappedComponent({
    subject, onComplete,
    ...props
  }: InjectedProps & TProps) {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    return (
      <>
        <Component
          {...props as TProps}
          setManageSubjectSidebarOpen={setIsSidebarOpen}
        />

        <ManageSubjectSidebar
          subject={subject}
          sidebarOpen={isSidebarOpen}
          setSidebarOpen={setIsSidebarOpen}
          onComplete={onComplete}
        />
      </>
    );
  }

  return WrappedComponent;
};

export default ManageSubjectSidebarWrapper;
