import { Loader, Select, SelectProps } from '@mantine/core';

import styles from './OrderSearchBarSelect.module.css';

interface Props extends SelectProps {
  isLoading?: boolean;
}

const OrderSearchBarSelect = ({ isLoading, ...props }: Props) => (
  <Select
    classNames={styles}
    {...props}
    rightSection={isLoading ? <Loader size={10} /> : null}
  />
);

export default OrderSearchBarSelect;
