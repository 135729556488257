import { useEffect } from 'react';

import { plainToClass } from 'class-transformer';

import { globalSseSources } from 'state/globalSseSources';

import { Message } from 'features/message/models/Message';
import { Event } from 'models/Event';

interface ThreadMessageSseSourceHandlerProps {
  messageId: string;
  setMessage: (message: Message) => void;
}

export const useThreadMessageSseSourceHandler = ({
  messageId,
  setMessage,
}: ThreadMessageSseSourceHandlerProps) => {
  useEffect(() => {
    if (!messageId) return;

    globalSseSources.addSourcesHandler(
      'messages',
      [
        'new_message',
        'classified_message',
        'processed_message',
        'failed_message',
      ],
      (messageEvent) => {
        try {
          // TODO(chihirokuya): move to utils as duplicate code
          const data = JSON.parse(messageEvent.data);
          const event = plainToClass(Event, data);
          const message = event.getDataInstanceOf(Message);
          if (message.id === messageId) {
            setMessage(message);
          }
        } catch (error) {
          console.error('Error parsing message event', error);
        }
      },
    );
  }, [messageId, setMessage]);
};
