import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';

import { useMutation } from '@tanstack/react-query';

import { ROUTES } from 'config/routes';

import { globalUser } from 'state/globalUser';

import { Chat } from '../models';
import { Message, MessageStatus } from 'features/message/models/Message';

import { createLLMChat, createLLMChatMessage } from '../apis';

import { genericErrorFeedback } from 'utils/errors';

interface Props {
  chat: Chat | null;
  setChat: (chat: Chat) => void;
  setTempMessage: (message: Message) => void;
  updateTempMessage: (updatedMessage: Partial<Message>) => void;
}

export const useLLMChatSender = (props: Props) => {
  const navigate = useNavigate();
  const {
    chat, setTempMessage, setChat, updateTempMessage,
  } = props;

  const createChatMutation = useMutation({
    mutationFn: createLLMChat,
    onError: (error) => {
      updateTempMessage({
        messageStatus: MessageStatus.FAILED,
      });
      genericErrorFeedback('Failed to send message')(error);
    },
  });

  const createChatMessageMutation = useMutation({
    mutationFn: createLLMChatMessage,
    onSuccess: (dataMessage) => {
      updateTempMessage(dataMessage);
    },
    onError: (error) => {
      updateTempMessage({
        messageStatus: MessageStatus.FAILED,
      });
      genericErrorFeedback('Failed to send message')(error);
    },
  });

  const sendMessage = useCallback((message: string) => {
    const newId = uuidv4();
    const tempMessage = new Message();
    tempMessage.id = newId;
    tempMessage.message = message;
    tempMessage.messageStatus = MessageStatus.SENDING;
    tempMessage.userSentBy = globalUser.id;

    if (!chat) {
      createChatMutation.mutate({}, {
        onSuccess: (dataChat) => {
          // Change url to the new chat id
          navigate(ROUTES.ADAM_CHAT_BY_ID(dataChat.id), { replace: true });
          setChat(dataChat);

          setTempMessage(tempMessage);

          createChatMessageMutation.mutate({
            chatId: dataChat.id,
            params: {
              message,
              pipeline: 'adam_assistant',
            },
          });
        },
      });
    } else {
      setTempMessage(tempMessage);
      createChatMessageMutation.mutate({
        chatId: chat.id,
        params: {
          message,
          pipeline: 'adam_assistant',
        },
      });
    }
  }, [chat, createChatMutation, navigate, setChat, createChatMessageMutation, setTempMessage]);

  return { sendMessage, isLoading: createChatMutation.isPending || createChatMessageMutation.isPending };
};
