import { User } from 'features/user/models';

import { isZeroTime } from 'utils/dateTime';

const isUserActivated = (user: User) => !isZeroTime(user?.activatedAt);

const getUserInitials = (user: User): string => {
  if (!user.firstName && !user.lastName) {
    return user.username?.substring(0, 2) ?? '';
  }

  if (user.firstName?.length && user.lastName?.length) {
    return user.lastName.substring(0, 1) + user.firstName.substring(0, 1);
  }

  if (user.firstName) {
    return user.firstName?.substring(0, 2);
  }

  return user.lastName?.substring(0, 2);
};

const getUserName = (user: User): string => {
  if (!user.firstName && !user.lastName) {
    return user.username ?? '';
  }

  if (!user.lastName) return user.firstName ?? '';

  return `${user.firstName} ${user.lastName}`;
};

const getUserContact = (user: User): string => {
  const emails = user.GetEmails();
  const phones = user.GetPhones();

  if (emails.length > 0) {
    return emails.join(', ');
  }

  if (phones.length > 0) {
    return phones.join(', ');
  }

  return '';
};

export {
  getUserContact,
  getUserInitials, getUserName, isUserActivated,
};
