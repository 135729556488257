import { format, startOfToday } from 'date-fns';

import { queryOptions } from '@tanstack/react-query';

import { MINUTE } from 'constants/miliseconds';

import { AvgTimeToFinalize } from '../models';

import { Metric } from '../types/metric';
import { TimePeriod } from '../types/timePeriod';

import { fetchAvgTimeToFinalize } from '../apis';

import { timePeriodToTimeWindow } from '../utils/timePeriod';

type FinalizationTimeData = {
  x: string;
  y: number;
};

const formatData = (
  data: AvgTimeToFinalize,
  timePeriod: TimePeriod,
): FinalizationTimeData[] => Array.from(data.avgTimes.entries()).map(([index, avgTime]) => {
  const startDate = data.timeWindowsLowerBounds[index];

  let x = '';

  switch (timePeriod) {
    case TimePeriod.Daily:
      x = format(startDate, 'MMM-dd');
      break;
    case TimePeriod.Weekly:
      x = format(startDate, 'MMM-dd');
      break;
    default:
      x = format(startDate, 'MMM');
      break;
  }

  return {
    x,
    y: avgTime / 1000, // Convert to seconds
  };
});

const formatGranularity = (timePeriod: TimePeriod) => {
  if (timePeriod === TimePeriod.Daily) {
    return '24h';
  }

  if (timePeriod === TimePeriod.Weekly) {
    // 24 * 7 = 168
    return '168h';
  }

  // 24 * 30 = 720
  return '720h';
};

export const finalizationTimeOptions = (timePeriod: TimePeriod, metric: Metric) => queryOptions({
  queryKey: ['finalization-time', timePeriod, metric],
  queryFn: () => fetchAvgTimeToFinalize({
    from_time: timePeriodToTimeWindow(timePeriod),
    to_time: startOfToday(),
    granularity: formatGranularity(timePeriod),
  }),
  enabled: metric === Metric.FinalizationTime,
  select: (data) => formatData(data, timePeriod),
  staleTime: 5 * MINUTE, // cache for 5 minutes
  meta: {
    errorMessage: 'Error fetching finalization time',
  },
});
