/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useSelectedGroupOrder } from '../../hooks/useSelectedGroupOrder';
import { useProductsErrorsAndWarnings } from 'features/order/hooks/useProductsErrorsAndWarnings';
import { useSortProducts } from 'features/order/hooks/useSortProducts';

import { ProductDetailsHeader, ProductExpandableCard } from './components';
import ErrorsTooltip from './components/ErrorsTooltip';

import { getProductFields } from 'features/order/store/process-order-drafts/utils';

import PriceAlert from './PriceAlert';
import { useProcessOrderDraftsStore } from 'features/order/store/process-order-drafts';

const ProductsDetails = () => {
  const { t } = useTranslation();

  const productsMap = useProcessOrderDraftsStore((state) => state.products);
  const groupOrders = useProcessOrderDraftsStore((state) => state.groupOrders);
  const selectedOrderId = useProcessOrderDraftsStore((state) => state.selectedOrderId);

  const { order } = useSelectedGroupOrder();

  const products = useMemo(() => productsMap[selectedOrderId], [productsMap, selectedOrderId]);
  const productFields = useMemo(() => getProductFields(groupOrders, selectedOrderId), [groupOrders, selectedOrderId]);

  const productNameField = productFields.find((field) => field.path.endsWith('name')) || null;
  const productQuantityField = productFields.find((field) => field.path.endsWith('quantity')) || null;
  const productUnitField = productFields.find((field) => field.path.endsWith('unit')) || null;

  const {
    errorsKeys, warningsKeys, productErrors,
  } = useProductsErrorsAndWarnings({
    products,
    selectedOrderId,
  });

  const { isSortLoading, sortedProducts } = useSortProducts({
    products,
    errorsKeys,
    warningsKeys,
  });

  if (!order?.customer) {
    return (
      <div>
        <div className="flex gap-2 items-center mb-1">
          <p className="font-semibold">
            {t('order.processOrderDrafts.rightSide.productsDetails')}
          </p>
        </div>
        <div className="text-sm text-gray-500 pt-20 text-center">
          {t('order.processOrderDrafts.rightSide.chooseCustomer')}
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="flex gap-2 items-center mb-1">
        <p className="font-semibold">
          {t('order.processOrderDrafts.rightSide.productsDetails')}
          {(products || []).length > 0 && (
            <span className="ml-1 text-xs text-gray-400">
              (
              {products.length}
              {' '}
              {t('order.processOrderDrafts.rightSide.products')}
              )
            </span>
          )}
        </p>
        {productErrors.length > 0 && (
        <ErrorsTooltip errors={productErrors}>
          <div className="flex items-center justify-center cursor-pointer rounded-full text-red-500 font-bold text-sm">
            <FontAwesomeIcon icon={faExclamationCircle} className="mr-1" />
            {productErrors.length}
          </div>
        </ErrorsTooltip>
        )}
      </div>

      <ProductDetailsHeader
        productLength={products?.length || 0}
      />

      <PriceAlert />

      {
        !products?.length && (
          <div className="text-sm text-gray-500 pt-20 text-center">
            {t('order.processOrderDrafts.rightSide.noProducts')}
          </div>
        )
      }

      <div className="space-y-2">
        {
          isSortLoading ? (
            <div className="text-sm text-gray-500 pt-20 text-center">
              {t('order.processOrderDrafts.rightSide.sortingProducts')}
            </div>
          ) : (
            sortedProducts.map((product) => (
              <ProductExpandableCard
                key={product.uiId}
                positionId={product.positionId}
                customerId={groupOrders[selectedOrderId]?.customer?.id}
                product={product}
                productNameField={productNameField}
                productQuantityField={productQuantityField}
                productUnitField={productUnitField}
              />
            ))
          )
        }
      </div>
    </div>
  );
};

export default ProductsDetails;
