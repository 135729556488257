import { Expose, Type } from 'class-transformer';
import { v4 as uuidv4 } from 'uuid';

import { Business } from '../../../models/Business';
import { TypeSpec } from '../../instruction/models';
import { Price, Product, Unit } from '../../product/models/Product';
import { Subject, User } from 'features/user/models';

const convertibleUnits = ['G', 'GR', 'GRAM', 'K', 'KG', 'KILO'];

class Order {
  @Expose({ name: 'id' })
    id: string;

  @Expose({ name: 'business_id' })
    businessId: string;

  @Expose({ name: 'number' })
    number?: string;

  @Expose({ name: 'external_id' })
    externalId?: string;

  @Expose({ name: 'supplier' })
    supplier: Business;

  @Expose({ name: 'customer' })
    customer: Business;

  @Expose({ name: 'created_by_user_info' })
  @Type(() => User)
    createdByUserInfo: User | Subject;

  @Expose({ name: 'created_by_user_info_id' })
    createdByUserInfoId: string;

  @Expose({ name: 'created_by_user_email' })
    createdByUserEmail: string;

  @Expose({ name: 'created_by_user_phone' })
    createdByUserPhone: string;

  @Expose({ name: 'products' })
    products: ProductWithQuantity[];

  @Expose({ name: 'total_price' })
    totalPrice: Price;

  @Expose({ name: 'requested_delivery_time' })
    requestedDeliveryTime: string;

  @Expose({ name: 'order_date' })
    orderDate: string;

  @Expose({ name: 'auto_matched_requested_delivery_time' })
    autoMatchedRequestedDeliveryTime: string;

  @Expose({ name: 'status' })
    status: OrderStatus;

  @Expose({ name: 'status_trails' })
    statusTrails: OrderStatusTrail[];

  @Expose({ name: 'delivery_status' })
    deliveryStatus: OrderDeliveryStatus;

  @Expose({ name: 'delivery_status_trails' })
    deliveryStatusTrails: OrderDeliveryStatusTrail[];

  @Expose({ name: 'created_at' })
    createdAt: string;

  @Expose({ name: 'last_erp_sync_at' })
    lastErpSyncAt?: string;

  @Expose({ name: 'created_by' })
    createdBy: string;

  @Expose({ name: 'updated_at' })
    updatedAt: string;

  @Expose({ name: 'is_draft' })
    isDraft: boolean;

  @Expose({ name: 'is_exported' })
    isExported: boolean;

  @Expose({ name: 'assignee_id' })
    assigneeId: string;

  @Expose({ name: 'draft' })
    draft: {
    comment: string;
  };

  @Expose({ name: 'group_id' })
    groupId: string;

  @Expose({ name: 'is_group_sibling' })
    isGroupSibling: boolean;

  @Expose({ name: 'free_fields' })
    freeFields?: Record<string, any>;

  @Expose({ name: 'type_specs' })
    typeSpecs: TypeSpec[];

  @Expose({ name: 'created_by_workflow_run_id' })
    createdByWorkflowRunId: string;

  // UI only to store prompt id
  promptId?: string;

  // UI only param to check whether we need to save order changes
  didChangeMade: boolean;

  // UI only param to check whether we need to create order instead of saving them
  isCreated: boolean;
}

enum OrderStatus {
  New = 'NEW',
  Confirmed = 'CONFIRMED',
  Rejected = 'REJECTED',
  TimedOut = 'TIMED_OUT',

  // trails only
  TrailSyncFailed = 'ERP_SYNC_FAILED',
}

enum OrderDeliveryStatus {
  Dispatched = 'DISPATCHED',
  Delivered = 'DELIVERED',
}

class OrderStatusTrail {
  createdAt: string;

  deliveryEta: string;

  message: string;

  status: OrderStatus;
}

class OrderDeliveryStatusTrail {
  createdAt: string;

  deliveryEta: string;

  status: OrderStatus;

  message: string;
}

enum LlmScore {
  BEST = 'BEST',
  ALMOST_SURE = 'ALMOST_SURE',
  SURE = 'SURE',
  CONFIDENT = 'CONFIDENT',
  NOT_CONFIDENT = 'NOT_CONFIDENT',
  NOT_SURE = 'NOT_SURE',
}

class ProductWithQuantity {
  id: string;

  positionId?: string; // Used to match the keyword and product

  name: string;

  quantity: number;

  product: Product;

  unit: Unit;

  price: Price;

  comment: string;

  score?: number;

  llmScore?: LlmScore;

  autoMatched: boolean;

  autoMatchedQuantity?: number;

  autoMatchedId?: string;

  autoMatchedIdOrSku?: string;

  autoMatchedUnit?: Unit;

  defaultUnitConversionFactor: number | null;

  freeFields?: Record<string, any>;

  // Used by ui to perform a correct draft search without changing displayed name
  updatedName?: string;

  // Error messages related to the product
  errors?: Record<string, string>;

  // User can confirm that this product is already checked
  confirmed?: boolean;

  // Ui only param to distinguish product with same name
  uiId: string;

  constructor(data: Partial<ProductWithQuantity>) {
    this.id = data.id;
    this.name = data.name;
    this.quantity = data.quantity ?? 0;
    this.product = data.product;
    this.unit = data.unit;
    this.price = data.price;
    this.comment = data.comment;
    this.score = data.score;
    this.autoMatched = data.autoMatched;
    this.autoMatchedId = data.autoMatchedId;
    this.autoMatchedIdOrSku = data.autoMatchedIdOrSku;
    this.autoMatchedUnit = data.autoMatchedUnit;
    this.defaultUnitConversionFactor = data.defaultUnitConversionFactor;
    this.freeFields = data.freeFields;
    this.updatedName = data.updatedName;
    this.errors = data.errors;
    this.confirmed = data.confirmed;

    this.uiId = uuidv4();
  }
}

class OrderPrices {
  @Expose({ name: 'position_prices' })
    positionPrices: Record<string, Price>; // Position ID -> Price

  @Expose({ name: 'total_price' })
    totalPrice: Price;
}

export {
  convertibleUnits,
  LlmScore,
  Order,
  OrderDeliveryStatus,
  OrderDeliveryStatusTrail,
  OrderPrices,
  OrderStatus,
  ProductWithQuantity,
};
