import { TopSellingProduct } from 'models/Analytics';

import { SalesMetric } from '../types/metric';
import { TimePeriod } from '../types/timePeriod';

import { httpGetV1 } from 'utils/xhr';

interface FetchTopSellingProductsProps {
  date?: Date;
  period: string;
  metric: SalesMetric;
  limit?: number;
}

export const fetchTopSellingProducts = (
  date: Date | null,
  period: TimePeriod,
  metric: SalesMetric,
  limit: number = 10,
): Promise<TopSellingProduct[]> => {
  if (!period) return Promise.reject(new Error('Period is required'));

  const params: FetchTopSellingProductsProps = {
    period: period.toLowerCase(),
    metric,
    limit,
  };

  if (date) {
    params.date = date;
  }

  return httpGetV1('/products/analytics/sales', {
    params,
    classType: TopSellingProduct,
  })
    .then((response) => response?.data?.result || []);
};
