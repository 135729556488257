import { useRef, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import { reaction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { twMerge } from 'tailwind-merge';

import {
  faArrowRight,
  faChevronDown,
  faInbox,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, Combobox, Loader, useCombobox,
} from '@mantine/core';

import { ROUTES } from 'config/routes';

import { globalInboxes } from 'state/globalInboxes';

import LoadingOverlay from 'components/ui/LoadingOverlay';

import DashboardInboxItem from './DashboardInboxItem';

const DashboardInbox = () => {
  const navigate = useNavigate();

  const combobox = useCombobox();
  const [newMessageIds, setNewMessageIds] = useState<Set<string>>(new Set());
  const prevMessageIdsRef = useRef<Set<string>>(new Set());

  const inboxName = globalInboxes.inboxes.find(
    (inbox) => inbox.id === globalInboxes.selectedInboxId,
  )?.name;

  reaction(() => globalInboxes.inboxMessages, () => {
    const currentMessages = globalInboxes.inboxMessages;

    if (!currentMessages) return;

    // If it's not the first render, track new messages
    if (prevMessageIdsRef.current.size > 0) {
      const newMsgIds: Set<string> = new Set();

      // Add all current message IDs to a set
      currentMessages.forEach((msg) => {
        // If this message ID wasn't in our previous tracking set, it's new
        if (!prevMessageIdsRef.current.has(msg.id)) {
          newMsgIds.add(msg.id);
        }
      });

      // Clear animation after 2 seconds
      if (newMsgIds.size > 0) {
        setNewMessageIds(newMsgIds);
        setTimeout(() => {
          setNewMessageIds(new Set());
        }, 2000);
      }
    }

    prevMessageIdsRef.current = new Set(currentMessages.map((msg) => msg.id));
  });

  return (
    <div className="flex flex-col border border-light-grey rounded-lg bg-white px-4 py-4 shadow-blue-light-tint-drop-shadow h-full">
      <div className="inline-flex h-fit w-full flex-col items-start justify-center gap-1 rounded-[20px]">
        <Combobox
          store={combobox}
        >
          <Combobox.Target>
            <button
              type="button"
              className="inline-flex items-center justify-between self-stretch rounded-md bg-black px-3 py-2 text-white"
              onClick={() => combobox.toggleDropdown()}
            >
              <div className="flex items-center justify-start gap-2">
                <FontAwesomeIcon icon={faInbox} />

                {globalInboxes.isLoading && <Loader size="xs" />}

                {inboxName && (
                  <div className="text-[15px] font-normal text-white">
                    {inboxName}
                  </div>
                )}
              </div>
              <FontAwesomeIcon icon={faChevronDown} className="text-white" />
            </button>
          </Combobox.Target>
          <Combobox.Dropdown>
            {
              globalInboxes.inboxes.map((inbox) => (
                <Combobox.Options
                  key={inbox.id}
                  onClick={() => {
                    globalInboxes.setSelectedInboxId(inbox.id);
                    combobox.closeDropdown();
                  }}
                  className={twMerge(
                    'text-sm',
                  )}
                >
                  <div className="flex items-center justify-between px-1 py-2 hover:cursor-pointer hover:bg-gray-50 mb-1 last:mb-0">
                    {inbox.name}
                  </div>
                </Combobox.Options>
              ))
            }
          </Combobox.Dropdown>
        </Combobox>
      </div>

      <div className="relative flex-1">
        {globalInboxes.isInboxMessagesLoading ? (
          <LoadingOverlay visible />
        ) : (
          globalInboxes.inboxMessages.map((message) => (
            <DashboardInboxItem
              key={message.id}
              message={message}
              newMessageIds={newMessageIds}
              navigate={navigate}
            />
          ))
        )}
      </div>

      {
        globalInboxes.inboxMessages.length > 0 && (
          <div className="w-full flex justify-center">
            <NavLink to={ROUTES.INBOX({})} className="w-fit">
              <Button
                variant="outline"
                size="xs"
                radius="xl"
                color="var(--purple-highlight-2)"
                rightSection={<FontAwesomeIcon icon={faArrowRight} />}
              >
                View all messages
              </Button>
            </NavLink>
          </div>
        )
      }
    </div>
  );
};

export default observer(DashboardInbox);
