import { StateCreator } from 'zustand';

import { PRODUCTS_MODEL_PATH } from 'features/instruction/constants';

import { ProductWithQuantity } from 'features/order/models';

import { ProcessOrderDraftsStore, ProductsSlice } from './types';

import { setValueByPath } from 'features/instruction/utils';

export const createProductSlice: StateCreator<ProcessOrderDraftsStore, [], [], ProductsSlice> = (set, get) => ({
  products: {},
  didProductsChangeMade: false,
  updateProductByUiId: (uiId: string, values: any, orderId_?: string) => {
    set((state) => {
      const orderId = orderId_ || state.selectedOrderId;
      const products = state.products[orderId] || [];

      const productIndex = products.findIndex((product) => product.uiId === uiId);
      if (productIndex === -1) {
        return {};
      }

      const newProducts = [...products];
      newProducts[productIndex] = { ...newProducts[productIndex], ...values };

      return {
        products: { ...state.products, [orderId]: newProducts },
        didProductsChangeMade: true,
      };
    });
  },
  updateProductByUiIdAndPath: (uiId: string, path: string, val: any, orderId_?: string) => {
    set((state) => {
      const orderId = orderId_ || state.selectedOrderId;
      const _path = path.split(`${PRODUCTS_MODEL_PATH}.*.`)[1];

      const products = state.products[orderId] || [];
      const productIndex = products.findIndex((product) => product.uiId === uiId);
      if (productIndex === -1) {
        return {};
      }

      const newProduct = setValueByPath(products[productIndex], val, _path);

      const newProducts = [...products];
      newProducts[productIndex] = newProduct;

      return {
        products: { ...state.products, [orderId]: newProducts },
        didProductsChangeMade: true,
      };
    });
  },
  addNewProduct: (product: ProductWithQuantity, orderId_?: string) => {
    set((state) => {
      const orderId = orderId_ || state.selectedOrderId;
      const products = state.products[orderId] || [];

      const newProducts = [...products, product];

      return {
        products: { ...state.products, [orderId]: newProducts },
        didProductsChangeMade: true,
      };
    });
  },
  removeProductByUiIdAndPositionId: (uiId: string, positionId: string, orderId_?: string) => {
    const orderId = orderId_ || get().selectedOrderId;
    get().removeKeywordsByFieldId(positionId);
    get().deleteProductErrorsByUiId(uiId);

    set((state) => {
      const products = state.products[orderId] || [];

      const newProducts = [...products].filter((product) => (
        (uiId ? product.uiId !== uiId : true)
        && (positionId ? product.positionId !== positionId : true)
      ));

      return {
        products: { ...state.products, [orderId]: newProducts },
        didProductsChangeMade: true,
      };
    });
  },
  resetProducts: (orderId_?: string) => {
    set((state) => {
      const orderId = orderId_ || state.selectedOrderId;
      return ({
        products: { ...state.products, [orderId]: [] },
        didProductsChangeMade: true,
      });
    });
  },
});
