import { useMemo } from 'react';

import { getFilteredErrors } from 'features/order/store/process-order-drafts/utils';
import { isUnitConvertible } from 'features/order/utils/order';
import { getUnits } from 'features/product/utils/product';

import { useProcessOrderDraftsStore } from 'features/order/store/process-order-drafts';

const getSaveButtonDisabledMsg = ({
  didChangeMade,
  isValidCustomer,
  existsAtLeastOneProduct,
  isValidQuantity,
  isValidUnit,
  deletedOrderIds,
}: {
  didChangeMade: boolean,
  isValidCustomer: boolean,
  existsAtLeastOneProduct: boolean,
  isValidQuantity: boolean,
  isValidUnit: boolean,
  deletedOrderIds: string[],
}) => {
  if (deletedOrderIds.length === 0 && !didChangeMade) {
    return 'No changes have been made yet';
  }

  if (!isValidCustomer) {
    return 'Select a customer';
  }

  // Check if there is at least one product
  if (!existsAtLeastOneProduct) {
    return 'Add at least one product';
  }

  if (!isValidQuantity) {
    return 'Add a valid quantity to all products';
  }

  if (!isValidUnit) {
    return 'Add a valid unit to all products';
  }

  return '';
};

const getConfirmButtonDisabledMsg = ({
  didChangeMade,
  existsAtLeastOneProduct,
  filteredErrors,
  deletedOrderIds,
}: {
  didChangeMade: boolean,
  existsAtLeastOneProduct: boolean,
  filteredErrors: string[],
  deletedOrderIds: string[],
}) => {
  if (didChangeMade || deletedOrderIds.length > 0) {
    return 'Save changes before confirming.';
  }

  if (!existsAtLeastOneProduct) {
    return 'Add at least one product';
  }

  if (filteredErrors.length > 0) {
    return 'Fix all errors before confirming.';
  }

  return '';
};

export const useButtonsStatus = () => {
  const groupOrders = useProcessOrderDraftsStore((state) => state.groupOrders);
  const deletedOrderIds = useProcessOrderDraftsStore((state) => state.deletedOrderIds);
  const products = useProcessOrderDraftsStore((state) => state.products);
  const didOrdersChangeMade = useProcessOrderDraftsStore((state) => state.didOrdersChangeMade);
  const didProductsChangeMade = useProcessOrderDraftsStore((state) => state.didProductsChangeMade);
  const errors = useProcessOrderDraftsStore((state) => state.errors);

  const filteredErrors = useMemo(() => getFilteredErrors(errors), [errors]);

  // Note that we set existsAtLeastOneProduct to false if there is no name to any of the products (means no product is added)
  const existsAtLeastOneProduct = !Object.values(groupOrders).some(
    (o) => !products[o.id] || products[o.id].length === 0 || products[o.id].some((p) => !p?.product?.name),
  );
  const isValidCustomer = !Object.values(groupOrders).some((o) => !o?.customer?.customer);
  const isValidQuantity = !Object.values(groupOrders).some((o) => products[o.id]?.some((p) => !p?.quantity));
  const isValidUnit = !Object.values(groupOrders).some((o) => products[o.id]?.some(
    (p) => !getUnits(p?.product).includes(p?.unit?.symbol) // Check if unit is included in product units
              && !isUnitConvertible(p?.unit?.symbol), // Check if unit is convertible, if so, it's valid
  ));

  const saveButtonDisabledMsg = getSaveButtonDisabledMsg({
    didChangeMade: didOrdersChangeMade || didProductsChangeMade,
    isValidCustomer,
    existsAtLeastOneProduct,
    isValidQuantity,
    isValidUnit,
    deletedOrderIds,
  });

  const confirmButtonDisabledMsg = getConfirmButtonDisabledMsg({
    didChangeMade: didOrdersChangeMade || didProductsChangeMade,
    existsAtLeastOneProduct,
    filteredErrors,
    deletedOrderIds,
  });

  return {
    saveButtonDisabledMsg,
    confirmButtonDisabledMsg,
  };
};
