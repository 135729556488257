import { BusinessSettings } from 'features/settings/models';

import { httpGetV1 } from 'utils/xhr';

export const fetchBusinessSettings = () => httpGetV1('/businesses/me/settings')
  .then((response) => {
    const businessSettings = {
      ...response.data.settings,
      businessTimes:
        response.data?.settings?.businessTimes
        && response.data?.settings?.businessTimes.length > 0
          ? response.data?.settings?.businessTimes
          : [{ from: null, to: null }],
    } as BusinessSettings;

    return businessSettings;
  });
