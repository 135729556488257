import { Chat } from '../models';
import { GetDocumentsQueryType } from 'models/GetDocumentsQueryType';

import { parsePaginatedApiResponse } from 'utils';
import { httpGetV2 } from 'utils/xhr';

export interface FetchChatsParams {
  include_temporary?: boolean;
  ai_chats_only?: boolean;

  cursor?: string;
  limit?: number;
  queryType?: GetDocumentsQueryType;
}

export const fetchChats = async (params: FetchChatsParams) => httpGetV2('/chats', {
  params,
  classType: Chat,
}).then((res) => parsePaginatedApiResponse<Chat>(res));
