import { create } from 'zustand';

type WizardState = {
  currentStep: number;
  completeStep: () => void;
};

export const useWizardSequenceStore = create<WizardState>((set) => ({
  currentStep: 0,
  completeStep: () => set((state) => ({ currentStep: state.currentStep + 1 })),
}));
