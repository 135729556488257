import {
  useEffect, useMemo, useRef, useState,
} from 'react';

import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

import { ArrowRightIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline';
import {
  InputWrapper, ScrollArea, Textarea,
  TextInput,
} from '@mantine/core';

import { Action, Workflow } from '../../../../features/instruction/types';
import { TagVariant } from '../../../../types/tag';

import { useInstructionContext } from '../../../../contexts/useInstructionContext';

import { Card } from 'components/common/Card';
import { Tag } from 'components/common/Tag';

import cartDark from '../../../../assets/utils/cart-dark.svg';
import cartLight from '../../../../assets/utils/cart-light.svg';
import docDark from '../../../../assets/utils/doc-dark.svg';
import docLight from '../../../../assets/utils/doc-light.svg';
import { capitalizeFirstLetter } from 'utils/strings';

import { Button } from '../../../ui/Button';
import {
  ChooseExistingInstruction,
  // ChooseAction,
  ChooseWorkflow,
  RedirectToOrder,
  SpecifyInstructionName,
} from './steps';

interface Props {
  onNext: () => void;

  isWithAdam?: boolean;
}

const vanishAnimationDuration = 300;

const buttonContainerVariants = {
  hidden: { opacity: 1 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3,
    },
  },
};

const buttonVariants = {
  hidden: { opacity: 0, scale: 0.5 },
  show: { opacity: 1, scale: 1 },
};

const workflows = [
  {
    category: Workflow.ORDER,
    icon: cartLight,
    iconDark: cartDark,
    text: 'Order',
    soon: false,
  },
  {
    category: Workflow.SUPPLIER_NOTE,
    icon: docLight,
    iconDark: docDark,
    text: 'Supplier note',
    soon: true,
  },
];

const ChooseInstructionType = ({ onNext, isWithAdam = false }: Props) => {
  const {
    selectedWorkflow,
    setSelectedWorkflow,
    selectedAction,
    instructionName,
    setInstructionName,
  } = useInstructionContext();
  const [scale, setScale] = useState(1);

  const categoryMsgRef = useRef<HTMLDivElement>(null);
  const actionMsgRef = useRef<HTMLDivElement>(null);
  const textareaWrapperRef = useRef<HTMLDivElement>(null);
  const mainWrapperRef = useRef<HTMLDivElement>(null);

  const inputActive = useMemo(() => {
    if (selectedWorkflow && selectedAction === Action.CREATE && instructionName === '') {
      return true;
    }

    return false;
  }, [selectedWorkflow, selectedAction, instructionName]);

  // const onActionButtonClicked = (_action: Action) => {
  //   setSelectedAction(_action);
  // };

  const chatEnterPressed = (value: string) => {
    setInstructionName(value);
  };

  useEffect(() => {
    if (mainWrapperRef.current) {
      mainWrapperRef.current.scrollTop = mainWrapperRef.current.scrollHeight;
    }
  }, [instructionName]);

  useEffect(() => {
    if (categoryMsgRef.current) {
      categoryMsgRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedWorkflow]);

  useEffect(() => {
    if (actionMsgRef.current) {
      actionMsgRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedAction]);

  const proceed = () => {
    setScale(0);
    setTimeout(() => {
      onNext();
    }, vanishAnimationDuration);
  };

  useEffect(() => {
    setScale(1);
  }, []);

  if (!isWithAdam) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <motion.div
          initial={{ scale: 1 }}
          animate={{ scale }}
          transition={{ duration: vanishAnimationDuration / 1000 }}
        >
          <Card>
            <Card.Body className="relative flex w-[40vw] flex-col gap-2 2xl:w-[40vw]">
              <div className="flex flex-col justify-center gap-2">
                <h1 className="text-xl font-semibold leading-none text-gray-900">
                  Create new instruction
                </h1>
                <div className="flex items-center gap-2 text-sm font-medium text-gray-600">
                  Set a name for your instruction and choose a workflow
                </div>
              </div>

              <TextInput
                label="Instruction name"
                className="w-full"
                onChange={(e) => setInstructionName(e.target.value)}
              />

              <InputWrapper label="Workflow">

                <motion.div
                  className="flex space-x-3 pt-3"
                  variants={buttonContainerVariants}
                  initial="hidden"
                  animate="show"
                >
                  {workflows.map((workflow) => (
                    <motion.div
                      key={workflow.category}
                      className="flex flex-col items-center space-y-2"
                      variants={buttonVariants}
                    >
                      <button
                        type="button"
                        className={twMerge(
                          'group relative flex flex-col items-center justify-center rounded-md border border-gray-200 bg-white px-2 py-3 shadow-sm hover:border-primary-200 hover:shadow-md',
                          selectedWorkflow === workflow.category && 'border-primary-200',
                        )}
                        onClick={() => {
                          if (workflow.soon) {
                            return;
                          }

                          setSelectedWorkflow(workflow.category);
                        }}
                      >
                        {workflow.soon && (
                        <Tag
                          className="right-2 top-2"
                          tagTitle="soon"
                          tagVariant={TagVariant.GRAY}
                          hideCircle
                        />
                        )}
                        <img
                          src={workflow.icon}
                          alt={workflow.text}
                          className={twMerge(
                            'h-[150px] w-[150px] group-hover:hidden',
                            selectedWorkflow === workflow.category && 'hidden',
                          )}
                        />
                        <img
                          src={workflow.iconDark}
                          alt={workflow.text}
                          className={twMerge(
                            'hidden h-[150px] w-[150px] group-hover:block',
                            selectedWorkflow === workflow.category && 'block',
                          )}
                        />
                        <p
                          className={twMerge(
                            'text-sm font-semibold text-gray-300',
                            selectedWorkflow === workflow.category
                              ? 'text-primary-500'
                              : 'group-hover:text-primary-500',
                          )}
                        >
                          {workflow.text}
                        </p>
                      </button>
                    </motion.div>
                  ))}
                </motion.div>
              </InputWrapper>

              <motion.div
                className="flex items-center justify-end"
                variants={buttonVariants}
              >
                <Button
                  icon={<ArrowRightIcon className="h-6 w-6 text-white" />}
                  theme="indigo"
                  onClick={proceed}
                  disabled={!selectedWorkflow || !instructionName}
                />
              </motion.div>
            </Card.Body>
          </Card>
        </motion.div>
      </div>
    );
  }

  return (
    <div className="flex flex-1 items-center justify-center">
      <motion.div
        initial={{ scale: 1 }}
        animate={{ scale }}
        transition={{ duration: vanishAnimationDuration / 1000 }}
      >
        <Card>
          <Card.Body className="relative flex max-h-[70vh] min-h-[70vh] w-[60vw] flex-col 2xl:max-h-[60vh] 2xl:min-h-[60vh] 2xl:w-[40vw]">
            <ScrollArea.Autosize
              scrollbars="y"
              className="flex-1 space-y-6"
              viewportRef={mainWrapperRef}
            >
              <ChooseWorkflow onComplete={setSelectedWorkflow} />

              {selectedWorkflow && (
              <div ref={categoryMsgRef} className="flex h-fit justify-end">
                <motion.div
                  initial={{ opacity: 0, y: 15 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.15, delay: 0.1 }}
                  exit={{ opacity: 0, transition: { duration: 0.15 } }}
                >
                  <span className="rounded-md bg-primary-50/80 px-4 py-2">
                    {capitalizeFirstLetter(selectedWorkflow)}
                  </span>
                </motion.div>
              </div>
              )}

              {/* <ChooseAction onActionButtonClicked={onActionButtonClicked} /> */}

              {/* {selectedWorkflow && selectedAction && (
              <motion.div
                ref={actionMsgRef}
                initial={{ opacity: 0, y: 15 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.15, delay: 0.1 }}
                exit={{ opacity: 0, transition: { duration: 0.15 } }}
                className="flex h-fit justify-end"
              >
                <span className="rounded-md bg-primary-50/80 px-4 py-2">
                  {capitalizeFirstLetter(selectedAction)}
                </span>
              </motion.div>
              )} */}

              {selectedWorkflow && selectedAction === Action.CREATE && (
              <>
                <SpecifyInstructionName />

                {instructionName && (
                <motion.div
                  ref={actionMsgRef}
                  initial={{ opacity: 0, y: 15 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.15, delay: 0.1 }}
                  exit={{ opacity: 0, transition: { duration: 0.15 } }}
                  className="flex h-fit justify-end"
                >
                  <span className="rounded-md bg-primary-50/80 px-4 py-2">
                    {instructionName}
                  </span>
                </motion.div>
                )}

                {selectedAction === Action.CREATE
                      && instructionName !== '' && (
                        <RedirectToOrder onNext={proceed} />
                )}
              </>
              )}

              {selectedAction === Action.EDIT && (
              <ChooseExistingInstruction />
              )}
            </ScrollArea.Autosize>
            {/* Textarea */}
            <div
              ref={textareaWrapperRef}
              className="flex max-h-[4rem] items-end"
            >
              <Textarea
                placeholder=""
                disabled={!inputActive}
                className="w-full border-0 focus:ring-0 focus-visible:ring-0"
                radius="lg"
                autosize
                maxRows={3}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    const value = (e.target as HTMLTextAreaElement).value;
                    (e.target as HTMLTextAreaElement).value = '';
                    chatEnterPressed(value);
                  }
                }}
                onChange={(e) => {
                  if (textareaWrapperRef.current) {
                    textareaWrapperRef.current.style.height = `${e.target.scrollHeight}px`;
                  }
                  if (mainWrapperRef.current) {
                    mainWrapperRef.current.scrollTop = mainWrapperRef.current.scrollHeight;
                  }
                }}
                rightSection={(
                  <div className="flex h-full flex-col justify-end pb-[0.5rem]">
                    <PaperAirplaneIcon
                      className={`h-5 w-5 ${inputActive ? 'text-gray-600' : 'text-gray-400'}`}
                    />
                  </div>
                  )}
              />
            </div>
          </Card.Body>
        </Card>
      </motion.div>
    </div>
  );
};

export default ChooseInstructionType;
