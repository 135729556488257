import { SVGProps } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props extends SVGProps<SVGSVGElement> {
  className?: string;
}

const ExclamationTriangleIcon = ({
  className,
}: Props) => (
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={twMerge('w-6 h-6', className)}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M2.17854 19.4773C1.64566 20.4764 2.36969 21.6832 3.50207 21.6832L20.8308 21.6832C21.9632 21.6832 22.6872 20.4764 22.1543 19.4773L13.9311 4.05882C13.1782 2.64706 11.1547 2.64706 10.4017 4.05882L2.17854 19.4773ZM13.1667 8.99831C13.1658 8.44602 12.7174 7.99903 12.1651 7.99992C11.6128 8.00081 11.1658 8.44924 11.1667 9.00153L11.1748 14.0016C11.1757 14.5539 11.6241 15.0009 12.1764 15C12.7287 14.9991 13.1757 14.5507 13.1748 13.9984L13.1667 8.99831ZM12.1667 16.7649C11.6144 16.7649 11.1667 17.2126 11.1667 17.7649V17.773C11.1667 18.3252 11.6144 18.773 12.1667 18.773H12.1748C12.727 18.773 13.1748 18.3252 13.1748 17.773V17.7649C13.1748 17.2126 12.727 16.7649 12.1748 16.7649H12.1667Z" />
  </svg>
);

export default ExclamationTriangleIcon;
