// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qQ2UmbIU7lx36qMraUV0 {\n    padding: 16px;\n    background-color: white;\n    border-radius: 10px;\n    border: 1.5px solid var(--light-grey);\n    box-shadow: var(--shadow-blue-light-tint-drop-shadow);\n}\n", "",{"version":3,"sources":["webpack://./src/features/order/components/order-list/OrderSearchBar/DatePickers/DatePickersPopover.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,qCAAqC;IACrC,qDAAqD;AACzD","sourcesContent":[".dropdown {\n    padding: 16px;\n    background-color: white;\n    border-radius: 10px;\n    border: 1.5px solid var(--light-grey);\n    box-shadow: var(--shadow-blue-light-tint-drop-shadow);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": "qQ2UmbIU7lx36qMraUV0"
};
export default ___CSS_LOADER_EXPORT___;
