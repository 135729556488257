import { forwardRef, Ref, SVGProps } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props extends SVGProps<SVGSVGElement> {
  className?: string;
}

const MenuParagraphIcon = ({ className, ...props }: Props, ref: Ref<SVGSVGElement>) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    className={twMerge('w-6 h-6', className)}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.34961 8.0001C5.34961 7.64111 5.64062 7.3501 5.99961 7.3501H17.9996C18.3586 7.3501 18.6496 7.64111 18.6496 8.0001C18.6496 8.35908 18.3586 8.6501 17.9996 8.6501H5.99961C5.64062 8.6501 5.34961 8.35908 5.34961 8.0001ZM5.34961 12.0001C5.34961 11.6411 5.64062 11.3501 5.99961 11.3501H9.99961C10.3586 11.3501 10.6496 11.6411 10.6496 12.0001C10.6496 12.3591 10.3586 12.6501 9.99961 12.6501H5.99961C5.64062 12.6501 5.34961 12.3591 5.34961 12.0001ZM5.99961 15.3501C5.64062 15.3501 5.34961 15.6411 5.34961 16.0001C5.34961 16.3591 5.64062 16.6501 5.99961 16.6501H13.9996C14.3586 16.6501 14.6496 16.3591 14.6496 16.0001C14.6496 15.6411 14.3586 15.3501 13.9996 15.3501H5.99961Z"
    />
  </svg>
);

export default forwardRef(MenuParagraphIcon);
