import {
  useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Textarea } from '@mantine/core';

import { Order } from 'features/order/models';

import { useFetchTeamMembers } from 'hooks/useTeamMembers';

import { Button } from 'components/ui/Button';
import { SelectOption } from 'components/ui/Select';

import AssigneeSelect from './Assignee/AssigneeSelect';

interface Props {
  order: Order;
  assignOrder: (
    teamMemberId: string,
    teamMemberName: string,
    assignedComment: string,
  ) => void;

  onCancel?: () => void;
  preventFetch?: boolean;

  assignMe?: boolean;
}

const InitialAssignee = ({
  order,
  assignOrder,
  onCancel,
  preventFetch = false,
  assignMe = true,
}: Props) => {
  const { t } = useTranslation();

  const {
    teamMembers, isLoading, loadTeamMembers,
  } = useFetchTeamMembers();

  const [assignedTeamMember, setAssignedTeamMember] = useState<SelectOption>(null);
  const [assignedComment, setAssignedComment] = useState<string>('');

  const onAssignButtonClick = useCallback(() => {
    if (assignedTeamMember?.value) {
      assignOrder(
        assignedTeamMember.value,
        assignedTeamMember.label,
        assignedComment,
      );
    }
  }, [
    assignedTeamMember?.value,
    assignedTeamMember?.label,
    assignOrder,
    assignedComment,
  ]);

  // Set comment if present
  useEffect(() => {
    if (preventFetch) return;

    if (order?.draft?.comment) {
      setAssignedComment(order.draft.comment);
    } else {
      setAssignedComment('');
    }
  }, [order, setAssignedComment, preventFetch]);

  return (
    <div>
      {/* title */}
      <div className="pb-xl">
        <h1 className="pb-sm text-title-sm font-semibold">
          {t('order.processOrderDrafts.rightSide.assignmentRequired')}
        </h1>
        <div className="text-label-caption-md text-description">
          {t('order.processOrderDrafts.rightSide.assignmentRequired.description')}
        </div>
      </div>
      {/* main */}
      <div className="space-y-4">
        <AssigneeSelect
          isLoading={isLoading}
          teamMembers={teamMembers}
          assignedTeamMember={assignedTeamMember}
          setAssignedTeamMember={setAssignedTeamMember}
          loadTeamMembers={loadTeamMembers}
          assignMe={assignMe}
        />

        <Textarea
          placeholder={t('order.processOrderDrafts.rightSide.addSomeComment')}
          label={t('order.processOrderDrafts.rightSide.comment')}
          autosize
          minRows={2}
          maxRows={4}
          value={assignedComment}
          onChange={(event) => setAssignedComment(event.currentTarget.value)}
        />

        <div
          className={`flex w-full ${onCancel ? 'justify-between' : 'justify-end'}`}
        >
          {onCancel && (
            <Button title={t('order.processOrderDrafts.rightSide.cancel')} theme="secondary" onClick={onCancel} />
          )}
          <Button
            title={t('order.processOrderDrafts.rightSide.assign')}
            theme="primary"
            disabled={assignedTeamMember === null}
            onClick={onAssignButtonClick}
          />
        </div>
      </div>
    </div>
  );
};

export default InitialAssignee;
