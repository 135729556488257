import { useMemo } from 'react';

import { useProcessOrderDraftsStore } from 'features/order/store/process-order-drafts';

export const useSelectedParentOrder = () => {
  const groupOrders = useProcessOrderDraftsStore((state) => state.groupOrders);

  const order = useMemo(() => Object.values(groupOrders).find(
    (o) => !o.isGroupSibling,
  ), [groupOrders]);

  return {
    order,
  };
};
