import { Notifications } from './classes/Notifications';

let globalNotifications: Notifications = null;

const initGlobalNotifications = async () => {
  globalNotifications = new Notifications();
  await globalNotifications.initPersistence();
};

export { globalNotifications, initGlobalNotifications };
