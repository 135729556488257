// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PMG4Lm_nOVcb2pFXr7L7 {\n  background-color: var(--violet-hue-selection-button-3);\n}\n\n.zr5RRTol5PGYYehLnJWo {\n  background-color: var(--black);\n  color: var(--white);\n}\n\n.FzOV_qEYzcm7e53Drd8h {\n  color: var(--black);\n  font-family: var(--font-screen-sm-regular-font-family);\n  font-size: var(--font-screen-sm-regular-font-size);\n  font-style: var(--font-screen-sm-regular-font-style);\n  font-weight: var(--font-screen-sm-regular-font-weight);\n  letter-spacing: var(--font-screen-sm-regular-letter-spacing);\n  line-height: var(--font-screen-sm-regular-line-height);\n\n  padding-left: 10px;\n  padding-right: 10px;\n}\n\n.FzOV_qEYzcm7e53Drd8h:where([data-active]) {\n    color: var(--white);\n  }\n", "",{"version":3,"sources":["webpack://./src/features/order/components/order-list/OrderSearchBar/ExportedSegmentedControl/ExportedSegmentedControl.module.css"],"names":[],"mappings":"AAAA;EACE,sDAAsD;AACxD;;AAEA;EACE,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,sDAAsD;EACtD,kDAAkD;EAClD,oDAAoD;EACpD,sDAAsD;EACtD,4DAA4D;EAC5D,sDAAsD;;EAEtD,kBAAkB;EAClB,mBAAmB;AAIrB;;AAHE;IACE,mBAAmB;EACrB","sourcesContent":[".root {\n  background-color: var(--violet-hue-selection-button-3);\n}\n\n.indicator {\n  background-color: var(--black);\n  color: var(--white);\n}\n\n.label {\n  color: var(--black);\n  font-family: var(--font-screen-sm-regular-font-family);\n  font-size: var(--font-screen-sm-regular-font-size);\n  font-style: var(--font-screen-sm-regular-font-style);\n  font-weight: var(--font-screen-sm-regular-font-weight);\n  letter-spacing: var(--font-screen-sm-regular-letter-spacing);\n  line-height: var(--font-screen-sm-regular-line-height);\n\n  padding-left: 10px;\n  padding-right: 10px;\n  &:where([data-active]) {\n    color: var(--white);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "PMG4Lm_nOVcb2pFXr7L7",
	"indicator": "zr5RRTol5PGYYehLnJWo",
	"label": "FzOV_qEYzcm7e53Drd8h"
};
export default ___CSS_LOADER_EXPORT___;
