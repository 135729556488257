/* eslint-disable jsx-a11y/label-has-associated-control */
import { ElementType, useEffect, useState } from 'react';

interface SimpleWizardProps {
  content: React.ReactNode;
  isVisible: boolean;
  onComplete: (doNotShowAgain: boolean) => void;

  Icon?: ElementType;
}

const Content = ({
  content,
  onComplete,
  doNotShowAgain,
  setDoNotShowAgain,
}: {
  content: React.ReactNode;
  onComplete: (doNotShowAgain: boolean) => void;
  doNotShowAgain: boolean;
  setDoNotShowAgain: (doNotShowAgain: boolean) => void;
}) => (
  <div className="inline-flex flex-col items-center gap-2 relative">
    {/* Content */}
    {content}

    <div className="flex w-full items-center gap-2 relative flex-[0_0_auto]">
      <input
        type="checkbox"
        id="doNotShowAgain"
        className="h-4 w-4 rounded border-black-text-70 text-purple-highlight-2"
        checked={doNotShowAgain}
        onChange={(e) => setDoNotShowAgain(e.target.checked)}
      />
      <label
        htmlFor="doNotShowAgain"
        className="relative w-fit mt-[-1.00px] font-font-screen-sm-regular font-[number:var(--font-screen-sm-regular-font-weight)] text-black-text-70 text-[length:var(--font-screen-sm-regular-font-size)] tracking-[var(--font-screen-sm-regular-letter-spacing)] leading-[var(--font-screen-sm-regular-line-height)] whitespace-nowrap [font-style:var(--font-screen-sm-regular-font-style)]"
      >
        Do not show again
      </label>
    </div>

    <div className="flex items-start justify-end gap-1 relative self-stretch w-full flex-[0_0_auto]">
      <button
        type="button"
        onClick={() => onComplete(false)}
        className="border border-solid border-purple-highlight-3 inline-flex items-center gap-1 px-3 py-1 relative flex-[0_0_auto] rounded-full"
      >
        <div className="relative w-fit mt-[-1.00px] font-font-screen-sm-regular font-[number:var(--font-screen-sm-regular-font-weight)] text-purple-highlight-3 text-[length:var(--font-screen-sm-regular-font-size)] tracking-[var(--font-screen-sm-regular-letter-spacing)] leading-[var(--font-screen-sm-regular-line-height)] whitespace-nowrap [font-style:var(--font-screen-sm-regular-font-style)]">
          Skip
        </div>
      </button>

      <button
        type="button"
        onClick={() => onComplete(doNotShowAgain)}
        className="bg-purple-highlight-2 inline-flex items-center gap-1 px-3 py-1 relative flex-[0_0_auto] rounded-full"
      >
        <div className="relative w-fit mt-[-1.00px] font-font-screen-sm-regular font-[number:var(--font-screen-sm-regular-font-weight)] text-white text-[length:var(--font-screen-sm-regular-font-size)] tracking-[var(--font-screen-sm-regular-letter-spacing)] leading-[var(--font-screen-sm-regular-line-height)] whitespace-nowrap [font-style:var(--font-screen-sm-regular-font-style)]">
          Got it
        </div>
      </button>
    </div>
  </div>
);

const Wizard = ({
  content, isVisible, onComplete,
  Icon,
}: SimpleWizardProps) => {
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);

  useEffect(() => {
    console.log('doNotShowAgain', doNotShowAgain);
  }, [doNotShowAgain]);

  if (!isVisible) {
    return null;
  }

  if (Icon) {
    return (
      <div className="inline-flex items-start gap-3 p-2 relative flex-[0_0_auto]">
        <Icon className="size-5" />
        <Content
          content={content}
          onComplete={onComplete}
          doNotShowAgain={doNotShowAgain}
          setDoNotShowAgain={setDoNotShowAgain}
        />
      </div>
    );
  }

  return (
    <div className="p-2">
      <Content
        content={content}
        onComplete={onComplete}
        doNotShowAgain={doNotShowAgain}
        setDoNotShowAgain={setDoNotShowAgain}
      />
    </div>
  );
};

export default Wizard;
