import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  isSameDay,
  isYesterday,
} from 'date-fns';

import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';

import { Message } from 'features/message/models/Message';
import { Subject } from 'features/user/models';
import { Organization } from 'models/Business';

import { formatDate } from 'utils/dateTime';
import { isZeroId } from 'utils/objectId';

interface Props {
  customer: Organization;
  subject: Subject;
  message: Message;

  onManageSubjectButtonClick?: () => void;
}

const MailHeader = ({
  customer, subject, message, onManageSubjectButtonClick,
}: Props) => {
  const { t } = useTranslation();

  const messageDatetimeText: string = useMemo(() => {
    if (!message?.createdAt) return '';

    const messageCreatedAt = new Date(message?.createdAt);
    const now = new Date();

    const diffInSeconds = differenceInSeconds(now, messageCreatedAt);
    const diffInMinutes = differenceInMinutes(now, messageCreatedAt);
    const diffInHours = differenceInHours(now, messageCreatedAt);
    const diffInDays = differenceInDays(now, messageCreatedAt);

    // Generate relative time text if enabled
    let relativeText = '';
    if (diffInSeconds < 60) {
      relativeText = t('dateTime.justNow');
    } else if (diffInMinutes < 60) {
      relativeText = t('dateTime.minutesAgo', { count: diffInMinutes });
    } else if (diffInHours < 24 && isSameDay(now, messageCreatedAt)) {
      relativeText = t('dateTime.hoursAgo', { count: diffInHours });
    } else if (isYesterday(messageCreatedAt)) {
      relativeText = t('dateTime.yesterday');
    } else if (diffInDays < 8) {
      relativeText = t('dateTime.daysAgo', { count: diffInDays });
    }

    // Format the full date
    const formattedDate = formatDate(messageCreatedAt);

    // Combine date with relative time if available
    return relativeText ? `${formattedDate} (${relativeText})` : formattedDate;
  }, [message, t]);

  const customerOrSubjectName = useMemo(() => {
    if (customer && customer?.name) return customer?.name;

    if (subject) {
      const name = `${subject.firstName || ''} ${subject.lastName || ''}`;

      if (name.replace(/\s/g, '').length > 0) return name;
    }

    return '';
  }, [customer, subject]);

  const contactMedium = useMemo(() => {
    // Get:
    // 1. The contact from the message (which matches one of the contacts of the subject), then
    // 2. The contact from the subject, finally
    // 3. The contact from the customer
    const contactMedium_ = message.context.from || message.context.phoneFrom;
    if (contactMedium_) return contactMedium_;

    const subjectContactMedium = subject?.GetEmail() || subject?.GetPhone();
    if (subjectContactMedium) return subjectContactMedium;

    const customerContactMedium = (
      customer?.email
      || (customer?.emails || [])[0]
      || (customer?.phones || [])[0]?.number);

    if (customerContactMedium) return customerContactMedium;

    return '';
  }, [message, customer, subject]);

  const subjectNeedsCustomerAssignment = useMemo(() => (
    isZeroId(subject?.subjectData?.organizationId)), [subject?.subjectData?.organizationId]);

  return (
    <>
      <div className="flex justify-between text-sm 4xl:text-base">
        <div className="grid grid-cols-[auto_1fr] items-start gap-3">
          <p className="text-gray-400">{t('order.processOrderDrafts.leftSide.mailFrom')}</p>

          {customerOrSubjectName ? (
            <div className="flex items-center gap-1">
              <p className="font-semibold">{customerOrSubjectName}</p>
              <p className="text-xs 4xl:text-sm text-gray-400">
                {'<'}
                {contactMedium}
                {'>'}
              </p>
            </div>
          ) : (
            <div>
              <p className="font-semibold">
                {contactMedium}
              </p>
            </div>
          )}

          <p className="text-gray-400">{t('order.processOrderDrafts.leftSide.subject')}</p>
          <div className="space-y-1 font-semibold">
            {message?.context?.subject || t('order.processOrderDrafts.leftSide.noSubject')}
          </div>
        </div>

        <div className="flex">
          <p className="text-xs 4xl:text-sm text-neutral-200 underline">
            {messageDatetimeText}
          </p>
        </div>
      </div>

      {subjectNeedsCustomerAssignment && onManageSubjectButtonClick && (
      <div className="mt-lg flex h-10 w-full items-center justify-between border-l-4 border-warning-border bg-warning-bg px-lg text-warning-title">
        <div className="flex items-center gap-x-md">
          <ExclamationTriangleIcon className="aspect-square h-5 text-warning-button" />
          {t('order.processOrderDrafts.leftSide.unknownContact')}
        </div>
        <div>
          <button
            type="button"
            className="px-1 text-warning-description"
            onClick={onManageSubjectButtonClick}
          >
            {t('order.processOrderDrafts.leftSide.manage')}
          </button>
        </div>
      </div>
      )}
    </>
  );
};

export default MailHeader;
