export type SidebarNavItem = SidebarNavItemSingle | SidebarNavItemMultiple;

export type SidebarNavItemSingle = {
  icon: React.ElementType;
  label: string;
  href: string;
};

export type SidebarNavItemMultiple = {
  label: string;
  icon: React.ElementType;
  basePath: string;
  children: SidebarNavItemChild[];
};

type SidebarNavItemChild = {
  label: string;
  href: string;
};

export const isSidebarNavItemMultiple = (item: SidebarNavItem): item is SidebarNavItemMultiple => 'children' in item;
