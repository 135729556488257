import { Business } from 'models/Business';
import { Phone } from 'models/Phone';

import { parsePaginatedApiResponse } from 'utils';
import { httpGetV1 } from 'utils/xhr';

export interface FetchCustomersParams {
  search_query?: string;
  cursor?: string | null;
  limit?: number;
}

export const fetchCustomers = (params: FetchCustomersParams) => httpGetV1('/businesses/me/customers', {
  params,
  classType: Business,
}).then((response) => parsePaginatedApiResponse<Business>(response))
  .then((data) => ({
    ...data,
    result: data.result.map((customer) => ({
      ...customer,
      phones: customer.phones?.map(
        (p, i) => ({ ...p, uiId: i.toString() }) as Phone,
      ),
    })),
  }));
