import { memo } from 'react';

import { Message } from '../../../../../features/message/models/Message';
import { Order } from '../../../../../features/order/models';

import { isOrderRefType } from 'features/message/utils/message';

import { OrderTags } from './OrderTags';

interface Props {
  message: Message;
  order: Order;
  isIntersecting: boolean;
  isOrderLoading: boolean;
}

const TagBase = memo(
  ({
    message, order, isIntersecting, isOrderLoading,
  }: Props) => {
    if (
      isOrderRefType(message.refType)
      && message.ref
      && isIntersecting
    ) {
      return <OrderTags order={order} isOrderLoading={isOrderLoading} />;
    }

    return null;
  },
);

export default TagBase;
