import { AssignableUserRole, User } from '../models';

import { parsePaginatedApiResponse } from 'utils';
import { httpGetV1 } from 'utils/xhr';

interface FetchUsersParams {
  query: string;
  roles: AssignableUserRole[];
  cursor: string;
  limit: number;
}

export const fetchUsers = async (params: FetchUsersParams) => httpGetV1('/businesses/me/users', {
  params,
  classType: User,
}).then((response) => parsePaginatedApiResponse<User>(response));
