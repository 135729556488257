import { StateCreator } from 'zustand';

import { ImageSlice, ProcessOrderDraftsStore } from './types';

export const createImageSlice: StateCreator<ProcessOrderDraftsStore, [], [], ImageSlice> = (set) => ({
  selectedDocIndex: 0,
  setSelectedDocIndex: (value: number) => set({ selectedDocIndex: value }),
  selectedDocPageIndex: 0,
  setSelectedDocPageIndex: (value: number) => set({ selectedDocPageIndex: value }),
  selectedDocImgIndex: 0,
  setSelectedDocImgIndex: (value: number) => set({ selectedDocImgIndex: value }),
});
