import { twMerge } from 'tailwind-merge';

import { PaperAirplaneIcon } from '@heroicons/react/24/solid';
import { Textarea } from '@mantine/core';

const ChatInput = ({
  userInput,
  setUserInput,
  onMessageSubmit,
  disabled,
}: {
  userInput: string;
  setUserInput: React.Dispatch<React.SetStateAction<string>>;
  onMessageSubmit: () => void;
  disabled?: boolean;
}) => (
  <div className="flex items-end w-full space-x-1 py-3 px-2">
    <Textarea
      autosize
      minRows={1}
      maxRows={4}
      radius="lg"
      className="flex-1"
      value={userInput}
      onChange={(event) => {
        setUserInput(event.currentTarget.value);
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
          if (userInput.length > 0) {
            onMessageSubmit();
          }
          event.preventDefault();
        }
      }}
      disabled={disabled}
    />

    <div className="h-9 flex justify-center items-center">
      {/* <MicrophoneIcon className="aspect-square h-6 text-light-gray-200" /> */}
      <button
        type="button"
        className={twMerge(
          'h-8 w-8 rounded-full flex justify-center items-center',
          userInput.length > 0 ? 'bg-green-400' : 'bg-neutral-100/50',
        )}
        onClick={onMessageSubmit}
        disabled={disabled || userInput.length === 0}
      >
        <PaperAirplaneIcon className="aspect-square h-5 text-white" />
      </button>
    </div>
  </div>
);

export default ChatInput;
