import { BusinessSettings } from 'features/settings/models';

import Input from '../../../ui/Input';

interface Props {
  businessSettings: BusinessSettings;
}

const microsoftDynamics = ({ businessSettings }: Props) => {
  const dynamics = businessSettings.erpConnectionSettings.dynamicsBc;

  return (
    <div className="grid gap-5 text-sm text-gray-700 lg:py-7.5">
      <Input label="Base URL" value={dynamics?.baseUrl} readOnly />
      <Input label="OAuth Base URL" value={dynamics?.oAuthBaseUrl} readOnly />
      <Input label="API Version" value={dynamics?.apiVersion} readOnly />
      <Input
        label="Extension API Version"
        value={dynamics?.extensionAPIVersion}
        readOnly
      />
      <Input label="Scopes" value={dynamics?.scopes?.join(', ')} readOnly />
      <Input label="Client ID" value={dynamics?.clientId} readOnly />
      <Input label="Company ID" value={dynamics?.companyId} readOnly />
      <Input label="Grant Type" value={dynamics?.grantType} readOnly />
    </div>
  );
};

export default microsoftDynamics;
