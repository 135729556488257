import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useInfiniteQuery } from '@tanstack/react-query';

import { Business } from 'models/Business';

import { FetchCustomersParams } from '../apis';

import { CustomerListTable, CustomerSearchBar } from '../components/customer-list';
import { Page } from 'components/layout/Page/Page';
import { CustomerDetailSidebarWrapper } from 'components/wrapper/CustomerDetailSidebarWrapper';

import { customersOptions } from '../queryOptions';

interface Props {
  navigateToCustomersImport: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CustomerListPage = ({ navigateToCustomersImport }: Props) => {
  const { t } = useTranslation();

  const [params, setParams] = useState<FetchCustomersParams>({});
  const {
    data, isLoading, fetchNextPage, hasNextPage,
  } = useInfiniteQuery({
    ...customersOptions(params),
  });

  const [customers, setCustomers] = useState<Business[]>([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState<string | null>(null);

  const customerDetailSidebarWrapperRef = useRef(null);

  const onSearchClicked = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    const query = formData.get('search_query');

    setParams({
      search_query: query?.toString(),
    });
  };

  const openDialog = useCallback((customerId: string) => {
    setSelectedCustomerId(customerId);
    if (customerDetailSidebarWrapperRef.current) {
      customerDetailSidebarWrapperRef.current.onManageCustomerButtonClick();
    }
  }, []);

  const onCustomerEditComplete = useCallback(
    (_customer: Business) => {
      setCustomers((c) => c.map((customer) => (customer.id === _customer.id ? _customer : customer)));
    },
    [],
  );

  const onScrolledEnd = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  useEffect(() => {
    setCustomers(data?.pages.flatMap((page) => page.result) ?? []);
  }, [data]);

  return (
    <Page
      pageTitle={t('customer.list.title')}
      pageDescription={t('customer.list.description')}
      className="p-4"
      contentWithBorder
    >
      <CustomerDetailSidebarWrapper
        ref={customerDetailSidebarWrapperRef}
        customer={customers.find((customer) => customer.id === selectedCustomerId) ?? null}
        onComplete={onCustomerEditComplete}
      >
        <div className="flex justify-between gap-lg">
          <CustomerSearchBar onSubmit={onSearchClicked} />
        </div>

        <CustomerListTable
          customers={customers}
          openDialog={openDialog}
          isLoading={isLoading}
          hasNextPage={hasNextPage}
          onScrolledToEnd={onScrolledEnd}
        />
      </CustomerDetailSidebarWrapper>
    </Page>
  );
};

export default CustomerListPage;
