import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'config/routes';

import { ProtectedScreen } from 'components/ui/ProtectedScreen';

import { CustomersImportPage } from 'features/customer/pages';

const CustomersImportScreen = () => {
  const navigate = useNavigate();

  const navigateToCustomersList = useCallback(() => {
    navigate(ROUTES.CUSTOMERS);
  }, [navigate]);

  return (
    <ProtectedScreen>
      <CustomersImportPage navigateToCustomersList={navigateToCustomersList} />
    </ProtectedScreen>
  );
};

export default CustomersImportScreen;
