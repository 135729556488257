import {
  createContext, ReactNode, useContext, useMemo,
} from 'react';

import { User } from '../features/user/models';

import { useFetchTeamMembers } from '../hooks/useTeamMembers';

type TeamMembersContextType = {
  teamMembers: User[];
  setTeamMembers: React.Dispatch<React.SetStateAction<User[]>>;
  loadTeamMembers: () => Promise<void>;
  isLoading: boolean;
};
const TeamMembersContext = createContext<TeamMembersContextType | undefined>(
  undefined,
);

type TeamMemberProviderProps = {
  children: ReactNode;

  preventFetch?: boolean;
};

const TeamMembersProvider: React.FC<TeamMemberProviderProps> = ({
  children,
  preventFetch = false,
}: TeamMemberProviderProps) => {
  const {
    teamMembers, setTeamMembers, loadTeamMembers, isLoading,
  } = useFetchTeamMembers(preventFetch);

  const contextValue = useMemo(
    () => ({
      teamMembers,
      setTeamMembers,
      loadTeamMembers,
      isLoading,
    }),
    [isLoading, loadTeamMembers, setTeamMembers, teamMembers],
  );

  return (
    <TeamMembersContext.Provider value={contextValue}>
      {children}
    </TeamMembersContext.Provider>
  );
};

const useTeamMemnersContext = () => {
  const context = useContext(TeamMembersContext);

  if (context === undefined) {
    throw new Error('Must be wrapped by TeamMembersContext provider.');
  }

  return context;
};

export { TeamMembersContext, TeamMembersProvider, useTeamMemnersContext };
