import { infiniteQueryOptions } from '@tanstack/react-query';

import { fetchSyncLogs, GetSyncLogsParams } from '../apis';

export const syncLogsOptions = (params: GetSyncLogsParams) => infiniteQueryOptions({
  queryKey: ['sync-logs', params],
  queryFn: ({ pageParam }) => fetchSyncLogs({
    ...params,
    cursor: pageParam,
  }),
  initialPageParam: '',
  getNextPageParam: (lastPage) => lastPage.cursor || null,
  gcTime: 0,
  meta: {
    errorMessage: 'Error fetching sync logs',
  },
});
