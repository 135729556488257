import { ActionFlow } from './types';

import { Button } from '../../../ui/Button';

interface Props {
  onNext: (next: ActionFlow) => void;
}

const _Body = ({ onNext }: Props) => (
  <div className="space-y-5 px-1 py-3">
    <div className="flex flex-col rounded-lg border border-[#f1f1f4] bg-white shadow-sm">
      {/* Header */}
      <div className="flex min-h-[56px] items-center justify-between border-b border-[#f1f1f4] px-[1.875rem] py-[0.75rem]">
        <h3 className="text-[1rem] font-semibold leading-6 text-gray-900">
          Assign contact to an existing customer
        </h3>
      </div>
      {/* Body */}
      <div className="grid flex-1 gap-5 px-8 py-5">
        <p className="text-sm font-medium text-gray-700">
          This option allows you to assign an unknown contact to an existing customer.
        </p>
        <div className="flex justify-end">
          <Button title="Next" onClick={() => onNext(ActionFlow.ASSIGN)} />
        </div>
      </div>
    </div>
  </div>
);

export { _Body };
