import { SyncLog, SyncModel, SyncRunStatus } from '../models';

import { parsePaginatedApiResponse } from 'utils';
import { httpGetV1 } from 'utils/xhr';

export interface GetSyncLogsParams {
  model?: SyncModel;
  status?: SyncRunStatus;
  from_time?: string;
  to_time?: string;

  cursor?: string;
}

export const fetchSyncLogs = async (params: GetSyncLogsParams) => httpGetV1('/erp/sync_logs', {
  params,
  classType: SyncLog,
}).then((response) => parsePaginatedApiResponse<SyncLog>(response));
