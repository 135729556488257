import {
  createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';

import { Thread } from 'models/Thread';

import { useFetchThreadById } from '../hooks/fetch/useFetchThreadById';
import {
  FetchThreadsType,
  ThreadsFilter,
  useFetchThreads,
} from '../hooks/fetch/useFetchThreads';

enum ThreadProviderType {
  DEFAULT = 'default',
  BY_THREAD_ID = 'by_thread_id',
  BY_THREAD_ID_WITH_COUNT = 'by_thread_id_with_count',
}

type ThreadsContextType = {
  threadsCount: number;
  setThreadsCount: React.Dispatch<React.SetStateAction<number>>;
  threads: Thread[];
  setThreads: React.Dispatch<React.SetStateAction<Thread[]>>;
  selectedThreadId: string;
  setSelectedThreadId: React.Dispatch<React.SetStateAction<string>>;
  loadThreads: (reset?: boolean, giveError?: boolean) => Promise<void>;
  isLoading: boolean;

  threadsFilter?: ThreadsFilter;
  setThreadsFilter?: React.Dispatch<React.SetStateAction<ThreadsFilter>>;
};

const ThreadsContext = createContext<ThreadsContextType | undefined>(
  undefined,
);

// Providers
type ThreadsProviderDefaultProps = {
  children: ReactNode;
  defaultFilter?: ThreadsFilter;
  preventInitialFetch: boolean;
};

const ThreadsProviderDefault: React.FC<ThreadsProviderDefaultProps> = ({
  children,
  defaultFilter,
  preventInitialFetch = false,
}: ThreadsProviderDefaultProps) => {
  const {
    threadsCount,
    setThreadsCount,
    threads,
    setThreads,
    isLoading,
    loadThreads,
    threadsFilter,
    setThreadsFilter,
  } = useFetchThreads({
    preventInitialFetch,
    defaultFilter,
    fetchType: FetchThreadsType.DATA_AND_COUNT,
  });

  const [selectedThreadId, setSelectedThreadId] = useState<string>();

  const contextValue = useMemo(
    () => ({
      threads,
      setThreads,
      threadsCount,
      setThreadsCount,
      selectedThreadId,
      setSelectedThreadId,
      loadThreads,
      isLoading,
      threadsFilter,
      setThreadsFilter,
    }),
    [
      isLoading,
      loadThreads,
      threads,
      threadsCount,
      threadsFilter,
      selectedThreadId,
      setThreads,
      setThreadsCount,
      setThreadsFilter,
    ],
  );

  return (
    <ThreadsContext.Provider value={contextValue}>
      {children}
    </ThreadsContext.Provider>
  );
};

type ThreadsProviderByIdProps = {
  children: ReactNode;
  threadId: string;
  preventInitialFetch: boolean;
};

const ThreadsProviderById: React.FC<ThreadsProviderByIdProps> = ({
  children,
  threadId,
  preventInitialFetch = false,
}: ThreadsProviderByIdProps) => {
  const {
    thread, isLoading, loadThread,
  } = useFetchThreadById();

  const [threads, setThreads] = useState<Thread[]>([]);
  const [selectedThreadId, setSelectedThreadId] = useState<string>();

  const loadThreads = useCallback((): Promise<void> => {
    loadThread(threadId);

    return Promise.resolve();
  }, [loadThread, threadId]);

  useEffect(() => {
    if (preventInitialFetch) return;

    loadThread(threadId);
  }, [loadThread, threadId, preventInitialFetch]);

  useEffect(() => {
    if (thread) {
      setThreads([thread]);
    }
  }, [thread]);

  const contextValue = useMemo(
    () => ({
      threadsCount: 1,
      setThreadsCount: () => {},
      threads,
      setThreads,
      selectedThreadId,
      setSelectedThreadId,
      loadThreads,
      isLoading,
    }),
    [isLoading, loadThreads, threads, selectedThreadId, setThreads],
  );

  return (
    <ThreadsContext.Provider value={contextValue}>
      {children}
    </ThreadsContext.Provider>
  );
};

type ThreadsProviderByIdWithCountProps = {
  children: ReactNode;
  threadId: string;
  preventInitialFetch: boolean;
};

const ThreadsProviderByIdWithCount: React.FC<ThreadsProviderByIdWithCountProps> = ({
  children,
  threadId,
  preventInitialFetch = false,
}: ThreadsProviderByIdWithCountProps) => {
  const {
    thread, isLoading, loadThread,
  } = useFetchThreadById();
  const {
    threadsCount,
    setThreadsCount,
  } = useFetchThreads({ fetchType: FetchThreadsType.COUNT });

  const [threads, setThreads] = useState<Thread[]>([]);
  const [selectedThreadId, setSelectedThreadId] = useState<string>();

  const loadThreads = useCallback((): Promise<void> => {
    loadThread(threadId);

    return Promise.resolve();
  }, [loadThread, threadId]);

  useEffect(() => {
    if (preventInitialFetch) return;

    loadThread(threadId);
  }, [loadThread, threadId, preventInitialFetch]);

  useEffect(() => {
    if (thread) {
      setThreads([thread]);
    }
  }, [thread]);

  const contextValue = useMemo(
    () => ({
      threadsCount,
      setThreadsCount,
      threads,
      setThreads,
      selectedThreadId,
      setSelectedThreadId,
      loadThreads,
      isLoading,
    }),
    [isLoading, loadThreads, threads, threadsCount, selectedThreadId, setThreads, setThreadsCount],
  );

  return (
    <ThreadsContext.Provider value={contextValue}>
      {children}
    </ThreadsContext.Provider>
  );
};

type ThreadsProviderProps = {
  children: ReactNode;
  providerType: ThreadProviderType;
  defaultFilter?: ThreadsFilter;
  threadId?: string;
  preventInitialFetch?: boolean;
};

const ThreadsProvider: React.FC<ThreadsProviderProps> = ({
  children,
  providerType,
  defaultFilter,
  threadId,
  preventInitialFetch = false,
}: ThreadsProviderProps) => {
  switch (providerType) {
    case ThreadProviderType.DEFAULT:
      return (
        <ThreadsProviderDefault
          defaultFilter={defaultFilter}
          preventInitialFetch={preventInitialFetch}
        >
          {children}
        </ThreadsProviderDefault>
      );
    case ThreadProviderType.BY_THREAD_ID:
      return (
        <ThreadsProviderById
          threadId={threadId}
          preventInitialFetch={preventInitialFetch}
        >
          {children}
        </ThreadsProviderById>
      );
    case ThreadProviderType.BY_THREAD_ID_WITH_COUNT:
      return (
        <ThreadsProviderByIdWithCount
          threadId={threadId}
          preventInitialFetch={preventInitialFetch}
        >
          {children}
        </ThreadsProviderByIdWithCount>
      );
    default:
      return null;
  }
};

const useThreadsContext = () => {
  const context = useContext(ThreadsContext);

  if (context === undefined) {
    throw new Error('Must be wrapped by ThreadsContext provider.');
  }

  return context;
};

export {
  ThreadProviderType,
  ThreadsContext,
  ThreadsProvider,
  useThreadsContext,
};
