import {
  useCallback,
  useEffect, useMemo, useRef,
  useState,
} from 'react';

import Dayjs from 'dayjs';
import { motion, useAnimation } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

import { NumberInput, Select, TextInput } from '@mantine/core';
import { DateTimePicker, DateValue } from '@mantine/dates';

import { UniversalFieldProps } from './types';

// Function to parse validation string
function parseValidators(validation: string) {
  if (!validation) return [];

  return validation.split(',')?.map((rule) => {
    const [key, value] = rule.split('=');
    return { key, value: value || true };
  });
}

// ADT for validation rules
type Validator =
  | { type: 'required' }
  | { type: 'min'; value: number }
  | { type: 'max'; value: number }
  | { type: 'alpha'; value: number }
  | { type: 'alphanum'; value: number };

function createValidator({ key, value }: { key: string; value: any }): Validator {
  switch (key) {
    case 'required':
      return { type: 'required' };
    case 'min':
      return { type: 'min', value: Number(value) };
    case 'max':
      return { type: 'max', value: Number(value) };
    case 'alpha':
      return { type: 'alpha', value: Number(value) };
    case 'alphanum':
      return { type: 'alphanum', value: Number(value) };
    default:
      return { type: 'required' };
  }
}

const UniversalField = ({
  type,
  label,
  value: defaultValue,
  size = 'sm',
  required = false,
  itemClassName,
  placeHolder,
  disabled,
  readOnly,
  shouldScroll = true,
  itemStyle,
  itemStyles,
  validation,
  rightSection,
  onValueChange,
  setError: setError_,
  ...motionProps
}: UniversalFieldProps) => {
  const itemRef = useRef<HTMLDivElement>(null);
  const controls = useAnimation();

  const [localValue, setLocalValue] = useState<any>(defaultValue || '');

  const [errorMsg, setErrorMsg] = useState('');

  const dateTimeValue = useMemo(() => (
    localValue ? Dayjs(localValue).toDate() : undefined
  ), [localValue]);

  const textInputPlaceholder = useMemo(() => (
    placeHolder || localValue || 'No value extracted'
  ), [placeHolder, localValue]);

  const onAnyChange = useCallback((val: any) => {
    setLocalValue(val);
    onValueChange?.(val);
  }, [setLocalValue, onValueChange]);

  const onTextInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onAnyChange(e.target.value);
  }, [onAnyChange]);

  const onNumberInputChange = useCallback((val: string | number) => {
    onAnyChange(parseFloat(val?.toString() || '0'));
  }, [onAnyChange]);

  const onDateTimeChange = useCallback((datetime: DateValue) => {
    onAnyChange(datetime ? Dayjs(datetime).format() : null);
  }, [onAnyChange]);

  const setError = useCallback((msg: string) => {
    setErrorMsg(msg);
    setError_?.(msg);
  }, [setError_, setErrorMsg]);

  useEffect(() => {
    const parsedValidators = parseValidators(validation);
    const validationRules = parsedValidators.map(createValidator);

    validationRules.every((rule) => {
      if (rule.type === 'required' && !localValue) {
        setError(`${label} is a required field and cannot be empty.`);
        return false;
      }

      if (
        rule.type === 'min'
        && (
          (type === 'input' && localValue.length < rule.value)
          || (type === 'number input' && Number(localValue) < rule.value)
        )
      ) {
        setError(`${label} must be at least ${rule.value}.`);
        return false;
      }

      if (
        rule.type === 'max'
        && (
          (type === 'input' && localValue.length > rule.value)
          || (type === 'number input' && Number(localValue) > rule.value)
        )
      ) {
        setError(`${label} must be at most ${rule.value}.`);
        return false;
      }

      if (
        rule.type === 'alpha'
        && !/^[a-zA-Z]+$/.test(localValue)
      ) {
        setError(`${label} must be alphabetic.`);
        return false;
      }

      if (
        rule.type === 'alphanum'
        && !/^[a-zA-Z0-9]+$/.test(localValue)
      ) {
        setError(`${label} must be alphanumeric.`);
        return false;
      }
      setError('');
      return true;
    });
  }, [label, required, setError, localValue, validation, type]);

  useEffect(() => {
    if (shouldScroll && itemRef.current) {
      itemRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    // Start the blinking animation
    controls.start({
      opacity: [1, 0, 1],
      transition: { duration: 0.3, repeat: 2 },
    });
  }, [controls, shouldScroll]); // Added shouldScroll to dependencies

  useEffect(() => {
    setLocalValue(defaultValue);
  }, [defaultValue]);

  const item = useMemo(() => {
    if (type === 'dropdown') {
      return (
        <Select
          className={twMerge('w-full', itemClassName)}
          label={label}
          value={localValue}
          placeholder={placeHolder}
          disabled={disabled}
          readOnly={readOnly}
          size={size}
          style={itemStyle}
          rightSection={rightSection}
          onChange={onAnyChange}
        />
      );
    }

    if (type === 'input') {
      return (
        <TextInput
          className={twMerge('w-full', itemClassName)}
          label={label}
          value={localValue}
          placeholder={placeHolder}
          disabled={disabled}
          readOnly={readOnly}
          size={size}
          style={itemStyle}
          styles={itemStyles}
          error={errorMsg}
          rightSection={rightSection}
          onChange={onTextInputChange}
        />
      );
    }

    if (type === 'number input') {
      return (
        <NumberInput
          className={twMerge('w-full', itemClassName)}
          label={label}
          value={localValue}
          placeholder={placeHolder}
          disabled={disabled}
          readOnly={readOnly}
          size={size}
          style={itemStyle}
          styles={itemStyles}
          error={errorMsg}
          hideControls
          rightSection={rightSection}
          onChange={onNumberInputChange}
        />
      );
    }

    if (type === 'datetime') {
      return (
        <DateTimePicker
          className={twMerge('w-full', itemClassName)}
          label={label}
          value={dateTimeValue}
          placeholder={placeHolder}
          disabled={disabled}
          readOnly={readOnly}
          size={size}
          style={itemStyle}
          styles={itemStyles}
          onChange={onDateTimeChange}
          error={errorMsg}
          rightSection={rightSection}
        />
      );
    }

    return (
      <TextInput
        variant="unstyled"
        className={twMerge('w-full', itemClassName)}
        label={label}
        value={localValue}
        placeholder={textInputPlaceholder}
        disabled={disabled}
        size={size}
        readOnly
        style={itemStyle}
        error={errorMsg}
        rightSection={rightSection}
        styles={{
          input: {
            borderBottom: '1px solid #A1A3AB',
            borderRadius: '0',
            ...itemStyles,
          },
        }}
      />
    );
  }, [type, itemClassName, label, localValue, textInputPlaceholder, disabled, itemStyles,
    size, itemStyle, placeHolder, readOnly, onAnyChange, onTextInputChange,
    onNumberInputChange, dateTimeValue, onDateTimeChange, errorMsg, rightSection]);

  return (
    <motion.div
      ref={itemRef}
      animate={controls}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0, scale: 0, transition: { duration: 0.3 } }}
      {...motionProps}
    >
      {item}
    </motion.div>
  );
};

export default UniversalField;
