import {
  forwardRef, useEffect,
  useImperativeHandle, useState,
} from 'react';

import { twMerge } from 'tailwind-merge';

import {
  faFileUpload, faImage, faPaperclip, faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionIcon, Select, TextInput } from '@mantine/core';
import BulletList from '@tiptap/extension-bullet-list';
import Document from '@tiptap/extension-document';
import Heading from '@tiptap/extension-heading';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

import { SMTPMessage } from 'models/Mail';

import { useDebouncedCallback } from '@mantine/hooks';
import { useFetchSubjects } from 'hooks/fetch/useFetchSubjects';

import { Button } from 'components/ui/Button';

import EmailAddressBadge from './EmailAddressBadge';
import EmailAddressHintsDropdown from './EmailAddressHintsDropdown';
import MenuBar from './MenuBar';

interface Props extends React.HTMLAttributes<HTMLFormElement> {
  onDiscard: () => void;

  subject?: string;
  onSetSubject?: (subject: string) => void;

  fromValues: string[];
  from: string;
  onSetFrom?: (from: string) => void;

  to?: string[];
  onSetTo?: (to: string[]) => void;
  cc?: string[];
  onSetCc?: (cc: string[]) => void;
  bcc?: string[];
  onSetBcc?: (bcc: string[]) => void;

  onSetSmtpMessage?: (smtpMessage: SMTPMessage) => void;
  onSendSmtpMessage: (smtpMessage: SMTPMessage) => void;
}

type Ref = {
  getHtml: () => string;
};

const extensions = [
  StarterKit,
  Underline,
  Text,
  Document,
  Heading,
  Paragraph,
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  Heading.configure({
    levels: [1, 2, 3],
  }),
  BulletList,
  OrderedList,
];

const isValidEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const EmailBase = forwardRef(({
  onDiscard,
  subject,
  onSetSubject,
  fromValues,
  from,
  onSetFrom,
  to,
  onSetTo,
  cc,
  onSetCc,
  bcc,
  onSetBcc,
  onSetSmtpMessage,
  onSendSmtpMessage,
  ...props
}: Props, ref: React.Ref<Ref>) => {
  const {
    subjects: subjectsTo,
    loadSubjects: loadSubjectsTo,
    setSubjectsFilter: setSubjectsFilterTo,
  } = useFetchSubjects({});
  const {
    subjects: subjectsCc,
    loadSubjects: loadSubjectsCc,
    setSubjectsFilter: setSubjectsFilterCc,
  } = useFetchSubjects({});
  const {
    subjects: subjectsBcc,
    loadSubjects: loadSubjectsBcc,
    setSubjectsFilter: setSubjectsFilterBcc,
  } = useFetchSubjects({});

  const [smtpMessage, setSmtpMessage] = useState<SMTPMessage>({
    from,
    to: to || [],
    cc: cc || [],
    bcc: bcc || [],
    subject: subject || '',
    headers: {},
    bodyContent: '',
    bodyContentType: 'text/plain',
    sendWithProxyAddress: true,
  });

  const content = '';

  const editor = useEditor({
    extensions,
    content,
    editorProps: {
      attributes: {
        class: twMerge(
          'prose max-w-none [&_ol]:list-decimal [&_ul]:list-disc',
          'rounded-sm min-h-[200px] py-3 bg-transparent border-0 px-4',
          'focus:outline-none text-sm leading-23',
        ),
      },
    },
    onUpdate: ({ editor: _editor }) => {
      setSmtpMessage((prev) => ({
        ...prev,
        bodyContent: _editor.getHTML(),
        bodyContentType: 'text/html',
      }));
      onSetSmtpMessage?.(smtpMessage);
    },
  });

  const [openCc, setOpenCc] = useState(false);
  const [openBcc, setOpenBcc] = useState(false);

  const [dragging, setDragging] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryCc, setSearchQueryCc] = useState('');
  const [searchQueryBcc, setSearchQueryBcc] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showSuggestionsCc, setShowSuggestionsCc] = useState(false);
  const [showSuggestionsBcc, setShowSuggestionsBcc] = useState(false);

  const debouncedLoadSubjectsTo = useDebouncedCallback((query: string) => {
    if (query) {
      setSubjectsFilterTo({ query });
      loadSubjectsTo(false, true);
    }
  }, 300);
  const debouncedLoadSubjectsCc = useDebouncedCallback((query: string) => {
    if (query) {
      setSubjectsFilterCc({ query });
      loadSubjectsCc(false, true);
    }
  }, 300);
  const debouncedLoadSubjectsBcc = useDebouncedCallback((query: string) => {
    if (query) {
      setSubjectsFilterBcc({ query });
      loadSubjectsBcc(false, true);
    }
  }, 300);

  useEffect(() => {
    debouncedLoadSubjectsTo(searchQuery);
  }, [searchQuery, debouncedLoadSubjectsTo]);
  useEffect(() => {
    debouncedLoadSubjectsCc(searchQueryCc);
  }, [searchQueryCc, debouncedLoadSubjectsCc]);
  useEffect(() => {
    debouncedLoadSubjectsBcc(searchQueryBcc);
  }, [searchQueryBcc, debouncedLoadSubjectsBcc]);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  useImperativeHandle(ref, () => ({
    getHtml: () => editor?.getHTML(),
  }));

  const _onDiscard = () => {
    onDiscard();
  };

  const _onSetFrom = (_from: string) => {
    setSmtpMessage((prev) => ({
      ...prev,
      from: _from,
    }));
    onSetFrom?.(_from);
  };

  const _onSetTo = (_to: string[]) => {
    setSmtpMessage((prev) => ({
      ...prev,
      to: _to,
    }));
    onSetTo?.(_to);
  };

  const _onSetSubject = (_subject: string) => {
    setSmtpMessage((prev) => ({
      ...prev,
      subject: _subject,
    }));
    onSetSubject?.(_subject);
  };

  const _onSetCc = (_cc: string[]) => {
    setSmtpMessage((prev) => ({
      ...prev,
      cc: _cc,
    }));
    onSetCc?.(_cc);
  };

  const _onSetBcc = (_bcc: string[]) => {
    setSmtpMessage((prev) => ({
      ...prev,
      bcc: _bcc,
    }));
    onSetBcc?.(_bcc);
  };

  const _onSendSmtpMessage = () => {
    onSendSmtpMessage(smtpMessage);
  };

  const removeEmail = (type: 'to' | 'cc' | 'bcc', email: string) => {
    let newTo: string[];
    let newCc: string[];
    let newBcc: string[];

    switch (type) {
      case 'to':
        newTo = smtpMessage.to.filter((e) => e !== email);
        setSmtpMessage((prev) => ({ ...prev, to: newTo }));
        onSetTo?.(newTo);
        break;
      case 'cc':
        newCc = smtpMessage.cc?.filter((e) => e !== email) || [];
        setSmtpMessage((prev) => ({ ...prev, cc: newCc }));
        onSetCc?.(newCc);
        break;
      case 'bcc':
        newBcc = smtpMessage.bcc?.filter((e) => e !== email) || [];
        setSmtpMessage((prev) => ({ ...prev, bcc: newBcc }));
        onSetBcc?.(newBcc);
        break;
      default:
        break;
    }
  };

  const addEmail = (
    e: React.KeyboardEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>,
    currentEmails: string[],
    setEmails: (emails: string[]) => void,
  ) => {
    const email = e.currentTarget.value.trim();
    if (email && currentEmails.includes(email)) {
      e.currentTarget.value = '';
      return;
    }

    if (email && !currentEmails.includes(email) && isValidEmail(email)) {
      setEmails([...currentEmails, email]);
      e.currentTarget.value = '';
    }
  };

  const handleEmailInputKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    currentEmails: string[],
    setEmails: (emails: string[]) => void,
  ) => {
    if (e.key === 'Enter' || e.key === ',' || e.key === ' ') {
      e.preventDefault();
      addEmail(e, currentEmails, setEmails);
    } else if (e.key === 'Backspace' && e.currentTarget.value === '' && currentEmails.length > 0) {
      e.preventDefault();
      const newEmails = currentEmails.slice(0, -1);
      setEmails(newEmails);
    }
  };

  return (
    <form
      id="kt_inbox_compose_form"
      {...props}
      className={twMerge(
        'rounded-md bg-white h-fit flex flex-col',
        props.className,
      )}
    >
      <div className="flex-1 flex flex-col overflow-y-hidden">
        <div className="flex items-center border-b border-[#DBDFE9] px-4 min-h-[50px]">

          <div className="text-gray-900 font-bold text-sm pr-2">
            From:
          </div>
          <div className="flex-1 flex flex-wrap gap-1 items-center relative">
            <Select
              name="compose_from"
              className="flex-1 min-w-[150px]"
              placeholder="Sender"
              variant="unstyled"
              defaultValue={from}
              data={fromValues}
              onChange={(value) => _onSetFrom(value)}
            />
          </div>
        </div>

        <div className="flex items-center border-b border-[#DBDFE9] px-4 min-h-[50px]">
          <div className="text-gray-900 font-bold text-sm pr-2">
            To:
          </div>
          <div className="flex-1 flex flex-wrap gap-1 items-center relative">
            {smtpMessage.to.map((email) => (
              <EmailAddressBadge
                key={email}
                email={email}
                onRemove={(e) => removeEmail('to', e)}
              />
            ))}
            <TextInput
              name="compose_to"
              className="flex-1 min-w-[150px]"
              placeholder={smtpMessage.to.length === 0 ? 'Recipients' : ''}
              variant="unstyled"
              onChange={(e) => {
                const value = e.currentTarget.value;
                if (value.length >= 2) {
                  setSearchQuery(value);
                  setShowSuggestions(true);
                } else {
                  setShowSuggestions(false);
                }
              }}
              onFocus={(e) => {
                if (e.currentTarget.value.length >= 2) {
                  setShowSuggestions(true);
                }
              }}
              onBlur={(e) => {
                setTimeout(() => setShowSuggestions(false), 300);
                addEmail(e, smtpMessage.to, _onSetTo);
              }}
              onKeyDown={(e) => handleEmailInputKeyDown(e, smtpMessage.to, _onSetTo)}
            />
            {showSuggestions && subjectsTo.length > 0 && (
              <EmailAddressHintsDropdown
                subjects={subjectsTo}
                onSelect={(email) => {
                  if (!smtpMessage.to.includes(email)) {
                    _onSetTo([...smtpMessage.to, email]);
                  }
                  setSearchQuery('');
                  setShowSuggestions(false);
                  const input = document.querySelector('input[name="compose_to"]') as HTMLInputElement;
                  if (input) {
                    input.value = '';
                  }
                }}
                onClose={() => {
                  setShowSuggestions(false);
                  setSearchQuery('');
                }}
              />
            )}
          </div>

          <div className="ml-auto w-[50p] text-end">
            <button
              type="button"
              className={twMerge(
                'text-gray-500 cursor-pointer hover:text-primary-500 text-sm mr-2',
                openCc && 'hidden',
              )}
              onClick={() => setOpenCc((prev) => !prev)}
            >
              Cc
            </button>
            <button
              type="button"
              className={twMerge(
                'text-gray-500 cursor-pointer hover:text-primary-500 text-sm',
                openBcc && 'hidden',
              )}
              onClick={() => setOpenBcc((prev) => !prev)}
            >
              Bcc
            </button>
          </div>
        </div>

        {
          openCc && (
            <div className="flex items-center border-b border-[#DBDFE9] px-4 min-h-[50px]">
              <div className="text-gray-900 font-bold text-sm pr-2">
                Cc:
              </div>
              <div className="flex-1 flex flex-wrap gap-1 items-center relative">
                {smtpMessage.cc?.map((email) => (
                  <EmailAddressBadge
                    key={email}
                    email={email}
                    onRemove={(e) => removeEmail('cc', e)}
                  />
                ))}
                <TextInput
                  name="compose_cc"
                  className="flex-1 min-w-[150px]"
                  placeholder={smtpMessage.cc?.length === 0 ? 'Recipients' : ''}
                  variant="unstyled"
                  onChange={(e) => {
                    const value = e.currentTarget.value;
                    if (value.length >= 2) {
                      setSearchQueryCc(value);
                      setShowSuggestionsCc(true);
                    } else {
                      setShowSuggestionsCc(false);
                    }
                  }}
                  onFocus={(e) => {
                    if (e.currentTarget.value.length >= 2) {
                      setShowSuggestionsCc(true);
                    }
                  }}
                  onBlur={(e) => {
                    setTimeout(() => setShowSuggestionsCc(false), 300);
                    addEmail(e, smtpMessage.cc, _onSetCc);
                  }}
                  onKeyDown={(e) => handleEmailInputKeyDown(e, smtpMessage.cc || [], _onSetCc)}
                />
                {showSuggestionsCc && subjectsCc.length > 0 && (
                  <EmailAddressHintsDropdown
                    subjects={subjectsCc}
                    onSelect={(email) => {
                      if (!smtpMessage.cc?.includes(email)) {
                        _onSetCc([...(smtpMessage.cc || []), email]);
                      }
                      setSearchQueryCc('');
                      setShowSuggestionsCc(false);
                      const input = document.querySelector('input[name="compose_cc"]') as HTMLInputElement;
                      if (input) {
                        input.value = '';
                      }
                    }}
                    onClose={() => {
                      setShowSuggestionsCc(false);
                      setSearchQueryCc('');
                    }}
                  />
                )}
              </div>
            </div>
          )
        }

        {
          openBcc && (
            <div className="flex items-center border-b border-[#DBDFE9] px-4 min-h-[50px]">
              <div className="text-gray-900 font-bold text-sm pr-2">
                Bcc:
              </div>
              <div className="flex-1 flex flex-wrap gap-1 items-center relative">
                {smtpMessage.bcc?.map((email) => (
                  <EmailAddressBadge
                    key={email}
                    email={email}
                    onRemove={(e) => removeEmail('bcc', e)}
                  />
                ))}
                <TextInput
                  name="compose_bcc"
                  className="flex-1 min-w-[150px]"
                  placeholder={smtpMessage.bcc?.length === 0 ? 'Recipients' : ''}
                  variant="unstyled"
                  onChange={(e) => {
                    const value = e.currentTarget.value;
                    if (value.length >= 2) {
                      setSearchQueryBcc(value);
                      setShowSuggestionsBcc(true);
                    } else {
                      setShowSuggestionsBcc(false);
                    }
                  }}
                  onFocus={(e) => {
                    if (e.currentTarget.value.length >= 2) {
                      setShowSuggestionsBcc(true);
                    }
                  }}
                  onBlur={(e) => {
                    setTimeout(() => setShowSuggestionsBcc(false), 300);
                    addEmail(e, smtpMessage.bcc, _onSetBcc);
                  }}
                  onKeyDown={(e) => handleEmailInputKeyDown(e, smtpMessage.bcc || [], _onSetBcc)}
                />
                {showSuggestionsBcc && subjectsBcc.length > 0 && (
                  <EmailAddressHintsDropdown
                    subjects={subjectsBcc}
                    onSelect={(email) => {
                      if (!smtpMessage.bcc?.includes(email)) {
                        _onSetBcc([...(smtpMessage.bcc || []), email]);
                      }
                      setSearchQueryBcc('');
                      setShowSuggestionsBcc(false);
                      const input = document.querySelector('input[name="compose_bcc"]') as HTMLInputElement;
                      if (input) {
                        input.value = '';
                      }
                    }}
                    onClose={() => {
                      setShowSuggestionsBcc(false);
                      setSearchQueryBcc('');
                    }}
                  />
                )}
              </div>
            </div>
          )
        }

        <div className="border-b border-[#DBDFE9] px-4">
          <TextInput
            className="py-1 text-sm"
            name="compose_subject"
            placeholder="Subject"
            variant="unstyled"
            defaultValue={subject || ''}
            onChange={(e) => _onSetSubject(e.target.value)}
          />
        </div>

        <MenuBar editor={editor} />

        <div
          className="flex-1 overflow-y-auto relative"
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <EditorContent editor={editor} />

          {
            dragging && (
              <div className="absolute inset-0 w-full h-full bg-white z-10 p-3 bg-opacity-30 backdrop-blur-sm">
                <div className="flex flex-col items-center justify-center border-dashed border-2 border-gray-300 rounded-md w-full h-full">
                  <FontAwesomeIcon icon={faFileUpload} className="text-gray-400 w-10 h-10" />
                  <div className="text-gray-500 text-sm mt-3">
                    Drop the files here
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </div>

      <div className="flex justify-between items-center flex-wrap gap-2 py-2 px-4 border-t">
        <div className="flex items-center space-x-2">
          <Button
            disabled={smtpMessage.to.length === 0}
            title="Send"
            onClick={_onSendSmtpMessage}
          />

          <ActionIcon
            type="button"
            variant="transparent"
            color="gray"
          >
            <FontAwesomeIcon icon={faPaperclip} />
          </ActionIcon>

          <ActionIcon
            type="button"
            variant="transparent"
            color="gray"
          >
            <FontAwesomeIcon icon={faImage} />
          </ActionIcon>
        </div>

        <ActionIcon
          type="button"
          variant="transparent"
          color="gray"
          onClick={_onDiscard}
        >
          <FontAwesomeIcon icon={faTrashCan} />
        </ActionIcon>
      </div>
    </form>
  );
});

export default EmailBase;
