import { infiniteQueryOptions } from '@tanstack/react-query';

import { fetchCustomers, FetchCustomersParams } from '../apis';

export const customersOptions = (params?: FetchCustomersParams) => infiniteQueryOptions({
  queryKey: ['customers', params?.search_query ?? ''],
  queryFn: ({ pageParam = '' }) => fetchCustomers({
    ...params,
    cursor: pageParam,
    limit: params?.limit ?? 20,
  }),
  initialPageParam: '',
  getNextPageParam: (lastPage) => lastPage.cursor || null,
  gcTime: 0,
  meta: {
    errorMessage: 'Error fetching customers',
  },
});
