import { Expose } from 'class-transformer';

class Language {
  @Expose({ name: 'code' })
    code: string;

  @Expose({ name: 'name' })
    name: string;

  @Expose({ name: 'native_name' })
    nativeName: string;
}

export { Language };
