import { memo } from 'react';

import Dayjs from 'dayjs';

import { CheckIcon, ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import {
  Textarea, Timeline,
} from '@mantine/core';

import { Order, OrderDeliveryStatusTrail, OrderStatus } from '../../models';

import { formatOrderStatus } from 'utils/enums';

interface Props {
  order: Order;
}

const StatusIcon = ({ status }: { status: OrderStatus }) => {
  switch (status) {
    case OrderStatus.Confirmed:
      return <CheckIcon className="h-4 w-4" />;
    case OrderStatus.Rejected:
    case OrderStatus.TimedOut:
      return <XMarkIcon className="h-4 w-4" />;
    case OrderStatus.TrailSyncFailed:
      return <ExclamationTriangleIcon className="h-4 w-4" />;
    default:
      return null;
  }
};

const StatusColor = (status: OrderStatus) => {
  switch (status) {
    case OrderStatus.Confirmed:
      return 'green';
    case OrderStatus.Rejected:
    case OrderStatus.TimedOut:
      return 'red';
    case OrderStatus.TrailSyncFailed:
      return 'orange';
    default:
      return 'grey';
  }
};

const ItemProps = (status: OrderStatus) => ({
  bullet: <StatusIcon status={status} />,
  color: StatusColor(status),
});

const TitleWithDatetime = memo(({
  status,
  datetime,
}: {
  status: OrderStatus;
  datetime: string;
}) => (
  <div className="flex-row justify-between">
    <div className="text-semibold">{`Order ${formatOrderStatus(status)}`}</div>
    <div className="text-xs text-gray-400">{Dayjs(datetime).format('lll')}</div>
  </div>
));

const Content = memo(({ statusTrail }: { statusTrail: OrderDeliveryStatusTrail }) => (
  statusTrail?.message ? (
    <Textarea
      label="Comment"
      variant="unstyled"
      value={statusTrail.message}
      classNames={{
        label: 'text-gray-400',
        input: 'text-gray-900',
      }}
      readOnly
    />
  ) : null
));

const Events = ({ order }: Props) => {
  if (!order) return null;

  return (
    <Timeline active={order.statusTrails.length - 1} bulletSize={24} lineWidth={2}>
      {
          order.statusTrails.reverse().map((s, index) => {
            // Prevents aliasing problem on s
            const trail = s;
            return (
              <Timeline.Item
                key={`timeline-item-${index.toString()}`}
                title={<TitleWithDatetime status={trail.status} datetime={trail.createdAt} />}
                {...ItemProps(trail.status)}
              >
                <Content statusTrail={trail} />
              </Timeline.Item>
            );
          })
        }
    </Timeline>
  );
};

export default Events;
