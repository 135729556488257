import {
  FormEvent, Fragment, useEffect, useState,
} from 'react';

import { Dialog, Transition } from '@headlessui/react';

import {
  MatchingLogic,
  ModelConfig,
  ModelFieldType,
} from '../../models/matcher/BaseHeaderMatcher';

import { genericErrorFeedback } from 'utils/errors';
import { httpPostV1 } from 'utils/xhr';

import { ThreeDots } from '../ThreeDots';

interface CreateMatchingDialogProp {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  model: 'product' | 'customer';
  modelConfig: ModelConfig;
  inputHeaders: string[];
  getMatchers: () => Promise<void>;
}

const CreateMatchingDialog = ({
  open,
  setOpen,
  model,
  modelConfig,
  inputHeaders,
  getMatchers,
}: CreateMatchingDialogProp) => {
  const [loading, setLoading] = useState<Boolean>(false);
  const [connectedList, setConnectedList] = useState<string[][]>([]);
  const [focusedTitle, setFocusedTitle] = useState('');
  const [unmatchedHeaders, setUnmatchedHeaders] = useState<ModelFieldType[]>([
    ...modelConfig.required,
    ...modelConfig.optional,
  ]);
  const [originalHeaders, setOriginalHeaders] = useState([]);
  const [matcherName, setMatcherName] = useState('');

  const reset = () => {
    setFocusedTitle('');
    setConnectedList([]);
    setUnmatchedHeaders([...modelConfig.required, ...modelConfig.optional]);
    setOriginalHeaders(['', ...inputHeaders]);
    setMatcherName('');
  };

  const onMatchingClicked = (header: string) => {
    if (focusedTitle !== '') {
      const updatedConnectedList = connectedList.filter(
        (item) => unmatchedHeaders.filter((i) => i.title === focusedTitle)[0].type
            === 'list' || item[0] !== focusedTitle,
      );
      updatedConnectedList.push([focusedTitle, header]);

      let updatedOriginalHeaders = originalHeaders;
      if (header !== '') {
        updatedOriginalHeaders = originalHeaders.filter(
          (item) => item !== header,
        );
      }
      if (
        connectedList.some(
          (item) => !connectedList.includes(item) && item[0] === focusedTitle,
        )
      ) {
        updatedOriginalHeaders.push(
          connectedList.find((item) => item[0] === focusedTitle)[1],
        );
      }

      setConnectedList(updatedConnectedList);
      setUnmatchedHeaders(
        unmatchedHeaders.filter(
          (item) => unmatchedHeaders.filter((i) => i.title === focusedTitle)[0].type
              === 'list' || item.title !== focusedTitle,
        ),
      );
      setOriginalHeaders(updatedOriginalHeaders);
      setFocusedTitle('');
    }
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    const requiredFields = modelConfig.required;
    const optionalFields = modelConfig.optional;
    const missingFields = requiredFields.some(
      (field) => !connectedList.some((item) => item[0] === field.title),
    );

    if (missingFields) {
      genericErrorFeedback('Match all the required fields')(null);
      setLoading(false);
      return;
    }

    const matchingLogic: MatchingLogic = {
      additional_features: originalHeaders,
      matcherName,
    };

    [...requiredFields, ...optionalFields].forEach((field) => {
      const match = connectedList.filter((item) => item[0] === field.title);
      if (match.length === 0) {
        matchingLogic[field.key] = '';
      } else if (field.type === 'string') {
        matchingLogic[field.key] = match[0][1];
      } else {
        matchingLogic[field.key] = match.map((item) => item[1]);
      }
    });

    httpPostV1(`/erp/match_header/manual/${model}`, {
      matching_logic: matchingLogic,
    })
      .then(async () => {
        await getMatchers();
        reset();
        setOpen(false);
      })
      .catch((error) => genericErrorFeedback(error))
      .finally(() => {});

    setLoading(false);
  };

  useEffect(() => {
    setOriginalHeaders(['', ...inputHeaders]);
  }, [setOriginalHeaders, inputHeaders]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[100]" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-lg text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="hidden-scrollbar relative transform overflow-hidden rounded-lg bg-white px-lg pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-h-[80vh] sm:w-full sm:max-w-[50vw] sm:overflow-scroll sm:p-xl">
                <form onSubmit={onSubmit}>
                  <div
                    className="border-b border-gray-900/10 pb-5 outline-none"
                    role="button"
                    tabIndex={0}
                    onClick={() => setFocusedTitle('')}
                    onKeyDown={() => {}}
                  >
                    <h2 className="text-lg font-semibold leading-7 text-gray-900">
                      Create Matching Logic
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                      Connect our database fileds with your file header.
                    </p>

                    <div className="mt-5">
                      <label
                        htmlFor="matcher-name"
                        className="flex items-center border-b border-gray-900/10 pb-4 text-sm font-medium leading-6 text-gray-900"
                      >
                        Matcher Name:
                        <input
                          type="text"
                          name="matcher-name"
                          id="matcher-name"
                          value={matcherName}
                          onChange={(e) => setMatcherName(e.target.value)}
                          required
                          autoComplete="off"
                          className="ml-2 block min-w-[300px] rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-6"
                        />
                      </label>
                    </div>

                    <div className="max-h-[40vh] overflow-hidden">
                      <div className="max-h-[40vh] overflow-auto">
                        {/* Connected Lists */}
                        <div className="w-full py-smd">
                          {connectedList.map((connected, idx) => (
                            <div
                              key={`connected-${idx.toString()}`}
                              className="mt-5 grid grid-cols-8 items-center text-center"
                            >
                              <div
                                role="button"
                                onClick={(e) => {
                                  setFocusedTitle(connected[0]);
                                  e.stopPropagation();
                                }}
                                tabIndex={0}
                                onKeyDown={() => {}}
                                className={`col-span-2 mx-2 flex min-h-10 cursor-pointer items-center justify-center rounded-md border border-green-500 py-smd text-center text-green-600 hover:scale-105 hover:bg-gray-50 ${focusedTitle === connected[0] && 'scale-105 border-2 border-indigo-500 text-indigo-500 shadow-md hover:bg-transparent'} ${focusedTitle !== '' && focusedTitle !== connected[0] && 'blur-[1px]'}`}
                              >
                                {connected[0]}
                              </div>
                              <div className="col-span-4 flex items-center">
                                <div className="h-1 w-full bg-green-400" />
                              </div>
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => {}}
                                onClick={(e) => {
                                  onMatchingClicked(connected[1]);
                                  e.stopPropagation();
                                }}
                                className={`col-span-2 mx-2 flex cursor-pointer items-center justify-center rounded-md border border-green-500 py-smd text-center text-green-600 hover:scale-105 hover:bg-gray-50 ${connected[1] === '' && 'py-[20px]'}`}
                              >
                                {connected[1]}
                              </div>
                            </div>
                          ))}
                        </div>

                        {/* Unconnected Lists */}
                        <div className="grid grid-cols-8">
                          <div className="relative col-span-2 flex flex-col">
                            <div className="flex-1 space-y-2 pb-2 pt-2">
                              <div className="flex flex-col space-y-2">
                                {unmatchedHeaders.map((header, idx) => (
                                  <div
                                    key={`header-${idx.toString()}`}
                                    role="button"
                                    onClick={(e) => {
                                      setFocusedTitle(header.title);
                                      e.stopPropagation();
                                    }}
                                    tabIndex={0}
                                    onKeyDown={(e) => {
                                      e.stopPropagation();
                                    }}
                                    className={`mx-2 h-full flex-1 cursor-pointer rounded-md border border-gray-300 py-smd text-center text-gray-400 hover:scale-105 hover:bg-gray-50 ${focusedTitle === header.title && 'scale-105 border-2 border-indigo-500 text-indigo-500 shadow-md hover:bg-transparent'} ${focusedTitle !== '' && focusedTitle !== header.title && 'blur-[1px]'} hover:blur-0`}
                                  >
                                    {header.title}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className="col-span-4" />
                          <div className="relative col-span-2 flex flex-col">
                            <div className="hidden-scrollbar flex-1 space-y-2 pb-2 pt-2">
                              <div className="flex flex-col space-y-2">
                                {originalHeaders.map((header, idx) => (
                                  <div
                                    key={`header-${idx.toString()}`}
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={() => {}}
                                    onClick={(e) => {
                                      onMatchingClicked(header);
                                      e.stopPropagation();
                                    }}
                                    className={`mx-2 h-full min-h-10 flex-1 cursor-pointer rounded-md border border-gray-300 py-smd text-center text-gray-400 hover:scale-105 hover:bg-gray-50 ${header === '' && 'py-[20px]'}`}
                                  >
                                    {header}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-5 flex space-x-2 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border-2 border-gray-300 px-slg py-smd text-sm font-semibold text-black shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500"
                      onClick={() => {
                        reset();
                        setOpen(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="inline-flex w-full items-center justify-center rounded-md bg-primary-500 px-slg py-smd text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500"
                    >
                      {loading ? (
                        <div>
                          <ThreeDots className="text-white before:text-white after:text-white" />
                        </div>
                      ) : (
                        <>Save</>
                      )}
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export { CreateMatchingDialog };
