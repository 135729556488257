import {
  startOfMonth, startOfToday, startOfWeek, subMonths,
  subWeeks,
} from 'date-fns';

import { TimePeriod } from 'features/analytics/types/timePeriod';

export const timePeriodToTimeWindow = (timePeriod: TimePeriod) => {
  if (timePeriod === TimePeriod.Daily) {
    return new Date(Date.now() - 1000 * 60 * 60 * 24 * 30); // 30 days
  }

  const today = startOfToday();

  if (timePeriod === TimePeriod.Weekly) {
    const startDate = startOfWeek(subWeeks(today, 4));
    return startDate; // 4 weeks
  }

  // Get start of the month of 6 months ago
  const startDate = startOfMonth(subMonths(today, 6));
  return startDate; // 6 months
};
