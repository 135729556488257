import { Chats } from './classes/Chats';

let globalChats: Chats = null;

const initGlobalChats = async () => {
  globalChats = new Chats();
  await globalChats.initPersistance();
};

export { globalChats, initGlobalChats };
