import { autorun } from 'mobx';

import {
  addHttpResponseInterceptor,
  removeHttpResponseInterceptor,
} from '../utils/xhr';

import { globalChats } from './globalChats';
import { globalInboxes } from './globalInboxes';
import { globalLROs } from './globalLROs';
import { globalMessageIntents } from './globalMessageIntents';
import { globalNotifications } from './globalNotifications';
import { globalOrderDrafts } from './globalOrderDrafts';
import { globalSseSources } from './globalSseSources';
import { globalThreads } from './globalThreads';
import { globalUser } from './globalUser';

const initGlobalEffects = () => {
  autorun(() => {
    if (globalUser.isSignedIn) {
      addHttpResponseInterceptor(
        'refreshTokenIf401',
        undefined,
        globalUser.refreshTokenIf401,
      );
    } else {
      removeHttpResponseInterceptor('refreshTokenIf401');
    }
  });

  autorun(() => {
    if (globalUser.isSignedIn) {
      addHttpResponseInterceptor(
        'blockNotActivatedUser',
        undefined,
        globalUser.blockNotActivatedUser,
      );
    } else {
      removeHttpResponseInterceptor('blockNotActivatedUser');
    }
  });

  autorun(() => {
    if (globalUser.isSignedIn) {
      globalSseSources.connectSources();
    } else {
      globalSseSources.disconnectSources();
    }
  });

  autorun(() => {
    if (globalSseSources.areConnected) {
      globalChats.addSseSourcesHandler();
      globalOrderDrafts.addSseSourcesHandler();
      globalMessageIntents.addSseSourcesHandler();
      globalLROs.addSseSourcesHandler();
      globalNotifications.addSseSourcesHandler();
      globalThreads.addSseSourcesHandler();
      globalInboxes.addSseSourcesHandler();
    } else {
      globalChats.removeSseSourcesHandler();
      globalOrderDrafts.removeSseSourcesHandler();
      globalMessageIntents.removeSseSourcesHandler();
      globalLROs.removeSseSourcesHandler();
      globalNotifications.removeSseSourcesHandler();
      globalThreads.removeSseSourcesHandler();
      globalInboxes.removeSseSourcesHandler();
    }
  });
};

export { initGlobalEffects };
