import { infiniteQueryOptions } from '@tanstack/react-query';

import { fetchOrders, FetchOrdersParams } from '../api';

export const ordersOptions = (params?: FetchOrdersParams) => infiniteQueryOptions({
  queryKey: ['orders', params],
  queryFn: ({ pageParam = '' }) => fetchOrders({
    ...params,
    cursor: pageParam,
  }),
  initialPageParam: '',
  getNextPageParam: (lastPage) => lastPage.cursor || null,
  gcTime: 0,
  meta: {
    errorMessage: 'Error fetching orders',
  },
});
