import { useCallback, useState } from 'react';

import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, NumberInput } from '@mantine/core';

interface Props {
  defaultQuantity: number;
  onUpdateQuantityConfirmed: (quantity: number) => void;
  onUpdateQuantityCanceled: () => void;
}

const ContentLowQuantity = ({
  defaultQuantity, onUpdateQuantityConfirmed, onUpdateQuantityCanceled,
}: Props) => {
  const [quantity, setQuantity] = useState(defaultQuantity);

  const onConfirmButtonClicked = useCallback(() => {
    if (!quantity || quantity <= 0) {
      return;
    }

    onUpdateQuantityConfirmed(quantity);
  }, [onUpdateQuantityConfirmed, quantity]);

  return (
    <>
      {/* Title */}
      <div className="flex items-center gap-1">
        <FontAwesomeIcon icon={faExclamationTriangle} className="text-warning-500" />
        <h1 className="text-lg font-bold text-warning-700">
          Low Quantity
        </h1>
      </div>
      <p className="text-xs text-gray-700">
        Adam has detected a very low quantity for this product.
        Please check the quantity and update it if necessary.
      </p>
      <div className="flex justify-center items-center gap-1 py-3">
        <NumberInput
          value={quantity}
          onChange={(value) => setQuantity(Number(value))}
          error={quantity <= 0 ? 'Quantity must be greater than 0' : ''}
          hideControls
          required
        />
      </div>
      <div className="flex justify-end pt-1">
        <div className="flex items-center gap-2">
          <Button
            variant="outline"
            size="sm"
            onClick={onUpdateQuantityCanceled}
          >
            Cancel
          </Button>
          <Button
            onClick={onConfirmButtonClicked}
            size="sm"
          >
            Confirm
          </Button>
        </div>
      </div>
    </>
  );
};

export default ContentLowQuantity;
