import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { FunnelIcon } from '@heroicons/react/24/outline';
import { MultiSelect, Popover, Select } from '@mantine/core';

import { MessageIntent } from 'features/message/models/Message';

import { FetchThreadsProps } from 'features/inbox/apis';

import { Button } from 'components/ui/Button';

import { toTitleLocaleCase } from 'utils/strings';

interface Props {
  popOverTarget?: React.ReactNode;
  filter?: FetchThreadsProps;
  setThreadsFilter: (filter: FetchThreadsProps) => void;
}

const intentOptions = [
  { value: MessageIntent.ORDER, label: toTitleLocaleCase(MessageIntent.ORDER) },
  { value: MessageIntent.OTHER, label: toTitleLocaleCase(MessageIntent.OTHER) },
];

const statusOptions = [
  { value: 'all', label: 'All' },
  { value: 'unread', label: 'Unread' },
];

const ThreadFilter = ({ popOverTarget, filter, setThreadsFilter }: Props) => {
  const { t } = useTranslation();

  const [opened, setOpened] = useState(false);

  const [, setSearchParams] = useSearchParams();

  const [localThreadsFilter, setLocalThreadsFilter] = useState<FetchThreadsProps>(filter || {});

  const onApplyClick = () => {
    setThreadsFilter({ ...filter, ...localThreadsFilter });

    const newSearchParams: Record<string, string> = {};

    // DEV-564: TODO(ntauth)
    // if (localThreadsFilter.intent) {
    //   newSearchParams.intent = localThreadsFilter.intent;
    // }

    // if (localMessagesFilter.sources) {
    //   newSearchParams.message_sources = localMessagesFilter.sources.join(',');
    // }

    // if (localThreadsFilter.unreadOnly) {
    //   newSearchParams.unread_only = 'true';
    // }

    setSearchParams(newSearchParams);
    setOpened(false);
  };

  return (
    <Popover
      position="bottom"
      clickOutsideEvents={['mouseup', 'touchend']}
      transitionProps={{ transition: 'pop', duration: 200 }}
      shadow="md"
      withArrow
      opened={opened}
      onChange={setOpened}
    >
      <Popover.Target>
        {popOverTarget || (
          <button
            type="button"
            className="cursor-pointer"
            onClick={() => setOpened(true)}
          >
            <div className="flex border border-gray-300/80 shadow-sm gap-1 items-center rounded-sm px-1.5 py-0.5">
              <FunnelIcon className="w-4 aspect-square text-gray-600" />
              <p className="text-sm text-gray-600">{t('inbox.filter.filter')}</p>
            </div>
          </button>
        )}
      </Popover.Target>
      <Popover.Dropdown>
        <div className="grid grid-cols-3 gap-2">
          <p className="col-span-1">{t('inbox.filter.intents')}</p>
          <MultiSelect
            placeholder={t('inbox.filter.intents.placeholder')}
            data={intentOptions}
            value={localThreadsFilter.intents}
            onChange={(value) => {
              setLocalThreadsFilter({ ...localThreadsFilter, intents: value as MessageIntent[] });
            }}
            comboboxProps={{ withinPortal: false }}
            className="col-span-2"
          />

          {/* <p className="col-span-1">Sources</p>
          <MultiSelect
            data={sourcesOptions}
            value={localMessagesFilter.sources}
            onChange={(value) => {
              if (!value) {
                setLocalMessagesFilter({ ...localMessagesFilter, sources: null });
              } else {
                setLocalMessagesFilter({ ...localMessagesFilter, sources: value as MessageSource[] });
              }
            }}
            comboboxProps={{ withinPortal: false }}
            className="col-span-2"
          /> */}

          <p className="col-span-1">{t('inbox.filter.status')}</p>
          <Select
            data={statusOptions}
            value={localThreadsFilter.unread_only ? t('inbox.filter.status.unread') : t('inbox.filter.status.all')}
            onChange={(value) => {
              setLocalThreadsFilter({ ...localThreadsFilter, unread_only: value === t('inbox.filter.status.unread') });
            }}
            comboboxProps={{ withinPortal: false }}
            className="col-span-2"
          />

          <div className="col-span-3">
            <Button
              title={t('inbox.filter.apply')}
              onClick={onApplyClick}
              className="w-full"
            />
          </div>
        </div>
      </Popover.Dropdown>
    </Popover>
  );
};

export default ThreadFilter;
