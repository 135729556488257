import {
  Bar,
  BarChart,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { twMerge } from 'tailwind-merge';

import { Formatter, NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { Tooltip as TooltipComponent } from 'components/ui';
import LoadingOverlay from 'components/ui/LoadingOverlay';

import {
  Card,
  CardContent,
} from './ChartCard';
import ChartCardHeaderWithSelect, { ChartCardHeaderWithSelectProps } from './ChartCardHeaderWithSelect';

type Data = {
  [key: string]: any;
};

interface Props {
  data: Data[];
  xDataKey: string;
  yDataKey: string;

  isLoading?: boolean;

  chartDivClassName?: string;
  horizontal?: boolean;

  tooltipFormatter?: Formatter<ValueType, NameType>;
}

const EllipsisTick = ({
  x,
  y,
  payload,
}: {
  x: number;
  y: number;
  payload: { value: string };
}) => {
  const text = payload.value;
  const truncatedText = text.length > 15 ? `${text.slice(0, 15)}...` : text;
  return (
    <g transform={`translate(${x},${y})`}>
      <TooltipComponent label={payload.value}>
        <text x={0} y={0} dy={4} textAnchor="end" fill="var(--black-lighter-text)" fontSize={11} fontWeight={300} width={100}>
          {truncatedText}
        </text>
      </TooltipComponent>
    </g>
  );
};

const BarChartComponent = ({
  data,
  xDataKey,
  yDataKey,
  isLoading = false,
  horizontal = true,
  chartDivClassName,
  tooltipFormatter = (value) => [value.toLocaleString()],
  ...headerProps // Destructure the rest of the props for ChartCardHeaderWithSelect
}: Props & ChartCardHeaderWithSelectProps) => (
  <Card className="w-full">
    <ChartCardHeaderWithSelect
      {...headerProps} // Pass the destructured props here
    />
    <CardContent className="relative">
      <LoadingOverlay visible={isLoading} />

      {
        (data || []).length === 0 ? (
          <div
            className={twMerge(
              'pt-10 w-full flex justify-center',
              chartDivClassName,
            )}
          >
            <div className="text-sm text-gray-500">No data available</div>
          </div>
        ) : (
          <div className={twMerge(
            'h-96 w-full',
            chartDivClassName,
          )}
          >
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={data}
                layout={horizontal ? 'horizontal' : 'vertical'}
                margin={{
                  top: 10,
                  left: horizontal ? 20 : 50,
                  bottom: 5,
                }}
              >
                <XAxis
                  type={horizontal ? 'category' : 'number'}
                  dataKey={horizontal ? xDataKey : yDataKey}
                  tick={{ fill: 'var(--black-lighter-text)', fontSize: 11, fontWeight: 300 }}
                  tickLine={{ stroke: 'var(--black-lighter-text)' }}
                  axisLine={{ stroke: 'var(--black-lighter-text)' }}
                  tickFormatter={(value) => value.toLocaleString()}
                />
                <YAxis
                  type={horizontal ? 'number' : 'category'}
                  dataKey={horizontal ? yDataKey : xDataKey}
                  tick={horizontal ? { fill: 'var(--black-lighter-text)', fontSize: 11, fontWeight: 300 } : EllipsisTick}
                  tickLine={{ stroke: 'var(--black-lighter-text)' }}
                  axisLine={{ stroke: 'var(--black-lighter-text)' }}
                />
                <Tooltip
                  contentStyle={{
                    backgroundColor: '#fff',
                    border: '1px solid #e5e7eb',
                    borderRadius: '0.5rem',
                  }}
                  formatter={tooltipFormatter}
                  cursor={{ fill: 'transparent' }}
                />
                <Bar
                  dataKey={yDataKey}
                  fill="var(--purple-highlight-2)"
                  radius={[0, 4, 4, 0]}
                  maxBarSize={30}
                  activeBar={<Rectangle fill="var(--purple-highlight-1)" />}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        )
      }
    </CardContent>
  </Card>
);

export default BarChartComponent;
