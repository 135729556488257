import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import { useChatWithAdamWizardStore, useWizardSequenceStore } from 'stores';
import { twMerge } from 'tailwind-merge';

import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ROUTES } from 'config/routes';

import { OVERVIEW_WIZARD_STEPS, WizardSteps } from 'constants/wizardSteps';

import { Popover, Wizard } from 'components/ui';

import adam from '../../../../assets/adam-face.png';

const ChatWithAdam = () => {
  const currentStep = useWizardSequenceStore((state) => state.currentStep);
  const completeStep = useWizardSequenceStore((state) => state.completeStep);

  const completed = useChatWithAdamWizardStore((state) => state.completed);
  const show = useChatWithAdamWizardStore((state) => state.show);
  const complete = useChatWithAdamWizardStore((state) => state.complete);

  const isCurrentStep = currentStep === OVERVIEW_WIZARD_STEPS.indexOf(WizardSteps.CHAT);

  useEffect(() => {
    if (isCurrentStep && (completed || !show)) {
      completeStep();
    }
  }, [completed, completeStep, isCurrentStep, show]);

  const onComplete = (doNotShowAgain: boolean) => {
    complete(doNotShowAgain);
    completeStep();
  };

  console.log(currentStep, completed, show);

  return (
    <Popover
      opened={isCurrentStep && !completed && show}
      position="bottom"
    >
      <Popover.Target>
        <NavLink to={ROUTES.ADAM_CHAT} className="flex items-center justify-center">
          <div
            className={twMerge(
              'inline-flex items-center justify-center gap-2 pl-0 py-0 relative flex-[0_0_auto] rounded-full desktop:bg-[rgba(255,255,255,1.0)] [background:linear-gradient(90deg,rgba(130,73,242,1)_0%,rgba(190,153,240,1)_100%)] shadow-blue-light-tint-drop-shadow cursor-pointer',
              'pr-3 desktop:pr-4',
            )}
          >
            <img
              src={adam}
              alt="adam"
              className="relative size-[30px] desktop:size-9 object-cover rounded-full"
            />
            <div className="inline-flex items-center relative flex-[0_0_auto]">
              <div className={twMerge(
                'relative w-fit mt-[-1.00px] whitespace-nowrap text-white',
                'font-font-screen-sm-regular font-[number:var(--font-screen-sm-regular-font-weight)] text-[length:var(--font-screen-sm-regular-font-size)] tracking-[var(--font-screen-sm-regular-letter-spacing)] leading-[var(--font-screen-sm-regular-line-height)] [font-style:var(--font-screen-sm-regular-font-style)]',
                'desktop:font-font-screen-nav-bar-medium desktop:font-[number:var(--font-screen-nav-bar-medium-font-weight)] desktop:text-[length:var(--font-screen-nav-bar-medium-font-size)] desktop:tracking-[var(--font-screen-nav-bar-medium-letter-spacing)] desktop:leading-[var(--font-screen-nav-bar-medium-line-height)] desktop:[font-style:var(--font-screen-nav-bar-medium-font-style)]',
              )}
              >
                Chat with Adam
              </div>
            </div>
          </div>
        </NavLink>
      </Popover.Target>
      <Popover.Dropdown>
        <Wizard
          // eslint-disable-next-line react/no-unstable-nested-components
          Icon={() => <FontAwesomeIcon icon={faChartLine} />}
          content={(
            <div className="inline-flex flex-col items-start justify-center gap-1.5 relative flex-[0_0_auto]">
              <div className="inline-flex flex-col items-start gap-1 pl-0 pr-2 py-0 relative flex-[0_0_auto]">
                <p className="relative w-fit mt-[-1.00px] font-font-screen-xs-medium font-[number:var(--font-screen-xs-medium-font-weight)] text-black text-[length:var(--font-screen-xs-medium-font-size)] tracking-[var(--font-screen-xs-medium-letter-spacing)] leading-[var(--font-screen-xs-medium-line-height)] whitespace-nowrap [font-style:var(--font-screen-xs-medium-font-style)]">
                  You can now chat with Adam to get insights about your data!
                </p>
                <p className="pt-2 font-font-screen-sm-regular font-[number:var(--font-screen-sm-regular-font-weight)] text-black-text-70 text-[length:var(--font-screen-sm-regular-font-size)] tracking-[var(--font-screen-sm-regular-letter-spacing)] leading-[var(--font-screen-sm-regular-line-height)] [font-style:var(--font-screen-sm-regular-font-style)] max-w-[400px]">
                  Adam has now access to your data and will help you to get insights about your business.
                  {' '}
                  Click here to start chatting!
                </p>
              </div>
            </div>
          )}
          isVisible={isCurrentStep && !completed && show}
          onComplete={onComplete}
        />
      </Popover.Dropdown>
    </Popover>
  );
};

export default ChatWithAdam;
