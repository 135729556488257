import { forwardRef, Ref, SVGProps } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props extends SVGProps<SVGSVGElement> {
  className?: string;
}

const ChevronDownIcon = ({ className, ...props }: Props, ref: Ref<SVGSVGElement>) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    className={twMerge('w-6 h-6', className)}
    {...props}
  >
    <path
      d="M5.99969 9L11.918 14.9642C11.9375 14.9839 11.9694 14.9839 11.989 14.9642L17.9072 9"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export default forwardRef(ChevronDownIcon);
