import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import DOMPurify from 'dompurify';
import { twMerge } from 'tailwind-merge';

import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Loader } from '@mantine/core';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { ROUTES } from 'config/routes';

import { OverflowEllipsisParagraph } from 'components/ui/OverflowEllipsisParagraph';

import styles from './NewChatButton.module.css';

import { chatByIdOptions, chatsOptions } from 'features/chat/options';
import { useAiChatStore } from 'features/chat/stores';

interface Props {
  chatId: string;
}

const formatChatTitle = (title: string) => {
  const boldReplaced = title.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

  // Replace '\n' with '<br />'
  const formattedText = boldReplaced.replace(/\n/g, '<br />');

  const html = DOMPurify.sanitize(formattedText);

  return React.createElement('div', { dangerouslySetInnerHTML: { __html: html } });
};

const LeftSide = ({ chatId }: Props) => {
  const chats = useAiChatStore((state) => state.chats);
  const setChats = useAiChatStore((state) => state.setChats);
  const currentChat = useAiChatStore((state) => state.currentChat);
  const setCurrentChat = useAiChatStore((state) => state.setCurrentChat);
  const loadingChatById = useAiChatStore((state) => state.loadingChatById);

  const {
    data, isFetching: isFetchingChats, hasNextPage, fetchNextPage,
  } = useInfiniteQuery({
    ...chatsOptions({
      ai_chats_only: true,
      limit: 10,
    }),
  });

  const {
    data: chatData, refetch: refetchChat, error: errorChat,
  } = useQuery({
    ...chatByIdOptions(chatId),
    enabled: false,
  });

  useEffect(() => {
    setChats(data?.pages.flatMap((page) => page.result) ?? []);
  }, [data, setChats]);

  useEffect(() => {
    if (!chatId || !chats.length) return;

    const chat = chats.find((c) => c.id === chatId);

    if (chat) {
      setCurrentChat(chat);
    } else {
      if (chatData?.id === chatId || errorChat) return;

      refetchChat()
        .then((res) => {
          if (res.data) {
            setCurrentChat(res.data);
          }
        });
    }
  }, [chats, chatId, chatData, setCurrentChat, refetchChat, errorChat]);

  return (
    <div
      className="w-full px-4 4xl:px-6 py-5 4xl:py-6 space-y-9 4xl:space-y-11"
    >
      <NavLink className="block" to={ROUTES.ADAM_CHAT}>
        <Button
          variant="white"
          radius="xl"
          classNames={styles}
          leftSection={<FontAwesomeIcon icon={faEdit} />}
          w="100%"
        >
          New chat
        </Button>
      </NavLink>

      {/* Chats list */}
      <div className="space-y-3">
        <p
          className="px-2 font-font-screen-sm-regular font-[number:var(--font-screen-sm-regular-font-weight)] text-placeholder-text text-[length:var(--font-screen-sm-regular-font-size)] tracking-[var(--font-screen-sm-regular-letter-spacing)] leading-[var(--font-screen-sm-regular-line-height)] whitespace-nowrap [font-style:var(--font-screen-sm-regular-font-style)]"
        >
          Chats
        </p>

        <div className="space-y-1">
          {chats.map((chat) => (
            <NavLink
              key={chat.id}
              to={ROUTES.ADAM_CHAT_BY_ID(chat.id)}
              className={twMerge(
                'block hover:bg-light-blue-hue-box px-3 py-2 rounded-full',
                chat.id === currentChat?.id && 'bg-light-blue-hue-box',
              )}
            >
              {
                loadingChatById[chat.id] ? (
                  <div className="w-full flex justify-center items-center">
                    <Loader type="dots" size="sm" />
                  </div>
                ) : (
                  <OverflowEllipsisParagraph
                    maxLines={1}
                    className="text-ellipsis font-font-screen-md-regular font-[number:var(--font-screen-md-regular-font-weight)] text-black-text-70 text-[length:var(--font-screen-md-regular-font-size)] tracking-[var(--font-screen-md-regular-letter-spacing)] leading-[var(--font-screen-md-regular-line-height)] whitespace-nowrap [font-style:var(--font-screen-md-regular-font-style)]"
                  >
                    {formatChatTitle(chat.title || chat.lastMessage) || 'New Chat'}
                  </OverflowEllipsisParagraph>
                )
              }
            </NavLink>
          ))}

          {
            hasNextPage && !isFetchingChats && (
              <div className="w-full flex justify-center pt-2">
                <button
                  type="button"
                  onClick={() => fetchNextPage()}
                  className="text-ellipsis text-gray-500 font-font-screen-md-regular font-[number:var(--font-screen-md-regular-font-weight)] text-[length:var(--font-screen-md-regular-font-size)] tracking-[var(--font-screen-md-regular-letter-spacing)] leading-[var(--font-screen-md-regular-line-height)] whitespace-nowrap [font-style:var(--font-screen-md-regular-font-style)]"
                >
                  Load more
                </button>
              </div>
            )
          }

          <div className={twMerge(
            'h-10 relative items-center justify-center hidden',
            isFetchingChats && 'flex',
          )}
          >
            <Loader type="dots" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftSide;
