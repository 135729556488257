import {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import Dayjs from 'dayjs';
import { twMerge } from 'tailwind-merge';

import { NumberInput, Select, TextInput } from '@mantine/core';
import { DateTimePicker, DateValue } from '@mantine/dates';

import LabelWrapper from './LabelWrapper';

export type FieldType = 'input' | 'number input' | 'dropdown' | 'datetime' | 'text';

interface Props {
  type: FieldType;
  label: string;
  value?: string;
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  validation?: string;

  onValueChange?: (value: string | Date | number) => void;
  addError?: (msg: string) => void;
  removeError?: () => void;

  placeHolder?: string;
  className?: string;
  rightSection?: React.ReactNode;
  itemStyle?: React.CSSProperties;
}

// Function to parse validation string
function parseValidators(validation: string) {
  if (!validation) return [];

  return validation.split(',')?.map((rule) => {
    const [key, value] = rule.split('=');
    return { key, value: value || true };
  });
}

  // ADT for validation rules
  type Validator =
    | { type: 'required' }
    | { type: 'min'; value: number }
    | { type: 'max'; value: number }
    | { type: 'alpha'; value: number }
    | { type: 'alphanum'; value: number };

function createValidator({ key, value }: { key: string; value: any }): Validator {
  switch (key) {
    case 'required':
      return { type: 'required' };
    case 'min':
      return { type: 'min', value: Number(value) };
    case 'max':
      return { type: 'max', value: Number(value) };
    case 'alpha':
      return { type: 'alpha', value: Number(value) };
    case 'alphanum':
      return { type: 'alphanum', value: Number(value) };
    default:
      return { type: 'required' };
  }
}

const DynamicField = ({
  type,
  label,
  value: defaultValue,
  required,
  validation,
  disabled,
  readOnly,

  onValueChange,
  addError,
  removeError,

  placeHolder,
  className,
  rightSection,
  itemStyle,
}: Props) => {
  const [localValue, setLocalValue] = useState<any>(defaultValue || '');

  const [errorMsg, setErrorMsg] = useState('');

  const dateTimeValue = useMemo(() => (
    localValue ? Dayjs(localValue).toDate() : undefined
  ), [localValue]);

  const textInputPlaceholder = useMemo(() => (
    placeHolder || localValue || 'No value extracted'
  ), [placeHolder, localValue]);

  const onAnyChange = useCallback((val: any) => {
    setLocalValue(val);
    onValueChange?.(val);
  }, [setLocalValue, onValueChange]);

  const onTextInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onAnyChange(e.target.value);
  }, [onAnyChange]);

  const onNumberInputChange = useCallback((val: string | number) => {
    onAnyChange(parseFloat(val?.toString() || '0'));
  }, [onAnyChange]);

  const onDateTimeChange = useCallback((datetime: DateValue) => {
    onAnyChange(datetime ? Dayjs(datetime).format() : null);
  }, [onAnyChange]);

  const setError = useCallback((msg: string) => {
    setErrorMsg(msg);

    if (msg) {
      addError?.(msg);
    } else {
      removeError?.();
    }
  }, [setErrorMsg, addError, removeError]);

  useEffect(() => {
    const parsedValidators = parseValidators(validation);
    const validationRules = parsedValidators.map(createValidator);

    validationRules.every((rule) => {
      if (rule.type === 'required' && !localValue) {
        setError(`${label} is a required field and cannot be empty.`);
        return false;
      }

      if (
        rule.type === 'min'
          && (
            (type === 'input' && localValue.length < rule.value)
            || (type === 'number input' && Number(localValue) < rule.value)
          )
      ) {
        setError(`${label} must be at least ${rule.value}.`);
        return false;
      }

      if (
        rule.type === 'max'
          && (
            (type === 'input' && localValue.length > rule.value)
            || (type === 'number input' && Number(localValue) > rule.value)
          )
      ) {
        setError(`${label} must be at most ${rule.value}.`);
        return false;
      }

      if (
        rule.type === 'alpha'
          && !/^[a-zA-Z]+$/.test(localValue)
      ) {
        setError(`${label} must be alphabetic.`);
        return false;
      }

      if (
        rule.type === 'alphanum'
          && !/^[a-zA-Z0-9]+$/.test(localValue)
      ) {
        setError(`${label} must be alphanumeric.`);
        return false;
      }
      setError('');
      return true;
    });
  }, [label, required, setError, localValue, validation, type]);

  useEffect(() => {
    setLocalValue(defaultValue || '');
  }, [defaultValue]);

  const item = useMemo(() => {
    if (type === 'dropdown') {
      return (
        <Select
          className={twMerge('w-full', className)}
          value={localValue}
          placeholder={placeHolder}
          disabled={disabled}
          readOnly={readOnly}
          onChange={onAnyChange}
          rightSection={rightSection}
          size="xs"
          style={itemStyle}
        />
      );
    }

    if (type === 'input') {
      return (
        <TextInput
          className={twMerge('w-full', className)}
          value={localValue}
          placeholder={placeHolder}
          disabled={disabled}
          readOnly={readOnly}
          error={errorMsg}
          size="xs"
          onChange={onTextInputChange}
          rightSection={rightSection}
          styles={{
            error: {
              position: 'absolute',
            },
            ...itemStyle,
          }}
        />
      );
    }

    if (type === 'number input') {
      return (
        <NumberInput
          className={twMerge('w-full', className)}
          value={localValue}
          placeholder={placeHolder}
          disabled={disabled}
          readOnly={readOnly}
          error={errorMsg}
          size="xs"
          hideControls
          onChange={onNumberInputChange}
          rightSection={rightSection}
          styles={{
            error: {
              position: 'absolute',
            },
            ...itemStyle,
          }}
        />
      );
    }

    if (type === 'datetime') {
      return (
        <DateTimePicker
          className={twMerge('w-full', className)}
          value={dateTimeValue}
          placeholder={placeHolder}
          disabled={disabled}
          readOnly={readOnly}
          onChange={onDateTimeChange}
          error={errorMsg}
          rightSection={rightSection}
          size="xs"
          styles={{
            error: {
              position: 'absolute',
            },
            ...itemStyle,
          }}
        />
      );
    }

    return (
      <TextInput
        variant="unstyled"
        className={twMerge('w-full', className)}
        value={localValue}
        placeholder={textInputPlaceholder}
        disabled={disabled}
        readOnly
        error={errorMsg}
        rightSection={rightSection}
        size="xs"
        styles={{
          input: {
            borderBottom: '1px solid #A1A3AB',
            borderRadius: '0',
          },
          error: {
            position: 'absolute',
          },
          ...itemStyle,
        }}
      />
    );
  }, [type, className, localValue, textInputPlaceholder,
    disabled, placeHolder, readOnly, onAnyChange, onTextInputChange,
    onNumberInputChange, dateTimeValue, onDateTimeChange, errorMsg, rightSection, itemStyle]);

  return (
    <LabelWrapper label={label} errorMsg={errorMsg}>
      {item}
    </LabelWrapper>
  );
};

export default DynamicField;
