/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo } from 'react';

import { motion, MotionProps } from 'framer-motion';
import { v4 as uuidv4 } from 'uuid';

import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { Tooltip } from '@mantine/core';

import { FieldSchema, FieldSpec } from '../../../../../features/instruction/models';

import { Card } from 'components/common/Card';

import { fieldSchemasToGropedOptions, getProductNameField, isProductSchema } from '../../../../../features/instruction/utils';

import { CommonBody, ProductBody } from './Body';

interface Props extends MotionProps {
  fieldSchema: FieldSchema;
  fieldSpecs: FieldSpec[];
  setFieldSpecs: React.Dispatch<React.SetStateAction<FieldSpec[]>>;
}

const containerVariants = {
  hidden: { opacity: 1 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3,
    },
  },
};

const itemVariant = {
  hidden: { opacity: 0, y: 20 },
  show: { opacity: 1, y: 0 },
};

const getNonCardableBody = (fieldSchema: FieldSchema) => {
  if (fieldSchema.name === 'Product') {
    return ProductBody;
  }

  return CommonBody;
};

const fieldSchemasToPaths = (fieldSpecs: FieldSchema[]) => {
  const paths: string[] = [];

  fieldSpecs.forEach((fieldSpec) => {
    paths.push(fieldSpec.path);

    if (fieldSpec?.typeSchema?.fields) {
      paths.push(...fieldSchemasToPaths(fieldSpec.typeSchema.fields));
    }
  });

  return paths;
};

const standardFieldTooltipContent = (
  <p>
    Put any additional fields other than product or customer fields here.
    <br />
    Examples include information related to delivery, billing, or other details.
  </p>
);

const InstructionCard = React.memo(({
  fieldSchema, fieldSpecs, setFieldSpecs, ...props
}: Props) => {
  const cardableFields: FieldSchema[] = (fieldSchema.typeSchema?.fields || []).filter((field) => field.isCardable);
  const nonCardableFields: FieldSchema[] = (fieldSchema.typeSchema?.fields || []).filter((field) => !field.isCardable);

  const groupedOptions = fieldSchemasToGropedOptions(nonCardableFields);

  const localFieldSpecs = useMemo(
    () => fieldSpecs.filter((fieldSpec) => (
      fieldSpec.lastCardableParentPath === fieldSchema.path),
    ).map((fieldSpec) => ({
      ...fieldSpec,
    })),
    [fieldSpecs, fieldSchema.path],
  );

  const NonCardableBody = useMemo(() => getNonCardableBody(fieldSchema), [fieldSchema]);

  useEffect(() => {
    if (isProductSchema(fieldSchema)) {
      const productField = getProductNameField(fieldSchema);

      if (!productField) return;

      setFieldSpecs((_fieldSpecs) => {
        if (_fieldSpecs.find((fieldSpec) => fieldSpec.path === productField.path)) {
          return _fieldSpecs.map((_fieldSpec) => (
            _fieldSpec.path === productField.path ? { ..._fieldSpec, isRemovable: false } : _fieldSpec
          ));
        }

        return [
          {
            name: 'Product name',
            path: productField.path,
            type: productField.type,
            isErpBindable: true,
            uiId: uuidv4(),
            inputType: productField.inputType,
            lastCardableParentPath: productField.lastCardableParentPath,
            isRemovable: false,
            added: true,
          } as FieldSpec,
          ..._fieldSpecs,
        ];
      });
    }
  }, [fieldSchema, setFieldSpecs]);

  if (!fieldSchema.typeSchema) {
    return null;
  }

  return (
    <motion.div variants={itemVariant} {...props}>
      <Card className="mb-6">
        <Card.Header>{fieldSchema.name}</Card.Header>
        <Card.Body className="flex-col">
          {
            cardableFields.length > 0 && nonCardableFields.length > 0 && (
              <Card>
                <Card.Header>
                  <div className="flex space-x-2 items-center">
                    <p>
                      Standard fields
                    </p>
                    {
                    fieldSchema.name === 'Order'
                    && (
                    <Tooltip
                      label={standardFieldTooltipContent}
                      transitionProps={{ transition: 'pop', duration: 200 }}
                      bg="white"
                      styles={{
                        tooltip: {
                          color: 'black',
                          border: '1.5px solid #E8E8E8',
                          boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
                        },
                      }}
                    >
                      <QuestionMarkCircleIcon className="w-5 h-5" />
                    </Tooltip>
                    )
                  }
                  </div>
                </Card.Header>
                <Card.Body className="flex">
                  <NonCardableBody
                    parentPath={fieldSchema.path}
                    fieldSpecs={localFieldSpecs}
                    setFieldSpecs={setFieldSpecs}
                    groupedOptions={groupedOptions}
                  />
                </Card.Body>
              </Card>
            )
          }

          {
            cardableFields.length === 0 && nonCardableFields.length > 0 && (
              <NonCardableBody
                parentPath={fieldSchema.path}
                fieldSpecs={localFieldSpecs}
                setFieldSpecs={setFieldSpecs}
                groupedOptions={groupedOptions}
              />
            )
          }

          <motion.div variants={containerVariants}>
            {cardableFields.map((field) => (
              <InstructionCard
                key={field.name}
                fieldSchema={field}
                fieldSpecs={fieldSpecs}
                setFieldSpecs={setFieldSpecs}
              />
            ))}
          </motion.div>
        </Card.Body>
      </Card>
    </motion.div>
  );

  // if (schema.isCardable) {
  //   // if (fieldSchema.typeSchema.name === 'Product') {
  //   //   return (
  //   //     <ProductBody
  //   //       instructions={fieldSchema.typeSchema?.fields}
  //   //       setInstructions={(instructions) => {
  //   //         setFieldSchema({
  //   //           ...fieldSchema,
  //   //           typeSchema: {
  //   //             ...fieldSchema.typeSchema,
  //   //             fields: instructions,
  //   //           },
  //   //         });
  //   //       }}
  //   //     />
  //   //   );
  //   // }

  //   return (
  //     <motion.div variants={itemVariant} {...props}>
  //       <Card>
  //         <Card.Header>{fieldSchema.name}</Card.Header>
  //         <Card.Body className="flex-col">
  //           {fieldSchema.typeSchema.fields.map(field => (
  //             <InstructionCard
  //               key={field.name}
  //               fieldSchema={field}
  //               // setFieldSchema={(fieldSchema) => {
  //               //   setFieldSchema({
  //               //     ...fieldSchema,
  //               //     typeSchema: {
  //               //       ...fieldSchema.typeSchema,
  //               //       fields: fieldSchema.typeSchema.fields,
  //               //     },
  //               //   });
  //               // }}
  //               setFieldSchema={setFieldSchema}
  //             />
  //           ))}
  //         </Card.Body>
  //       </Card>
  //     </motion.div>
  //   );
  // }
  // return (
  //   <div>
  //     {nonCardableFields.map(field => (
  //       <div key={field.name}>{field.name}</div>
  //     ))}
  //   </div>
  // );
});

export default InstructionCard;
