import {
  memo,
  useEffect, useMemo,
  useRef, useState,
} from 'react';

import DOMPurify from 'dompurify';
import TypeWriter from 'typewriter-effect';

import { AdamMessageWrapper } from '../../../wrapper/AdamMessageWrapper';

interface Props {
  text: string;
  useWrapper?: boolean;
  useTypeWriter?: boolean;
  callback?: () => void;
}

function formatText(text: string): string {
  // Replace '**...**' with '<strong>...</strong>'
  const boldReplaced = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

  // Replace '\n' with '<br />'
  const formattedText = boldReplaced.replace(/\n/g, '<br />');

  return formattedText;
}

const AdamTextContent = ({
  text,
  useTypeWriter = true,
  callback,
}: {
  text: string;
  useTypeWriter?: boolean;
  callback?: () => void;
}) => {
  const [adamTextFinished, setAdamTextFinished] = useState(false);
  const endOfTextRef = useRef<HTMLDivElement>(null);

  const modifiedText = useMemo(() => DOMPurify.sanitize(formatText(text)), [text]);

  useEffect(() => {
    setTimeout(() => {
      if (adamTextFinished && endOfTextRef.current) {
        endOfTextRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }, 100);
  }, [adamTextFinished]);

  if (!useTypeWriter) {
    return (
      <div>
        {React.createElement('div', { dangerouslySetInnerHTML: { __html: modifiedText } })}
      </div>
    );
  }

  return (
    <>
      <TypeWriter
        onInit={(typewriter) => {
          typewriter
            .changeDelay(15)
            .typeString(modifiedText)
            .callFunction(() => {
              document
                .querySelectorAll('.Typewriter__cursor')
                ?.forEach((cursor) => {
                  cursor.classList.add('hidden');
                });
              setAdamTextFinished(true);
              if (callback) {
                callback();
              }
            })
            .start();
        }}
        options={{
          delay: 0,
        }}
      />
      <div ref={endOfTextRef} />
    </>
  );
};

const AdamText = memo(({
  text,
  useWrapper = true,
  useTypeWriter = true,
  callback,
}: Props) => {
  if (!useWrapper) {
    return <AdamTextContent text={text} useTypeWriter={useTypeWriter} callback={callback} />;
  }

  return (
    <AdamMessageWrapper variant="small">
      <AdamTextContent text={text} useTypeWriter={useTypeWriter} callback={callback} />
    </AdamMessageWrapper>
  );
});

export default AdamText;
