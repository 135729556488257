import { SettingSection } from './types';

import FeedButton from './FeedButton';

interface Props {
  currentSectionId: string;
  sections: SettingSection[];
}

const Feed = ({ currentSectionId, sections }: Props) => (
  <div className="active left-auto w-[230px]">
    <div
      className="relative flex grow flex-col before:absolute before:bottom-0 before:left-[11px] before:top-0 before:border-l before:border-gray-200"
      data-scrollspy="true"
      data-scrollspy-offset="80px|lg:110px"
      data-scrollspy-target="body"
    >
      {sections.map((section) => (section.children && section.children.length > 0 ? (
        <div key={`feed-${section.sectionId}`} className="flex flex-col">
          <div className="py-2.5 pl-6 pr-2.5 text-sm font-semibold text-gray-900">
            {section.title}
          </div>
          <div className="flex flex-col">
            {section.children.map((child) => (
              <span key={`feed-${child.sectionId}`}>
                <FeedButton
                  currentSectionId={currentSectionId}
                  section={child}
                  isSubSection
                />
              </span>
            ))}
          </div>
        </div>
      ) : (
        <span key={`feed-${section.sectionId}`}>
          <FeedButton currentSectionId={currentSectionId} section={section} />
        </span>
      )),
      )}
    </div>
  </div>
);

export default Feed;
