/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';

import { Product } from 'features/product/models/Product';

import { Page } from 'components/layout/Page/Page';

interface Props {
  productId: string;
  navigateToProductsList: () => void;
}

const ProductDetailsPage = ({ productId, navigateToProductsList }: Props) => (
  <Page>
    Product page content
  </Page>
);

export default ProductDetailsPage;
