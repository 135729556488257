import { useCallback, useState } from 'react';

import { v4 as uuidv4 } from 'uuid';

import { Schema } from '../../features/instruction/models';

import { TypeRef } from '../../features/instruction/types';

import { genericErrorFeedback } from 'utils/errors';
import { httpGetV1 } from 'utils/xhr';

const useFetchSchemaByTypeRef = () => {
  const [schema, setSchema] = useState<Schema | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const loadSchema = useCallback(
    (
      typeRef: TypeRef,
      giveErrorFeedback: boolean = true,
    ): Promise<Schema> => {
      if (!typeRef) return Promise.reject();
      setIsLoading(true);
      return httpGetV1('/schemas', {
        params: {
          type_ref: typeRef,
        },
      })
        .then((response) => {
          const _schema = response.data as Schema;
          const _schemaWithUiId: Schema = {
            ..._schema,
            fields: _schema.fields.map((field) => ({
              ...field,
              uiId: uuidv4(),
            })),
          };
          setSchema(_schemaWithUiId);
          return _schemaWithUiId;
        })
        .catch((error) => {
          if (giveErrorFeedback) {
            genericErrorFeedback('An error has occured while fetching type schema')(
              error,
            );
          }
          return Promise.reject(error);
        })
        .finally(() => setIsLoading(false));
    },
    [],
  );

  return {
    schema,
    loadSchema,
    isLoading,
  };
};

export { useFetchSchemaByTypeRef };
