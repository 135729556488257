import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useSidebarStore } from 'stores';

import { AlertTheme } from 'state/classes/AlertDataList';
import { globalAlertDataList } from 'state/globalAlertDataList';

import { MainContent, Navigator } from '../components/process-order-draft_beta';
import { Page } from 'components/layout/Page/Page';

import { useProcessOrderDraftsStore } from '../store/process-order-drafts';

interface Props {
  navigateToHome: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ProcessOrderDraftsPage = ({ navigateToHome }: Props) => {
  const { t } = useTranslation();
  const closeSidebar = useSidebarStore((state) => state.close);
  useEffect(() => {
    closeSidebar();
  }, [closeSidebar]);

  const currentIndex = useProcessOrderDraftsStore((state) => state.currentIndex);
  const loadCurrentOrderAndMessages = useProcessOrderDraftsStore((state) => state.loadCurrentOrderAndMessages);
  const loadOrderDrafts = useProcessOrderDraftsStore((state) => state.loadOrderDrafts);
  const setCustomOnOrderDraftProcessed = useProcessOrderDraftsStore((state) => state.setCustomOnOrderDraftProcessed);

  useEffect(() => {
    loadOrderDrafts(true)
      .then(() => {
        loadCurrentOrderAndMessages();
      })
      .catch((e) => {
        navigateToHome();
        globalAlertDataList.create(
          'Error loading order drafts',
          AlertTheme.ERROR,
          e.message,
        );
      });
  }, [loadOrderDrafts, loadCurrentOrderAndMessages, navigateToHome]);

  // Every time the current index changes, load the current order
  useEffect(() => {
    loadCurrentOrderAndMessages();
  }, [currentIndex, loadCurrentOrderAndMessages]);

  useEffect(() => {
    setCustomOnOrderDraftProcessed(null);
  }, [setCustomOnOrderDraftProcessed]);

  return (
    <Page
      pageTitle={t('order.processOrderDrafts.title')}
      contentWithBorder
      className="gap-0"
    >
      <Navigator />
      <MainContent />
    </Page>
  );
};

export default ProcessOrderDraftsPage;
