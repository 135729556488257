import { AttachmentWithIndex, Message, MessageContextUtils } from 'features/message/models/Message';

import { useProcessOrderDraftsStore } from 'features/order/store/process-order-drafts';

export const useDocsUtils = ({ message }: { message: Message }) => {
  const setSelectedDocIndex = useProcessOrderDraftsStore((state) => state.setSelectedDocIndex);
  const setSelectedDocPageIndex = useProcessOrderDraftsStore((state) => state.setSelectedDocPageIndex);
  const selectedDocIndex = useProcessOrderDraftsStore((state) => state.selectedDocIndex);
  const selectedDocPageIndex = useProcessOrderDraftsStore((state) => state.selectedDocPageIndex);

  const docs = MessageContextUtils.documentAttachments(message.context);
  const docImages: AttachmentWithIndex[] = docs.map((doc, docIndex) => doc.imagifiedPages?.map((page, pageIndex) => ({
    ...page,
    docIndex,
    pageIndex,
  })) || []).reduce(
    (acc, curr) => [...acc, ...curr],
    [],
  );

  const docUrl = docs[selectedDocIndex]?.url;

  const selectedDocImgIndex = MessageContextUtils.docAndPageIndexToImgIndex(docs, selectedDocIndex, selectedDocPageIndex);

  const imageLength = docImages.length;
  const showImage = imageLength > 0;
  const showRecording = MessageContextUtils.audioAttachments(message?.context).length > 0;

  const handleImageTabClick = (index: number) => {
    const img = docImages[index];
    if (img) {
      setSelectedDocIndex(img.docIndex);
      setSelectedDocPageIndex(img.pageIndex);
    }
  };

  const handleAttachmentClick = (index: number) => {
    if (docs[index]) {
      setSelectedDocIndex(index);
      setSelectedDocPageIndex(0);
    }
  };

  return {
    docImages,
    docs,
    docUrl,
    imageLength,
    selectedDocImgIndex,
    showImage,
    showRecording,
    handleImageTabClick,
    handleAttachmentClick,
  };
};
