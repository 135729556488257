import { twMerge } from 'tailwind-merge';

import { OverflowEllipsisParagraph } from 'components/ui/OverflowEllipsisParagraph';

interface Props {
  visibleId: string;
  shrink?: boolean;
}

const VisibleId = ({ visibleId, shrink }: Props) => {
  if (!visibleId) return null;
  return (
    <div className={twMerge(
      shrink ? 'flex-shrink-0' : 'min-w-[20%] max-w-[20%]',
    )}
    >
      <OverflowEllipsisParagraph
        maxLines={1}
        isTooltipEnabled
        className="!font-mono"
      >
        {visibleId}
      </OverflowEllipsisParagraph>
    </div>
  );
};

export default VisibleId;
