import { useProcessOrderDraftsStore } from 'features/order/store/process-order-drafts';

const PriceAlert = () => {
  const isOrderPricesAlertVisible = useProcessOrderDraftsStore((state) => state.isOrderPricesAlertVisible);

  if (!isOrderPricesAlertVisible) return null;

  return (
    <div className="bg-blue-50 border-l-4 border-blue-400 p-4 my-2">
      <div className="flex items-start">
        <div className="flex-shrink-0">
          {/* Information icon */}
          <svg className="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2h-1V9a1 1 0 00-1-1z" clipRule="evenodd" />
          </svg>
        </div>
        <div className="ml-3">
          <p className="text-sm text-blue-700">
            The prices shown here are not updated. If you want to see the updated prices, please save the order.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PriceAlert;
