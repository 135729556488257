import {
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { Product } from 'features/product/models/Product';

import { Page } from 'components/layout/Page/Page';
import { ProductDetailsModal, ProductListTableWithExportButton, ProductSearchBar } from 'features/product/components';

import { genericErrorFeedback } from 'utils/errors';

import { FetchProductsParams } from '../api';
import { productsOptions } from '../queryOptions';
import { productCategoriesOptions } from '../queryOptions/productCategoriesOptions';

const ProductsListPage = () => {
  const { t } = useTranslation();

  const [products, setProducts] = useState<Product[]>([]);

  const [showDialog, setShowDialog] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState<string | null>(null);

  const [params, setParams] = useState<FetchProductsParams>({});

  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    error: productsError,
  } = useInfiniteQuery({
    ...productsOptions(params),
  });

  const {
    data: categories,
    error: categoriesError,
  } = useQuery({
    ...productCategoriesOptions(),
  });

  const openDialog = (productId: string) => {
    setSelectedProductId(productId);
    setShowDialog(true);
  };

  useEffect(() => {
    setProducts(data?.pages.flatMap((page) => page.result) ?? []);
  }, [data]);

  useEffect(() => {
    if (productsError) {
      genericErrorFeedback(t('error.fetchingProducts'))(productsError);
    }

    if (categoriesError) {
      genericErrorFeedback(t('error.fetchingCategories'))(categoriesError);
    }
  }, [productsError, categoriesError, t]);

  return (
    <Page
      pageTitle={t('product.list.title')}
      pageDescription={t('product.list.description')}
      className="p-4"
      contentWithBorder
    >
      <div className="flex justify-between gap-lg">
        <div>
          <ProductSearchBar
            categories={categories}
            setParams={setParams}
          />
        </div>
      </div>

      <ProductListTableWithExportButton
        products={products}
        onDetailsButtonClick={openDialog}
        isLoading={isLoading}
        onScrolledToEnd={fetchNextPage}
        hasNextPage={hasNextPage}
      />

      <ProductDetailsModal
        opened={showDialog}
        onClose={() => setShowDialog(false)}
        product={products.find((product) => product.id === selectedProductId) ?? null}
      />
    </Page>
  );
};

export default ProductsListPage;
