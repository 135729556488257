import { forwardRef, Ref, SVGProps } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props extends SVGProps<SVGSVGElement> {
  className?: string;
}

const SidebarIcon = ({ className, ...props }: Props, ref: Ref<SVGSVGElement>) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    className={twMerge('w-6 h-6', className)}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.96631 19.6949L7.96632 4.30108H7.3165C5.46635 4.30108 3.9665 5.80093 3.9665 7.65108V16.3449C3.9665 18.195 5.46635 19.6949 7.3165 19.6949H7.96631ZM9.26631 19.6949H16.0112C17.8613 19.6949 19.3612 18.195 19.3612 16.3449V7.65108C19.3612 5.80093 17.8613 4.30108 16.0112 4.30108H9.26632L9.26631 19.6949ZM8.65414 3.00108H16.0112C18.5793 3.00108 20.6612 5.08296 20.6612 7.65108V16.3449C20.6612 18.913 18.5793 20.9949 16.0112 20.9949H7.3165C4.74838 20.9949 2.6665 18.913 2.6665 16.3449V7.65108C2.6665 5.08296 4.74838 3.00108 7.3165 3.00108H8.57849C8.59101 3.00036 8.60362 3 8.61632 3C8.62901 3 8.64162 3.00036 8.65414 3.00108Z"
    />
  </svg>

);

export default forwardRef(SidebarIcon);
