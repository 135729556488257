import { Subject } from 'features/user/models';

interface Props {
  subjects: Subject[];
  onSelect: (email: string) => void;
  onClose: () => void;
}

const EmailAddressHintsDropdown = ({ subjects, onSelect, onClose }: Props) => (
  <div className="absolute left-0 right-0 top-full mt-1 bg-white border border-gray-200 rounded-md shadow-lg z-50 max-h-48 overflow-y-auto">
    {subjects.map((s) => (
      s.GetEmails().map((email) => (
        <button
          type="button"
          key={email}
          className="w-full text-left px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center gap-3"
          onClick={() => {
            onSelect(email);
            onClose();
          }}
        >
          <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center flex-shrink-0">
            <span className="text-blue-700 font-medium">
              {(`${s.firstName} ${s.lastName}`.trim() || email).charAt(0).toUpperCase()}
            </span>
          </div>
          <div>
            <div className="text-sm font-medium">
              {(`${s.firstName} ${s.lastName}`.trim())}
            </div>
            <div className="text-xs text-gray-500">{email}</div>
          </div>
        </button>
      ))
    ))}
  </div>
);

export default EmailAddressHintsDropdown;
