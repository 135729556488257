import { useEffect, useRef } from 'react';

import { observer } from 'mobx-react-lite';

import { LRO_STATUSES } from 'state/classes/LROs';
import { globalLROs } from 'state/globalLROs';

import LroListItem from './LroListItem';

const LroList = observer(() => {
  const observerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const interactionObserver = new IntersectionObserver(
      () => {
        globalLROs.loadPendingLROs();
      },
      { threshold: 0.5 },
    );

    const currentObserverRef = observerRef.current;
    if (currentObserverRef) {
      interactionObserver.observe(currentObserverRef);
    }

    return () => {
      if (currentObserverRef) {
        interactionObserver.unobserve(currentObserverRef);
      }
    };
  }, []);

  return (
    <>
      {globalLROs.lros.filter((lro) => lro.status === LRO_STATUSES.PENDING).map((lro) => (
        <LroListItem lro={lro} />
      ))}

      <div ref={observerRef} />
    </>
  );
});

export default LroList;
