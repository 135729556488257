import {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import { Group } from 'features/instruction/constants';

interface Props {
  resetTrigger: number;
}

export type GroupErrorsAndWarningsState = Record<Group, Record<string, string>>;
type ErrorsAndWarningsState = Record<string, GroupErrorsAndWarningsState>;

const DefaultGroupErrorsAndWarningsState: GroupErrorsAndWarningsState = {
  [Group.Customer]: {},
  [Group.Product]: {},
  [Group.StandardFields]: {},
};

type UseWarningAndErrorsReturnType = {
  getErrors: (orderId: string) => GroupErrorsAndWarningsState;
  addError: (orderId: string, group: Group, key: string, error: string) => void;
  removeError: (orderId: string, group: Group, key: string) => void;
  deleteProductErrorsByUiId: (orderId: string, uiId: string) => void;

  getWarnings: (orderId: string) => GroupErrorsAndWarningsState;
  addWarning: (orderId: string, group: Group, key: string, warning: string) => void;
  removeWarning: (orderId: string, group: Group, key: string) => void;
  deleteProductWarningsByUiId: (orderId: string, uiId: string) => void;

  filteredErrors: string[];

  resetGroupErrorsAndWarnings: (orderId: string, group: Group) => void;
};

const add = (
  setState: React.Dispatch<React.SetStateAction<ErrorsAndWarningsState>>,
) => (orderId: string, group: Group, key: string, value: string) => {
  setState((prevState) => {
    const prevStateByOrderId = prevState[orderId] || DefaultGroupErrorsAndWarningsState;
    const newGroupState = { ...prevStateByOrderId[group] };
    newGroupState[key] = value;
    return {
      ...prevState,
      [orderId]: {
        ...prevStateByOrderId,
        [group]: newGroupState,
      },
    };
  });
};

const remove = (
  setState: React.Dispatch<React.SetStateAction<ErrorsAndWarningsState>>,
) => (orderId: string, group: Group, key: string) => {
  setState((prevState) => {
    const prevStateByOrderId = prevState[orderId] || DefaultGroupErrorsAndWarningsState;
    const newGroupState = { ...prevStateByOrderId[group] };
    delete newGroupState[key];
    return {
      ...prevState,
      [orderId]: {
        ...prevStateByOrderId,
        [group]: newGroupState,
      },
    };
  });
};

const deleteProduct = (
  setState: React.Dispatch<React.SetStateAction<ErrorsAndWarningsState>>,
) => (orderId: string, uiId: string) => {
  setState((prevState) => {
    const prevStateByOrderId = prevState[orderId] || DefaultGroupErrorsAndWarningsState;
    const updatedProductGroup = Object.keys(prevStateByOrderId[Group.Product]).reduce((acc, key) => {
      if (!key.startsWith(uiId)) {
        acc[key] = prevStateByOrderId[Group.Product][key];
      }
      return acc;
    }, {} as Record<string, string>);
    return {
      ...prevState,
      [orderId]: {
        ...prevStateByOrderId,
        [Group.Product]: updatedProductGroup,
      },
    };
  });
};

export const useWarningAndErrors = ({ resetTrigger }: Props): UseWarningAndErrorsReturnType => {
  const [errors, setErrors] = useState<ErrorsAndWarningsState>({});
  const [warnings, setWarnings] = useState<ErrorsAndWarningsState>({});

  const getErrors = useCallback((orderId: string) => errors[orderId] || DefaultGroupErrorsAndWarningsState, [errors]);
  const getWarnings = useCallback((orderId: string) => warnings[orderId] || DefaultGroupErrorsAndWarningsState, [warnings]);

  const filteredErrors = useMemo(() => (
    Object.values(errors)
      .map((groupErrsRec) => Object.values(groupErrsRec))
      .flat()
      .map((errsRec) => Object.values(errsRec))
      .flat()
      .filter((e) => e !== '')
  ), [errors]);

  const addError = useCallback(
    (orderId: string, group: Group, key: string, error: string) => add(setErrors)(orderId, group, key, error),
    [],
  );
  const removeError = useCallback(
    (orderId: string, group: Group, key: string) => remove(setErrors)(orderId, group, key),
    [],
  );
  const deleteProductErrorsByUiId = useCallback(
    (orderId: string, uiId: string) => deleteProduct(setErrors)(orderId, uiId),
    [],
  );

  const addWarning = useCallback(
    (orderId: string, group: Group, key: string, warning: string) => add(setWarnings)(orderId, group, key, warning),
    [],
  );
  const removeWarning = useCallback(
    (orderId: string, group: Group, key: string) => remove(setWarnings)(orderId, group, key),
    [],
  );
  const deleteProductWarningsByUiId = useCallback(
    (orderId: string, uiId: string) => deleteProduct(setWarnings)(orderId, uiId),
    [],
  );

  const resetGroupErrorsAndWarnings = useCallback((orderId: string, group: Group) => {
    setErrors((prevState) => {
      const prevStateByOrderId = prevState[orderId] || DefaultGroupErrorsAndWarningsState;
      prevStateByOrderId[group] = {};
      return {
        ...prevState,
        [orderId]: prevStateByOrderId,
      };
    });
  }, []);

  useEffect(() => {
    setErrors({});
    setWarnings({});
  }, [resetTrigger]);

  return {
    getErrors,
    addError,
    removeError,
    deleteProductErrorsByUiId,

    getWarnings,
    addWarning,
    removeWarning,
    deleteProductWarningsByUiId,

    filteredErrors,

    resetGroupErrorsAndWarnings,
  };
};
