import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface WizardState {
  show: boolean;
  completed: boolean;
  complete: (doNotShowAgain: boolean) => void;
}

// Factory function to create wizard stores
export const createWizardStore = (wizardName: string) => create<WizardState>()(
  persist(
    (set) => ({
      show: true,
      completed: false,
      complete: (doNotShowAgain: boolean) => set(() => ({
        show: false,
        ...(doNotShowAgain ? { completed: true } : {}),
      })),
    }),
    {
      name: `wizard-${wizardName}`,
      partialize: (state) => ({ completed: state.completed }),
    },
  ),
);
