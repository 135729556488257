import { useEffect, useRef } from 'react';

import { twMerge } from 'tailwind-merge';

import { useInfiniteScrollTableContext } from './InfiniteScrollTable.context';

interface Props extends React.HTMLAttributes<HTMLTableSectionElement> {
  children: React.ReactNode;
}

const InfiniteScrollTableThead = ({ children, ...props }: Props) => {
  const { onScrolledToEnd, hasNextPage } = useInfiniteScrollTableContext();

  const observerRef = useRef(null);

  useEffect(() => {
    const interactionObserver = new IntersectionObserver(
      (entries) => {
        if (!hasNextPage) return;

        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            onScrolledToEnd();
          }
        });
      },
      { threshold: 0.5 },
    );

    const currentObserverRef = observerRef.current;
    if (currentObserverRef) {
      interactionObserver.observe(currentObserverRef);
    }

    return () => {
      if (currentObserverRef) {
        interactionObserver.unobserve(currentObserverRef);
      }
    };
  }, [onScrolledToEnd, hasNextPage]);

  return (
    <tbody
      {...props}
      className={twMerge(
        'font-font-screen-xs-regular font-[number:var(--font-screen-xs-regular-font-weight)] text-black text-[length:var(--font-screen-xs-regular-font-size)] tracking-[var(--font-screen-xs-regular-letter-spacing)] leading-[var(--font-screen-xs-regular-line-height)] whitespace-nowrap [font-style:var(--font-screen-xs-regular-font-style)]',
        props.className,
      )}
    >
      {children}

      <tr>
        <td ref={observerRef} />
      </tr>
    </tbody>
  );
};

export default InfiniteScrollTableThead;
