import { twMerge } from 'tailwind-merge';

interface Props extends React.HTMLAttributes<HTMLTableCellElement> {
  text?: string;
  Icon?: React.ElementType;

  children?: React.ReactNode;

  isLast?: boolean;
}

const Th = ({ className, isLast, ...props }: Props) => (
  <th
    {...props}
    scope="col"
    className={twMerge(
      'table-cell text-left p-1.5 relative border-b-[0.5px] border-solid border-violet-hue-selection-button-1',
      !isLast && 'border-r-[0.5px]',
      className,
    )}
  />
);

const InfiniteScrollTableTh = ({
  text, Icon, children, ...props
}: Props) => {
  if (children) {
    return (
      <Th {...props}>
        {children}
      </Th>
    );
  }

  return (
    <Th {...props}>
      <div className="flex items-center gap-1">
        {Icon && <Icon className="size-[18px]" />}
        <p
          className="relative flex-1 whitespace-nowrap font-font-screen-sm-medium font-[number:var(--font-screen-sm-medium-font-weight)] text-black text-[length:var(--font-screen-sm-medium-font-size)] tracking-[var(--font-screen-sm-medium-letter-spacing)] leading-[var(--font-screen-sm-medium-line-height)] [font-style:var(--font-screen-sm-medium-font-style)]"
        >
          {text}
        </p>
      </div>
    </Th>
  );
};

export default InfiniteScrollTableTh;
