import { useEffect, useRef } from 'react';

import { observer } from 'mobx-react-lite';

import { globalNotifications } from 'state/globalNotifications';

import NotificationListItem from './NotificationListItem';

const NotificationList = observer(() => {
  const observerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const interactionObserver = new IntersectionObserver(
      (entries) => {
        // If the last notification is visible, load more notifications
        if (entries[0].isIntersecting) {
          globalNotifications.loadNotifications();
        }
      },
      { threshold: 0.5 },
    );

    const currentObserverRef = observerRef.current;
    if (currentObserverRef) {
      interactionObserver.observe(currentObserverRef);
    }

    return () => {
      if (currentObserverRef) {
        interactionObserver.unobserve(currentObserverRef);
      }
    };
  }, []);

  return (
    <>
      {globalNotifications.notifications.map((event) => (
        <NotificationListItem
          key={event.id}
          event={event}
        />
      ))}

      <div ref={observerRef} />
    </>
  );
});

export default NotificationList;
