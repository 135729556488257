import { useTranslation } from 'react-i18next';

import { twMerge } from 'tailwind-merge';

import { Badge } from '@mantine/core';

import { User } from 'features/user/models';

import { isUserActivated } from 'features/user/utils';

import TeamMemberIcon from './TeamMemberIcon';

interface Props {
  user: User;
  className?: string;
}

const TeamMemberIconWIthAcrtiveStatus = ({ user, className }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={twMerge('mb-1 relative overflow-visible w-full flex justify-center', className)}>
      <TeamMemberIcon user={user} />

      {!isUserActivated(user) && (
      <Badge
        variant="dot"
        color="orange"
        className="absolute -top-3 left-1/2 -translate-x-1/2 whitespace-nowrap z-10 min-w-max"
      >
        {t('teammember.notActivated')}
      </Badge>
      )}
    </div>
  );
};

export default TeamMemberIconWIthAcrtiveStatus;
