import { useEffect, useRef, useState } from 'react';

import { Formik } from 'formik';
import { array, object, string } from 'yup';

import { MultiSelect } from '@mantine/core';

import { AssignableUserRole } from 'features/user/models';

import { useFetchAssignableRoles } from 'hooks/fetch/useFetchAssignableRoles';

import { FormInput } from 'components/FormInput';
import { Page } from 'components/layout/Page/Page';
import { Button } from 'components/ui/Button';
import { FlashCard } from 'components/ui/FlashCard';

import { genericErrorFeedback } from 'utils/errors';
import { httpPostV1 } from 'utils/xhr';

const formInitialValues = {
  email: '',
  roles: [AssignableUserRole.BusinessUser],
};

const formValidationSchema = object({
  email: string().required(),
  roles: array().of(string().oneOf(Object.values(AssignableUserRole))).min(1).required(),
});

interface Props {
  navigateToTeamMembersList: () => void;
}

const TeamMemberInvitePage = ({ navigateToTeamMembersList }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const buttonRef = useRef(null);
  const { assignableRoles } = useFetchAssignableRoles({ preventInitialFetch: false });

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (buttonRef.current && event.key === 'Enter') {
        buttonRef.current.click();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <Page>
      <div className="flex-1">
        <div
          className="m-4 flex w-96 flex-col gap-lg overflow-y-auto rounded-lg border border-solid bg-white p-lg pb-0"
          style={{ maxHeight: 'calc(100vh - 90px)' }}
        >
          <FlashCard
            title="Invite team member"
            description="An invite email will be sent to this email address with simple instructions to create the user account."
          />

          <Formik
            initialValues={formInitialValues}
            validationSchema={formValidationSchema}
            onSubmit={(values) => {
              setIsLoading(true);
              httpPostV1('/users/invite', {
                email: values.email,
                roles: values.roles,
              })
                .then(() => {
                  navigateToTeamMembersList();
                })
                .catch(genericErrorFeedback('Error inviting user'))
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              errors,
              touched,
            }) => (
              <div className="flex flex-col gap-smd">
                <FormInput
                  field="email"
                  disabled={isLoading}
                  label="Member email"
                  value={values.email}
                  error={errors.email}
                  touched={touched.email}
                  required
                  onChange={handleChange('email')}
                  onBlur={handleBlur('email')}
                />
                <MultiSelect
                  value={values.roles}
                  data={assignableRoles}
                  onChange={(_values) => handleChange({ target: { name: 'roles', value: _values } })}
                  onBlur={handleBlur('roles')}
                  label="Roles"
                  clearable
                  required
                />

                <div className="sticky bottom-0 z-10 mt-4 w-full border-t border-solid bg-white pb-4 pt-4">
                  <Button
                    ref={buttonRef}
                    title="Send invite"
                    disabled={isLoading}
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </Page>
  );
};

export default TeamMemberInvitePage;
