import { v4 as uuidv4 } from 'uuid';

import { Schema } from '../models';

import { httpGetV1 } from 'utils/xhr';

export const fetchSchemaByTypeRef = async (typeRef: string) => httpGetV1('/schemas', {
  params: {
    type_ref: typeRef,
  },
})
  .then((response) => {
    const schema = response.data as Schema;
    const schemaWithUiId: Schema = {
      ...schema,
      fields: schema.fields.map((field) => ({
        ...field,
        uiId: uuidv4(),
      })),
    };
    return schemaWithUiId;
  });
