import {
  useCallback, useEffect, useRef,
  useState,
} from 'react';

import { uniqBy } from 'lodash';

import { Order, OrderStatus } from '../models';

import { genericErrorFeedback } from 'utils/errors';
import { httpGetV1 } from 'utils/xhr';

type GetOrdersParams = {
  is_not_exported?: boolean;
  status?: OrderStatus[];
  from_time?: string;
  to_time?: string;
  group_id?: string;
  assignee_id?: string;

  cursor?: string;
};

interface FetchOrderDraftsProps {
  initialFetch?: boolean;
  autoLoad?: boolean;
}

const useFetchOrders = ({
  initialFetch = false,
  autoLoad = false,
}: FetchOrderDraftsProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [orders, setOrders] = useState<Order[]>([]);

  const [params, setParams] = useState<GetOrdersParams>({});

  const ordersPaginationCursor = useRef<string | null>(null);
  const initialRenderRef = useRef(true);
  const endReachedRef = useRef(false);
  const currentAbortController = useRef<AbortController | null>(null);

  const resetRefs = () => {
    ordersPaginationCursor.current = null;
    endReachedRef.current = false;
  };

  const resetParams = () => {
    setParams({});
  };

  const loadOrders = useCallback(
    (
      giveErrorFeedback: boolean = true,
      reset: boolean = false,
    ): Promise<Order[]> => {
      if (endReachedRef.current) {
        return Promise.reject(new Error('No more orders to load'));
      }

      if (currentAbortController.current) {
        currentAbortController.current.abort();
      }

      const controller = new AbortController();
      currentAbortController.current = controller;

      setIsLoading(true);

      const paramsWithCursor = {
        ...params,
        cursor: ordersPaginationCursor.current,
      };

      return httpGetV1('/orders', {
        params: paramsWithCursor,
      })
        .then((response) => {
          const responseOrders = response.data.result || [];
          const cursor = response.data.cursor;

          if (reset) {
            setOrders(responseOrders);
          } else {
            setOrders((os) => uniqBy([...os, ...responseOrders], 'id'));
          }

          if (!cursor || responseOrders.length === 0) {
            endReachedRef.current = true;
          } else {
            ordersPaginationCursor.current = cursor;
          }

          return responseOrders;
        })
        .catch((error) => {
          if (giveErrorFeedback) {
            genericErrorFeedback('Error loading orders')(error);
          }
          return Promise.reject(error);
        })
        .finally(() => {
          if (!controller.signal.aborted) {
            setIsLoading(false);
          }
          currentAbortController.current = null;
        });
    },
    [params],
  );

  useEffect(() => {
    if (initialFetch && initialRenderRef.current) {
      resetRefs();
      setOrders([]);
      loadOrders();
      initialRenderRef.current = false;
      return () => {};
    }

    if (autoLoad) {
      resetRefs();
      setOrders([]);
      loadOrders();
    }

    return () => {
      if (currentAbortController.current) {
        currentAbortController.current.abort();
      }
    };
  }, [loadOrders, initialFetch, autoLoad]);

  return {
    isLoading,
    orders,
    setOrders,
    params,
    setParams,
    loadOrders,
    resetParams,
  };
};

export { GetOrdersParams, useFetchOrders };
