import { ReactNode } from 'react';

import HeaderButtons from './PageHeaderButtons';
import HeaderTitle from './PageHeaderTitle';

interface Props {
  title: string;
  description?: string | ReactNode;
}

const Header = ({ title, description }: Props) => (
  <div className="flex items-center justify-between relative self-stretch w-full flex-[0_0_auto]">
    <HeaderTitle title={title} description={description} />

    <HeaderButtons />
  </div>
);

export default Header;
