import { forwardRef, Ref, SVGProps } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props extends SVGProps<SVGSVGElement> {
  className?: string;
}

const HomeIcon = ({ className, ...props }: Props, ref: Ref<SVGSVGElement>) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    className={twMerge('w-6 h-6', className)}
    {...props}
  >
    <path d="M4 18V10.75C4 10.2779 4.22229 9.83328 4.6 9.55L10.8 4.9C11.5111 4.36667 12.4889 4.36667 13.2 4.9L19.4 9.55C19.7777 9.83328 20 10.2779 20 10.75V18C20 19.1046 19.1046 20 18 20H15.5C14.6716 20 14 19.3284 14 18.5V15.5C14 14.6716 13.3284 14 12.5 14H11.5C10.6716 14 10 14.6716 10 15.5V18.5C10 19.3284 9.32843 20 8.5 20H6C4.89543 20 4 19.1046 4 18Z" strokeWidth="1.3" />
  </svg>
);

export default forwardRef(HomeIcon);
