import { useTranslation } from 'react-i18next';

import { TFunction } from 'i18next';

import { useQuery } from '@tanstack/react-query';

import { Metric } from 'features/analytics/types/metric';
import { TimePeriod } from 'features/analytics/types/timePeriod';

import { AreaChart } from 'components/charts';
import { InformationIconWithTooltip } from 'components/ui';

import { correctionsOptions, finalizationTimeOptions } from 'features/analytics/queryOptions';
import { useOrderMetricsStore } from 'features/analytics/stores';

interface Props {
  chartDivClassName?: string;
}

const finalizationTimeTooltipFormatter = (value: any) => [`${value.toLocaleString()} sec`];

const correctionsTooltipFormatter = (value: any) => [`${value.toLocaleString()} corrections`];

const title = (metric: Metric, t: TFunction) => (
  <div className="flex items-center gap-2">
    <p>
      {t('dashboard.orderProcessingMetrics.title')}
    </p>
    <InformationIconWithTooltip
      iconClassName="text-gray-400 hover:text-gray-700"
      label={(
        <div className="max-w-[300px] whitespace-normal">
          {metric === Metric.FinalizationTime ? (
            <div className="space-y-3">
              <div className="grow shrink basis-0 text-[#191919]/75 text-sm font-normal font-['Inter']">
                {t('dashboard.orderProcessingMetrics.finalizationTime.description')}
              </div>
              <div className="self-stretch text-[#232d42]/60 text-[13px] font-normal font-['Inter']">
                {t('dashboard.orderProcessingMetrics.finalizationTime.trend')}
              </div>
            </div>
          ) : (
            <div className="space-y-3">
              <div className="grow shrink basis-0 text-[#191919]/75 text-sm font-normal font-['Inter']">
                {t('dashboard.orderProcessingMetrics.corrections.description')}
              </div>
              <div className="self-stretch text-[#232d42]/60 text-[13px] font-normal font-['Inter']">
                {t('dashboard.orderProcessingMetrics.corrections.trend')}
              </div>
            </div>
          )}
        </div>
    )}
    />
  </div>
);

const FinalizationTimeAndCorrectionsLinechart = ({ chartDivClassName }: Props) => {
  const { t } = useTranslation();

  const timePeriod = useOrderMetricsStore((state) => state.timePeriod);
  const setTimePeriod = useOrderMetricsStore((state) => state.setTimePeriod);
  const metric = useOrderMetricsStore((state) => state.metric);
  const setMetric = useOrderMetricsStore((state) => state.setMetric);

  const {
    data: processTimeData,
    isFetching: isProcessTimeLoading,
  } = useQuery(finalizationTimeOptions(timePeriod, metric));
  const {
    data: correctionsData,
    isFetching: isCorrectionsLoading,
  } = useQuery(correctionsOptions(timePeriod, metric));

  return (
    <AreaChart
      data={metric === Metric.FinalizationTime ? processTimeData : correctionsData}
      xDataKey="x"
      yDataKey="y"
      isLoading={isProcessTimeLoading || isCorrectionsLoading}
      title={title(metric, t)}
      subtitle={metric === Metric.FinalizationTime ? t('dashboard.orderProcessingMetrics.finalizationTime.subtitle') : t('dashboard.orderProcessingMetrics.corrections.subtitle')}
      chartDivClassName={chartDivClassName}
      rightSelectOptions={[
        { value: TimePeriod.Daily, label: t('timePeriod.daily') },
        { value: TimePeriod.Weekly, label: t('timePeriod.weekly') },
        { value: TimePeriod.Monthly, label: t('timePeriod.monthly') },
      ]}
      selectedRightOptionValue={timePeriod}
      setRightOption={setTimePeriod}
      leftSelectOptions={[
        { value: Metric.FinalizationTime, label: t('dashboard.orderProcessingMetrics.finalizationTime.title') },
        { value: Metric.Corrections, label: t('dashboard.orderProcessingMetrics.corrections.title') },
      ]}
      selectedLeftOptionValue={metric}
      setLeftOption={setMetric}
      toolTipFormatter={metric === Metric.FinalizationTime ? finalizationTimeTooltipFormatter : correctionsTooltipFormatter}
    />
  );
};

export default FinalizationTimeAndCorrectionsLinechart;
