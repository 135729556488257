import { debounce, uniqBy } from 'lodash';
import {
  action, makeObservable, observable, runInAction,
} from 'mobx';
import { makePersistable } from 'mobx-persist-store';

import { GetDocumentsQueryType } from '../../models/GetDocumentsQueryType';
import { Event } from 'models/Event';

import { httpGetV1, httpPatchV1 } from 'utils/xhr';

import { globalSseSources } from '../globalSseSources';

class Notifications {
  notifications: Event[] = [];

  unreadCount: number = 0;

  isLoading?: boolean = false;

  cursor?: string = null;

  getUnreadOnly: boolean = false;

  constructor() {
    makeObservable(this, {
      notifications: observable,
      unreadCount: observable,
      getUnreadOnly: observable,
      isLoading: observable,
      loadCount: action,
      loadNotifications: action,
      markAsRead: action,
      updateGetUnreadOnly: action,
      _onNewSseReceived: action,
    });
  }

  initPersistence = async () => {
    await makePersistable(this, {
      name: 'notifications',
      properties: ['getUnreadOnly'],
      storage: window.localStorage,
    });
  };

  _reset = () => {
    runInAction(() => {
      this.notifications = [];
      this.unreadCount = 0;
      this.isLoading = false;
      this.cursor = null;
    });
  };

  refresh = () => {
    this._reset();
    this.loadCount();
    this.loadNotifications();
  };

  loadCount = async () => {
    runInAction(() => {
      this.isLoading = true;
    });

    const countResponse = await httpGetV1('/events', {
      params: {
        get_notification_events: true,
        get_unconsumed_only: true,
        query_type: GetDocumentsQueryType.count,
      },
    });

    runInAction(() => {
      this.unreadCount = countResponse.data.count;
      this.isLoading = false;
    });
  };

  loadNotifications = async (reset?: boolean) => {
    runInAction(() => {
      if (reset) this.cursor = null;
      this.isLoading = true;
    });

    const dataResponse = await httpGetV1('/events', {
      params: {
        get_notification_events: true,
        get_unconsumed_only: this.getUnreadOnly,
        query_type: GetDocumentsQueryType.data,
        cursor: this.cursor,
        limit: 20,
      },
      classType: Event,
    });

    runInAction(() => {
      this.cursor = dataResponse.data.cursor;

      if (reset) {
        this.notifications = dataResponse.data.result || [];
      } else {
        this.notifications = uniqBy(
          [...this.notifications, ...(dataResponse.data.result || [])],
          'id',
        );
      }
      this.isLoading = false;
    });
  };

  _debouncedLoadNotifications = debounce(this.loadNotifications, 2000);

  _debouncedLoadCount = debounce(this.loadCount, 2000);

  markAsRead = async (eventIds: string[]) => {
    await httpPatchV1('/events', { eventIds });

    // After successfully marking as read, reload the count
    runInAction(() => {
      const newNotifications = this.notifications.map((notification) => {
        if (eventIds.includes(notification.id) || eventIds.length === 0) {
          const newEvent = Object.assign(Object.create(Object.getPrototypeOf(notification)), notification);
          newEvent.consumedAt = new Date().toISOString();
          return newEvent;
        }
        return notification;
      });

      // Manually update the consumedAt field of the notifications
      // to avoid re-fetching the notifications from the server.
      this.notifications = newNotifications;
    });

    this.loadCount();
  };

  updateGetUnreadOnly = (getUnreadOnly: boolean) => {
    runInAction(() => {
      this.getUnreadOnly = getUnreadOnly;
    });
  };

  addSseSourcesHandler = () => {
    globalSseSources.addSourcesHandler(
      'notifications',
      [], // Listen to all events
      this._onNewSseReceived,
    );
  };

  // eslint-disable-next-line class-methods-use-this
  removeSseSourcesHandler = () => {
    globalSseSources.removeSourcesHandler('notifications');
  };

  _onNewSseReceived = () => {
    // DEV-266: TODO(ntauth): Only reload if the received SSE is a notification
    this._debouncedLoadNotifications(true);
    this._debouncedLoadCount();
  };
}

export { Notifications };
