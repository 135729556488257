import { Expose, Type } from 'class-transformer';

import { User } from 'state/classes/User';

import { Business } from 'models/Business';
import { OrderParserMessageContext as OrderWorkflowMessageContext, WorkflowRunFSMState } from 'models/Workflow';

class Attachment {
  @Expose({ name: 'type' })
    type: AttachmentType;

  @Expose({ name: 'name' })
    name: string;

  @Expose({ name: 'url' })
    url: string;

  @Expose({ name: 'size' })
    size: number;

  @Expose({ name: 'content_type' })
    contentType?: string;

  @Expose({ name: 'transcription' })
    transcription?: string;

  @Expose({ name: 'is_inline' })
    isInline: boolean;

  @Expose({ name: 'intents' })
    intents: Record<string, boolean>;

  @Type(() => Attachment)
    imagifiedPages?: Attachment[]; // For documents, these are the imagified pages
}

class AttachmentWithIndex extends Attachment {
  docIndex: number;

  pageIndex: number;
}

enum AttachmentType {
  AUDIO = 0,
  IMAGE = 1,
  PDF = 2,
  EXCEL = 3,
  HTML = 4,
  OTHER = 5,
  // Needed for messages migrated from v0 to v1 since we did not know the original attachment type
  DOCUMENT_UNKNOWN = 6,
  AUDIO_TRANSCRIPTION = 7,
  LEGACY_EXCEL = 8,
  WORD = 9,
  LEGACY_WORD = 10,
}

namespace AttachmentType {
  export function isAudio(attachmentType: AttachmentType): boolean {
    return (
      attachmentType === AttachmentType.AUDIO
    );
  }

  export function isDocument(attachmentType: AttachmentType): boolean {
    return (
      attachmentType === AttachmentType.IMAGE
      || attachmentType === AttachmentType.PDF
      || attachmentType === AttachmentType.EXCEL
      || attachmentType === AttachmentType.LEGACY_EXCEL
      || attachmentType === AttachmentType.WORD
      || attachmentType === AttachmentType.LEGACY_WORD
      || attachmentType === AttachmentType.HTML
      || attachmentType === AttachmentType.DOCUMENT_UNKNOWN
    );
  }
}

enum MessageType {
  TEXT = 'text',
  EMAIL = 'email',
  VOICE = 'voice',
}

enum MessageChannel {
  INTERNAL = 'internal',
  IMAP = 'imap',
  SMTP = 'smtp',
  VOICE_BRIDGE = 'voice_bridge',
}

enum MessageRefType {
  ORDER = 'ORDER',
  ORDER_GROUP = 'ORDER_GROUP',
}

class MessageRefAction {
  @Expose({ name: 'details' })
    details: { [key: string]: string };

  @Expose({ name: 'type' })
    type: string;
}

enum MessageIntent {
  ORDER = 'order',
  OTHER = 'other',
}

enum MessageStatus {
  SENDING = 'sending',
  SENT = 'sent',
  FAILED = 'failed',
}

export enum MessageStateType {
  NEW = 'NEW',
  CLASSIFIED = 'CLASSIFIED',
  PROCESSED = 'PROCESSED',
  WORKFLOW_FAILED = 'WORKFLOW_FAILED',
}

class MessageStateDetails {
  @Expose({ name: 'workflows_by_intent' })
    workflowsByIntent: Record<MessageIntent, Record<string, WorkflowRunFSMState>>;
}

class MessageState {
  @Expose({ name: 'type' })
    type: MessageStateType;

  @Expose({ name: 'details' })
  @Type(() => MessageStateDetails)
    details: MessageStateDetails;
}

enum ExternalMessageStatus {
  PENDING = 'pending',
  SENT = 'sent',
  FAILED = 'failed',
}

class OutboundExternalMessageBase {
  status: ExternalMessageStatus;
}

class IMAPMessage {
  raw: string;
}

class SMTPMessage extends OutboundExternalMessageBase {
  raw: string;

  sentWithProxyAddress: boolean;
}

class RagItem {
  dataId: string;

  content: string;

  score: number;

  dataType: 'message';

  reasoning: string;

  partOfItem: string;
}

export enum PlotType {
  LINE_CHART = 'line_chart',
  AREA_CHART = 'area_chart',
  BAR_PLOT = 'bar_plot',
}

export class PlotData {
  @Expose({ name: 'type' })
    type: PlotType;

  @Expose({ name: 'title' })
    title: string;

  @Expose({ name: 'description' })
    description?: string;

  @Expose({ name: 'x' })
    x: any[];

  @Expose({ name: 'y' })
    y: any[];

  @Expose({ name: 'y_unit' })
    yUnit: string;
}

class AIMetadata {
  @Expose({ name: 'plot_data' })
  @Type(() => PlotData)
    plotData?: PlotData;
}

class MessageContext {
  @Expose({ name: 'html' })
    html?: string;

  @Expose({ name: 'subject' })
    subject?: string;

  @Expose({ name: 'attachments' })
  @Type(() => Attachment)
    attachments?: Attachment[];

  @Expose({ name: 'workflow_order' })
  @Type(() => OrderWorkflowMessageContext)
    workflowOrder?: OrderWorkflowMessageContext;

  @Expose({ name: 'rag_item' })
  @Type(() => RagItem)
    ragItem?: RagItem;

  @Expose({ name: 'from' })
    from?: string[];

  @Expose({ name: 'to' })
    to?: string[];

  @Expose({ name: 'phone_from' })
    phoneFrom?: string;
}

// TODO(ntauth): Need to do this instead of simple getters because we are not instantiating MessageContext pdddd.
export const MessageContextUtils = {
  audioAttachments(messageContext?: MessageContext): Attachment[] {
    return messageContext?.attachments?.filter((attachment) => attachment.type === AttachmentType.AUDIO) || [];
  },

  documentAttachments(messageContext?: MessageContext): Attachment[] {
    return messageContext?.attachments?.filter((attachment) => AttachmentType.isDocument(attachment.type)) || [];
  },

  ragAttachment(messageContext?: MessageContext): RagItem | null {
    return messageContext?.ragItem || null;
  },

  docAndPageIndexToImgIndex(attachments: Attachment[], docIndex: number, pageIndex: number): number {
    let imgIndex = 0;
    for (let i = 0; i < docIndex; i += 1) {
      const attachment = attachments[i];
      imgIndex += attachment.imagifiedPages?.length || 0;
    }

    imgIndex += pageIndex;
    return imgIndex;
  },
};

class Message {
  @Expose({ name: 'id' })
    id: string;

  @Expose({ name: 'chat_id_v2' })
    chatId?: string;

  @Expose({ name: 'state' })
  @Type(() => MessageState)
    state: MessageState;

  @Expose({ name: 'thread_id' })
    threadId?: string;

  @Expose({ name: 'business_sent_by' })
    businessSentBy: string;

  @Expose({ name: 'business_sent_by_info' })
  @Type(() => Business)
    businessSentByInfo?: Business;

  @Expose({ name: 'business_sent_to' })
    businessSentTo: string; // DEV-714: TODO(ntauth): Remove, use businessesSentToIds instead

  @Expose({ name: 'business_sent_to_ids' })
    businessSentToIds: string[];

  @Expose({ name: 'created_at' })
    createdAt: string;

  @Expose({ name: 'original_created_at' })
    originalCreatedAt: string;

  @Expose({ name: 'message' })
    message: string;

  @Expose({ name: 'message_type' })
    messageType: MessageType;

  @Expose({ name: 'source' })
    source: MessageChannel;

  @Expose({ name: 'ref' })
    ref: string;

  @Expose({ name: 'ref_type' })
    refType: MessageRefType;

  @Expose({ name: 'read_at' })
    readAt: string;

  @Expose({ name: 'user_sent_by' })
    userSentBy: string;

  @Expose({ name: 'user_sent_by_info' })
    userSentByInfo?: User;

  @Expose({ name: 'user_sent_to' })
    userSentTo: string; // DEV-714: TODO(ntauth): Remove, use userSentToIds instead

  @Expose({ name: 'user_sent_to_ids' })
    userSentToIds: string[];

  @Expose({ name: 'ai_metadata' })
  @Type(() => AIMetadata)
    aiMetadata?: AIMetadata;

  @Expose({ name: 'context' })
  @Type(() => MessageContext)
    context: MessageContext;

  @Expose({ name: 'intents' })
    intents: MessageIntent[];

  @Expose({ name: 'message_status' })
    messageStatus?: MessageStatus;

  @Expose({ name: 'is_added' })
    isAdded?: boolean;

  // Linked external message
  @Expose({ name: 'imap_message' })
  @Type(() => IMAPMessage)
    imapMessage?: IMAPMessage;

  @Expose({ name: 'smtp_message' })
  @Type(() => SMTPMessage)
    smtpMessage?: SMTPMessage;

  @Expose({ name: '__version' })
    docVersion?: string;

  // Local fields
  isAdamThinking?: boolean;
}

export {
  Attachment,
  AttachmentType,
  AttachmentWithIndex,
  ExternalMessageStatus,
  Message,
  MessageChannel,
  MessageIntent,
  MessageRefAction,
  MessageRefType,
  MessageStatus,
  MessageType,
  RagItem,
};
