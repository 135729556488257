import { Subject } from 'features/user/models';

import { parsePaginatedApiResponse } from 'utils';
import { httpGetV1 } from 'utils/xhr';

export interface FetchSubjectsParams {
  query?: string;
  cursor?: string | null;
  limit?: number;
}

export const fetchSubjects = (params: FetchSubjectsParams) => httpGetV1('/businesses/me/subjects', {
  params,
  classType: Subject,
}).then((response) => parsePaginatedApiResponse<Subject>(response));
