import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { MagnifyGlassIcon } from 'components/icon/outline';

interface SearchBarProp {
  onSubmit: React.FormEventHandler<HTMLFormElement>;
}

function CustomerSearchBar({ onSubmit }: SearchBarProp) {
  const { t } = useTranslation();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        const form = event.target as HTMLFormElement;
        form.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onSubmit]);

  return (
    <form onSubmit={onSubmit} className="flex w-[400px] desktop:w-[588px] items-start gap-3 relative rounded-full">
      <div className="flex justify-between flex-1 grow bg-violet-hue-selection-button-3 rounded-full items-center relative">
        <input
          type="text"
          name="search_query"
          id="search_query"
          className="relative flex-1 mx-2 font-font-screen-sm-regular font-[number:var(--font-screen-sm-regular-font-weight)] text-placeholder-text text-[length:var(--font-screen-sm-regular-font-size)] tracking-[var(--font-screen-sm-regular-letter-spacing)] leading-[var(--font-screen-sm-regular-line-height)] whitespace-nowrap [font-style:var(--font-screen-sm-regular-font-style)] bg-transparent border-none focus:outline-none focus:ring-0"
          placeholder={t('customer.list.searchBar.placeholder')}
        />

        <div className="inline-flex justify-center gap-1 px-3 py-2 flex-[0_0_auto] bg-black rounded-full items-center relative">
          <MagnifyGlassIcon className="!h-4 !w-4 text-white" />
          <button
            type="submit"
            className="relative w-fit mt-[-1.50px] font-font-screen-sm-regular font-[number:var(--font-screen-sm-regular-font-weight)] text-[#ffffff] text-[length:var(--font-screen-sm-regular-font-size)] tracking-[var(--font-screen-sm-regular-letter-spacing)] leading-[var(--font-screen-sm-regular-line-height)] whitespace-nowrap [font-style:var(--font-screen-sm-regular-font-style)]"
          >
            {t('customer.list.searchBar.searchButton')}
          </button>
        </div>
      </div>
    </form>
  );
}

export default CustomerSearchBar;
