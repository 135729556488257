import {
  CustomerChurnRate,
  RevenueLineChart,
  TopSellingProductBarChart,
  TopTierCustomerBarChart,
} from 'features/analytics/components';

const Analytics = () => (
  <div className="grid grid-cols-12 gap-4">
    <div className="col-span-8 3xl:col-span-9 row-start-1 h-[500px]">
      <RevenueLineChart />
    </div>

    <div className="col-span-4 3xl:col-span-3 row-start-1 h-[500px]">
      <CustomerChurnRate />
    </div>

    <div className="col-span-6 row-start-2 h-[500px]">
      <TopSellingProductBarChart />
    </div>

    <div className="col-span-6 row-start-2 h-[500px]">
      <TopTierCustomerBarChart />
    </div>
  </div>
);

export default Analytics;
