import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { observer } from 'mobx-react-lite';
import { useSidebarStore } from 'stores';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Button } from '@mantine/core';

import { ROUTES } from 'config/routes';

import { OrderStatus } from '../models';

import { useSelectedParentOrder } from '../components/process-order-draft_beta/main-content/right-side/hooks/useSelectedParentOrder';

import { MainContent } from '../components/process-order-draft_beta';
import { Page } from 'components/layout/Page/Page';

import { useProcessOrderDraftsStore } from '../store/process-order-drafts';

interface Props {
  orderId: string;
  isGroupOrder?: boolean;
}

const ProcessOrderDraftByIdPage = observer(({ orderId, isGroupOrder }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const closeSidebar = useSidebarStore((state) => state.close);
  useEffect(() => {
    closeSidebar();
  }, [closeSidebar]);

  const isGroupOrdersLoading = useProcessOrderDraftsStore((state) => state.isGroupOrdersLoading);
  const loadOrderByReferenceId = useProcessOrderDraftsStore((state) => state.loadOrderByReferenceId);
  const loadMessage = useProcessOrderDraftsStore((state) => state.loadMessage);
  const setCustomOnOrderDraftProcessed = useProcessOrderDraftsStore((state) => state.setCustomOnOrderDraftProcessed);

  const { order } = useSelectedParentOrder();

  // const onOrderDraftRetried = useCallback(async (retriedOrderId: string) => {
  //   navigate(ROUTES.PROCESS_ORDER_DRAFT_BY_ID(retriedOrderId));
  // }, [navigate]);

  useEffect(() => {
    loadOrderByReferenceId(orderId, isGroupOrder)
      .then(() => loadMessage().catch((err) => console.log(err)))
      .catch((err) => console.log(err));
  }, [loadOrderByReferenceId, orderId, isGroupOrder, loadMessage]);

  useEffect(() => {
    setCustomOnOrderDraftProcessed(() => navigate(ROUTES.ORDER_BY_ID(orderId)));
  }, [navigate, setCustomOnOrderDraftProcessed, orderId]);

  if (!isGroupOrdersLoading && !order) {
    return (
      <Page
        pageTitle={t('order.processOrderDrafts.title')}
        className="gap-0"
      >
        <div className="flex-1 flex justify-center items-center h-full">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all my-8 w-full max-w-lg p-6">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <ExclamationTriangleIcon
                  className="h-6 w-6 text-red-600"
                  aria-hidden="true"
                />
              </div>
              <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <h1 className="text-base font-semibold leading-6 text-gray-900">
                  {t('order.processOrderDraftById.notFound')}
                </h1>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    {t('order.processOrderDraftById.notFound.description')}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:ml-10 sm:mt-4 sm:flex sm:pl-4">
              <Button
                onClick={() => {
                  navigate(ROUTES.ORDER_BY_ID(orderId));
                }}
                rightSection={<FontAwesomeIcon icon={faArrowRight} />}
              >
                {t('order.processOrderDraftById.goToOrderDetails')}
              </Button>
            </div>
          </div>
        </div>
      </Page>
    );
  }

  if (order && order.status !== OrderStatus.New) {
    return (
      <Page
        pageTitle={t('order.processOrderDrafts.title')}
      >
        <div className="flex-1 flex justify-center items-center h-full">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all my-8 w-full max-w-lg p-6">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                <ExclamationTriangleIcon
                  className="h-6 w-6 text-green-600"
                  aria-hidden="true"
                />
              </div>
              <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <h1 className="text-base font-semibold leading-6 text-gray-900">
                  {t('order.processOrderDraftById.alreadyProcessed')}
                </h1>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    {t('order.processOrderDraftById.alreadyProcessed.description')}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:ml-10 sm:mt-4 sm:flex sm:pl-4">
              <Button
                onClick={() => {
                  navigate(ROUTES.ORDER_BY_ID(orderId));
                }}
                rightSection={<FontAwesomeIcon icon={faArrowRight} />}
              >
                {t('order.processOrderDraftById.goToOrderDetails')}
              </Button>
            </div>
          </div>
        </div>
      </Page>
    );
  }

  return (
    <Page
      pageTitle={t('order.processOrderDrafts.title')}
      contentWithBorder
    >
      <MainContent />
    </Page>
  );
});

export default ProcessOrderDraftByIdPage;
