import { Order } from '../models';

import { httpPatchV1 } from 'utils/xhr';

export const saveOrderDrafts = async (
  orderDrafts: Order[],
): Promise<any> => httpPatchV1('/orders/drafts', {
  updates: orderDrafts.map((orderDraft) => ({
    number: orderDraft?.number,
    order_id: orderDraft?.id,
    customerId: orderDraft?.customer?.id,
    requestedDeliveryTime: orderDraft?.requestedDeliveryTime,
    order_date: orderDraft?.orderDate,
    products: orderDraft?.products,
    comment: orderDraft?.draft?.comment,
    freeFields: orderDraft?.freeFields,
  })),
});
