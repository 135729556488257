import { forwardRef, Ref, SVGProps } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props extends SVGProps<SVGSVGElement> {
  className?: string;
}

const ShoppingBagIcon = ({ className, ...props }: Props, ref: Ref<SVGSVGElement>) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    className={twMerge('w-6 h-6', className)}
    {...props}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M11.9998 5.3501C10.5362 5.3501 9.34977 6.53654 9.34977 8.0001V9.3501H8.1756C7.11552 9.3501 6.15744 9.98185 5.73986 10.9562L3.597 15.9562C2.84758 17.7049 4.13026 19.6501 6.03274 19.6501H17.9666C19.8691 19.6501 21.1517 17.7049 20.4023 15.9562L18.2595 10.9562C17.8419 9.98185 16.8838 9.3501 15.8237 9.3501H14.6498V8.0001C14.6498 6.53654 13.4633 5.3501 11.9998 5.3501ZM13.3498 10.6501V13.0001C13.3498 13.3591 13.6408 13.6501 13.9998 13.6501C14.3588 13.6501 14.6498 13.3591 14.6498 13.0001V10.6501H15.8237C16.3638 10.6501 16.8518 10.9719 17.0646 11.4683L19.2074 16.4683C19.5892 17.3591 18.9358 18.3501 17.9666 18.3501H6.03274C5.06355 18.3501 4.41011 17.3591 4.79189 16.4683L6.93475 11.4683C7.14748 10.9719 7.63556 10.6501 8.1756 10.6501H9.34977V13.0001C9.34977 13.3591 9.64079 13.6501 9.99977 13.6501C10.3588 13.6501 10.6498 13.3591 10.6498 13.0001V10.6501H13.3498ZM13.3498 9.3501V8.0001C13.3498 7.25451 12.7454 6.6501 11.9998 6.6501C11.2542 6.6501 10.6498 7.25451 10.6498 8.0001V9.3501H13.3498Z" />
  </svg>
);

export default forwardRef(ShoppingBagIcon);
