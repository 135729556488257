import { twMerge } from 'tailwind-merge';

import { useSelectedGroupOrder } from '../../hooks/useSelectedGroupOrder';

import { formatPrice } from 'utils/formatting';

import { useProcessOrderDraftsStore } from 'features/order/store/process-order-drafts';

const TotalPrice = () => {
  const { order } = useSelectedGroupOrder();

  const isOrderPricesLoading = useProcessOrderDraftsStore((state) => state.isOrderPricesLoading);

  if (!order?.totalPrice?.amount) {
    return null;
  }

  return (
    <div className="flex justify-end mt-4">
      <div className={twMerge(
        'px-4 relative py-2 rounded-md',
        !isOrderPricesLoading && 'border-[1.5px] border-solid border-[#2d426b66]',
      )}
      >
        <div className={twMerge(
          'bg-gray-200 w-full h-full animate-pulse rounded-md',
          isOrderPricesLoading ? 'absolute' : 'hidden',
        )}
        />

        <div className={twMerge(
          'gap-[7px] flex items-end justify-center',
          isOrderPricesLoading && 'invisible',
        )}
        >
          <div className="gap-[3px] inline-flex items-center relative flex-[0_0_auto]">
            <div className="relative w-fit font-medium text-[#2d426b66] text-[15px] tracking-[-0.15px] leading-[normal] whitespace-nowrap">
              Total
            </div>
          </div>

          <div className="gap-[3px] inline-flex items-center relative flex-[0_0_auto]">
            <div className="gap-[3px] inline-flex items-center relative flex-[0_0_auto]">
              <div className="relative w-fit mt-[-1.00px] font-medium text-[#2d426b66] text-sm tracking-[-0.14px] leading-[normal] !font-mono">
                {formatPrice(order?.totalPrice?.amount, order?.totalPrice?.currency)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalPrice;
