import {
  useCallback, useEffect, useRef, useState,
} from 'react';

import { Formik, useFormikContext } from 'formik';
import { number, object } from 'yup';

import { RingProgress, Text } from '@mantine/core';

import { globalAlertDataList } from '../../state/globalAlertDataList';
import { globalUser } from '../../state/globalUser';
import { AlertTheme } from 'state/classes/AlertDataList';

import {
  AccessData,
  InvitedUserDetails,
  Totp,
} from '../../types/invitation-sign-up';

import {
  genericErrorFeedback,
  genericErrorMessage,
} from 'utils/errors';
import { httpPostV1 } from 'utils/xhr';

import { FormInput } from '../FormInput';
import { Button } from '../ui/Button';

const formInitialValues = {
  totpCode: '',
};

const formValidationSchema = object().shape({
  totpCode: number().required(),
});

const ProgressBar = ({
  progress,
  seconds,
}: {
  progress: number;
  seconds: number;
}) => (
  <RingProgress
    size={45}
    thickness={3}
    sections={[{ value: progress, color: 'blue' }]}
    label={(
      <Text c="blue" fw={700} ta="center" size="xs">
        {Math.ceil(seconds)}
        s
      </Text>
    )}
  />
);

interface Props {
  onCompletion: () => void;
  isLoading: boolean;
  accessData: AccessData;
  inviteAppLink: string;
  invitedUserDetails: InvitedUserDetails;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  resetSteps: () => void;
}

interface InnerFormProps {
  isLoading: boolean;
  accessData: AccessData;
  inviteAppLink: string;
  invitedUserDetails: InvitedUserDetails;
  progress: number;
  seconds: number;
  retryAvailable: boolean;
  setRetryAvailable: React.Dispatch<React.SetStateAction<boolean>>;
  setProgress: React.Dispatch<React.SetStateAction<number>>;
  setSeconds: React.Dispatch<React.SetStateAction<number>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  resetSteps: () => void;
}

const InnerForm = ({
  inviteAppLink,
  isLoading,
  accessData,
  invitedUserDetails,
  progress,
  seconds,
  retryAvailable,
  setRetryAvailable,
  setProgress,
  setSeconds,
  setIsLoading,
  resetSteps,
}: InnerFormProps) => {
  const {
    setFieldValue, values, errors, touched, handleBlur, handleSubmit,
  } = useFormikContext<Totp>();

  // Totp generation
  const generateTotp = useCallback(() => {
    if (accessData) {
      httpPostV1('/auth/totp', { phone: accessData.phone })
        .catch(genericErrorFeedback('Error generating TOTP code'))
        .finally(() => {});
    }
  }, [accessData]);

  // Timeout
  const interval = 30;
  const duration = 30000;
  const totalSteps = duration / interval;
  const decrement = 100 / totalSteps;
  const secondsDecrement = 30 / totalSteps;

  const procedureTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>();
  const circleTimerRef = useRef<ReturnType<typeof setTimeout> | null>();

  const resetProcedureTimeout = useCallback(() => {
    setRetryAvailable(false);
    generateTotp();

    if (procedureTimeoutRef.current) {
      clearTimeout(procedureTimeoutRef.current);
      procedureTimeoutRef.current = null;
    }
    procedureTimeoutRef.current = setTimeout(() => {
      globalAlertDataList.create(
        'TOTP code expired',
        AlertTheme.ERROR,
        'You need to complete the sign up procedure in less then 30 seconds for security purposes.',
      );
    }, 30 * 1000);

    if (circleTimerRef.current) {
      clearInterval(circleTimerRef.current);
      circleTimerRef.current = null;
    }

    circleTimerRef.current = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress - decrement;
        if (newProgress <= 0) {
          clearInterval(circleTimerRef.current!);
          circleTimerRef.current = null;
          return 0;
        }
        return newProgress;
      });

      setSeconds((prevSeconds) => {
        const newSeconds = prevSeconds - secondsDecrement;
        if (newSeconds <= 0) {
          clearInterval(circleTimerRef.current!);
          circleTimerRef.current = null;
          setRetryAvailable(true);
          return 0;
        }
        return newSeconds;
      });
    }, interval);

    setFieldValue('totpCode', '');
    setProgress(100);
    setSeconds(30);
  }, [
    decrement,
    generateTotp,
    secondsDecrement,
    setFieldValue,
    setProgress,
    setRetryAvailable,
    setSeconds,
  ]);

  // Sign up on submit
  const performInvitedSignup = useCallback(
    (totp: Totp) => {
      if (accessData && invitedUserDetails && totp?.totpCode !== '') {
        setIsLoading(true);
        globalUser
          .invitedSignup({
            inviteAppLink,
            ...totp,
            ...invitedUserDetails,
            ...accessData,
          })
          .then(() => {
            handleSubmit();
          })
          .catch((err) => {
            if (
              genericErrorMessage(err).includes('invalid or expired totp code')
            ) {
              generateTotp();
              resetProcedureTimeout();
            } else {
              genericErrorFeedback('An error has occured while performing invitation signup')(err);
              resetSteps();
            }
            setIsLoading(false);
          });
      }
    },
    [
      accessData,
      invitedUserDetails,
      inviteAppLink,
      handleSubmit,
      setIsLoading,
      generateTotp,
      resetProcedureTimeout,
      resetSteps,
    ],
  );

  useEffect(() => {
    resetProcedureTimeout();

    return () => {
      if (procedureTimeoutRef.current) clearInterval(procedureTimeoutRef.current);
      if (circleTimerRef.current) clearInterval(circleTimerRef.current);
    };
  }, [resetProcedureTimeout]);

  useEffect(() => {
    if (values.totpCode && values.totpCode.length === 6) {
      performInvitedSignup({ totpCode: values.totpCode });
    }
  }, [values.totpCode, performInvitedSignup]);

  return (
    <div className="flex flex-col gap-y-lg">
      <div className="flex items-end justify-center space-x-2">
        <FormInput
          required
          field="totpCode"
          type="totpCode"
          disabled={isLoading}
          label="TOTP Code"
          value={values.totpCode}
          error={errors.totpCode}
          touched={touched.totpCode}
          setFieldValue={setFieldValue}
          onBlur={handleBlur('totpCode')}
        />
        <ProgressBar progress={progress} seconds={seconds} />
      </div>
      <div className="mt-lg flex w-full justify-end border-t pt-lg">
        <Button
          title="Retry"
          disabled={!retryAvailable}
          onClick={resetProcedureTimeout}
          theme="indigo"
          radius="md"
        />
      </div>
    </div>
  );
};

const TotpStep = ({
  onCompletion,
  isLoading,
  accessData,
  inviteAppLink,
  invitedUserDetails,
  setIsLoading,
  resetSteps,
}: Props) => {
  const [progress, setProgress] = useState(100);
  const [seconds, setSeconds] = useState(30);

  const [retryAvailable, setRetryAvailable] = useState(false);

  return (
    <>
      <div className="text-description">
        Enter the code sent via SMS to your phone number.
      </div>

      <div className="flex flex-col gap-lg py-lg">
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={onCompletion}
        >
          <InnerForm
            isLoading={isLoading}
            accessData={accessData}
            inviteAppLink={inviteAppLink}
            invitedUserDetails={invitedUserDetails}
            progress={progress}
            seconds={seconds}
            retryAvailable={retryAvailable}
            setRetryAvailable={setRetryAvailable}
            setProgress={setProgress}
            setSeconds={setSeconds}
            setIsLoading={setIsLoading}
            resetSteps={resetSteps}
          />
        </Formik>
      </div>
    </>
  );
};

export { TotpStep };
