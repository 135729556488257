import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from '@mantine/core';

interface EmailAddressBadgeProps {
  email: string;
  onRemove: (email: string) => void;
}

const EmailAddressBadge = ({ email, onRemove }: EmailAddressBadgeProps) => (
  <Badge
    className="bg-white border border-gray-200"
    rightSection={(
      <FontAwesomeIcon
        icon={faXmark}
        className="w-3 h-3 text-white hover:text-gray-200 cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          onRemove(email);
        }}
      />
    )}
    styles={{
      root: {
        paddingRight: 5,
        textTransform: 'none',
      },
    }}
  >
    {email}
  </Badge>
);

export default EmailAddressBadge;
