import { PlotData, PlotType } from 'features/message/models/Message';

import { AreaChart, BarChart, LineChart } from 'components/charts';

interface Props {
  plotData: PlotData;
}

const AdamChatMessagePlot = ({ plotData }: Props) => {
  const data = Array.from({ length: plotData.x.length }, (_, i) => {
    const yValue = plotData.y[i];
    const parsedY = parseFloat(yValue);
    return {
      x: plotData.x[i],
      y: !Number.isNaN(parsedY) ? parsedY : yValue,
    };
  });

  switch (plotData.type) {
    case PlotType.LINE_CHART:
      return (
        <LineChart
          title={plotData.title}
          subtitle={plotData.description}
          data={data}
          xDataKey="x"
          yDataKey="y"
        />
      );
    case PlotType.AREA_CHART:
      return (
        <AreaChart
          title={plotData.title}
          subtitle={plotData.description}
          data={data}
          xDataKey="x"
          yDataKey="y"
        />
      );
    case PlotType.BAR_PLOT:
      return (
        <BarChart
          title={plotData.title}
          subtitle={plotData.description}
          data={data}
          xDataKey="x"
          yDataKey="y"
        />
      );
    default:
      return null;
  }
};

export default AdamChatMessagePlot;
