enum ContactMedium {
  Email = 'EMAIL',
  Text = 'TEXT',
}

class ContactUser {
  contactMedium: ContactMedium;

  firstName: string;

  lastName: string;

  email: string; // DEV-834: deprecated, use Emails instead

  phone: string; // DEV-834: deprecated, use Phones instead

  emails: string[];

  phones: string[];

  GetEmail(): string {
    if (this.emails.length > 0) {
      return this.emails[0];
    }
    if (this.email !== '') {
      return this.email;
    }

    return '';
  }

  GetPhone(): string {
    if (this.phones.length > 0) {
      return this.phones[0];
    }
    if (this.phone !== '') {
      return this.phone;
    }
    return '';
  }

  GetEmails(): string[] {
    if (this.emails.length > 0) {
      return this.emails;
    }
    if (this.email !== '') {
      return [this.email];
    }
    return [];
  }

  GetPhones(): string[] {
    if (this.phones.length > 0) {
      return this.phones;
    }
    if (this.phone !== '') {
      return [this.phone];
    }
    return [];
  }
}

export { ContactMedium, ContactUser };
