import { forwardRef, Ref, SVGProps } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props extends SVGProps<SVGSVGElement> {
  className?: string;
}

const ArrowRedoIcon = ({ className, ...props }: Props, ref: Ref<SVGSVGElement>) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    className={twMerge('w-6 h-6', className)}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.45972 13.5403C5.20588 13.2864 4.79432 13.2864 4.54048 13.5403C4.28664 13.7941 4.28664 14.2057 4.54048 14.4595L8.54048 18.4595C8.79432 18.7134 9.20588 18.7134 9.45972 18.4595L13.4597 14.4595C13.7136 14.2057 13.7136 13.7941 13.4597 13.5403C13.2059 13.2864 12.7943 13.2864 12.5405 13.5403L9.60176 16.479V9.50039C9.60176 7.37409 11.3255 5.65039 13.4518 5.65039C15.5781 5.65039 17.3018 7.37409 17.3018 9.50039V13.0004C17.3018 13.3594 17.5928 13.6504 17.9518 13.6504C18.3107 13.6504 18.6018 13.3594 18.6018 13.0004V9.50039C18.6018 6.65613 16.296 4.35039 13.4518 4.35039C10.6075 4.35039 8.30176 6.65612 8.30176 9.50039L8.30176 16.3823L5.45972 13.5403Z"
      fill="#191919"
    />
  </svg>
);

export default forwardRef(ArrowRedoIcon);
