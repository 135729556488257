import { forwardRef, Ref, SVGProps } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props extends SVGProps<SVGSVGElement> {
  className?: string;
}

const QRCodeScanIcon = ({ className, ...props }: Props, ref: Ref<SVGSVGElement>) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    className={twMerge('w-6 h-6', className)}
    {...props}
  >
    <path
      d="M18.9333 20H20M15.7333 20H13.6V16.8M16.8 16.8H20V13.6H18.9333M13.6 13.6H15.7333M4 16.8C4 15.8059 4 15.3088 4.16213 14.9163C4.37865 14.3938 4.79378 13.9786 5.31627 13.7621C5.7088 13.6 6.20587 13.6 7.2 13.6C8.19413 13.6 8.6912 13.6 9.08373 13.7621C9.6064 13.9787 10.0213 14.3936 10.2379 14.9163C10.4 15.3088 10.4 15.8059 10.4 16.8C10.4 17.7941 10.4 18.2912 10.2379 18.6827C10.1308 18.9416 9.97373 19.1769 9.7757 19.3751C9.57768 19.5733 9.34254 19.7305 9.08373 19.8379C8.6912 20 8.19413 20 7.2 20C6.20587 20 5.7088 20 5.31627 19.8379C4.79378 19.6214 4.37865 19.2062 4.16213 18.6837C4 18.2912 4 17.7941 4 16.8ZM13.6 7.2C13.6 6.20587 13.6 5.7088 13.7621 5.31627C13.9786 4.79378 14.3938 4.37865 14.9163 4.16213C15.3088 4 15.8059 4 16.8 4C17.7941 4 18.2912 4 18.6837 4.16213C19.2064 4.37867 19.6213 4.7936 19.8379 5.31627C20 5.7088 20 6.20587 20 7.2C20 8.19413 20 8.6912 19.8379 9.08267C19.7308 9.34158 19.5737 9.57685 19.3757 9.77507C19.1777 9.97328 18.9425 10.1305 18.6837 10.2379C18.2912 10.4 17.7941 10.4 16.8 10.4C15.8059 10.4 15.3088 10.4 14.9163 10.2379C14.3938 10.0214 13.9786 9.60622 13.7621 9.08373C13.6 8.6912 13.6 8.19413 13.6 7.2ZM4 7.2C4 6.20587 4 5.7088 4.16213 5.31627C4.37865 4.79378 4.79378 4.37865 5.31627 4.16213C5.7088 4 6.20587 4 7.2 4C8.19413 4 8.6912 4 9.08373 4.16213C9.6064 4.37867 10.0213 4.7936 10.2379 5.31627C10.4 5.7088 10.4 6.20587 10.4 7.2C10.4 8.19413 10.4 8.6912 10.2379 9.08267C10.1308 9.34158 9.97373 9.57685 9.7757 9.77507C9.57768 9.97328 9.34254 10.1305 9.08373 10.2379C8.6912 10.4 8.19413 10.4 7.2 10.4C6.20587 10.4 5.7088 10.4 5.31627 10.2379C4.79378 10.0214 4.37865 9.60622 4.16213 9.08373C4 8.6912 4 8.19413 4 7.2Z"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default forwardRef(QRCodeScanIcon);
