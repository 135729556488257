import { ComponentType, useState } from 'react';

import { WorkflowRun } from 'models/Workflow';

import { RetryWorkflowSidebar } from 'components/sidebars/RetryWorkflow';

interface InjectedProps {
  workflowRunId: string;
  isVoiceAttachment?: boolean;
  transcriptionLanguage?: string;
  onRetryWorkflow: (
    selectedWorkflow: string, workflowRun: WorkflowRun, transcriptionLanguage?: string,
  ) => void;
}

const WorkflowRetrySidebarWrapper = <TProps extends object>(
  Component: ComponentType<TProps>,
): ComponentType<TProps & InjectedProps> => {
  function WrappedComponent({
    workflowRunId, isVoiceAttachment, transcriptionLanguage, onRetryWorkflow,
    ...props
  }: InjectedProps & TProps) {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    return (
      <>
        <Component
          {...props as TProps}
          workflowRunId={workflowRunId}
          setWorkflowRetrySidebarOpen={setIsSidebarOpen}
        />

        <RetryWorkflowSidebar
          workflowRunId={workflowRunId}
          sidebarOpen={isSidebarOpen}
          setSidebarOpen={setIsSidebarOpen}
          isVoiceAttachment={isVoiceAttachment}
          transcriptionLanguage={transcriptionLanguage}
          onComplete={onRetryWorkflow}
        />
      </>
    );
  }

  return WrappedComponent;
};

export default WorkflowRetrySidebarWrapper;
