import { useCallback, useState } from 'react';

import { v4 as uuidv4 } from 'uuid';

import { Prompt } from '../../features/instruction/models';

import { genericErrorFeedback } from 'utils/errors';
import { httpGetV1 } from 'utils/xhr';

const useFetchPromptById = () => {
  const [prompt, setPrompt] = useState<Prompt>(null);
  const [isLoading, setIsLoading] = useState(false);

  const loadPrompt = useCallback(
    (
      promptId: string,
      giveErrorFeedback: boolean = true,
    ): Promise<Prompt> => {
      if (!promptId) return Promise.reject();
      setIsLoading(true);
      return httpGetV1(`/llm/pipelines/prompts/${promptId}`)
        .then((response) => {
          const _prompt = response.data as Prompt;

          setPrompt({
            ..._prompt,
            boundTypeSpecs: _prompt.boundTypeSpecs.map((typeSpec) => ({
              ...typeSpec,
              fields: typeSpec.fields.map((field) => ({
                ...field,
                uiId: uuidv4(),
              })),
            })),
          });
          return response.data;
        })
        .catch((error) => {
          if (giveErrorFeedback) {
            genericErrorFeedback('An error has occured while fetching prompt instructions')(
              error,
            );
          }
          return Promise.reject(error);
        })
        .finally(() => setIsLoading(false));
    },
    [],
  );

  return {
    prompt,
    setPrompt,
    loadPrompt,
    isLoading,
  };
};

export { useFetchPromptById };
