import { Prompt } from '../models';

import { parsePaginatedApiResponse } from 'utils';
import { httpGetV1 } from 'utils/xhr';

interface FetchPromptsParams {
  cursor?: string;
}

export const fetchPrompts = async (params: FetchPromptsParams) => httpGetV1('/llm/pipelines/prompts', {
  params,
  classType: Prompt,
}).then((response) => parsePaginatedApiResponse<Prompt>(response));
