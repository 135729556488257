import { omit, uniqBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { StateCreator } from 'zustand';

import { AlertTheme } from 'state/classes/AlertDataList';
import { globalAlertDataList } from 'state/globalAlertDataList';
import { globalOrderDrafts } from 'state/globalOrderDrafts';
import { globalUser } from 'state/globalUser';

import { Order, ProductWithQuantity } from 'features/order/models';
import { Subject } from 'features/user/models';

import { OrderSlice, ProcessOrderDraftsStore } from './types';

import { setValueByPath } from 'features/instruction/utils';
import { genericErrorFeedback } from 'utils/errors';
import { isZeroId } from 'utils/objectId';
import { httpGetV1 } from 'utils/xhr';

import { assignOrderDraft } from 'features/order/api/assignOrderDraft';
import { confirmOrderDrafts } from 'features/order/api/confirmOrderDrafts';
import { createAndGroupOrderDrafts } from 'features/order/api/createAndGroupOrderDrafts';
import { declineOrderDraft } from 'features/order/api/declineOrderDraft';
import { deleteOrderDrafts } from 'features/order/api/deleteOrderDrafts';
import { fetchOrderById } from 'features/order/api/fetchOrderById';
import { fetchOrdersByGroupId } from 'features/order/api/fetchOrdersByGroupId';
import { saveOrderDrafts } from 'features/order/api/saveOrderDrafts';
import { updateAndCreateOrderDrafts } from 'features/order/api/updateAndCreateOrderDrafts';

export const createOrderSlice: StateCreator<ProcessOrderDraftsStore, [], [], OrderSlice> = (set, get) => ({
  customOnOrderDraftProcessed: null,
  setCustomOnOrderDraftProcessed: (customOnOrderDraftProcessed: (() => void) | null) => {
    set({ customOnOrderDraftProcessed });
  },

  globalOrders: [],

  groupOrders: {},
  selectedOrderId: '',
  deletedOrderIds: [],
  didOrdersChangeMade: false,
  resetOrderStore: () => {
    set({
      groupOrders: {},
      selectedOrderId: '',
      deletedOrderIds: [],
      didOrdersChangeMade: false,
      globalOrders: [],
    });
  },
  createNewOrder: () => {
    const newOrder = new Order();
    newOrder.id = `new-${uuidv4()}`;
    newOrder.assigneeId = globalUser.id;
    newOrder.isCreated = true;

    set((state) => ({
      groupOrders: { ...state.groupOrders, [newOrder.id]: newOrder },
      selectedOrderId: newOrder.id,
    }));
  },
  removeOrderById: (orderId: string) => {
    set((state) => {
      const newGroupOrders = omit(state.groupOrders, orderId);
      const newSelectedOrderId = state.selectedOrderId === orderId ? Object.keys(newGroupOrders)[0] : state.selectedOrderId;

      const newProducts = omit(state.products, orderId);

      const newErrors = omit(state.errors, orderId);
      const newWarnings = omit(state.warnings, orderId);

      return ({
        groupOrders: newGroupOrders,
        selectedOrderId: newSelectedOrderId,
        deletedOrderIds: [...state.deletedOrderIds, orderId],
        errors: newErrors,
        warnings: newWarnings,
        products: newProducts,
      });
    });
  },
  updateOrderByFn: (updateFn: (currentOrder: Order) => Order, orderId_?: string, updateChangeMade = true) => {
    set((state) => {
      const orderId = orderId_ || state.selectedOrderId;
      const prevOrder = state.groupOrders[orderId];
      if (!prevOrder) return {};

      const newOrder = updateFn(prevOrder);

      const newState: Partial<ProcessOrderDraftsStore> = {
        groupOrders: { ...state.groupOrders, [orderId]: newOrder },
      };

      if (updateChangeMade) {
        newState.didOrdersChangeMade = true;
      }

      return newState;
    });
  },
  updateValueByPath: (value: any, path: string, indices?: number[], orderId_?: string) => {
    set((state) => {
      const orderId = orderId_ || state.selectedOrderId;
      const prevOrder = state.groupOrders[orderId];
      if (!prevOrder) return {};

      const newOrder = setValueByPath(prevOrder, value, path, indices);
      return {
        groupOrders: { ...state.groupOrders, [orderId]: newOrder },
        didOrdersChangeMade: true,
      };
    });
  },
  onCommentChange: (comment: string, orderId_?: string) => {
    set((state) => {
      const orderId = orderId_ || state.selectedOrderId;
      const prevOrder = state.groupOrders[orderId];
      if (!prevOrder) return {};

      const newOrder = { ...prevOrder, comment };
      return {
        groupOrders: { ...state.groupOrders, [orderId]: newOrder },
        didOrdersChangeMade: true,
      };
    });
  },
  onOrderDraftsSaved: () => {
    set({ didOrdersChangeMade: false, didProductsChangeMade: false });
  },
  onOrderDraftsCompleted: () => {
    const { groupOrders } = get();
    const orderId = Object.values(groupOrders).find((order) => !order.isGroupSibling)?.id;
    if (!orderId) {
      genericErrorFeedback('No order found to mark as processed')(null);
      return;
    }

    // Change global count
    globalOrderDrafts.markOrderAsProcessed();

    if (get().customOnOrderDraftProcessed) {
      get().customOnOrderDraftProcessed();
    } else {
      const newGlobalOrders = get().globalOrders.filter((order) => order.id !== orderId);
      // Remove the order from the global orders
      set((state) => ({
        globalOrders: newGlobalOrders,
        currentIndex: Math.max(state.currentIndex - 1, 0),
      }));

      // Load the next order
      if (newGlobalOrders.length === 0) {
        get().loadOrderDrafts()
          .finally(() => get().loadCurrentOrderAndMessages());
      } else {
        get().loadCurrentOrderAndMessages();
      }
    }
  },
  onTabChange: (orderId: string) => {
    set({ selectedOrderId: orderId });
  },
  onSubjectAssignmentDone: (subject: Subject) => {
    get().updateOrderByFn((order) => ({
      ...order,
      createdByUserInfo: subject,
      createdByUserInfoId: subject.id,
    }));
    set((state) => {
      const newGlobalOrders = [...state.globalOrders];
      newGlobalOrders[state.currentIndex] = {
        ...newGlobalOrders[state.currentIndex],
        createdByUserInfo: subject,
        createdByUserInfoId: subject.id,
      };

      return {
        globalOrders: newGlobalOrders,
      };
    });
  },

  // Actions
  isAssigningOrder: false,
  isDecliningOrder: false,
  isSavingOrder: false,
  isConfirmingOrder: false,
  getFinalOrders: () => {
    const { groupOrders, products } = get();

    const finalOrders = Object.values(groupOrders).map((order) => ({
      ...order,
      products: products[order.id],
    }));

    // Make sure that the parent order is the first one
    finalOrders.sort((a, b) => (a.isGroupSibling ? 1 : 0) - (b.isGroupSibling ? 1 : 0));

    return finalOrders;
  },
  assignOrder: (userId: string, userName: string, assignedComment: string): Promise<any> => {
    const {
      currentIndex, globalOrders, groupOrders,
    } = get();
    let orderId = globalOrders[currentIndex]?.id;

    if (!orderId) {
      orderId = Object.values(groupOrders).find((order) => !order.isGroupSibling)?.id;
    }

    if (!orderId) {
      genericErrorFeedback('Order not found')(null);
      return Promise.reject(new Error('Order not found'));
    }

    set({ isAssigningOrder: true });

    return assignOrderDraft({
      orderId,
      userId,
      assignedComment,
    })
      .then(() => {
        globalAlertDataList.create(`Order assigned to ${userName} successfully`, AlertTheme.SUCCESS);
        set((state) => {
          const newGlobalOrders = [...state.globalOrders];
          newGlobalOrders[currentIndex] = {
            ...newGlobalOrders[currentIndex],
            assigneeId: userId,
            draft: {
              ...newGlobalOrders[currentIndex].draft,
              comment: assignedComment,
            },
          };

          const newGroupOrders = { ...state.groupOrders };
          newGroupOrders[orderId] = {
            ...newGroupOrders[orderId],
            assigneeId: userId,
            draft: {
              ...newGroupOrders[orderId].draft,
              comment: assignedComment,
            },
          };

          return {
            globalOrders: newGlobalOrders,
            groupOrders: newGroupOrders,
          };
        });
      })
      .catch((error) => {
        genericErrorFeedback('Error assigning order')(error);
      })
      .finally(() => {
        set({ isAssigningOrder: false });
      });
  },
  declineOrder: () => {
    const {
      currentIndex, globalOrders, groupOrders,
    } = get();
    let orderId = globalOrders[currentIndex]?.id;

    if (!orderId) {
      orderId = Object.values(groupOrders).find((order) => !order.isGroupSibling)?.id;
    }

    if (!orderId) {
      genericErrorFeedback('No order selected')(null);
      return;
    }

    set({ isDecliningOrder: true });
    declineOrderDraft(orderId)
      .then(() => {
        globalAlertDataList.create('Order declined successfully', AlertTheme.SUCCESS);
        get().onOrderDraftsCompleted();
      })
      .catch((error) => {
        genericErrorFeedback('Error declining order')(error);
      })
      .finally(() => {
        set({ isDecliningOrder: false });
      });
  },
  saveOrder: () => {
    const { getFinalOrders, deletedOrderIds, message } = get();
    const finalOrders = getFinalOrders();
    if (finalOrders.length === 0) {
      genericErrorFeedback('No order selected')(null);
      return;
    }

    set({ isSavingOrder: true });
    updateAndCreateOrderDrafts(
      finalOrders,
      deletedOrderIds,
      deleteOrderDrafts,
      createAndGroupOrderDrafts,
      saveOrderDrafts,
      message?.id,
    )
      .then((orders) => {
        globalAlertDataList.create('Order saved successfully', AlertTheme.SUCCESS);

        const ordersWithUiIds = orders.map((order) => ({
          ...order,
          products: order.products.map((product) => ({
            ...product,
            uiId: uuidv4(),
          })),
        }));
        set({
          groupOrders: ordersWithUiIds.reduce((acc, order) => ({
            ...acc,
            [order.id]: order,
          }), {}),
          products: ordersWithUiIds.reduce((acc, order) => ({
            ...acc,
            [order.id]: order.products,
          }), {}),
          deletedOrderIds: [],
          didOrdersChangeMade: false, // Reset the change made flag after saving
          didProductsChangeMade: false,
          selectedOrderId: orders[0].id,
        });
        get().onOrderDraftsSaved();

        get().loadOrderPrices(orders[0].id);

        get().setIsOrderPricesAlertVisible(false);
      })
      .catch((error) => {
        genericErrorFeedback('Error saving order')(error);
      })
      .finally(() => {
        set({ isSavingOrder: false });
      });
  },
  confirmOrder: () => {
    const { groupOrders, selectedOrderId } = get();
    const orderId = selectedOrderId;
    const groupId = groupOrders[orderId]?.groupId;
    set({ isConfirmingOrder: true });
    confirmOrderDrafts(orderId, groupId)
      .then(() => {
        globalAlertDataList.create('Order confirmed successfully', AlertTheme.SUCCESS);
        get().onOrderDraftsCompleted();
      })
      .catch((error) => {
        genericErrorFeedback('Error confirming order')(error);
      })
      .finally(() => {
        set({ isConfirmingOrder: false });
      });
  },

  // Fetch related
  isGroupOrdersLoading: false,
  loadGroupOrders: (groupId: string, onlyDrafts = true) => {
    set({ isGroupOrdersLoading: true });
    return fetchOrdersByGroupId(groupId, onlyDrafts)
      .then((orders) => {
        const ordersWithProducts = orders.map((order) => ({
          ...order,
          products: order.products.map((product: ProductWithQuantity) => ({
            ...product,
            uiId: uuidv4(),
          })),
        }));

        const parentOrder = ordersWithProducts.find((order) => !order.isGroupSibling);

        set({
          groupOrders: ordersWithProducts.reduce((acc, order) => ({ ...acc, [order.id]: order }), {}),
          products: ordersWithProducts.reduce((acc, order) => ({ ...acc, [order.id]: order.products }), {}),
          selectedOrderId: parentOrder?.id,
        });
      })
      .catch((error) => {
        genericErrorFeedback('Error loading orders')(error);
      })
      .finally(() => {
        set({ isGroupOrdersLoading: false });
      });
  },
  loadCurrentOrder: async () => {
    // Reset states
    get().setIsOrderPricesAlertVisible(false);

    const { currentIndex, globalOrders } = get();

    const currentOrder = globalOrders[currentIndex];
    if (!currentOrder) {
      return Promise.reject(new Error('No order selected'));
    }

    const groupId = currentOrder.groupId;
    if (isZeroId(groupId)) {
      // Add UiId to the products
      const currentOrderWithUiIds = {
        ...currentOrder,
        products: currentOrder.products.map((product: ProductWithQuantity) => ({
          ...product,
          uiId: uuidv4(),
        })),
      };
      set({
        groupOrders: { [currentOrder.id]: currentOrderWithUiIds },
        products: { [currentOrder.id]: currentOrderWithUiIds.products },
        selectedOrderId: currentOrder.id,
      });
      return Promise.resolve();
    }

    return get().loadGroupOrders(groupId);
  },
  loadCurrentOrderAndMessages: async () => {
    const { loadCurrentOrder, loadMessage, loadOrderPrices } = get();

    loadCurrentOrder()
      .then(() => {
        const { currentIndex, globalOrders } = get();
        const currentOrder = globalOrders[currentIndex];

        set({
          errors: {},
          warnings: {},
          didOrdersChangeMade: false,
          didProductsChangeMade: false,
        });

        loadOrderPrices(currentOrder.id);
        loadMessage().catch((err) => console.log(err));
      });
  },
  loadOrderByReferenceId: async (referenceId: string, isGroupId?: boolean) => {
    if (isGroupId) {
      return get().loadGroupOrders(referenceId);
    }

    return fetchOrderById(referenceId)
      .then((order) => {
        if (!isZeroId(order.groupId)) {
          return get().loadGroupOrders(order.groupId);
        }

        const orderWithProducts = {
          ...order,
          products: order.products.map((product: ProductWithQuantity) => ({
            ...product,
            uiId: uuidv4(),
          })),
        };

        set({
          globalOrders: [orderWithProducts],
          currentIndex: 0,
          groupOrders: { [order.id]: orderWithProducts },
          products: { [order.id]: orderWithProducts.products },
          selectedOrderId: order.id,
        });

        return Promise.resolve();
      })
      .catch((error) => {
        set({
          groupOrders: {}, products: {}, selectedOrderId: '', globalOrders: [], currentIndex: 0,
        });
        genericErrorFeedback('Error loading order')(error);
        return Promise.reject(error);
      })
      .finally(() => {
        set({ isGroupOrdersLoading: false });
      });
  },

  isOrderDraftListLoading: false,
  cursor: null,
  abortController: null,
  endReached: false,
  loadOrderDrafts: async (reset?: boolean) => {
    if (get().endReached) {
      return Promise.resolve();
    }

    if (get().abortController) {
      get().abortController.abort();
    }

    if (reset) {
      set({
        cursor: null, endReached: false, globalOrders: [], currentIndex: 0,
      });
    }

    const controller = new AbortController();
    set({ abortController: controller, isOrderDraftListLoading: true });

    return httpGetV1('/orders/drafts', {
      params: {
        assignee_id: globalUser.id,
        include_unassigned: true,
        cursor: get().cursor,
        limit: 5,
      },
    })
      .then((response) => {
        // TODO(chihirokuya): use exposed types
        const responseOrders = uniqBy((response.data.result || []) as Order[], 'id').map((order) => ({
          ...order,
          products: order.products.map((product: ProductWithQuantity) => ({
            ...product,
            uiId: uuidv4(),
          })),
        }));
        const cursor = response.data.cursor;

        set({
          globalOrders: uniqBy([...get().globalOrders, ...responseOrders], 'id'),
        });

        set({
          endReached: !cursor || responseOrders.length === 0,
          cursor,
        });
      })
      .catch((error) => {
        genericErrorFeedback('Error loading orders')(error);
        return Promise.reject(error);
      })
      .finally(() => {
        set({ isOrderDraftListLoading: false, abortController: null });
      });
  },
});
