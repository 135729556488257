import {
  ArrowUturnLeftIcon,
  EllipsisVerticalIcon,
  StarIcon,
} from '@heroicons/react/24/outline';
import { Tooltip } from '@mantine/core';

import { ExternalMessageStatus, Message } from 'features/message/models/Message';

import { Button } from 'components/ui/Button';
import { MessageTags } from 'features/message/components';

interface Props {
  message: Message;
  time: {
    current: string;
    original: string;
    relative: string;
  };
  senderBusinessName: string;
  senderName: string;
  canToggleExpanded: boolean;
  isExpanded: boolean;
  onToggle: () => void;
  onReply: () => void;
}

const getStatusText = (message: Message) => {
  switch (message.smtpMessage?.status) {
    case ExternalMessageStatus.PENDING:
      return (
        <span className="animate-[blink_1s_ease-in-out_infinite]">
          • Sending
        </span>
      );
    case ExternalMessageStatus.FAILED:
      return '• Failed';
    default:
      return message.messageStatus || '';
  }
};

const ThreadMessageHeader = ({
  message, time, senderBusinessName, senderName, canToggleExpanded, isExpanded, onToggle, onReply,
}: Props) => {
  const statusText = getStatusText(message);

  return (
    <div
      role="rowheader"
      tabIndex={0}
      onKeyDown={canToggleExpanded && isExpanded ? (e) => {
        if (e.key === 'Enter' || e.key === 'Space') {
          e.preventDefault();
          onToggle();
        }
      } : undefined}
      onClick={canToggleExpanded && isExpanded ? onToggle : () => {}}
      className={`flex items-center justify-between ${canToggleExpanded && isExpanded ? 'cursor-pointer' : ''}`}
    >
      <div className="flex items-baseline">
        <span className="font-semibold text-sm">{senderBusinessName}</span>
        <span className="ml-2 text-xs text-gray-500">
          {senderName}
        </span>
        <span className="ml-2 text-sm text-gray-500">
          {time.current}
          <span className="mx-1">
            (
            {time.relative}
            )
          </span>
        </span>
        {message.smtpMessage?.status !== ExternalMessageStatus.SENT && (
        <span className={`ml-2 text-xs ${message.smtpMessage?.status === ExternalMessageStatus.PENDING ? 'text-orange-500' : 'text-red-500'}`}>
            {statusText}
        </span>
        )}

        <MessageTags message={message} className="gap-2" hideReading />
      </div>

      {/* Actions */}
      <div className="flex items-center space-x-2 opacity-0 group-hover:opacity-100 transition-opacity">
        <Tooltip position="bottom" label="Star">
          <Button
            disabled
            theme="secondary"
            variant="small"
            icon={<StarIcon className="aspect-square w-4" />}
            onClick={() => {}}
          />
        </Tooltip>
        <Tooltip position="bottom" label="Reply">
          <Button
            theme="secondary"
            variant="small"
            icon={<ArrowUturnLeftIcon className="aspect-square w-4" />}
            onClick={onReply}
          />
        </Tooltip>
        <Tooltip position="bottom" label="More actions">
          <Button
            disabled
            theme="secondary"
            variant="small"
            icon={<EllipsisVerticalIcon className="aspect-square w-4" />}
            onClick={() => {}}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default ThreadMessageHeader;
