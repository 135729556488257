import { useTranslation } from 'react-i18next';

import { TagVariant } from 'types/tag';

import { Card } from 'components/common/Card';
import { Tag } from 'components/common/Tag';
import { Button } from 'components/ui/Button';

interface Props {
  handleDeleteUser: () => void;
}

function RightSide({ handleDeleteUser }: Props) {
  const { t } = useTranslation();
  return (
    <div className="flex-1 space-y-4 pb-10">
      <Card>
        <Card.Header>
          <div className="flex gap-2">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              User&apos;s Schedule
            </h3>
            <Tag
              tagTitle="Soon"
              tagVariant={TagVariant.BLUE}
              hideCircle
              className="static"
            />
          </div>
        </Card.Header>
        <Card.Body>
          <div className="flex justify-center items-center h-[20vh]">
            Here you will see the schedule of the user
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>
          <div className="flex gap-2">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              User&apos;s Tasks
            </h3>
            <Tag
              tagTitle="Soon"
              tagVariant={TagVariant.BLUE}
              hideCircle
              className="static"
            />
          </div>
        </Card.Header>
        <Card.Body>
          <div className="flex justify-center items-center h-[40vh]">
            Here you will see the tasks assigned to the user
          </div>
        </Card.Body>
      </Card>
      <div className="flex justify-end">
        <Button
          title={t('teammember.details.delete.button')}
          theme="red"
          onClick={handleDeleteUser}
        />
      </div>
    </div>
  );
}

export default RightSide;
