import { queryOptions } from '@tanstack/react-query';

import { MINUTE } from 'constants/miliseconds';

import { SalesMetric } from '../types/metric';
import { TimePeriod } from '../types/timePeriod';

import { fetchTopSellingProducts } from '../apis';

export const topSellingProductsOptions = (
  date: Date | null,
  period: TimePeriod,
  metric: SalesMetric,
  limit: number = 10,
) => queryOptions({
  queryKey: ['top-selling-products', date, period, metric, limit],
  queryFn: () => fetchTopSellingProducts(date, period, metric, limit),
  staleTime: 30 * MINUTE, // cache for 30 minutes
  meta: {
    errorMessage: 'Error fetching top selling products',
  },
});
