import { queryOptions } from '@tanstack/react-query';

import { fetchLanguages } from '../api/fetchLanguages';

export const languagesOptions = () => queryOptions({
  queryKey: ['languages'],
  queryFn: fetchLanguages,
  gcTime: 0,
  meta: {
    errorMessage: 'Error fetching languages',
  },
});
