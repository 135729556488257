import { useState } from 'react';

import { twMerge } from 'tailwind-merge';

import { useSelectedGroupOrder } from '../hooks/useSelectedGroupOrder';

import { SelectOption } from 'components/ui/Select';

import { Assignee } from './Assignee';
import Tabs from './Tabs';

interface Props {
  onAssignButtonClick: (teamMemberId: string, teamMemberName: string, comment: string) => void;
}

const TabsAndAssignee = ({ onAssignButtonClick }: Props) => {
  const { order } = useSelectedGroupOrder();

  const [assignedTeamMember, setAssignedTeamMember] = useState<SelectOption>(null);

  const isAssigneeChanged = order?.assigneeId !== assignedTeamMember?.value;

  return (
    <div className={twMerge(
      'flex justify-between mb-4',
      isAssigneeChanged ? 'items-start' : 'items-center',
    )}
    >
      <div className="flex-1 flex overflow-hidden">
        <div className="flex-1 overflow-x-auto hidden-scrollbar">
          <Tabs />
        </div>
      </div>
      <Assignee
        order={order}
        assignOrder={onAssignButtonClick}
        assignedTeamMember={assignedTeamMember}
        setAssignedTeamMember={setAssignedTeamMember}
      />
    </div>
  );
};

export default TabsAndAssignee;
