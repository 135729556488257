import { StateCreator } from 'zustand';

import { OrderPricesSlice, ProcessOrderDraftsStore } from './types';

import { fetchOrderPrices } from 'features/order/api/fetchOrderPrices';

export const createOrderPricesSlice: StateCreator<ProcessOrderDraftsStore, [], [], OrderPricesSlice> = (set, get) => ({
  isOrderPricesLoading: false,
  loadOrderPrices: async (orderId: string) => {
    set({ isOrderPricesLoading: true });

    try {
      const orderPrices = await fetchOrderPrices(orderId);

      const currentProducts = get().products[orderId];

      Object.entries(orderPrices.positionPrices).forEach(([positionId, positionPrice]) => {
        const currentProduct = currentProducts.find((product) => product.positionId === positionId);

        if (currentProduct) {
          currentProduct.price = positionPrice;
        }
      });

      set({
        products: {
          ...get().products,
          [orderId]: currentProducts,
        },
        globalOrders: get().globalOrders.map((order) => {
          if (order.id === orderId) {
            return {
              ...order,
              totalPrice: orderPrices.totalPrice,
              products: currentProducts,
            };
          }

          return order;
        }),
        groupOrders: {
          ...get().groupOrders,
          [orderId]: {
            ...get().groupOrders[orderId],
            totalPrice: orderPrices.totalPrice,
            products: currentProducts,
          },
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        set({ isOrderPricesLoading: false });
      }, 1000);
    }
  },
  isOrderPricesAlertVisible: false,
  setIsOrderPricesAlertVisible: (isOrderPricesAlertVisible: boolean) => {
    const products = get().products;
    const selectedOrderId = get().selectedOrderId;

    // Check that current products have at least one product with price
    const hasProductsWithPrice = products[selectedOrderId]?.some((product) => product.price && product.price.amount);

    if (!hasProductsWithPrice) {
      set({ isOrderPricesAlertVisible: false });
      return;
    }

    set({ isOrderPricesAlertVisible });
  },
});
