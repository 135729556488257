import {
  ChangeEvent,
  createContext, ReactNode, useCallback, useContext, useEffect, useMemo,
  useState,
} from 'react';

import * as PROMPT from 'features/instruction/constants';

import { Order, ProductWithQuantity } from '../../models';
import { FieldSpec, Schema } from 'features/instruction/models';
import { Unit } from 'features/product/models/Product';

import { GroupedFields } from 'features/instruction/types';
import { ProductSortingColumn, SortExtractedProductsBy } from 'features/product/types/product';

import { useMessagesContext } from 'contexts/useMessagesContext';
import { useSchemasContext } from 'contexts/useSchemasContext';

import {
  GroupErrorsAndWarningsState,
  useAnnotations, useOrder, useProducts,
  useUIState, useWarningAndErrors,
} from './hooks-beta';
import { usePrompt } from './hooks-beta/usePrompt';
import { QuerySearchType, useFetchProducts } from 'hooks/fetch/useFetchProducts';

import { Annotation } from 'components/chat/Chat/ChatMessage/ImageMessage/ImageOverlay/KonvaStage/type';

import {
  getCustomerFields, getGroupKeyByPath, getProductFields, getStandardFields, groupFields,
} from 'features/instruction/utils';

import { KeywordProvider } from '../useKeywordContext';

type ProcessOrderContextType = {
  order: Order;
  updateOrderByFn: (updateFn: (currentOrder: Order) => Order) => void;
  onOrderDraftProcessed: (orderId: string) => void;

  schema: Schema;
  isPromptLoading: boolean;

  productFields: FieldSpec[];
  customerFields: FieldSpec[];
  standardFields: FieldSpec[];

  sortingColumn: ProductSortingColumn | null;
  setSortingColumn: (sortingColumn: ProductSortingColumn | null) => void;
  filterEnabledProducts: boolean;
  setFilterEnabledProducts: (filterEnabledProducts: boolean) => void;
  sortExtractedProductsBy: SortExtractedProductsBy | null;
  setSortExtractedProductsBy: (sortExtractedProductsBy: SortExtractedProductsBy | null) => void;

  getErrors: (orderId: string) => GroupErrorsAndWarningsState;
  addError: (orderId: string, group: PROMPT.Group, key: string, error: string) => void;
  removeError: (orderId: string, group: PROMPT.Group, key: string) => void;
  deleteProductErrorsByUiId: (orderId: string, uiId: string) => void;
  filteredErrors: string[];
  resetGroupErrorsAndWarnings: (orderId: string, group: PROMPT.Group) => void;

  getWarnings: (orderId: string) => GroupErrorsAndWarningsState;
  addWarning: (orderId: string, group: PROMPT.Group, key: string, warning: string) => void;
  removeWarning: (orderId: string, group: PROMPT.Group, key: string) => void;
  deleteProductWarningsByUiId: (orderId: string, uiId: string) => void;

  updateValueByPath: (value: any, path: string, indices?: number[]) => void;
  updateProductByUiId: (uiId: string, values: any) => void;
  updateProductByUiIdAndPath: (uiId: string, path: string, val: any) => void;
  onCommentChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  addNewProduct: (product: ProductWithQuantity) => void;
  addNonProductFields: (fields: Record<string, any>[]) => string[];
  addNewParsedProducts: (fields: Record<string, any>[]) => Promise<ProductWithQuantity[]>;
  removeProductByUiIdAndPositionId: (orderId: string, uiId: string, positionId: string) => void;
  removeAllProducts: (orderId: string) => void;

  selectedDocIndex: number;
  setSelectedDocIndex: (value: number) => void;
  selectedDocPageIndex: number;
  setSelectedDocPageIndex: (value: number) => void;
  selectedDocImgIndex: number;
  setSelectedDocImgIndex: (value: number) => void;

  hoverId: string | null;
  triggerHoverEffect: (hoverId: string) => void;
  scrollToKeyword: (scrollToId: string, scrollSection?: string) => void;
  zoomToId: (zoomId: string, fieldId?: string) => void;

  annotationsRecord: Record<string, Annotation[]>;
  setAnnotations: (key: string, annotations: Annotation[]) => void;
  setAnnotationIsLoading: (key: string, annotationKey: string, isLoading: boolean) => void;
  removeAnnotationByPoint: (orderId: string, key: string, x: number, y: number) => void;
  addAnnotationProductUiIds: (key: string, annotationKey: string, productUiIds: string[]) => void;
  addAnnotationPopupContent: (key: string, annotationKey: string, popupContent: string) => void;
  addAnnotationPath: (key: string, annotationKey: string, path: string) => void;
  onOrderDraftsSaved: (orders: Order[]) => void;
  onTabChange: (index: number) => void;
};

const ProcessOrderContext = createContext<ProcessOrderContextType | undefined>(undefined);

type ProcessOrderProviderProps = {
  children: ReactNode;
  onOrderDraftProcessed: (orderId: string) => void;
  updateGlobalCurrentOrder?: (newOrder: Order) => void;
  resetTrigger?: number;
};

const ProcessOrderProvider: React.FC<ProcessOrderProviderProps> = ({
  children,
  onOrderDraftProcessed,
  updateGlobalCurrentOrder,
  resetTrigger,
}: ProcessOrderProviderProps) => {
  const { messages, setMessages } = useMessagesContext();
  const { schemas } = useSchemasContext();
  const { loadProducts } = useFetchProducts({ preventInitialFetch: true });

  const removeKeywordsByFieldId = useCallback((id: string) => {
    setMessages((prevMessages) => prevMessages.map((message) => {
      const newMessage = { ...message };
      if (newMessage?.context?.workflowOrder?.ocrKeywords) {
        newMessage.context.workflowOrder.ocrKeywords = (
          newMessage.context.workflowOrder.ocrKeywords.filter((keyword) => keyword.fieldId !== id)
        );
      }
      if (newMessage?.context?.workflowOrder?.audioKeywords) {
        newMessage.context.workflowOrder.audioKeywords = (
          newMessage?.context?.workflowOrder?.audioKeywords.filter((keyword) => keyword.fieldId !== id)
        );
      }
      if (newMessage?.context?.workflowOrder?.keywords) {
        newMessage.context.workflowOrder.keywords = (
          newMessage?.context?.workflowOrder?.keywords.filter((keyword) => keyword.fieldId !== id)
        );
      }
      return newMessage;
    }));
  }, [setMessages]);

  const [groupedFields, setGroupedFields] = useState<GroupedFields[]>([]);

  const productGroupKey = useMemo(() => getGroupKeyByPath(PROMPT.ORDER_DRAFT_PWQ_NAME_PATH, groupedFields), [groupedFields]);

  const {
    order,
    updateOrderByFn,
    updateValueByPath,
    onCommentChange,
    onOrderDraftsSaved,
    onTabChange,
  } = useOrder({ updateGlobalCurrentOrder });

  const { isLoading: isPromptLoading } = usePrompt({ order, updateOrderByFn });

  const {
    selectedDocIndex,
    setSelectedDocIndex,
    selectedDocPageIndex,
    setSelectedDocPageIndex,
    selectedDocImgIndex,
    setSelectedDocImgIndex,
    hoverId,
    triggerHoverEffect,
  } = useUIState({
    resetTrigger,
  });

  const {
    getErrors,
    addError,
    removeError,
    deleteProductErrorsByUiId,

    getWarnings,
    addWarning,
    removeWarning,
    deleteProductWarningsByUiId,
    filteredErrors,
    resetGroupErrorsAndWarnings,
  } = useWarningAndErrors({ resetTrigger });

  const {
    updateProductByUiId,
    updateProductByUiIdAndPath,
    addNewProduct,
    removeProductByUiIdAndPositionId,

    sortingColumn,
    setSortingColumn,
    filterEnabledProducts,
    setFilterEnabledProducts,
    sortExtractedProductsBy,
    setSortExtractedProductsBy,
  } = useProducts({
    deleteProductErrorsByUiId,
    removeKeywordsByFieldId,
    updateOrderByFn,
  });

  const {
    annotationsRecord,
    resetAnnotationsRecord,
    setAnnotations,
    setAnnotationIsLoading,
    removeAnnotationByPoint,
    addAnnotationProductUiIds,
    addAnnotationPath,
    addAnnotationPopupContent,
  } = useAnnotations({ removeProductByUiIdAndPositionId });

  const typeRef = useMemo(() => order?.typeSpecs?.[0]?.typeRef, [order?.typeSpecs]);
  const orderFields = useMemo(() => order?.typeSpecs?.[0]?.fields, [order?.typeSpecs]);
  const schema = useMemo(() => schemas[typeRef], [schemas, typeRef]);

  const productFields = useMemo(() => getProductFields(orderFields), [orderFields]);
  const customerFields = useMemo(() => getCustomerFields(orderFields), [orderFields]);
  const standardFields = useMemo(() => getStandardFields(orderFields), [orderFields]);

  const removeAllProducts = useCallback((orderId: string) => {
    resetAnnotationsRecord();
    order?.products?.forEach((product) => {
      removeProductByUiIdAndPositionId(orderId, product.uiId, product.positionId);
    });
  }, [resetAnnotationsRecord, order?.products, removeProductByUiIdAndPositionId]);

  const addNonProductFields = useCallback((fields: Record<string, any>[]): string[] => {
    const nonProductValues: string[] = [];

    type Fields = Record<string, any>[];

    // Group fields by group key
    const keyAndFields: Record<string, Fields> = fields.reduce(
      (acc: Record<string, Fields>, field: Record<string, any>) => {
        const paths = Object.keys(field) || [];
        if (paths.length === 0) return acc;

        let groupKey = '';

        paths.every((path) => {
          const _groupKey = getGroupKeyByPath(path, groupedFields);
          if (_groupKey) {
            groupKey = _groupKey;
            return false;
          }
          return true;
        });

        if (!groupKey) return acc;

        if ((Object.keys(acc) || []).includes(groupKey)) {
          acc[groupKey].push(field);
        } else {
          acc[groupKey] = [field];
        }

        return acc;
      }, {},
    );

    Object.entries(keyAndFields).forEach(([groupKey, _fields]) => {
      if (groupKey === productGroupKey) return;

      _fields.forEach((field) => {
        Object.keys(field).forEach((key) => {
          const value = field[key];
          updateValueByPath(value, key.replace('order_drafts.*.', ''));
          nonProductValues.push(value);
        });
      });
    });
    return nonProductValues;
  }, [groupedFields, updateValueByPath, productGroupKey]);

  const addNewParsedProducts = useCallback(async (fields: Record<string, any>[]) => {
    const newProducts: ProductWithQuantity[] = await Promise.all(fields.map(async (field: Record<string, any>) => {
      const newProduct = new ProductWithQuantity({
        name: field[PROMPT.ORDER_DRAFT_PWQ_NAME_PATH],
        autoMatchedIdOrSku: field[PROMPT.ORDER_DRAFT_PWQ_IDorSKU_PATH],
        quantity: parseFloat(field[PROMPT.ORDER_DRAFT_PWQ_QUANTITY_PATH]),
        unit: new Unit({
          symbol: field[PROMPT.ORDER_DRAFT_PWQ_UNIT_PATH],
        }),
        autoMatchedUnit: new Unit({
          symbol: field[PROMPT.ORDER_DRAFT_PWQ_UNIT_PATH],
        }),
      });

      if (!newProduct.quantity) {
        return null;
      }

      let matchedById = false;
      let products = [];
      if (newProduct.autoMatchedIdOrSku) {
        try {
          products = await loadProducts({
            searchQuery: newProduct.autoMatchedIdOrSku,
            customerId: order?.createdBy,
            querySearchType: QuerySearchType.Standard,
            filterEnabledProducts: true,
            reset: true,
          });

          matchedById = products?.length > 0;
        } catch {
          /* do nothing */
        }
      }

      if (!matchedById) {
        try {
          products = await loadProducts({
            searchQuery: newProduct.name,
            customerId: order?.createdBy,
            querySearchType: QuerySearchType.Embedding,
            filterEnabledProducts: true,
            reset: true,
          });

          const product = products?.[0];
          newProduct.product = product;
          newProduct.id = product?.id;
        } catch {
          /* do nothing */
        }
      }

      addNewProduct(newProduct);
      return newProduct;
    }));

    return newProducts.filter((product) => product !== null);
  }, [addNewProduct, loadProducts, order?.createdBy]);

  const scrollToKeyword = useCallback((scrollToId: string) => {
    setTimeout(() => {
      let element = document.getElementById(scrollToId);
      if (!element) {
        element = document.querySelector(`[data-name="${scrollToId}"]`);
      }
      if (!element) {
        element = document.querySelector(`[data-field-path="${scrollToId}"]`);
      }
      element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 200);
  }, []);

  const zoomToId = useCallback((id: string, fieldId?: string) => {
    const context = messages[0].context;
    const ocrKeywords = context?.workflowOrder?.ocrKeywords || [];
    const keyword = ocrKeywords.find((keyword_) => keyword_.path === id && (fieldId ? keyword_.fieldId === fieldId : true));
    if (!keyword) return;

    setSelectedDocIndex(keyword.docIndex);
    setSelectedDocPageIndex(keyword.pageIndex);
  }, [messages, setSelectedDocIndex, setSelectedDocPageIndex]);

  useEffect(() => {
    const _groupedFields = groupFields(orderFields, schemas[typeRef]);

    setGroupedFields(_groupedFields);
  }, [orderFields, schemas, typeRef]);

  const contextValue = useMemo(
    () => ({
      order,
      updateOrderByFn,
      schema,
      isPromptLoading,

      getErrors,
      addError,
      removeError,
      deleteProductErrorsByUiId,
      getWarnings,
      addWarning,
      removeWarning,
      deleteProductWarningsByUiId,
      filteredErrors,
      resetGroupErrorsAndWarnings,

      onOrderDraftProcessed,
      updateValueByPath,
      updateProductByUiId,
      updateProductByUiIdAndPath,
      onCommentChange,
      addNewProduct,
      addNonProductFields,
      addNewParsedProducts,
      removeProductByUiIdAndPositionId,
      removeAllProducts,

      productFields,
      customerFields,
      standardFields,

      sortingColumn,
      setSortingColumn,
      filterEnabledProducts,
      setFilterEnabledProducts,
      sortExtractedProductsBy,
      setSortExtractedProductsBy,

      selectedDocIndex,
      setSelectedDocIndex,
      selectedDocPageIndex,
      setSelectedDocPageIndex,
      selectedDocImgIndex,
      setSelectedDocImgIndex,

      annotationsRecord,
      setAnnotations,
      setAnnotationIsLoading,
      removeAnnotationByPoint,
      addAnnotationProductUiIds,
      addAnnotationPopupContent,
      addAnnotationPath,
      scrollToKeyword,
      triggerHoverEffect,
      hoverId,
      zoomToId,

      onOrderDraftsSaved,
      onTabChange,
    }),
    [order, schema, isPromptLoading, getErrors, addError, removeError, deleteProductErrorsByUiId,
      getWarnings, addWarning, removeWarning, deleteProductWarningsByUiId,
      onOrderDraftProcessed,
      updateValueByPath, updateProductByUiId, updateProductByUiIdAndPath,
      onCommentChange, addNewProduct, addNonProductFields,
      addNewParsedProducts, removeProductByUiIdAndPositionId, removeAllProducts, productFields, customerFields,
      standardFields, filteredErrors,
      sortingColumn, updateOrderByFn, onTabChange,
      setSortingColumn,
      filterEnabledProducts,
      setFilterEnabledProducts,
      sortExtractedProductsBy,
      resetGroupErrorsAndWarnings,
      setSortExtractedProductsBy,
      onOrderDraftsSaved,
      selectedDocIndex, setSelectedDocIndex, selectedDocPageIndex, setSelectedDocPageIndex, selectedDocImgIndex,
      setSelectedDocImgIndex, annotationsRecord, setAnnotations, setAnnotationIsLoading, removeAnnotationByPoint,
      addAnnotationProductUiIds, addAnnotationPopupContent, scrollToKeyword, triggerHoverEffect, hoverId, zoomToId,
      addAnnotationPath,
    ],
  );

  return (
    <KeywordProvider>
      <ProcessOrderContext.Provider value={contextValue}>
        {children}
      </ProcessOrderContext.Provider>
    </KeywordProvider>
  );
};

const useProcessOrderContext = () => {
  const context = useContext(ProcessOrderContext);

  if (context === undefined) {
    throw new Error('Must be wrapped by ProcessOrderContext provider.');
  }

  return context;
};

export { ProcessOrderContext, ProcessOrderProvider, useProcessOrderContext };
