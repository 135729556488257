import { Menu, MenuProps } from '@mantine/core';

import styles from './Menu.module.css';

interface Props extends MenuProps {
}

const MenuComponent = ({ children, ...props }: Props) => (
  <Menu {...props} classNames={styles}>
    {children}
  </Menu>
);

export default MenuComponent;
