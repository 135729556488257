import { forwardRef, Ref, SVGProps } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props extends SVGProps<SVGSVGElement> {
  className?: string;
}

const CommandLine = ({ className, ...props }: Props, ref: Ref<SVGSVGElement>) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    className={twMerge('w-6 h-6', className)}
    {...props}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M4.3501 7.99985C4.3501 5.98401 5.98426 4.34985 8.0001 4.34985H16.0001C18.0159 4.34985 19.6501 5.98401 19.6501 7.99985V15.9999C19.6501 18.0157 18.0159 19.6499 16.0001 19.6499H8.0001C5.98426 19.6499 4.3501 18.0157 4.3501 15.9999V7.99985ZM8.0001 5.64985C6.70223 5.64985 5.6501 6.70198 5.6501 7.99985V15.9999C5.6501 17.2977 6.70223 18.3499 8.0001 18.3499H16.0001C17.298 18.3499 18.3501 17.2977 18.3501 15.9999V7.99985C18.3501 6.70198 17.298 5.64985 16.0001 5.64985H8.0001ZM16.6502 12C16.6502 12.359 16.3592 12.65 16.0002 12.65H12.0002C11.6412 12.65 11.3502 12.359 11.3502 12C11.3502 11.641 11.6412 11.35 12.0002 11.35L16.0002 11.35C16.3592 11.35 16.6502 11.641 16.6502 12ZM8.45972 7.54023C8.20588 7.28639 7.79432 7.28639 7.54048 7.54023C7.28664 7.79408 7.28664 8.20563 7.54048 8.45947L9.08086 9.99985L7.54048 11.5402C7.28664 11.7941 7.28664 12.2056 7.54048 12.4595C7.79432 12.7133 8.20588 12.7133 8.45972 12.4595L10.4244 10.4948C10.6977 10.2215 10.6977 9.77824 10.4244 9.50488L8.45972 7.54023Z" />
  </svg>
);

export default forwardRef(CommandLine);
