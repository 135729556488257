import { useEffect, useRef, useState } from 'react';

import { twMerge } from 'tailwind-merge';

import { XMarkIcon } from '@heroicons/react/20/solid';

import { globalAlertDataList } from 'state/globalAlertDataList';

import { getAlertStyle } from './utils';

interface Props {
  alertId: string;
  theme: 'success' | 'info' | 'warning' | 'error-alert';
  title: string;
  message?: string;
}

const Alert = ({
  alertId,
  theme,
  title,
  message,
}: Props) => {
  const [visible, setVisible] = useState(true);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const {
    borderColor, icon, titleColor, descriptionColor, buttonColor,
  } = getAlertStyle(theme);

  // Start timer to dismiss the alert automatically after 5 seconds
  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      setVisible(false);
      globalAlertDataList.dismiss(alertId);
    }, 5000);

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [alertId]);

  const onConfirmClick = () => {
    // If the user dismisses manually, clear the timer and start the slide-to-top animation immediately
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    setVisible(false);
    globalAlertDataList.dismiss(alertId);
  };
  return (
    <div
      className={twMerge(
        visible ? 'animate-slide-from-bottom' : 'animate-slide-to-top',
        borderColor,
        'flex w-[350px] 4xl:w-[450px] justify-between border-l-4 bg-white p-4 shadow-lg',
      )}
    >
      <div className="flex">
        <div className="flex-shrink-0">
          {icon}
        </div>
        <div className="ml-3">
          <h3 className={twMerge(
            'text-md font-medium',
            titleColor,
          )}
          >
            {title}
          </h3>
          {message && message.length > 0 && (
          <div className={twMerge(
            'mt-2 text-sm',
            descriptionColor,
          )}
          >
            <p>{message}</p>
          </div>
          )}
        </div>
      </div>
      <div className="flex flex-col justify-start">
        <button
          type="button"
          className={twMerge(
            buttonColor,
          )}
          onClick={onConfirmClick}
        >
          <span className="sr-only">Dismiss</span>
          <XMarkIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

export default Alert;
