import { twMerge } from 'tailwind-merge';

import { SelectOption } from 'components/ui/Select';

import { CardDescription, CardHeader, CardTitle } from './ChartCard';

export interface ChartCardHeaderWithSelectProps {
  title: string | React.ReactNode;
  subtitle?: string | React.ReactNode;

  rightSelectOptions?: SelectOption[];
  selectedRightOptionValue?: any;
  setRightOption?: (value: any) => void;

  leftSelectOptions?: SelectOption[];
  selectedLeftOptionValue?: any;
  setLeftOption?: (value: any) => void;

  leftSection?: React.ReactNode;
  rightSection?: React.ReactNode;
}

const ChartCardHeaderWithSelect = ({
  rightSelectOptions, selectedRightOptionValue, setRightOption,
  leftSelectOptions, selectedLeftOptionValue, setLeftOption,
  title, subtitle,
  leftSection, rightSection,
}: ChartCardHeaderWithSelectProps) => (
  <CardHeader>
    {
        (leftSelectOptions || leftSection || rightSection) && (
          <div className="flex justify-between">
            <div className="flex gap-2">
              {
                leftSection
              }
              {
                  leftSelectOptions && (
                  <div className="inline-flex items-center rounded-full bg-light-blue-hue-box">
                      {
                          leftSelectOptions.map((option) => (
                            <button
                              type="button"
                              className={twMerge(
                                'shadow-card hover:shadow-card rounded-full px-slg py-1.5 text-xs font-medium text-gray-600 transition-all duration-300 ease-in-out',
                                selectedLeftOptionValue === option.value ? 'bg-purple-highlight-2 text-white' : 'hover:text-gray-800',
                              )}
                              onClick={() => setLeftOption(option.value)}
                            >
                              {option.label}
                            </button>
                          ))
                      }
                  </div>
                  )
              }
            </div>

            <div className="flex gap-2">
              {
                rightSection
              }
              {
                  rightSelectOptions && (
                  <div className="inline-flex items-center rounded-full bg-whiter">
                      {
                          rightSelectOptions.map((option) => (
                            <button
                              type="button"
                              className={twMerge(
                                'shadow-card hover:shadow-card rounded-full px-slg py-1.5 text-xs font-medium text-gray-600 transition-all duration-300 ease-in-out',
                                selectedRightOptionValue === option.value ? 'bg-black text-white' : 'hover:text-gray-800',
                              )}
                              onClick={() => setRightOption(option.value)}
                            >
                              {option.label}
                            </button>
                          ))
                      }
                  </div>
                  )
              }
            </div>
          </div>
        )
      }
    <div className="flex justify-between items-center">
      <div className="flex flex-col space-y-1">
        <CardTitle className="flex items-center w-fit">
          {title}
        </CardTitle>
        <CardDescription>
          {subtitle}
        </CardDescription>
      </div>

      {
            !leftSelectOptions && rightSelectOptions && (
            <div className="inline-flex items-center rounded-md bg-whiter p-1.5">
                {
                    rightSelectOptions.map((option) => (
                      <button
                        type="button"
                        className={twMerge(
                          'shadow-card hover:shadow-card rounded px-slg py-1 text-xs font-medium text-black hover:bg-white',
                          selectedRightOptionValue === option.value && 'bg-white',
                        )}
                        onClick={() => setRightOption(option.value)}
                      >
                        {option.label}
                      </button>
                    ))
                }
            </div>
            )
        }
    </div>
  </CardHeader>
);

export default ChartCardHeaderWithSelect;
