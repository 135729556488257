import { useTranslation } from 'react-i18next';

import { Tooltip } from 'components/ui';

interface Props {
  warnings: string[];
  children: React.ReactNode;
}

const WarningsTooltip = ({ warnings, children }: Props) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      label={(
        <div className="p-2">
          <p className="text-warning-500 font-medium">
            {warnings.length}
            {' '}
            {t('order.processOrderDrafts.rightSide.warningsDetected')}
          </p>
          <p className="text-xxs">
            {t('order.processOrderDrafts.rightSide.warningsDetected.description')}
          </p>
          <ul className="list-disc pl-3 space-y-1 pt-2 text-xs">
            {warnings.map((warning) => (
              <li key={warning}>{warning}</li>
            ))}
          </ul>
        </div>
      )}
    >
      {children}
    </Tooltip>
  );
};

export default WarningsTooltip;
