import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Group } from 'features/instruction/constants';

import { useSchema } from '../../hooks/useSchema';

import { ExpandableCard, FieldRenderer } from './components';

import { getStandardFields } from 'features/order/store/process-order-drafts/utils';

import { useProcessOrderDraftsStore } from 'features/order/store/process-order-drafts';

interface Props {
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
}

const OrderDetails = ({ isExpanded, setIsExpanded }: Props) => {
  const { t } = useTranslation();

  const groupOrders = useProcessOrderDraftsStore((state) => state.groupOrders);
  const selectedOrderId = useProcessOrderDraftsStore((state) => state.selectedOrderId);

  const schema = useSchema({
    groupOrders,
    selectedOrderId,
  });

  const standardFieldSpecs = useMemo(() => getStandardFields(groupOrders, selectedOrderId), [groupOrders, selectedOrderId]);

  const defaultFieldSpecs = standardFieldSpecs.slice(0, 4);
  const expandedFieldSpecs = standardFieldSpecs.slice(4);

  return (
    <ExpandableCard
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      title={t('order.processOrderDrafts.rightSide.orderDetails')}
      group={Group.StandardFields}
      className="grid grid-cols-[auto_1fr] gap-2 items-center"
      isExpandable={expandedFieldSpecs.length > 0}
    >
      <ExpandableCard.DefaultContent>
        {
          defaultFieldSpecs?.map((fieldSpec) => (
            <FieldRenderer
              key={fieldSpec.path}
              group={Group.StandardFields}
              fieldPath={fieldSpec.path}
              wrapperClassName="contents"
              schema={schema}
              fieldSpec={fieldSpec}
            />
          ))
        }
      </ExpandableCard.DefaultContent>
      <ExpandableCard.ExpandedContent>
        {
          expandedFieldSpecs?.map((fieldSpec) => (
            <FieldRenderer
              key={fieldSpec.path}
              group={Group.StandardFields}
              fieldPath={fieldSpec.path}
              wrapperClassName="contents"
              schema={schema}
              fieldSpec={fieldSpec}
            />
          ))
        }
      </ExpandableCard.ExpandedContent>
    </ExpandableCard>
  );
};

export default OrderDetails;
