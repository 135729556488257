import { User } from 'features/user/models';

import { Card } from 'components/common/Card';
import { Tag } from 'components/common/Tag';
import { TextWithLabel } from 'components/ui/TextWithLabel';

import { formatUserRole, userRoleToTagVariant } from 'utils/enums';

import { TeamMemberIconWithActiveStatus } from '../team-member';

interface Props {
  user: User;
}

const LeftSide = ({ user }: Props) => (
  <Card>
    <Card.Body>
      <div className="flex flex-col items-center">
        <TeamMemberIconWithActiveStatus user={user} className="mb-3.5" />
        <div className="flex gap-2">
          {user?.roles?.map((role) => (
            <Tag
              key={role}
              className="static"
              isTagLoading={!user}
              tagTitle={formatUserRole(role)}
              tagVariant={userRoleToTagVariant(role)}
              hideCircle
            />
          ))}
        </div>
      </div>

      <div className="space-y-1">
        {
        user?.username && (
        <TextWithLabel
          label="Username"
          value={user?.username}
        />
        )
        }
        {
        user?.GetEmail() && (
        <TextWithLabel
          label="Email"
          value={user?.GetEmail()}
        />
        )
        }
        {
        user?.GetPhone() && (
        <TextWithLabel
          label="Phone"
          value={user?.GetPhone()}
        />
        )
        }
      </div>
    </Card.Body>
  </Card>
);

export default LeftSide;
