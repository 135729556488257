import { twMerge } from 'tailwind-merge';

import { Loader } from '@mantine/core';

interface Props {
  percentage: number;
  orderCount: number;
  title: string;
  subtitle: string;

  opacity?: 0.4 | 0.6 | 1;
  isLoading?: boolean;
}

const opacityToClassName = (opacity: number) => {
  if (opacity === 0.4) return 'opacity-40';
  if (opacity === 0.6) return 'opacity-60';
  if (opacity === 1) return 'opacity-100';
  return '';
};

const CircleBase = ({
  percentage, orderCount, title, subtitle, opacity = 0.4, isLoading = false,
}: Props) => {
  const radius = 45;
  const centerX = 50;
  const centerY = 50;

  // Start at the top (0 degrees in SVG coordinates)
  const startX = centerX;
  const startY = centerY - radius;

  let arcPath;
  if (percentage === 100) {
  // Draw two 50% arcs
    const firstHalf = `M ${startX} ${startY} A ${radius} ${radius} 0 1 1 ${centerX} ${centerY + radius}`;
    const secondHalf = `A ${radius} ${radius} 0 1 1 ${startX} ${startY}`;
    arcPath = `${firstHalf} ${secondHalf}`;
  } else {
    const drawDegrees = percentage * 3.6;
    const endAngle = drawDegrees * (Math.PI / 180);
    const endX = centerX + radius * Math.sin(endAngle);
    const endY = centerY - radius * Math.cos(endAngle);
    const largeArcFlag = drawDegrees > 180 ? 1 : 0;
    arcPath = `M ${startX} ${startY} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY}`;
  }

  // Create the SVG path
  // const arcPath = `M ${startX} ${startY} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY}`;
  return (
    <div
      className={twMerge(
        'flex flex-col items-center justify-center',
        opacityToClassName(opacity),
      )}
    >
      <div className="relative flex items-center justify-center w-20 3xl:w-24 h-20 3xl:h-24">
        {/* Outer circle */}
        <svg className="absolute w-full h-full" viewBox="0 0 100 100">
          {/* Background circle (light opacity) */}
          {/* If percentage is more than 100, stoke's opacity is set to 0.7 */}
          <circle
            cx={centerX}
            cy={centerY}
            r={radius}
            fill="none"
            stroke={percentage > 100 ? 'rgba(255, 255, 255, 0.7)' : 'rgba(255, 255, 255, 0.2)'}
            strokeWidth="4"
          />

          <path
            d={arcPath}
            fill="none"
            stroke={percentage > 100 ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.7)'}
            strokeWidth="4"
            strokeLinecap="round"
          />
        </svg>

        {/* Inner circle with number */}
        <div className="bg-gold-filter w-15 3xl:w-17 h-15 3xl:h-17 rounded-full flex items-center justify-center">
          <span className="text-2xl 3xl:text-3xl text-blue-100 font-light">
            {isLoading ? <Loader type="dots" color="blue.1" /> : orderCount}
          </span>
        </div>
      </div>

      {/* Text labels */}
      <div className="text-white text-center mt-2 3xl:mt-4">
        <div className="text-xs 3xl:text-sm font-light">{subtitle}</div>
        <div className="text-sm 3xl:text-base font-medium mt-1">{title}</div>
      </div>
    </div>
  );
};

export default CircleBase;
