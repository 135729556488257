import { twMerge } from 'tailwind-merge';

interface Props extends React.HTMLAttributes<HTMLTableRowElement> {
}

const InfiniteScrollTableTr = ({
  children, ...props
}: Props) => (
  <tr
    {...props}
    className={twMerge(
      'border-b-[0.5px] border-solid border-violet-hue-selection-button-1',
      props.className,
    )}
  >
    {children}
  </tr>
);

export default InfiniteScrollTableTr;
