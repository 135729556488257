import { Expose, Type } from 'class-transformer';

import { TypeRef } from '../types';

export type FieldType = 'string' | 'numeric' | 'bool' | 'struct' | 'array';

export type InputType = 'standard' | 'queryable' | 'multivalue' | 'datetime' | 'readonly';

export enum StandardFieldType {
  StandardFieldTypeOrderNumber = 'order_number',
  StandardFieldTypeProductName = 'product_name',
  StandardFieldTypeProductUnit = 'product_unit',
  StandardFieldTypeProductIdOrSku = 'product_id_or_sku',
  StandardFieldTypeProductQuantity = 'product_quantity',
  StandardFieldTypeOrderDeliveryDateTime = 'order_delivery_datetime',
}

type FieldValidator = {
  min?: number;

  max?: number;

  required: boolean;
};

class TypeSchema {
  name: string;

  typeRef: TypeRef;

  fields: FieldSchema[];
}

export class FieldSchema {
  name: string;

  description?: string;

  path: string; // Check

  type: FieldType; // check

  typeSchema?: TypeSchema;

  validators?: FieldValidator[];

  isStandard: boolean;

  isCardable: boolean;

  isErpBindable: boolean;

  inputType: InputType;

  standardFieldType: string;

  queryURLPath: string;

  modelPath: string;

  autoMatchedModelPath: string;

  lastCardableParentPath: string;

  // UI onlu field
  uiId: string;

  added?: boolean;
}

export class Schema {
  typeRef: TypeRef;

  name: string;

  fields: FieldSchema[];
}

export class FieldSpec {
  @Expose({ name: 'name' })
    name?: string;

  @Expose({ name: 'description' })
    description?: string;

  @Expose({ name: 'examples' })
    examples?: string[];

  @Expose({ name: 'path' })
    path: string;

  @Expose({ name: 'type' })
    type: FieldType;

  @Expose({ name: 'validation' })
    validation?: string;

  @Expose({ name: 'is_erp_bindable' })
    isErpBindable: boolean;

  @Expose({ name: 'model_path' })
    modelPath: string;

  @Expose({ name: 'auto_matched_model_path' })
    modelAutoMatchedPath: string;

  @Expose({ name: 'last_cardable_parent_path' })
    lastCardableParentPath: string;

  @Expose({ name: 'ui_id' })
    uiId: string;

  @Expose({ name: 'is_removable' })
    isRemovable?: boolean;

  @Expose({ name: 'input_type' })
    inputType: InputType;

  added?: boolean;
}

export class TypeSpec {
  @Expose({ name: 'type_ref' })
    typeRef: TypeRef;

  @Expose({ name: 'fields' })
  @Type(() => Array<FieldSpec>)
    fields: FieldSpec[];
}
