import { isSameDay, startOfToday } from 'date-fns';
import dayjs from 'dayjs';

import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DatePickerInput, DatePickerType, DatePickerValue, MonthPickerInput,
} from '@mantine/dates';

import { TimePeriod } from 'features/analytics/types/timePeriod';

interface Props {
  date?: Date | null;
  setDate?: (date: Date | Date[]) => void;
  timePeriod?: TimePeriod;
}

const formatter = ({
  date: date_, format, locale, timePeriod,
}: {
  type: DatePickerType;
  date: DatePickerValue<DatePickerType>;
  locale: string;
  format: string;
  labelSeparator: string;
  timePeriod?: TimePeriod
}) => {
  if (!date_) {
    return '';
  }

  let date: Date;
  if (Array.isArray(date_)) {
    date = date_[0];
  } else {
    date = new Date(date_);
  }

  if (isSameDay(date, startOfToday()) && timePeriod !== TimePeriod.Monthly) {
    return 'Today';
  }

  return dayjs(date).locale(locale).format(format);
};

const DateOrMonthPickerInput = ({
  date, setDate, timePeriod,
}: Props) => {
  if (timePeriod === TimePeriod.Monthly) {
    return (
      <MonthPickerInput
        leftSection={<FontAwesomeIcon icon={faCalendar} />}
        placeholder="Select month"
        value={typeof date === 'string' ? new Date(date) : date}
        onChange={(value) => setDate(value)}
        valueFormatter={(value) => formatter({ ...value, timePeriod })}
        maxDate={startOfToday()}
        size="xs"
        radius="xl"
        variant="filled"
        styles={{
          input: {
            backgroundColor: 'rgb(245,247,253)',
          },
        }}
      />
    );
  }

  return (
    <DatePickerInput
      leftSection={<FontAwesomeIcon icon={faCalendar} />}
      placeholder="Select date"
      value={typeof date === 'string' ? new Date(date) : date}
      onChange={(value) => setDate(value)}
      valueFormatter={(value) => formatter({ ...value, timePeriod })}
      maxDate={startOfToday()}
      size="xs"
      radius="xl"
      variant="filled"
      styles={{
        input: {
          backgroundColor: 'rgb(245,247,253)',
        },
      }}
    />
  );
};

export default DateOrMonthPickerInput;
