import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import { useLanguageWizardStore, useWizardSequenceStore } from 'stores';

import { ROUTES } from 'config/routes';

import { OVERVIEW_WIZARD_STEPS, WizardSteps } from 'constants/wizardSteps';

import { CogIcon, LanguageIcon } from 'components/icon/outline';
import { Popover, Wizard } from 'components/ui';

const SettingsButton = () => {
  const currentStep = useWizardSequenceStore((state) => state.currentStep);
  const completeStep = useWizardSequenceStore((state) => state.completeStep);

  const completed = useLanguageWizardStore((state) => state.completed);
  const show = useLanguageWizardStore((state) => state.show);
  const complete = useLanguageWizardStore((state) => state.complete);

  const isCurrentStep = currentStep === OVERVIEW_WIZARD_STEPS.indexOf(WizardSteps.LANGUAGE);

  useEffect(() => {
    if (isCurrentStep && (completed || !show)) {
      completeStep();
    }
  }, [completed, completeStep, isCurrentStep, show]);

  const onComplete = (doNotShowAgain: boolean) => {
    complete(doNotShowAgain);
    completeStep();
  };

  return (
    <Popover
      opened={isCurrentStep && !completed && show}
      position="bottom"
    >
      <Popover.Target>
        <NavLink to={ROUTES.SETTINGS}>
          <div className="inline-flex items-center justify-center p-1.5 desktop:p-2 relative flex-[0_0_auto] bg-white rounded-full overflow-hidden border border-solid border-light-grey shadow-blue-light-tint-drop-shadow hover:shadow-blue-tint-drop-shadow">
            <CogIcon className="!relative size-[18px] desktop:size-5" color="#191919" />
          </div>
        </NavLink>
      </Popover.Target>
      <Popover.Dropdown>
        <Wizard
          Icon={LanguageIcon}
          content={(
            <div className="inline-flex flex-col items-start justify-center gap-1.5 relative flex-[0_0_auto]">
              <div className="inline-flex flex-col items-start gap-1 pl-0 pr-2 py-0 relative flex-[0_0_auto]">
                <p className="relative w-fit mt-[-1.00px] font-font-screen-xs-medium font-[number:var(--font-screen-xs-medium-font-weight)] text-black text-[length:var(--font-screen-xs-medium-font-size)] tracking-[var(--font-screen-xs-medium-letter-spacing)] leading-[var(--font-screen-xs-medium-line-height)] whitespace-nowrap [font-style:var(--font-screen-xs-medium-font-style)]">
                  You can now customize the application language in Settings.
                </p>
                <p className="pt-2 font-font-screen-sm-regular font-[number:var(--font-screen-sm-regular-font-weight)] text-black-text-70 text-[length:var(--font-screen-sm-regular-font-size)] tracking-[var(--font-screen-sm-regular-letter-spacing)] leading-[var(--font-screen-sm-regular-line-height)] whitespace-nowrap [font-style:var(--font-screen-sm-regular-font-style)]">
                  Click the Settings icon to access language preferences.
                </p>
              </div>
            </div>
          )}
          isVisible={isCurrentStep && !completed && show}
          onComplete={onComplete}
        />
      </Popover.Dropdown>
    </Popover>
  );
};

export default SettingsButton;
