import {
  useEffect, useRef, useState,
} from 'react';

import Dayjs from 'dayjs';

import {
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/outline';

import { ParsedEmail } from 'models/Mail';

interface Props {
  recipientNames: string[];
  recipientBusinessName: string;
  parsedMessage: ParsedEmail;
}

const getRecipientDisplay = (recipientNames: string[], recipientBusinessName: string) => {
  if (recipientNames.length > 0) {
    if (recipientNames.length > 1) {
      return `${recipientNames[0]},..`;
    }
    return recipientNames[0];
  }
  return recipientBusinessName;
};

const ThreadMessageToSection = ({
  recipientNames, recipientBusinessName, parsedMessage,
}: Props) => {
  const [showEmailDetails, setShowEmailDetails] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowEmailDetails(false);
      }
    };

    if (showEmailDetails) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showEmailDetails]);

  const recipientDisplay = getRecipientDisplay(recipientNames, recipientBusinessName);

  return (
    <div className="text-xs text-gray-600 relative">
      <div className="flex items-center">
        <span>
          To:
          {' '}
          {recipientDisplay}
        </span>
        <button
          type="button"
          onClick={() => setShowEmailDetails(!showEmailDetails)}
          className="mt-0.5 p-1 hover:bg-gray-100 rounded-full"
        >
          {showEmailDetails
            ? <ChevronUpIcon className="h-4 w-4" />
            : <ChevronDownIcon className="h-4 w-4" />}
        </button>
      </div>

      {showEmailDetails && parsedMessage && (
      <div ref={dropdownRef} className="absolute left-0 mt-2 z-10 bg-white shadow-lg border rounded-md p-3 min-w-[400px] max-w-[600px] transform -translate-x-4">
        <div className="space-y-2">
          {parsedMessage.headers.from?.length > 0 && (
          <div className="grid grid-cols-[80px_1fr] gap-2">
            <span className="font-semibold text-gray-900">From:</span>
            <span>{parsedMessage.headers.from.map((from) => from.address).join(', ')}</span>
          </div>
          )}
          {parsedMessage.headers.sender?.length > 0 && (
          <div className="grid grid-cols-[80px_1fr] gap-2">
            <span className="font-semibold text-gray-900">Sender:</span>
            <span>{parsedMessage.headers.sender.map((sender) => sender.address).join(', ')}</span>
          </div>
          )}
          {parsedMessage.headers.to?.length > 0 && (
          <div className="grid grid-cols-[80px_1fr] gap-2">
            <span className="font-semibold text-gray-900">To:</span>
            <span>{parsedMessage.headers.to.map((to) => to.address).join(', ')}</span>
          </div>
          )}
          {parsedMessage.headers.replyTo?.length > 0 && (
          <div className="grid grid-cols-[80px_1fr] gap-2">
            <span className="font-semibold text-gray-900">Reply-To:</span>
            <span>{parsedMessage.headers.replyTo.map((replyTo) => replyTo.address).join(', ')}</span>
          </div>
          )}
          {parsedMessage.headers.cc?.length > 0 && (
          <div className="grid grid-cols-[80px_1fr] gap-2">
            <span className="font-semibold text-gray-900">Cc:</span>
            <span>{parsedMessage.headers.cc.map((cc) => cc.address).join(', ')}</span>
          </div>
          )}
          {parsedMessage.headers.bcc?.length > 0 && (
          <div className="grid grid-cols-[80px_1fr] gap-2">
            <span className="font-semibold text-gray-900">Bcc:</span>
            <span>{parsedMessage.headers.bcc.map((bcc) => bcc.address).join(', ')}</span>
          </div>
          )}
          {parsedMessage.headers.subject && (
          <div className="grid grid-cols-[80px_1fr] gap-2">
            <span className="font-semibold text-gray-900">Subject:</span>
            <span>{parsedMessage.headers.subject}</span>
          </div>
          )}
          {parsedMessage.headers.date && (
          <div className="grid grid-cols-[80px_1fr] gap-2">
            <span className="font-semibold text-gray-900">Date:</span>
            <span>{Dayjs(parsedMessage.headers.date).format('MMM D YYYY, HH:mm')}</span>
          </div>
          )}
        </div>
      </div>
      )}
    </div>
  );
};

export default ThreadMessageToSection;
