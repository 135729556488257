import { useState } from 'react';

import CustomerDetails from './CustomerDetails';
import OrderDetails from './OrderDetails';
import ProductsDetails from './ProductsDetails';
import TotalPrice from './TotalPrice';

const ProcessContent = () => {
  const [isCustomerExpanded, setIsCustomerExpanded] = useState(false);
  const [isOrderExpanded, setIsOrderExpanded] = useState(false);
  return (
    <>
      {/* Grid layout for side-by-side sections */}
      <div
        data-order-section="upper-section"
        className="grid grid-cols-2 gap-4 mb-6"
      >
        {/* Order Details */}
        <OrderDetails isExpanded={isOrderExpanded} setIsExpanded={setIsOrderExpanded} />

        {/* Customer Details */}
        <CustomerDetails isExpanded={isCustomerExpanded} setIsExpanded={setIsCustomerExpanded} />
      </div>

      {/* Product List - Full Width */}
      <ProductsDetails />

      {/* Total Price */}
      <TotalPrice />
    </>
  );
};

export default ProcessContent;
