/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';

import { Business } from '../../../models/Business';

import { Page } from 'components/layout/Page/Page';

interface Props {
  businessId: string;
  navigateToCustomersList: () => void;
}

const CustomerDetailsPage = ({ businessId, navigateToCustomersList }: Props) => (
  <Page>
    Customer page content
  </Page>
);

export default CustomerDetailsPage;
