import { useMemo } from 'react';

import { useInfiniteQuery } from '@tanstack/react-query';

import { AssignableUserRole } from 'features/user/models';

import { OrderSearchBarSelect } from '../OrderSearchBarSelect';
import { usersOptions } from 'features/user/queryOptions';

interface Props {
  value: string;
  onChange: (value: string, usename?: string) => void;
}

const AssigneeSelect = ({ value, onChange }: Props) => {
  const { data, isFetching, fetchNextPage } = useInfiniteQuery({
    ...usersOptions(),
    select: (data_) => ({
      ...data_,
      pages: data_.pages.map((page) => ({
        ...page,
        result: page.result.filter((user) => !user.roles.includes(AssignableUserRole.AIAssistant)),
      })),
    }),
    enabled: false,
  });

  const selectData = useMemo(() => data?.pages.flatMap((page) => page.result.map((user) => ({
    value: user.id,
    label: `${user.firstName} ${user.lastName}`,
  }))), [data]);

  return (
    <OrderSearchBarSelect
      isLoading={isFetching}
      data={selectData}
      placeholder="Assignee"
      value={value}
      size="xs"
      onChange={(v, option) => onChange(v, option.label)}
      onDropdownOpen={() => fetchNextPage()}
    />
  );
};

export default AssigneeSelect;
