import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useInfiniteQuery } from '@tanstack/react-query';

import { AlertTheme } from 'state/classes/AlertDataList';
import { globalAlertDataList } from 'state/globalAlertDataList';

import { AssignableUserRole } from '../models';

import { TeamMemberListItem } from '../components';
import { PlusIcon } from 'components/icon/outline';
import { Page } from 'components/layout/Page/Page';
import LoadingOverlay from 'components/ui/LoadingOverlay';
import { InviteTeammeberSidebarWrapper } from 'components/wrapper/InviteTeammeberSidebarWrapper';

import { usersOptions } from '../queryOptions';

const TeamMemberListPage = () => {
  const { t } = useTranslation();

  const inviteTeammeberSidebarWrapperRef = useRef(null);

  const { data, isLoading } = useInfiniteQuery({
    ...usersOptions(),
    select: (data_) => ({
      ...data_,
      pages: data_.pages.map((page) => ({
        ...page,
        result: page.result.filter((user) => !user.roles.includes(AssignableUserRole.AIAssistant)),
      })),
    }),
  });

  const onAddMemberButtonPress = useCallback(() => {
    inviteTeammeberSidebarWrapperRef.current?.openSidebar();
  }, []);

  const onInvitationComplete = useCallback(() => {
    globalAlertDataList.create('User invitation sent successfully', AlertTheme.SUCCESS);
  }, []);

  return (
    <Page
      pageTitle={t('teammember.list.title')}
      pageDescription={t('teammember.list.description')}
      contentWithBorder
    >
      <InviteTeammeberSidebarWrapper
        ref={inviteTeammeberSidebarWrapperRef}
        onComplete={onInvitationComplete}
      >
        <LoadingOverlay visible={isLoading} />

        <div className="grid grid-cols-6 desktop:grid-cols-7 gap-4 p-6 relative self-stretch w-full overflow-y-auto">
          {
            data?.pages.flatMap((group) => group.result).map((user) => (
              <TeamMemberListItem
                key={`member-${user?.id}`}
                user={user}
              />
            ))
          }
        </div>

        <div className="inline-flex items-end justify-end gap-2 p-4 relative flex-1 grow">
          <button
            type="button"
            onClick={onAddMemberButtonPress}
            className="inline-flex items-center justify-center gap-1 px-4 py-2 relative flex-[0_0_auto] bg-purple-highlight-2 hover:bg-[#8249F2]/90 rounded-full shadow-blue-tint-drop-shadow"
          >
            <PlusIcon className="!relative !w-5 !h-5" color="white" />
            <div
              className="relative w-fit font-font-screen-md-regular font-[number:var(--font-screen-md-regular-font-weight)] text-white text-[length:var(--font-screen-md-regular-font-size)] tracking-[var(--font-screen-md-regular-letter-spacing)] leading-[var(--font-screen-md-regular-line-height)] whitespace-nowrap [font-style:var(--font-screen-md-regular-font-style)]"
            >
              {t('teammember.add.button')}
            </div>
          </button>
        </div>
      </InviteTeammeberSidebarWrapper>
    </Page>
  );
};

export default TeamMemberListPage;
