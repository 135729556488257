import { forwardRef, Ref, SVGProps } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props extends SVGProps<SVGSVGElement> {
  className?: string;
}

const PreviewOpenIcon = ({ className, ...props }: Props, ref: Ref<SVGSVGElement>) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    className={twMerge('w-6 h-6', className)}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.99961 4.3501C3.98377 4.3501 2.34961 5.98426 2.34961 8.0001V16.0001C2.34961 18.0159 3.98377 19.6501 5.99961 19.6501H17.9996C20.0154 19.6501 21.6496 18.0159 21.6496 16.0001V8.0001C21.6496 5.98426 20.0154 4.3501 17.9996 4.3501H5.99961ZM3.64961 8.0001C3.64961 6.70223 4.70174 5.6501 5.99961 5.6501H17.9996C19.2975 5.6501 20.3496 6.70223 20.3496 8.0001V16.0001C20.3496 17.298 19.2975 18.3501 17.9996 18.3501H5.99961C4.70174 18.3501 3.64961 17.298 3.64961 16.0001V8.0001ZM13.9996 7.0001C13.4473 7.0001 12.9996 7.44781 12.9996 8.0001V16.0001C12.9996 16.5524 13.4473 17.0001 13.9996 17.0001H17.9996C18.5519 17.0001 18.9996 16.5524 18.9996 16.0001V8.0001C18.9996 7.44781 18.5519 7.0001 17.9996 7.0001H13.9996Z"
    />
  </svg>
);

export default forwardRef(PreviewOpenIcon);
