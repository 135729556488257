export type ProductSortingColumn = 'id_or_sku' | 'score' | 'name' | 'created_at';

export enum SortExtractedProductsBy {
  DEFAULT = 'default',
  SCORE = 'score',
  ErrorStatus = 'error_status',
}

export type QuantityConversionPopupContent = {
  prevQuantity: number;
  newQuantity: number;
  alermLowQuantity?: boolean;
};
