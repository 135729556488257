import { Chat } from '../models';

import { httpPostV2 } from 'utils/xhr';

interface CreateChatParams {
  title?: string;
}

export const createLLMChat = (params?: CreateChatParams) => httpPostV2('llm/chat/', params, {
  classType: Chat,
}).then((res) => res.data as Chat);
