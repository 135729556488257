import { BusinessSales } from 'models/Analytics';

import { TimePeriod } from '../types/timePeriod';

import { httpGetV1 } from 'utils/xhr';

export const fetchBusinessSales = async (
  period: TimePeriod,
): Promise<BusinessSales[]> => {
  if (!period) return Promise.reject(new Error('Period is required'));

  return httpGetV1('/businesses/me/analytics/sales', {
    params: {
      period: period.toLowerCase(),
    },
    classType: BusinessSales,
  })
    .then((response) => response.data);
};
