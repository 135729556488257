import { Expose, Type } from 'class-transformer';

import { ContactUser } from '../features/user/models';

import { OAuthBinding } from '../types/oauth';

import { Phone } from './Phone';

enum BusinessType {
  Restaurant = 'RESTAURANT',
  Supplier = 'SUPPLIER',
}

class OrganizationNotes {
  @Expose({ name: 'note' })
    note: string;
}

class OrganizationData {
  @Expose({ name: 'is_auto_created' })
    isAutoCreated: boolean;

  @Expose({ name: 'notes' })
  @Type(() => OrganizationNotes)
    notes: OrganizationNotes[];
}

class Business {
  @Expose({ name: 'id' })
    id: string;

  @Expose({ name: 'external_id' })
    externalId: string;

  @Expose({ name: 'name' })
    name: string;

  @Expose({ name: 'name_supplement' })
    nameSupplement?: string;

  @Expose({ name: 'short_name' })
    shortName?: string;

  @Expose({ name: 'phones' })
    phones: Phone[];

  @Expose({ name: 'email' })
    email: string;

  @Expose({ name: 'emails' })
    emails: string[];

  @Expose({ name: 'address' })
    address: string;

  @Expose({ name: 'type' })
    type: BusinessType;

  @Expose({ name: 'contact_users' })
    contactUsers: ContactUser[];

  @Expose({ name: 'created_at' })
    createdAt: string;

  @Expose({ name: 'created_by' })
    createdBy: string;

  @Expose({ name: 'external' })
    external: boolean;

  @Expose({ name: 'updated_at' })
    updatedAt: string;

  @Expose({ name: 'customer' })
  @Type(() => OrganizationData)
    customer?: OrganizationData;

  @Expose({ name: 'disabled' })
    disabled?: boolean;

  @Expose({ name: 'oauth_bindings' })
    oauthBindings: Map<string, OAuthBinding>;
}

type Organization = Business;

export { Business, BusinessType, Organization };
