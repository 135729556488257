import {
  useEffect, useState,
} from 'react';

import { Buffer } from 'buffer';

import {
  Message as MessageModel,
} from 'features/message/models/Message';
import { ParsedEmail } from 'models/Mail';
import { Workflow } from 'models/Workflow';

import { useThreadMessageSseSourceHandler } from 'features/thread/hooks';

import ThreadMessageAdamSection from './ThreadMessageAdamSection';
import ThreadMessageContent from './ThreadMessageContent';
import ThreadMessageHeader from './ThreadMessageHeader';
import ThreadMessageParticipantIcons from './ThreadMessageParticipantIcons';
import ThreadMessageToSection from './ThreadMessageToSection';

interface ThreadMessageProps {
  message: MessageModel;
  isExpanded: boolean;
  canToggleExpanded: boolean;
  senderName: string;
  senderBusinessName: string;
  recipientNames: string[];
  recipientBusinessName: string;
  time: {
    current: string;
    original: string;
    relative: string;
  };
  onToggle: () => void;
  onReply: () => void;
}

const ThreadMessage = ({
  message: initialMessage,
  isExpanded,
  canToggleExpanded,
  senderName,
  senderBusinessName,
  recipientNames,
  recipientBusinessName,
  time,
  onToggle,
  onReply,
}: ThreadMessageProps) => {
  const [message, setMessage] = useState<MessageModel>(initialMessage);

  useThreadMessageSseSourceHandler({
    messageId: message.id,
    setMessage,
  });

  const isAdamParticipating = Object
    .entries(message.state?.details?.workflowsByIntent || {})
    ?.filter(([workflow]) => workflow === Workflow.Order).length > 0;

  const [parsedMessage, setParsedMessage] = useState<ParsedEmail | null>(null);

  useEffect(() => {
    const parseMessage = async () => {
      if (message.smtpMessage) {
        const decodedRaw = Buffer.from(message.smtpMessage.raw, 'base64').toString();
        const decoded = JSON.parse(decodedRaw) as ParsedEmail;
        setParsedMessage(decoded);
      } else if (message.imapMessage) {
        const decodedRaw = Buffer.from(message.imapMessage.raw, 'base64').toString();
        const decoded = JSON.parse(decodedRaw) as ParsedEmail;
        setParsedMessage(decoded);
      }
    };

    parseMessage();
  }, [message]);

  return (
    <div
      role="rowheader"
      tabIndex={0}
      onKeyDown={canToggleExpanded && !isExpanded ? (e) => {
        if (e.key === 'Enter' || e.key === 'Space') {
          e.preventDefault();
          onToggle();
        }
      } : () => {}}
      onClick={canToggleExpanded && !isExpanded ? onToggle : () => {}}
      className={`px-4 py-3 hover:bg-gray-50 group ${canToggleExpanded && !isExpanded ? 'cursor-pointer' : ''}`}
    >
      <div className="flex items-start">
        <ThreadMessageParticipantIcons
          senderBusinessName={senderBusinessName}
          senderName={senderName}
          isAdamParticipating={isAdamParticipating}
        />

        <div className="flex-1 min-w-0">
          <ThreadMessageHeader
            message={message}
            time={time}
            senderBusinessName={senderBusinessName}
            senderName={senderName}
            canToggleExpanded={canToggleExpanded}
            isExpanded={isExpanded}
            onToggle={onToggle}
            onReply={onReply}
          />

          <ThreadMessageToSection
            recipientNames={recipientNames}
            recipientBusinessName={recipientBusinessName}
            parsedMessage={parsedMessage}
          />

          <ThreadMessageAdamSection
            message={message}
            isExpanded={isExpanded}
            isAdamParticipating={isAdamParticipating}
          />

          <ThreadMessageContent
            message={message}
            isAdamParticipating={isAdamParticipating}
            isExpanded={isExpanded}
            parsedMessage={parsedMessage}
          />
        </div>
      </div>
    </div>
  );
};

export default ThreadMessage;
