import { useMemo } from 'react';

import { useProcessOrderDraftsStore } from 'features/order/store/process-order-drafts';

export const useSelectedGroupOrder = () => {
  const groupOrders = useProcessOrderDraftsStore((state) => state.groupOrders);
  const selectedOrderId = useProcessOrderDraftsStore((state) => state.selectedOrderId);

  const order = useMemo(() => groupOrders[selectedOrderId], [groupOrders, selectedOrderId]);

  return {
    order,
    groupOrders,
    selectedOrderId,
  };
};
