import { infiniteQueryOptions } from '@tanstack/react-query';

import { fetchProducts, FetchProductsParams } from '../api';

export const productsOptions = (params?: FetchProductsParams) => infiniteQueryOptions({
  queryKey: ['products', params],
  queryFn: ({ pageParam = '' }) => fetchProducts({
    ...params,
    cursor: pageParam,
  }),
  initialPageParam: '',
  getNextPageParam: (lastPage) => lastPage.cursor || null,
  gcTime: 0,
  meta: {
    errorMessage: 'Error fetching products',
  },
});
