import { httpDelV1 } from 'utils/xhr';

export const deleteOrderDrafts = async (
  ids: string[],
): Promise<any> => {
  if (ids.length === 0) {
    return Promise.resolve();
  }

  return httpDelV1('/orders/drafts', {
    data: {
      ids,
    },
  });
};
