import { NavLink } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';

import { ROUTES } from 'config/routes';

import { SidebarIcon } from 'components/icon/outline';

import hoshiiHLogo from 'assets/logo/hoshii_h.svg';
import hoshiiLogo from 'assets/logo/hoshii_temp.svg';

interface Props {
  isOpen: boolean;
  toggleSidebar: () => void;
}

const SidebarHeader = ({ isOpen, toggleSidebar }: Props) => {
  if (!isOpen) {
    return (
      <div className="relative inline-flex flex-[0_0_auto] flex-col items-center gap-4">
        <NavLink to={ROUTES.HOME}>
          <img
            className="!relative !flex-[0_0_auto]"
            alt="Hoshii Logo"
            src={hoshiiHLogo}
          />
        </NavLink>

        <button type="button" onClick={toggleSidebar}>
          <SidebarIcon className="!h-5 !w-5 text-black-menu-80" />
        </button>
      </div>
    );
  }

  return (
    <div
      className={twMerge(
        'relative flex w-[139px] flex-[0_0_auto] items-center justify-between py-0 pl-2 pr-0',
        'desktop:w-[158px]',
      )}
    >
      <div className="relative inline-flex flex-[0_0_auto] flex-col items-start gap-3.5">
        <div className="relative flex h-[19.75px] w-full items-center self-stretch">
          <NavLink to={ROUTES.HOME}>
            <img
              className="relative h-[19.75px] w-[60.2px]"
              alt="Hoshii Logo"
              src={hoshiiLogo}
            />
          </NavLink>
        </div>
      </div>

      <button type="button" onClick={toggleSidebar}>
        <SidebarIcon className="!h-5 !w-5 text-black-menu-80" />
      </button>
    </div>
  );
};

export default SidebarHeader;
