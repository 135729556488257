import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { observer } from 'mobx-react-lite';
import { useSidebarStore } from 'stores';

import { ROUTES } from 'config/routes';

import { useSelectedGroupOrder } from '../components/process-order-draft_beta/main-content/right-side/hooks/useSelectedGroupOrder';

import { MainContent } from '../components/process-order-draft_beta';
import { Page } from 'components/layout/Page/Page';

import { useProcessOrderDraftsStore } from '../store/process-order-drafts';

const ProcessNewOrderDraftPage = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const messageId = searchParams.get('message_id');

  const { order } = useSelectedGroupOrder();

  const closeSidebar = useSidebarStore((state) => state.close);
  useEffect(() => {
    closeSidebar();
  }, [closeSidebar]);

  const createNewOrder = useProcessOrderDraftsStore((state) => state.createNewOrder);
  const resetOrderStore = useProcessOrderDraftsStore((state) => state.resetOrderStore);
  const resetMessageStore = useProcessOrderDraftsStore((state) => state.resetMessageStore);
  const loadMessageById = useProcessOrderDraftsStore((state) => state.loadMessageById);
  const setCustomOnOrderDraftProcessed = useProcessOrderDraftsStore((state) => state.setCustomOnOrderDraftProcessed);

  useEffect(() => {
    resetMessageStore();
    if (messageId) {
      loadMessageById(messageId);
    }
  }, [messageId, loadMessageById, resetMessageStore]);

  useEffect(() => {
    resetOrderStore();
    createNewOrder();
  }, [createNewOrder, resetOrderStore]);

  useEffect(() => {
    if (order?.id) {
      setCustomOnOrderDraftProcessed(() => navigate(ROUTES.ORDER_BY_ID(order.id)));
    } else {
      setCustomOnOrderDraftProcessed(() => navigate(ROUTES.ORDERS));
    }
  }, [navigate, setCustomOnOrderDraftProcessed, order?.id]);

  return (
    <Page
      pageTitle={t('order.processOrderDrafts.title')}
      contentWithBorder
    >
      <MainContent
        isNewOrderCreationPage
      />
    </Page>
  );
});

export default ProcessNewOrderDraftPage;
