import { useCallback, useEffect, useMemo } from 'react';

import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Group } from 'features/instruction/constants';

import { FieldSpec, Schema } from 'features/instruction/models';

import { useSelectedGroupOrder } from '../../../hooks/useSelectedGroupOrder';

import { Tooltip } from 'components/ui';

import { FieldPathWrapper } from './utils';
import { getFieldByPath, getValueByPath, inputTypeToUniversalFieldType } from 'features/instruction/utils';

import DynamicField from './DynamicField';
import StandardField from './StandardField';
import { useProcessOrderDraftsStore } from 'features/order/store/process-order-drafts';

interface Props {
  group: Group;
  schema: Schema;
  fieldSpec: FieldSpec;

  checkMatchStatus?: boolean;
}

const addToContext = (fn: CallableFunction, group: Group, fieldSpec: FieldSpec, orderId?: string) => (
  (text: string) => fn(group, fieldSpec.path, text, orderId));

const removeFromContext = (fn: CallableFunction, group: Group, fieldSpec: FieldSpec, orderId?: string) => (
  () => fn(group, fieldSpec.path, orderId));

const FieldRenderer = ({
  group, schema, fieldSpec, checkMatchStatus,
}: Props) => {
  const { selectedOrderId, order } = useSelectedGroupOrder();

  const updateValueByPath = useProcessOrderDraftsStore((state) => state.updateValueByPath);
  const addContextError = useProcessOrderDraftsStore((state) => state.addError);
  const removeContextError = useProcessOrderDraftsStore((state) => state.removeError);
  const addContextWarning = useProcessOrderDraftsStore((state) => state.addWarning);
  const removeContextWarning = useProcessOrderDraftsStore((state) => state.removeWarning);

  const field = getFieldByPath(schema?.fields, fieldSpec?.path);

  const type = inputTypeToUniversalFieldType(field?.inputType, field?.type);
  const label = fieldSpec?.name;
  const validation = fieldSpec?.validation;
  const trueValue = getValueByPath(order, fieldSpec.modelPath);
  const matchedValue = getValueByPath(order, fieldSpec.modelAutoMatchedPath);

  const value = checkMatchStatus
    ? trueValue // If checkMatchStatus is true, the value is always the value from the modelPath
    : trueValue ?? matchedValue;

  const showUnmatchedWarning = checkMatchStatus && matchedValue && trueValue !== matchedValue;

  const onValueChange = useCallback(
    async (_value: string | Date | number) => updateValueByPath(
      _value, fieldSpec.modelPath), [updateValueByPath, fieldSpec.modelPath]);

  const addError = useCallback((error: string) => addToContext(addContextError, group, fieldSpec)(error),
    [addContextError, group, fieldSpec]);

  const removeError = useCallback(() => removeFromContext(removeContextError, group, fieldSpec)(),
    [removeContextError, group, fieldSpec]);

  const rightSection = useMemo(() => {
    if (!showUnmatchedWarning) {
      return null;
    }

    return (
      <Tooltip
        label={(
          <div className="p-2 text-sm">
            <p className="text-warning-500 font-medium">Mismatch detected in email</p>
            {/* <p className="text-gray-600 text-xs">Adam has extracted a different value from the email</p> */}
            <p className="text-gray-600 text-xxs">Click icon to view extracted value</p>
            {/* TODO: refine this text */}
            <p className="text-gray-600 text-xxs">This is an optional warning. No action is required.</p>

            <div className="pt-2">
              <div className="text-gray-600">Current value:</div>
              <div className="p-1 bg-gray-100 rounded text-gray-700">{trueValue}</div>
            </div>

            <div className="pt-1">
              <div className="text-gray-600">Adam&apos;s extracted value:</div>
              <div className="p-1 bg-warning-50 border border-warning-200 rounded text-gray-700">{matchedValue}</div>
            </div>
          </div>
        )}
      >
        <FontAwesomeIcon icon={faTriangleExclamation} className="text-warning-500" />
      </Tooltip>
    );
  }, [matchedValue, showUnmatchedWarning, trueValue]);

  useEffect(() => {
    if (showUnmatchedWarning) {
      addContextWarning(group, fieldSpec.path, `Mismatch detected in ${label}`);
    } else {
      removeContextWarning(group, fieldSpec.path);
    }
  }, [showUnmatchedWarning, addContextWarning, removeContextWarning, group, fieldSpec.path, label]);

  if (!field || !fieldSpec) {
    return null;
  }

  if (field.isStandard) {
    return (
      <StandardField
        key={selectedOrderId} // To re-render when the order changes
        fieldSchema={field}
        type={type}
        label={label}
        value={value}
        validation={validation}
        order={order}
        onValueChange={onValueChange}
        addError={addError}
        removeError={removeError}
      />
    );
  }

  return (
    <DynamicField
      key={selectedOrderId} // To re-render when the order changes
      type={type}
      label={label}
      validation={validation}
      value={value}
      rightSection={rightSection}
      onValueChange={onValueChange}
      addError={addError}
      removeError={removeError}
    />
  );
};

export default FieldPathWrapper(FieldRenderer);
