import { AttachmentType } from 'features/message/models/Message';

import excel from 'assets/document-icons/excel.svg';
import generic from 'assets/document-icons/generic.svg';
import pdf from 'assets/document-icons/pdf.svg';
import phone2 from 'assets/document-icons/phone.svg';
import png from 'assets/document-icons/png.svg';
import word from 'assets/document-icons/word.svg';

export const getIconForAttachment = (attachmentType: AttachmentType) => {
  switch (attachmentType) {
    case AttachmentType.PDF:
      return pdf;
    case AttachmentType.EXCEL:
    case AttachmentType.LEGACY_EXCEL:
      return excel;
    case AttachmentType.AUDIO:
      return phone2;
    case AttachmentType.IMAGE:
      return png;
    case AttachmentType.WORD:
    case AttachmentType.LEGACY_WORD:
      return word;
    default:
      return generic;
  }
};
