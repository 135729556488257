import { ROUTES } from 'config/routes';

export const isActive = (pathname: string, href: string) => {
  // Check if the path is the same
  if (pathname === href) return true;

  // If the path is not home, and href is home, return false
  if (href === ROUTES.HOME) return false;

  return pathname.startsWith(href);
};
