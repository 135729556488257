import { Product } from '../models/Product';

import { QuerySearchType } from 'hooks/fetch/useFetchProducts';

import { parsePaginatedApiResponse } from 'utils';
import { httpGetV1 } from 'utils/xhr';

export type FetchProductsParams = {
  supplier_id?: string;
  category?: string;
  query?: string;
  include_order_count?: string;
  customer_id?: string;

  query_search_type?: QuerySearchType;
  only_enabled?: boolean;
  num_candidates?: number;

  sorting_columns?: string[];
  cursor?: string | null;
  limit?: number;
};

type FetchProductsResponse = {
  result: Product[],
  cursor: string | null;
};

export const fetchProducts = async (params: FetchProductsParams): Promise<FetchProductsResponse> => httpGetV1('/products', { params })
  .then((response) => parsePaginatedApiResponse<Product>(response));
