import { SMTPMessage } from 'models/Mail';

import EmailBase from './EmailBase';

interface InlineEmailPanelProps {
  onDiscard: () => void;

  subject?: string;
  onSetSubject?: (subject: string) => void;
  fromValues: string[];
  from: string;
  onSetFrom?: (from: string) => void;
  to?: string[];
  onSetTo?: (to: string[]) => void;
  cc?: string[];
  onSetCc?: (cc: string[]) => void;
  bcc?: string[];
  onSetBcc?: (bcc: string[]) => void;

  onSetSmtpMessage?: (smtpMessage: SMTPMessage) => void;
  onSendSmtpMessage: (smtpMessage: SMTPMessage) => void;
}

const InlineEmailPanel = (props: InlineEmailPanelProps) => (
  <div className="pt-2 px-10">
    <div className="shadow-lg border rounded-xl border-gray-200">
      <EmailBase
        className="w-full"
        {...props}
      />
    </div>
  </div>
);

export default InlineEmailPanel;
