import { Expose, Type } from 'class-transformer';

import {
  MessageChannel, MessageRefAction, MessageRefType, MessageType,
} from 'features/message/models/Message';
import { User } from 'features/user/models';
import { Business } from 'models/Business';

class Chat {
  @Expose({ name: 'id' })
    id: string;

  @Expose({ name: 'business' })
    business: Business;

  @Expose({ name: 'title' })
    title?: string;

  @Expose({ name: 'user' })
  @Type(() => User)
    user?: User;

  @Expose({ name: 'last_message_text' })
    lastMessage: string;

  @Expose({ name: 'last_message_created_at' })
    lastMessageCreatedAt: string;

  @Expose({ name: 'last_message_ref_action' })
  @Type(() => MessageRefAction)
    lastMessageRefAction: MessageRefAction;

  @Expose({ name: 'last_message_ref_type' })
    lastMessageRefType: MessageRefType;

  @Expose({ name: 'last_message_type' })
    lastMessageType: MessageType;

  @Expose({ name: 'last_message_source' })
    lastMessageSource: MessageChannel;
}

export { Chat };
