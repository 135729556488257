import { ReactNode } from 'react';

import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
} from '@heroicons/react/24/outline';

import { Button } from '../ui/Button';

interface Props {
  children: ReactNode;
  stepCriteria?: boolean;
  isButtonLoading?: boolean;
  onStepClicked?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onStepBackClicked?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onConfirmClicked?: () => void;
}

const Wrapper = ({
  children,
  stepCriteria,
  isButtonLoading,
  onStepClicked,
  onStepBackClicked,
  onConfirmClicked,
}: Props) => (
  <div
    className="border-light-border-primary flex max-h-[calc(100vh-80px)] min-h-[calc(100vh-80px)] flex-col overflow-auto rounded-[16px] border border-solid p-lg"
    style={{ height: 'calc(100vh - 80px)' }}
  >
    <div className="flex-grow">{children}</div>
    <div className="mt-10 flex justify-end divide-y divide-gray-100 border-t border-gray-200">
      <div className="flex w-full justify-between">
        {onStepBackClicked ? (
          <div
            role="button"
            tabIndex={0}
            className=""
            onClick={onStepBackClicked}
            onKeyDown={() => {}}
          >
            <ArrowLeftCircleIcon className="mt-4 aspect-square w-11 text-primary-500 hover:text-primary-600" />
          </div>
        ) : null}
      </div>

      {onStepClicked ? (
        <div
          role="button"
          tabIndex={0}
          className=""
          onClick={onStepClicked}
          onKeyDown={() => {}}
        >
          <ArrowRightCircleIcon
            className={`mt-4 aspect-square w-11 ${stepCriteria ? 'text-primary-500 hover:text-primary-600' : 'text-blue-gray-50'}`}
          />
        </div>
      ) : null}

      {onConfirmClicked ? (
        <Button
          title="Upload"
          onClick={onConfirmClicked}
          disabled={isButtonLoading}
        />
      ) : null}
    </div>
  </div>
);

export { Wrapper };
