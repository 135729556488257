import { LeftSide, RightSide } from '../components/chat-with-adam';
import { Page } from 'components/layout/Page/Page';

interface Props {
  chatId: string;
}

const ChatWithAdamPage = ({ chatId }: Props) => (
  <Page
    pageTitle="Chat with Adam"
    pageDescription="You can ask Adam anything about your orders, products, customers, and more."
    secondarySidebar={(
      <div className="w-[253px] 4xl:w-[300px] h-full overflow-x-hidden overflow-y-auto bg-[#1e3d7a0f]">
        <LeftSide chatId={chatId} />
      </div>
      )}
  >
    <div className="flex h-full w-full">
      <div className="flex-1 h-full overflow-x-hidden overflow-y-auto">
        <RightSide chatId={chatId} />
      </div>
    </div>
  </Page>
);

export default ChatWithAdamPage;
