import { useTranslation } from 'react-i18next';

import FocusMode from './components/FocusMode';
import LoadingOverlay from 'components/ui/LoadingOverlay';
import { ManageSubjectSidebarWrapper, WorkflowRetrySidebarWrapper } from 'components/wrapper/';

import { isZeroId } from 'utils/objectId';

import { useProcessOrderDraftsStore } from 'features/order/store/process-order-drafts';

interface Props {
  workflowRunId: string;
  setWorkflowRetrySidebarOpen?: (isSidebarOpen: boolean) => void;
  setManageSubjectSidebarOpen?: (isSidebarOpen: boolean) => void;

  isNewOrderCreationPage?: boolean;
}

const LeftSide = ({
  workflowRunId,
  setWorkflowRetrySidebarOpen, setManageSubjectSidebarOpen,
  isNewOrderCreationPage,
}: Props) => {
  const { t } = useTranslation();

  const isMessageLoading = useProcessOrderDraftsStore((state) => state.isMessageLoading);
  const message = useProcessOrderDraftsStore((state) => state.message);

  if (isNewOrderCreationPage && !isMessageLoading && !message) {
    return <div className="flex-1 overflow-hidden border-r border-blue-gray-50 relative" />;
  }

  if (isMessageLoading) {
    return (
      <div className="flex-1 overflow-hidden border-r border-blue-gray-50 relative">
        <LoadingOverlay visible displayText={t('loading.message')} />
      </div>
    );
  }

  return (
    <div className="flex-1 overflow-hidden border-r border-blue-gray-50">
      <div className="h-full w-full flex flex-col overflow-hidden relative">
        {!isZeroId(workflowRunId) && (
          <div className="relative w-full bg-white border-b flex py-1 px-lg text-sm">
            <p className="text-blue-gray-200 px-lg">
              {t('order.processOrderDrafts.leftSide.retry.description')}
              <button
                type="button"
                className="text-primary-500 pl-sm"
                onClick={() => setWorkflowRetrySidebarOpen?.(true)}
              >
                {t('order.processOrderDrafts.leftSide.retry')}
              </button>
            </p>
          </div>
        )}

        {
            !isMessageLoading && message && (
              <FocusMode
                onManageSubjectButtonClick={() => setManageSubjectSidebarOpen?.(true)}
              />
            )
          }

        {
          !isMessageLoading && !message && (
            <div className="flex justify-center items-center h-full">
              {t('order.processOrderDrafts.leftSide.noMessagesFound')}
            </div>
          )
        }
      </div>
    </div>
  );
};

export default ManageSubjectSidebarWrapper(
  WorkflowRetrySidebarWrapper(LeftSide));
