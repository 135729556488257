import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { PlusIcon } from '@heroicons/react/20/solid';
import { HoverCard } from '@mantine/core';
import { useGoogleLogin } from '@react-oauth/google';

import { AlertTheme } from 'state/classes/AlertDataList';
import { globalAlertDataList } from 'state/globalAlertDataList';
import { globalUser } from 'state/globalUser';

import { BusinessSector, BusinessSettings } from 'features/settings/models';

import {
  OAuthAppState,
  OAuthBinding,
  OAuthFlow,
  OAuthProvider,
} from 'types/oauth';

import { useFetchTranscriptionLanguages } from 'hooks/fetch/useFetchTranscriptionLanguages';

import { Card } from 'components/common/Card';
import { Button } from 'components/ui/Button';

import standardEmail from 'assets/email.svg';
import google from 'assets/logo/google.svg';
import outlook from 'assets/logo/outlook.svg';

import { decimalCommaToValue } from 'features/settings/utils';
import { genericErrorFeedback } from 'utils/errors';

import Select from './ui/Select/Select';
import SubsectionTitle from './ui/SubsectionTitle';
import TimeFrame from './ui/TimeFrame/TimeFrame';

const getEmailIcon = (email: string) => {
  if (email.includes('gmail.com')) return google;
  if (email.includes('outlook.com')) return outlook;
  return standardEmail;
};

const getOAuthProviderIcon = (provider: OAuthProvider) => {
  switch (provider) {
    case OAuthProvider.GOOGLE:
      return google;
    case OAuthProvider.MICROSOFT:
      return outlook;
    default:
      return standardEmail;
  }
};

interface Props {
  businessSettings: BusinessSettings;
  onBusinessTimesTimeChange: (index: number, from: string, to: string) => void;
  onRemoveBusinessTimesIntervalButtonClick: (i: number) => void;
  onAddBusinessTimesIntervalButtonClick: () => void;
  onBusinessSectorChange: (value: BusinessSector) => void;
  onDefaultCurrencyChange: (value: string) => void;
  onDecimalCommaChange: (value: string) => void;
  onDefaultTranscriptionLanguageChange: (value: string) => void;
  onSaveButtonClick: () => void;
  isLoading: boolean;
}

const BasicSettings = ({
  businessSettings,
  onBusinessTimesTimeChange,
  onRemoveBusinessTimesIntervalButtonClick,
  onAddBusinessTimesIntervalButtonClick,
  onDefaultCurrencyChange,
  onBusinessSectorChange,
  onDecimalCommaChange,
  onDefaultTranscriptionLanguageChange,
  onSaveButtonClick,
  isLoading,
}: Props) => {
  const { t } = useTranslation();

  const { transcriptionLanguages } = useFetchTranscriptionLanguages({ preventInitialFetch: false });

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const flow = searchParams.get('flow') as OAuthFlow;
    const binding = JSON.parse(
      atob(searchParams.get('binding') ?? '') || '{}',
    ) as OAuthBinding;

    let needsRefresh = false;
    switch (flow) {
      case OAuthFlow.BUSINESS_EMAIL_CONNECTION:
        if (binding.email) {
          globalAlertDataList.create(
            `Email ${binding.email} connected successfully`,
            AlertTheme.SUCCESS,
          );
        }
        needsRefresh = true;
        break;
      default:
        return;
    }

    searchParams.delete('flow');
    searchParams.delete('binding');
    searchParams.delete('user');
    window.history.replaceState({}, '', `?${searchParams.toString()}`);

    if (needsRefresh) {
      globalUser
        .refresh()
        .catch((err) => genericErrorFeedback(err.error_description));
    }
  }, [searchParams]);

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      const appState: OAuthAppState = {
        origin_uri: window.location.href,
        flow: OAuthFlow.BUSINESS_EMAIL_CONNECTION,
      };
      const appStateB64 = btoa(JSON.stringify(appState));

      const redirectSearchParams = new URLSearchParams();
      redirectSearchParams.append('code', codeResponse.code);
      redirectSearchParams.append('app_state', appStateB64);

      window.location.href = `${HOSHII_API_URL}/v1/auth/google?${redirectSearchParams.toString()}`;
    },
    onError: (err) => genericErrorFeedback('Error connecting to Google')(err),
    onNonOAuthError: (err) => genericErrorFeedback('Error connecting to Google')(err),
    flow: 'auth-code',
    select_account: true,
  });

  return (
    <Card id="basic_settings" className="">
      <Card.Header>{t('settings.card.basicSettings.title')}</Card.Header>
      <Card.Body className="text-sm text-gray-700">
        {/* Business time */}
        {businessSettings?.businessTimes?.map((bt, i) => (
          <span key={`bt-${i.toString()}`} className="text-sm text-gray-700">
            <TimeFrame
              label={i === 0 ? t('settings.card.basicSettings.businessTimes') : ''}
              from={bt.from}
              to={bt.to}
              onChange={(from, to) => onBusinessTimesTimeChange(i, from, to)}
              isLoading={isLoading}
              onRemove={() => onRemoveBusinessTimesIntervalButtonClick(i)}
            />
          </span>
        ))}
        {!businessSettings?.businessTimes
        || businessSettings?.businessTimes?.length < 21 ? (
          <div className="flex justify-end">
            <Button
              icon={<PlusIcon className="h-4 w-4" />}
              // title="Add"
              onClick={onAddBusinessTimesIntervalButtonClick}
              theme="secondary"
              variant="small"
              disabled={isLoading}
            />
          </div>
          ) : null}

        <Select
          label={t('settings.card.basicSettings.businessSector')}
          value={businessSettings?.sector}
          values={Object.values(BusinessSector)}
          readOnly={false}
          disabled={isLoading}
          onChange={onBusinessSectorChange}
        />

        <Select
          label={t('settings.card.basicSettings.defaultCurrency')}
          value={businessSettings?.defaultCurrency}
          values={['CHF', 'EUR']}
          readOnly={false}
          disabled={isLoading}
          onChange={onDefaultCurrencyChange}
        />

        <Select
          label={t('settings.card.basicSettings.decimalComma')}
          value={decimalCommaToValue(businessSettings?.decimalComma)}
          values={['Dot (0.1)', 'Comma (0,1)']}
          readOnly={false}
          disabled={isLoading}
          fitWidth
          onChange={onDecimalCommaChange}
        />

        <Select
          label={t('settings.card.basicSettings.defaultTranscriptionLanguage')}
          value={transcriptionLanguages?.get(businessSettings?.defaultTranscriptionLanguageCode)?.nativeName}
          values={Array.from(transcriptionLanguages?.values() ?? []).map((l) => l.nativeName)}
          readOnly={false}
          disabled={isLoading}
          onChange={(value) => {
            const language = Array.from(transcriptionLanguages?.values() ?? []).find((l) => l.nativeName === value);
            onDefaultTranscriptionLanguageChange(language?.code ?? '');
          }}
        />

        <SubsectionTitle
          title={t('settings.card.basicSettings.connectedEmails')}
          subtitle={t('settings.card.basicSettings.connectedEmails.subtitle')}
        />
        <div className="flex flex-col gap-4">
          {globalUser.business?.emails?.map((email, i) => {
            const oauthBindings = Array.from(new Map(Object.entries(globalUser.business?.oauthBindings ?? {})).values());
            const oauthBinding = Array.from(oauthBindings.values()).find(
              (b) => b.email === email,
            );
            return (
              <div key={`email-${i.toString()}`} className="flex space-x-4">
                <img
                  className="relative h-5 w-5"
                  src={
                    oauthBinding
                      ? getOAuthProviderIcon(oauthBinding.provider)
                      : getEmailIcon(email)
                  }
                  alt=""
                />
                <div className={`text-sm ${oauthBinding ? 'font-bold' : ''}`}>
                  {email}
                </div>
              </div>
            );
          })}

          <div className="justify-start">
            <HoverCard
              shadow="md"
              position="bottom"
              openDelay={10}
              transitionProps={{ transition: 'scale-y', duration: 100 }}
            >
              <HoverCard.Target>
                <Button
                  theme="secondary"
                  variant="small"
                  icon={<PlusIcon className="h-4 w-4" />}
                  title="Connect email"
                  onClick={() => true}
                />
              </HoverCard.Target>
              <HoverCard.Dropdown p={0}>
                <Button
                  title="Gmail"
                  icon={(
                    <img
                      className="relative h-5 w-5"
                      src={getOAuthProviderIcon(OAuthProvider.GOOGLE)}
                      alt=""
                    />
                  )}
                  theme="secondary"
                  radius="sm"
                  className="w-full text-center font-semibold hover:bg-light-gray-50"
                  onClick={googleLogin}
                />
                <Button
                  title="Outlook"
                  icon={(
                    <img
                      className="relative h-5 w-5"
                      src={getOAuthProviderIcon(OAuthProvider.MICROSOFT)}
                      alt=""
                    />
                  )}
                  theme="secondary"
                  radius="sm"
                  className="w-full text-center font-semibold hover:bg-light-gray-50"
                  disabled
                  onClick={() => false}
                />
              </HoverCard.Dropdown>
            </HoverCard>
          </div>
        </div>

        <div className="flex justify-end">
          <Button
            title={t('settings.card.basicSettings.saveButton')}
            onClick={() => onSaveButtonClick()}
            disabled={isLoading}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

export default BasicSettings;
