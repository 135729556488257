import {
  useCallback, useEffect, useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { LoadingOverlay } from '@mantine/core';

import { ROUTES } from 'config/routes';

import { MessageIntent } from 'features/message/models/Message';
import { Thread } from 'models/Thread';

import { FetchThreadsProps } from 'features/inbox/apis';

import { useMarkThreadsAsRead } from 'hooks/useMarkThreadsAsRead';
import { useMarkThreadsAsUnread } from 'hooks/useMarkThreadsAsUnread';

import { isZeroTime, zeroTime } from 'utils/dateTime';

import TableHeader from './TableHeader';
import TableRow from './TableRow';

interface Props {
  threads: Thread[];
  setThreads: (threads: Thread[]) => void;
  loadMoreThreads: () => void;
  hasNextPage: boolean;
  isThreadsLoading: boolean;
  threadsFilter: FetchThreadsProps;
  setThreadsFilter: (threadsFilter: FetchThreadsProps) => void;
  reload: () => void;
}

const ListTable = ({
  threads,
  setThreads,
  isThreadsLoading,
  hasNextPage,
  loadMoreThreads,
  threadsFilter,
  setThreadsFilter,
  reload,
}: Props) => {
  const navigate = useNavigate();

  const {
    markThreadsAsRead: _markThreadsAsRead,
    isLoading: isMarkThreadsAsReadLoading,
  } = useMarkThreadsAsRead();

  const {
    markThreadsAsUnread: _markThreadsAsUnread,
    isLoading: isMarkThreadsAsUnreadLoading,
  } = useMarkThreadsAsUnread();

  const [allSelected, setAllSelected] = useState(false);
  const [selectedThreads, setSelectedThreads] = useState<Thread[]>([]);

  const toggleAll = useCallback(() => {
    setSelectedThreads((prev) => (prev.length > 0 ? [] : threads));
    setAllSelected((prev) => !prev);
  }, [threads]);

  const onThreadRowClick = useCallback(
    (thread: Thread, index: number) => {
      navigate(ROUTES.THREAD_BY_ID(thread.id), {
        state: {
          threads,
          currentIndex: index,
        },
      });
    },
    [navigate, threads],
  );

  const markThreadsAsRead = (threadsToMarkAsRead: Thread[]) => {
    if (threadsToMarkAsRead.length === 0) {
      return;
    }

    _markThreadsAsRead(threadsToMarkAsRead.map((t) => t.id)).then(() => {
      if (threadsFilter.unread_only) {
        setThreads(threads.filter((t) => !selectedThreads.includes(t)),
        );
      } else {
        setThreads(threads.map((t) => ({
          ...t,
          readAt:
              selectedThreads.map((st) => st.id).includes(t.id)
                ? new Date().toISOString()
                : t.readAt,
        })),
        );
      }
      setSelectedThreads([]);
    });
  };

  const markThreadsAsUnread = (threadsToMarkAsUnread: Thread[]) => {
    if (threadsToMarkAsUnread.length === 0) {
      return;
    }

    _markThreadsAsUnread(threadsToMarkAsUnread.map((t) => t.id)).then(() => {
      setThreads(threads.map((t) => ({
        ...t,
        readAt: selectedThreads.map((st) => st.id).includes(t.id) ? zeroTime : t.readAt,
      })));
      setSelectedThreads([]);
    });
  };

  const toggleThreadsReadStatus = () => {
    markThreadsAsRead(selectedThreads.filter((t) => isZeroTime(t.readAt)));
    markThreadsAsUnread(selectedThreads.filter((t) => !isZeroTime(t.readAt)));
  };

  const lastDivRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          loadMoreThreads();
        }
      },
      { threshold: 0.5 },
    );

    const currentLastDiv = lastDivRef.current;
    if (currentLastDiv) {
      observer.observe(currentLastDiv);
    }

    return () => {
      if (currentLastDiv) {
        observer.unobserve(currentLastDiv);
      }
    };
  }, [hasNextPage, loadMoreThreads]);

  return (
    <div className="flex h-full w-full flex-col">
      <div className="relative">
        {(isMarkThreadsAsReadLoading || isMarkThreadsAsUnreadLoading) && (
        <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-white/50">
          <LoadingOverlay
            visible={isMarkThreadsAsReadLoading || isMarkThreadsAsUnreadLoading}
            loaderProps={{ type: 'dots' }}
            overlayProps={{ blur: 2 }}
          />
        </div>
        )}
        <TableHeader
          allSelected={allSelected}
          toggleAll={toggleAll}
          reload={reload}
          toggleThreadsReadStatus={toggleThreadsReadStatus}
          selectedThreads={selectedThreads}
          threadsFilter={threadsFilter}
          setThreadsFilter={setThreadsFilter}
        />
      </div>
      <div className="flex-1 overflow-y-auto">
        {
          !isThreadsLoading && threads.length === 0 && (
            <div className="flex h-full w-full pt-10 items-center justify-center">
              No threads found
            </div>
          )
        }
        <table className="min-w-full divide-y divide-gray-300">
          <tbody className="divide-y divide-gray-200 bg-white">
            {threads.map((thread, index) => (
              <TableRow
                key={thread.id}
                thread={thread}
                selectedThreads={selectedThreads}
                setSelectedThreads={setSelectedThreads}
                onThreadRowClick={() => onThreadRowClick(thread, index)}
                priorityIntent={threadsFilter.intents?.[0] || MessageIntent.ORDER}
              />
            ))}
          </tbody>
        </table>

        <div ref={lastDivRef} />

        <div className="relative flex h-10 w-full items-center justify-center">
          <LoadingOverlay
            visible={isThreadsLoading}
            loaderProps={{ type: 'dots' }}
            overlayProps={{ blur: 2 }}
          />
        </div>
      </div>
    </div>
  );
};

export default ListTable;
