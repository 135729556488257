import { queryOptions } from '@tanstack/react-query';

import { fetchChatById } from '../apis';

export const chatByIdOptions = (chatId: string) => queryOptions({
  queryKey: ['chatById', chatId],
  queryFn: () => fetchChatById(chatId),
  meta: {
    errorMessage: 'Error fetching chat',
  },
});
