import { Popover as MantinePopover, PopoverProps } from '@mantine/core';

interface Props extends PopoverProps {
  children: React.ReactNode;
}

const Popover = ({ children, ...props }: Props) => (
  <MantinePopover
    {...props}
    withArrow
    arrowSize={15}
    styles={{
      dropdown: {
        border: '1px solid var(--violet-hue-selection-button-4)',
        boxShadow: 'var(--blue-light-tint-drop-shadow)',
      },
      arrow: {
        borderTop: '1px solid var(--violet-hue-selection-button-4)',
        borderLeft: '1px solid var(--violet-hue-selection-button-4)',
      },
    }}
    offset={3}
  >
    {children}
  </MantinePopover>
);

Popover.Target = MantinePopover.Target;
Popover.Dropdown = MantinePopover.Dropdown;

export default Popover;
