import { infiniteQueryOptions } from '@tanstack/react-query';

import { fetchThreads, FetchThreadsProps } from '../apis';

export const threadsOptions = (inboxId: string, params?: FetchThreadsProps) => infiniteQueryOptions({
  queryKey: ['threads', params, inboxId],
  queryFn: ({ pageParam = '' }) => fetchThreads({
    ...params,
    inbox_id: inboxId,
    cursor: pageParam,
  }),
  initialPageParam: '',
  getNextPageParam: (lastPage) => lastPage.cursor || null,
  enabled: !!inboxId,
  gcTime: 0,
  meta: {
    errorMessage: 'Error fetching threads',
  },
});
