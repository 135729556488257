// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EnjBENcjk568wQjbRz2Q {\n    border: none;\n}\n\n.WVFVWLhNddkVck6bnUSX {\n    border-radius: 5px;\n}\n\n.WVFVWLhNddkVck6bnUSX:hover {\n        background-color: var(--light-blue-hue-box);\n    }", "",{"version":3,"sources":["webpack://./src/features/thread/components/threads/ThreadMessage/Accordion.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;AAItB;;AAHI;QACI,2CAA2C;IAC/C","sourcesContent":[".item {\n    border: none;\n}\n\n.control {\n    border-radius: 5px;\n    &:hover {\n        background-color: var(--light-blue-hue-box);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "EnjBENcjk568wQjbRz2Q",
	"control": "WVFVWLhNddkVck6bnUSX"
};
export default ___CSS_LOADER_EXPORT___;
