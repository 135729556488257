import { useNavigate } from 'react-router-dom';

import { reaction } from 'mobx';
import { observer } from 'mobx-react-lite';

import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';

import { ROUTES } from 'config/routes';

import { globalOrderDrafts } from 'state/globalOrderDrafts';

import { useProcessOrderDraftsStore } from 'features/order/store/process-order-drafts';

const Navigator = observer(() => {
  const navigate = useNavigate();

  const currentIndex = useProcessOrderDraftsStore((state) => state.currentIndex);
  const selectPrevOrderDraft = useProcessOrderDraftsStore((state) => state.selectPrevOrderDraft);
  const selectNextOrderDraft = useProcessOrderDraftsStore((state) => state.selectNextOrderDraft);
  const globalOrders = useProcessOrderDraftsStore((state) => state.globalOrders);
  const isOrderDraftsLoading = useProcessOrderDraftsStore((state) => state.isOrderDraftListLoading);

  const listLength = globalOrderDrafts.count || globalOrders.length;

  reaction(
    () => globalOrderDrafts.count,
    (count) => {
      if (count === 0) {
        navigate(ROUTES.HOME);
      }
    },
  );

  return (
    <div
      className="flex h-fit items-center justify-center gap-lg border-b border-solid bg-white py-sm w-full"
    >
      {
        isOrderDraftsLoading ? (
          <div>Loading...</div>
        ) : (
          <>
            <button type="button" onClick={selectPrevOrderDraft}>
              <ArrowLeftIcon height={16} width={16} />
            </button>
            <div>{`${currentIndex + 1} of ${listLength}`}</div>
            <button type="button" onClick={selectNextOrderDraft}>
              <ArrowRightIcon height={16} width={16} />
            </button>
          </>
        )
      }
    </div>
  );
});

export default Navigator;
