import { AxiosError } from 'axios';
import {
  action, makeObservable, observable, runInAction,
} from 'mobx';
import { makePersistable } from 'mobx-persist-store';

import { globalAlertDataList } from 'state/globalAlertDataList';

import { TOKEN_EXPIRY_ALERT_ID } from 'constants/alertTypes';

import {
  newUserRolesDagFromCsv, UserRole,
} from 'features/user/models';
import { Business } from 'models/Business';

import {
  httpGetBlobV1, httpGetV1, httpPostV1, httpRequestV1,
} from 'utils/xhr';

import { AlertTheme } from './AlertDataList';

type UserSigninParams = {
  username: string;
  password: string;
};

type UserSimplifiedSigninParams = {
  phone: string;
  totpCode: string;
  username?: string;
};

type UserInvitedSignupParams = {
  inviteAppLink: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  phone: string;
  totpCode: string;
};

class User {
  id?: string | null = null;

  business?: Business | null = null;

  firstName?: string | null = null;

  lastName?: string | null = null;

  email?: string | null = null;

  phone?: string | null = null;

  roles?: UserRole[] = [];

  isTokenRefreshing: boolean = false;

  isSignedIn: boolean = false;

  isSimplifiedAccess: boolean = false;

  activated: boolean = true;

  rolesText?: string | null = null;

  constructor() {
    makeObservable(this, {
      id: observable,
      business: observable,
      firstName: observable,
      lastName: observable,
      email: observable,
      roles: observable,
      isSignedIn: observable,
      isSimplifiedAccess: observable,
      activated: observable,
      rolesText: observable,
      signIn: action,
      signOut: action,
      setUserActivation: action,
    });
  }

  initPersistence = async () => {
    await makePersistable(this, {
      name: 'user',
      properties: [
        'id',
        'business',
        'firstName',
        'lastName',
        'email',
        'roles',
        'isSignedIn',
        'isSimplifiedAccess',
        'activated',
        'rolesText',
      ],
      storage: window.localStorage,
    });
  };

  hasRole = (targetRole: UserRole) => {
    if ((this.roles || []).includes(targetRole)) {
      return true;
    }

    const dag = newUserRolesDagFromCsv(this.rolesText);

    return this.roles?.some((role: UserRole) => dag.canReachFrom(role, targetRole));
  };

  refresh = async () => {
    await httpGetV1('/users/me')
      .then((response) => {
        runInAction(() => {
          this.id = response.data.id;
          this.business = response.data.business;
          this.firstName = response.data.firstName;
          this.lastName = response.data.lastName;
          this.email = response.data.email;
          this.roles = response.data.roles;
        });
      })
      .catch((error) => {
        console.error('Error refreshing user', error);
        return Promise.reject(error);
      });
  };

  signIn = async (params: UserSigninParams) => {
    const signInResponse = await httpPostV1('/auth/signin', params, {
      baseURL: `${HOSHII_API_URL}/v2`,
    });
    runInAction(() => {
      this.id = signInResponse.data.id;
      this.business = signInResponse.data.business;
      this.firstName = signInResponse.data.firstName;
      this.lastName = signInResponse.data.lastName;
      this.email = signInResponse.data.email;
      this.roles = signInResponse.data.roles;
      this.isSignedIn = true;
      this.activated = true;
    });

    await httpGetBlobV1('/auth/roles/hierarchy')
      .then(async (response) => {
        const rolesText = await response.data.text() as string;
        if (!rolesText) {
          throw new Error('No roles or malformed response found');
        }

        runInAction(() => {
          this.rolesText = rolesText;
        });
      })
      .catch((error) => {
        console.error('Error fetching role hierarchy', error);
        return Promise.reject(error);
      });
  };

  invitedSignup = async (params: UserInvitedSignupParams) => {
    const response = await httpPostV1(
      `/auth/signup/${params.inviteAppLink}`,
      params,
      { baseURL: `${HOSHII_API_URL}/v2` },
    );
    runInAction(() => {
      this.id = response.data.id;
      this.business = response.data.business;
      this.firstName = response.data.firstName;
      this.lastName = response.data.lastName;
      this.email = response.data.email;
      this.roles = response.data.roles;
      this.isSignedIn = true;
      this.activated = true;
    });
  };

  setUserActivation = (activated: boolean) => {
    this.activated = activated;
  };

  static refreshTokens = async () => httpPostV1('/auth/tokens/refresh');

  signOut = async () => {
    if (!this.isSignedIn) return Promise.resolve();

    runInAction(() => {
      this.id = null;
      this.business = null;
      this.firstName = null;
      this.lastName = null;
      this.email = null;
      this.isSignedIn = false;
      this.isSimplifiedAccess = false;
      this.activated = true;
    });
    return httpPostV1('/auth/signout');
  };

  blockNotActivatedUser = async (error: AxiosError) => {
    // TODO: Custom hoshii error format type
    // @ts-ignore
    if (error?.response?.data?.code === 1) {
      runInAction(() => {
        this.activated = false;
      });
      return new Promise(() => {});
    }
    throw error;
  };

  refreshTokenIf401 = async (error: AxiosError) => {
    if (this.isTokenRefreshing) {
      return Promise.reject(error);
    }

    if (
      error?.response?.status === 401
      // TODO: Replace url check with custom backend error code
      && !error.response?.request?.responseURL?.includes('/auth/tokens/refresh')
    ) {
      runInAction(() => {
        this.isTokenRefreshing = true;
      });

      try {
        await User.refreshTokens();

        return await httpRequestV1(error.response.config!);
      } catch (refreshError: unknown) {
        this.signOut();

        globalAlertDataList.create('Login expired', AlertTheme.ERROR, 'The current login session has expired. Please sign in again.', TOKEN_EXPIRY_ALERT_ID);

        throw new Error('Login expired');
      } finally {
        runInAction(() => {
          this.isTokenRefreshing = false;
        });
      }
    } else {
      throw error;
    }
  };
}

export { User, UserSigninParams, UserSimplifiedSigninParams };
