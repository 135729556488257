import { useMemo } from 'react';

import { Order } from '../../../../../../features/order/models';
import { User } from '../../../../../../features/user/models';

import { AssignedStatus } from '../../../../../../features/order/types/order';

import { useTeamMemnersContext } from '../../../../../../contexts/useTeamMembersContext';

import { getAssignedStatus } from '../../../../../../features/order/utils/order';
import {
  getTagTitle_temp,
  getTagVariant,
} from '../../../../../../utils/enums';

import { Tag } from '../../../../../common/Tag';

interface Props {
  order: Order;
  isOrderLoading: boolean;
}

const OrderTags = ({ order, isOrderLoading }: Props) => {
  const { teamMembers } = useTeamMemnersContext();

  const tagTitle = useMemo(() => {
    if (order) {
      return getTagTitle_temp(
        order.status,
        getAssignedStatus(order.assigneeId) !== AssignedStatus.UNASSIGNED,
      );
    }

    return '';
  }, [order]);

  const teamMemberName: {
    initial?: string;
    fullName?: string;
    status?: string;
  } = useMemo(() => {
    if (!teamMembers || !order || isOrderLoading || !order?.assigneeId) return null;

    const assignedStatus = getAssignedStatus(order.assigneeId);

    let member: User = null;
    switch (assignedStatus) {
      case AssignedStatus.OTHER:
        member = teamMembers.find((_member) => _member.id === order.assigneeId);
        return member
          ? {
            initial: member.firstName.substring(0, 2),
            fullName: `${member.firstName} ${member.lastName}`,
          }
          : { status: 'Unassigned', fullName: 'Unassigned' };
      case AssignedStatus.ME:
        return { initial: 'Me', fullName: 'Me' };
      default:
        return { status: 'Unassigned', fullName: 'Unassigned' };
    }
  }, [teamMembers, order, isOrderLoading]);
  return (
    <div className="absolute -top-[30px] right-0 w-fit">
      <div className="group flex w-full items-baseline space-x-2">
        <Tag
          className="static"
          isTagLoading={isOrderLoading}
          tagTitle={tagTitle}
          tagVariant={getTagVariant(tagTitle)}
          hoverText={
            teamMemberName?.fullName
              ? `Assignee: ${teamMemberName?.fullName}`
              : null
          }
        />
      </div>
    </div>
  );
};

export default OrderTags;
