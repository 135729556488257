import { forwardRef, Ref, SVGProps } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props extends SVGProps<SVGSVGElement> {
  className?: string;
}

const MailLetterIcon = ({ className, ...props }: Props, ref: Ref<SVGSVGElement>) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    className={twMerge('w-6 h-6', className)}
    {...props}
  >
    <path
      d="M3 9C3 7.34315 4.34315 6 6 6H18C19.6569 6 21 7.34315 21 9V15C21 16.6569 19.6569 18 18 18H6C4.34315 18 3 16.6569 3 15V9Z"
      strokeWidth="1.3"
    />
    <path
      d="M7 10L11.2283 12.537C11.7033 12.822 12.2967 12.822 12.7717 12.537L17 10"
      strokeWidth="1.3"
      strokeLinecap="round"
    />
  </svg>
);

export default forwardRef(MailLetterIcon);
