// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Sm4tqMe7VUTE7402KDGw {\n  color: gray;\n}\n.Sm4tqMe7VUTE7402KDGw:hover {\n    background-color: white;\n    color: black;\n  }\n", "",{"version":3,"sources":["webpack://./src/features/chat/components/chat-with-adam/ChatWithAdamLeftSide/NewChatButton.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AAMb;AAJE;IACE,uBAAuB;IACvB,YAAY;EACd","sourcesContent":[".root {\n  color: gray;\n\n  &:hover {\n    background-color: white;\n    color: black;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Sm4tqMe7VUTE7402KDGw"
};
export default ___CSS_LOADER_EXPORT___;
