import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BusinessSettings, SettingsTab } from '../components';
import AccountSettings from '../components/AccountSettings/AccountSettings';
import { Page } from 'components/layout/Page/Page';

const SettingsPage = () => {
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState<'account' | 'businesses'>('account');

  return (
    <Page
      pageTitle={t('settings.title')}
      pageDescription={t('settings.description')}
    >
      <div className="inline-flex flex-col items-start relative flex-[0_0_auto] border-b [border-bottom-style:solid] border-violet-hue-selection-button-4 w-fit">
        <div className="flex items-start relative self-stretch w-full flex-[0_0_auto]">
          <SettingsTab
            label={t('settings.tab.account')}
            isSelected={selectedTab === 'account'}
            onClick={() => setSelectedTab('account')}
          />

          <SettingsTab
            label={t('settings.tab.businesses')}
            isSelected={selectedTab === 'businesses'}
            onClick={() => setSelectedTab('businesses')}
          />
        </div>
      </div>

      {
        selectedTab === 'account' && <AccountSettings />
      }

      {
        selectedTab === 'businesses' && <BusinessSettings />
      }
    </Page>
  );
};

export default SettingsPage;
