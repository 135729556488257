import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertTheme } from 'state/classes/AlertDataList';
import { globalAlertDataList } from 'state/globalAlertDataList';

import { genericErrorFeedback } from 'utils/errors';
import { httpGetBlobV1 } from 'utils/xhr';

export const useExportProducts = () => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const exportProducts = useCallback((selectedProductIds: string[]) => {
    if (!selectedProductIds) return;

    setIsLoading(true);

    httpGetBlobV1('/products/export', {
      params: {
        ids: selectedProductIds,
      },
    })
      .then((response) => {
        const contentDisposition = response.headers['content-disposition'];
        let filename = 'products.csv';
        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(/filename="?(.+)?"/);
          if (filenameMatch.length === 2) {
            filename = filenameMatch[1];
          }
        }
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        URL.revokeObjectURL(url);

        globalAlertDataList.create(t('success.exportProducts'), AlertTheme.SUCCESS);
      })
      .catch(genericErrorFeedback('Error loading products'))
      .finally(() => {
        setIsLoading(false);
      });
  }, [t]);

  return { isLoading, exportProducts };
};
