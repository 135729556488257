import { forwardRef, Ref, SVGProps } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props extends SVGProps<SVGSVGElement> {
  className?: string;
}

const LanguageIcon = ({ className, ...props }: Props, ref: Ref<SVGSVGElement>) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    className={twMerge('w-6 h-6', className)}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.16628 2.3501C6.80729 2.3501 6.51628 2.64111 6.51628 3.0001C6.51628 3.35908 6.80729 3.6501 7.16628 3.6501H7.99961C8.35859 3.6501 8.64961 3.35908 8.64961 3.0001C8.64961 2.64111 8.35859 2.3501 7.99961 2.3501H7.16628ZM2.99961 4.8501C2.64062 4.8501 2.34961 5.14111 2.34961 5.5001C2.34961 5.85908 2.64062 6.1501 2.99961 6.1501H10.1184L9.16115 7.58599L7.58294 9.16419L5.95923 7.54048C5.70539 7.28664 5.29383 7.28664 5.03999 7.54048C4.78615 7.79432 4.78615 8.20588 5.03999 8.45972L6.6637 10.0834L4.20666 12.5405C3.95282 12.7943 3.95282 13.2059 4.20666 13.4597C4.4605 13.7136 4.87205 13.7136 5.1259 13.4597L7.58294 11.0027L10.04 13.4597C10.2938 13.7136 10.7054 13.7136 10.9592 13.4597C11.2131 13.2059 11.2131 12.7943 10.9592 12.5405L8.50218 10.0834L10.1259 8.45972C10.1562 8.42945 10.1834 8.39627 10.2071 8.36065L11.6808 6.1501H12.9996C13.3586 6.1501 13.6496 5.85908 13.6496 5.5001C13.6496 5.14111 13.3586 4.8501 12.9996 4.8501H11.3329H2.99961ZM16.9996 10.3501C17.2654 10.3501 17.5044 10.5119 17.6031 10.7587L21.6031 20.7587C21.7364 21.092 21.5743 21.4703 21.241 21.6036C20.9077 21.7369 20.5294 21.5748 20.3961 21.2415L18.9595 17.6501L15.0397 17.6501L13.6031 21.2415C13.4698 21.5748 13.0915 21.7369 12.7582 21.6036C12.4249 21.4703 12.2628 21.092 12.3961 20.7587L16.3961 10.7587C16.4948 10.5119 16.7338 10.3501 16.9996 10.3501ZM16.9996 12.7503L18.4395 16.3501H15.5597L16.9996 12.7503Z"
    />
  </svg>
);

export default forwardRef(LanguageIcon);
