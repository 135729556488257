import { useButtonsStatus } from '../hooks/useButtonsStatus';

import { Tooltip } from 'components/ui';
import { Button } from 'components/ui/Button';

interface Props {
  onConfirmButtonClick: () => void;
  saveOrder: () => void;
  onDeclineButtonClick: () => void;
}

const Buttons = ({
  onConfirmButtonClick,
  saveOrder,
  onDeclineButtonClick,
}: Props) => {
  const {
    saveButtonDisabledMsg,
    confirmButtonDisabledMsg,
  } = useButtonsStatus();

  return (
    <div className="py-3 px-3 flex justify-between items-center">
      <Button
        title="Decline"
        theme="red"
        onClick={onDeclineButtonClick}
      />
      <div className="text-[0.6rem] px-4 h-full flex items-center italic">
        <p className="text-gray-400">
          This is an alpha version of Adam. Please double check the
          output and report bugs to
          {' '}
          <a href="mailto:support@hoshii.ai">
            support@hoshii.ai
          </a>
          .
        </p>
      </div>
      <div className="flex gap-2">
        <Tooltip
          label={saveButtonDisabledMsg}
          disabled={!saveButtonDisabledMsg}
        >
          <Button
            title="Save"
            disabled={!!saveButtonDisabledMsg}
            onClick={saveOrder}
          />
        </Tooltip>
        <Tooltip
          label={confirmButtonDisabledMsg}
          disabled={!confirmButtonDisabledMsg}
        >
          <Button
            title="Confirm"
            theme="primary"
            disabled={!!confirmButtonDisabledMsg}
            onClick={onConfirmButtonClick}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default Buttons;
