import { useCallback, useState } from 'react';

import { globalThreads } from 'state/globalThreads';

import { httpPatchV1 } from '../utils/xhr';

const useMarkThreadsAsRead = () => {
  const [isLoading, setIsLoading] = useState(false);

  const markThreadsAsRead = useCallback((ids: string[]): Promise<void> => {
    setIsLoading(true);
    return httpPatchV1('/threads/read', { ids })
      .then(() => {
        globalThreads.markOneAsRead();
      })
      .catch((error) => {
        console.error('failed to mark threads as read', error);
        return Promise.reject(error);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return { isLoading, markThreadsAsRead };
};

export { useMarkThreadsAsRead };
