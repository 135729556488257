import adam from 'assets/adam-face.png';

interface Props {
  senderBusinessName: string;
  senderName: string;
  isAdamParticipating: boolean;
}

const ThreadMessageParticipantIcons = ({ senderBusinessName, senderName, isAdamParticipating }: Props) => (
  <div className="flex-shrink-0 mr-4">
    <div className="relative">
      <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center z-10 relative border border-white">
        <span className="text-blue-700 font-medium">
          {(senderBusinessName || senderName).charAt(0).toLocaleUpperCase()}
        </span>
      </div>
      {isAdamParticipating && (
        <div className="w-8 h-8 rounded-full overflow-hidden absolute -right-2 -top-2 border border-white">
          <img src={adam} alt="adam" className="w-full h-full" />
        </div>
      )}
    </div>
  </div>
);

export default ThreadMessageParticipantIcons;
