import {
  memo, useEffect, useMemo, useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';

import { Chat } from 'features/chat/models';
import { MessageIntent } from 'features/message/models/Message';

import { BusinessSettingsProvider } from 'contexts/useBusinessSettingsContext';
import { ChatMessagesProvider } from 'contexts/useChatMessagesContext';
import { ChatsProvider, useChatsContext } from 'contexts/useChatsContext';
import {
  DetailPanelContentContext,
  DetailPanelContentType,
} from 'contexts/useDetailPanelContentContext';
import {
  DetailPanelWidthContext,
  DetailPanelWidthType,
} from 'contexts/useDetailPanelWidthContext';
import {
  MessageProviderType,
  MessagesProvider,
  useMessagesContext,
} from 'contexts/useMessagesContext';
import { PlayRecordingProvider } from 'contexts/usePlayRecordingIndex';
import { TeamMembersProvider } from 'contexts/useTeamMembersContext';
import { OrderProvider } from 'features/order/contexts/useOrderContext';
import { ProcessOrderProvider } from 'features/order/contexts/useProcessOrderContext';

import { Chat as ChatComponent } from 'components/chat/Chat/Chat';
import { ChatList } from 'components/chat/chat-list';
import { Page } from 'components/layout/Page/Page';

import { isAiChat as isAichatHelper } from 'features/chat/utils/chat';

interface Props {
  // chat related
  chatId: string;
  setChat: (chat: Chat) => void;
  navigateToChat: (chatId: string) => void;
}

const ChatPageContent = memo(({
  chatId,
  setChat,
  detailPanelWidth,
}: {
  chatId: string;
  setChat: (chat: Chat) => void;
  detailPanelWidth: DetailPanelWidthType;
}) => {
  const { chats } = useChatsContext();
  const { setMessages } = useMessagesContext();

  const chat: Chat | undefined = useMemo(
    () => chats.find((c) => c.id === chatId),
    [chats, chatId],
  );

  const isAiChat = useMemo(() => (chat ? isAichatHelper(chat) : true), [chat]);

  const mainWidthClassName = useMemo(() => {
    if (detailPanelWidth === DetailPanelWidthType.SMALL) {
      return isAiChat ? 'max-w-full min-w-full' : 'min-w-[70%] max-w-[70%]';
    }

    return isAiChat ? 'min-w-full max-w-full' : 'min-w-[50%] max-w-[50%]';
  }, [detailPanelWidth, isAiChat]);

  return (
    <ProcessOrderProvider onOrderDraftProcessed={() => {}}>
      <ChatMessagesProvider setMessages={setMessages} chat={chat} setChat={setChat}>
        <div
          className={twMerge(
            mainWidthClassName,
            'border-r border-blue-gray-50',
          )}
        >
          <ChatComponent chatId={chatId} />
        </div>
        {/* TODO(chihirokuya): create a better detail panel */}
        {/* <div
          className={twMerge(
            detailPanelWidthClassName,
            'h-full',
          )}
        >
          <DetailPanel chatId={chatId} />
        </div> */}
      </ChatMessagesProvider>
    </ProcessOrderProvider>
  );
});

function ChatPage({ chatId, navigateToChat, setChat }: Props) {
  const [searchParams] = useSearchParams();

  const unreadOnly = searchParams.get('unread_only') === 'true';
  const intent = searchParams.get('intent') as MessageIntent | null || null;
  // const messageSources = searchParams.get('message_sources')?.split(',') as MessageSource[];

  const [detailPanelWidth, setDetailPanelWidth] = useState<DetailPanelWidthType>(DetailPanelWidthType.SMALL);
  const [detailPanelContent, setDetailPanelContent] = useState<DetailPanelContentType>(DetailPanelContentType.USER_INFO);

  const detailPanelWidthContextValue = useMemo(
    () => ({
      detailPanelWidth,
      setDetailPanelWidth,
    }),
    [detailPanelWidth],
  );

  const detailPanelContentContextValue = useMemo(
    () => ({
      detailPanelContent,
      setDetailPanelContent,
    }),
    [detailPanelContent],
  );

  useEffect(() => {
    // Reset when chatId changes
    setDetailPanelWidth(DetailPanelWidthType.SMALL);
    setDetailPanelContent(DetailPanelContentType.USER_INFO);
  }, [chatId]);

  return (
    <Page contentWithBorder>
      <ChatsProvider>
        <div className="flex h-full w-full">
          <div className="h-full min-w-[30%] max-w-[30%] md:min-w-[25%] md:max-w-[25%] xl:min-w-[20%] xl:max-w-[20%] 2xl:min-w-[15%] 2xl:max-w-[15%]">
            <ChatList selectedChatId={chatId} navigateToChat={navigateToChat} />
          </div>

          <div className="flex h-full flex-1">
            <PlayRecordingProvider>
              <DetailPanelWidthContext.Provider
                value={detailPanelWidthContextValue}
              >
                <DetailPanelContentContext.Provider
                  value={detailPanelContentContextValue}
                >
                  <MessagesProvider
                    providerType={MessageProviderType.BY_CHAT_ID}
                    chatId={chatId}
                    defaultFilter={{
                      unreadOnly, intent,
                    }}
                  >
                    <OrderProvider>
                      <BusinessSettingsProvider>
                        <TeamMembersProvider>
                          <ChatPageContent
                            chatId={chatId}
                            detailPanelWidth={detailPanelWidth}
                            setChat={setChat}
                          />
                        </TeamMembersProvider>
                      </BusinessSettingsProvider>
                    </OrderProvider>
                  </MessagesProvider>
                </DetailPanelContentContext.Provider>
              </DetailPanelWidthContext.Provider>
            </PlayRecordingProvider>
          </div>
        </div>
      </ChatsProvider>
    </Page>
  );
}

export default ChatPage;
