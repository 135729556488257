import {
  useCallback,
  useState,
} from 'react';

import { Message } from '../features/message/models/Message';

import { genericErrorFeedback } from '../utils/errors';
import { httpPostV1, httpPostV2 } from '../utils/xhr';

const useSendLLMMessage = () => {
  const [isLoading, setIsLoading] = useState(false);

  const sendLLMMessage = useCallback(
    async (
      message: string,
      chatId?: string,
      isTemporary: boolean = false,
      pipeline: string = '',
      giveErrorFeedback: boolean = true,
    ): Promise<Message> => {
      setIsLoading(true);

      if (!message) {
        return Promise.reject(new Error('Message is required'));
      }

      if (chatId) {
        return httpPostV2(`/llm/chat/${chatId}/messages`, {
          message,
          isTemporary,
          pipeline,
        })
          .then((response) => response.data)
          .catch((error) => {
            if (giveErrorFeedback) {
              genericErrorFeedback('Failed to send message to AI')(error);
            }
            return Promise.reject(error);
          })
          .finally(() => setIsLoading(false));
      }
      return httpPostV1('/llm/chat/messages', {
        message,
        isTemporary,
        pipeline,
      })
        .then((response) => response.data)
        .catch((error) => {
          if (giveErrorFeedback) {
            genericErrorFeedback('Failed to send message to AI')(error);
          }
          return Promise.reject(error);
        })
        .finally(() => setIsLoading(false));
    },
    [],
  );

  return {
    isLoading,
    sendLLMMessage,
  };
};

export { useSendLLMMessage };
