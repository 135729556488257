import { ReactNode } from 'react';

import { DEFAULT_THEME, MantineProvider, MantineTheme } from '@mantine/core';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { ErrorBoundary } from './components/ui/ErrorBoundary';
import { AlertList } from 'components/layout';

import { genericErrorFeedback } from 'utils/errors';

interface Props {
  children: ReactNode;
}

const mantineTheme: MantineTheme = {
  ...DEFAULT_THEME,
  primaryShade: 5,
  defaultRadius: 'md',
  breakpoints: {
    ...DEFAULT_THEME.breakpoints,
    '4xl': '2000px',
  },
  primaryColor: 'purple',
  colors: {
    ...DEFAULT_THEME.colors,
    warning: [
      '#FFF1E6',
      '#FFD2B0',
      '#FFBD8A',
      '#FE9F54',
      '#FE8C33',
      '#FE6F00',
      '#E76500',
      '#B44F00',
      '#8C3D00',
      '#6B2F00',
    ],
    blue: [
      '#e8f3ff',
      '#b8d9ff',
      '#96c6ff',
      '#66adff',
      '#499dff',
      '#1b84ff',
      '#1978e8',
      '#135eb5',
      '#0f498c',
      '#0b376b',
    ],
    purple: [
      'var(--purple-highlight-3)',
      'var(--purple-highlight-3)',
      'var(--purple-highlight-2)',
      'var(--purple-highlight-2)',
      'var(--purple-highlight-2)',
      'var(--purple-highlight-2)',
      'var(--purple-highlight-1)',
      'var(--purple-highlight-1)',
      'var(--purple-highlight-1)',
      'var(--purple-highlight-1)',
      'var(--purple-highlight-1)',
    ],
  },
};

const client = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (query.meta.errorMessage && typeof query.meta.errorMessage === 'string') {
        genericErrorFeedback(query.meta.errorMessage)(error);
      } else {
        genericErrorFeedback('Error fetching data')(error);
      }
    },
  }),
});

const AppGlobalProviders = ({ children }: Props) => (
  <QueryClientProvider client={client}>
    <MantineProvider theme={mantineTheme}>
      <ErrorBoundary>
        <AlertList />
        {children}
      </ErrorBoundary>
    </MantineProvider>
  </QueryClientProvider>
);

export { AppGlobalProviders };
