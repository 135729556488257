import { ChatWithAdam } from '../ChatWithAdam';
import { NotificationButton, SettingsButton, UserButton } from './Buttons';
import FeedbackButton from './Buttons/FeedbackButton';

const HeaderButtons = () => (
  <div
    className="inline-flex items-center justify-end gap-[11px] relative"
  >
    <ChatWithAdam />

    <div className="inline-flex items-center justify-center gap-1 relative flex-[0_0_auto]">
      <FeedbackButton />

      <NotificationButton />

      <SettingsButton />

      <UserButton />
    </div>
  </div>
);

export default HeaderButtons;
