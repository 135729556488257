import { AxiosResponse } from 'axios';

export function parsePaginatedApiResponse<T>(
  response: AxiosResponse,
): { result: T[], cursor: string | null } {
  return {
    result: (response.data.result || []) as T[],
    cursor: response.data.cursor,
  };
}
