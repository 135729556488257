import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';

import { ORDER_DRAFT_PWQ_NAME_PATH } from 'features/instruction/constants';

import { Message } from 'features/message/models/Message';
import { OCRKeyword } from 'models/Workflow';

import { useParseFields } from 'hooks/useParseFields';

import { Annotation, KonvaStageRef, Tool } from 'components/chat/Chat/ChatMessage/ImageMessage/ImageOverlay/KonvaStage/type';

import { findAnnotationInRecord } from 'components/chat/Chat/ChatMessage/ImageMessage/ImageOverlay/KonvaStage/utils';

import { useProcessOrderDraftsStore } from 'features/order/store/process-order-drafts';

export const useKonvaUtils = ({
  message,
  selectedDocIndex,
  selectedDocPageIndex,
  imageDimensions,
  naturalImageDimensions,
  scaleX,
  scaleY,
  transformWrapperRef,
  imageRef,
  konvaStageRef,
}: {
  message: Message;
  selectedDocIndex: number;
  selectedDocPageIndex: number;
  imageDimensions: {
    width: number;
    height: number;
  };
  naturalImageDimensions: {
    width: number;
    height: number;
  };
  scaleX: number;
  scaleY: number;
  transformWrapperRef: React.RefObject<ReactZoomPanPinchRef>;
  imageRef: React.RefObject<HTMLImageElement>;
  konvaStageRef: React.RefObject<KonvaStageRef>;
}) => {
  const annotationsRecord = useProcessOrderDraftsStore((state) => state.annotationsRecord);
  const setAnnotations = useProcessOrderDraftsStore((state) => state.setAnnotations);
  const addNewParsedProducts = useProcessOrderDraftsStore((state) => state.addNewParsedProducts);
  const addParsedNonProductFields = useProcessOrderDraftsStore((state) => state.addParsedNonProductFields);

  const toZoomKeyword = useProcessOrderDraftsStore((state) => state.toZoomKeyword);
  const setToZoomKeyword = useProcessOrderDraftsStore((state) => state.setToZoomKeyword);

  const { parseFields } = useParseFields();

  const [selectedTool, setSelectedTool] = useState<Tool>('Hand');
  const [scale, setScale] = useState(1);

  const sendScreenshot = (annotation: Annotation) => {
    const container = imageRef.current;

    // rescale the annotation to the image size
    const scaledAnnotation = {
      x: annotation.x * (naturalImageDimensions.width / imageDimensions.width),
      y:
        annotation.y * (naturalImageDimensions.height / imageDimensions.height),
      width:
        annotation.width
        * (naturalImageDimensions.width / imageDimensions.width),
      height:
        annotation.height
        * (naturalImageDimensions.height / imageDimensions.height),
    };

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // Set canvas dimensions to match the screenshot area
    canvas.width = scaledAnnotation.width;
    canvas.height = scaledAnnotation.height;

    // Draw the selected part of the image onto the canvas
    ctx.drawImage(
      container,
      scaledAnnotation.x,
      scaledAnnotation.y,
      scaledAnnotation.width,
      scaledAnnotation.height,
      0,
      0,
      scaledAnnotation.width,
      scaledAnnotation.height,
    );

    // convert base64 to file
    // parseFields([new File([screenshot], 'screenshot.png', { type: 'image/png' })]);
    canvas.toBlob((blob) => {
      if (blob) {
        konvaStageRef.current?.setIsLoading(annotation.key, true);
        parseFields([
          new File([blob], 'screenshot.png', { type: 'image/png' }),
        ]).then((data) => {
          const popupContents: string[] = [];

          const nonProductValues = addParsedNonProductFields(data);
          popupContents.push(...nonProductValues);

          addNewParsedProducts(data)
            .then((newProducts) => {
              if (newProducts.length === 0) return;

              newProducts.forEach((product) => {
                popupContents.push(product.name);
              });

              konvaStageRef.current?.addProductUiIds(
                annotation.key,
                newProducts.map((product) => product.uiId),
              );
              konvaStageRef.current?.addPath(
                annotation.key,
                ORDER_DRAFT_PWQ_NAME_PATH,
              );
            })
            .finally(() => {
              konvaStageRef.current?.setIsLoading(annotation.key, false);
              konvaStageRef.current?.addPopup(
                annotation,
                <ul className="list-disc list-inside">
                  {popupContents.map((content) => (
                    <li key={content}>{content}</li>
                  ))}
                </ul>,
              );
            });
        }).catch(() => {
          konvaStageRef.current?.setIsLoading(annotation.key, false);
        });
      }
    });
  };

  const keywordPatches = useMemo(() => {
    const patches: any[] = [];
    const keywords = message.context?.workflowOrder?.ocrKeywords || [];

    keywords
      .filter((keyword: OCRKeyword) => keyword.docIndex === selectedDocIndex && keyword.pageIndex === selectedDocPageIndex)
      .forEach((keyword: OCRKeyword, index: number) => {
        const geometry = keyword.geometry;

        if (geometry.length === 0) return;

        if (geometry.length % 2 !== 0) {
          console.error('polygonal geometry length is not even');
          return;
        }

        const points: number[][] = geometry.reduce((acc, x, i, arr) => {
          if (i % 2 === 0) {
            acc.push([x, arr[i + 1]]);
          }
          return acc;
        }, []);

        patches.push({
          id: `${keyword.docIndex}:${index}`,
          fieldId: keyword.fieldId,
          path: keyword.path,
          label: keyword.fieldName,
          keyword: keyword.word,
          points,
        });
      });

    return patches;
  }, [selectedDocIndex, selectedDocPageIndex, message.context?.workflowOrder?.ocrKeywords]);

  const rescalePoint = useCallback((point: number[]) => {
    const [x, y] = point;
    return [
      x * scaleX,
      y * scaleY,
    ];
  }, [scaleX, scaleY]);

  const rescaledKeywordAnnotations = useMemo(() => {
    const pointsToXYWH = (points: number[][]) => {
      // find the min and max x and y
      const minX = Math.min(...points.map((point) => point[0]));
      const minY = Math.min(...points.map((point) => point[1]));
      const maxX = Math.max(...points.map((point) => point[0]));
      const maxY = Math.max(...points.map((point) => point[1]));
      return [minX, minY, maxX - minX, maxY - minY];
    };

    return keywordPatches.map((patch) => ({
      ...patch,
      points: patch.points.map(rescalePoint),
    })).map((patch) => {
      const [x, y, width, height] = pointsToXYWH(patch.points);
      return {
        key: patch.id,
        x,
        y,
        width,
        height,
        popupContent: patch.keyword,
        path: patch.path,
        label: patch.label,
        productUiIds: [patch.fieldId],
      };
    });
  }, [keywordPatches, rescalePoint]);

  const onTransformed = useCallback((state: any) => {
    if (state.state?.scale) {
      setScale(state.state?.scale);
    }
  }, []);

  useEffect(() => {
    const toZoomAnnotation = findAnnotationInRecord(annotationsRecord, toZoomKeyword);
    if (toZoomAnnotation) {
      // Note that x is given by: original_x * scaleX = originalX * imgRescaledWidth / imgNaturalWidth
      const x = toZoomAnnotation.x;
      const y = toZoomAnnotation.y;

      // Width and heights are instead not rescaled, yes I know, we should change this
      const width = toZoomAnnotation.width * scaleX;
      const height = toZoomAnnotation.height * scaleY;

      const padding = imageDimensions.width > 1000 ? 500 : 300;

      // Compute the to zoom area width and height
      const toZoomAreaWidth = width + padding;
      const toZoomAreaHeight = height + padding;

      // Center width
      const toZoomScale = Math.min(
        imageDimensions.width / toZoomAreaWidth,
        imageDimensions.height / toZoomAreaHeight,
      );

      const offsetX = (-x + padding / 2) * toZoomScale;
      const offsetY = (-y + padding / 2) * toZoomScale;

      transformWrapperRef.current?.setTransform(offsetX, offsetY, toZoomScale, 200, 'easeOut');
      setToZoomKeyword(null);
    }
  }, [imageDimensions, annotationsRecord, toZoomKeyword, transformWrapperRef, scale, setToZoomKeyword, scaleX, scaleY]);

  useEffect(() => {
    setAnnotations(
      `${selectedDocIndex}-${selectedDocPageIndex}`,
      rescaledKeywordAnnotations,
    );
  }, [rescaledKeywordAnnotations, selectedDocIndex, selectedDocPageIndex, setAnnotations]);

  return {
    sendScreenshot,
    selectedTool,
    setSelectedTool,
    onTransformed,
  };
};
