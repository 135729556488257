import {
  differenceInDays,
  differenceInMonths,
  differenceInSeconds,
  differenceInYears,
  format,
  isSameDay,
  isSameYear,
  isThisMonth,
  isThisYear,
  isToday,
  isYesterday,
} from 'date-fns';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import i18next from 'i18next';

const initDateTimeHelper = () => {
  dayjs.extend(LocalizedFormat);
};

const zeroTime = '0001-01-01T00:00:00Z';
const isZeroTime = (datetime: string) => datetime === zeroTime;

// Default formatting for date and time
const formatDate = (date: Date) => {
  const now = new Date();
  if (isSameDay(now, date)) {
    return dayjs(date).format('h:mm A');
  }

  if (isSameYear(now, date)) {
    return dayjs(date).format('MMM D, h:mm A');
  }

  return dayjs(date).format('MMM D, YYYY, h:mm A');
};

const formatEventTime = (eventTime: Date) => {
  const t = i18next.t.bind(i18next);

  const now = new Date();
  const diffInSeconds = differenceInSeconds(now, eventTime);

  if (diffInSeconds < 10) {
    return t('dateTime.justNow');
  }

  if (isToday(eventTime)) {
    return format(eventTime, 'HH:mm');
  }

  if (isYesterday(eventTime)) {
    return t('dateTime.yesterday');
  }

  const diffInDays = differenceInDays(now, eventTime);
  if (isThisMonth(eventTime) || diffInDays < 31) {
    return t('dateTime.daysAgo', { count: diffInDays });
  }

  const diffInMonths = differenceInMonths(now, eventTime);
  if (isThisYear(eventTime) || diffInMonths < 12) {
    return t('dateTime.monthsAgo', { count: diffInMonths });
  }

  const diffInYears = differenceInYears(now, eventTime);
  return t('dateTime.yearsAgo', { count: diffInYears });
};

export {
  formatDate, formatEventTime,
  initDateTimeHelper, isZeroTime, zeroTime,
};
