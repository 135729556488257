// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oxVTl6cqVN6fXhxsH8N3 {\n    background-color: var(--violet-hue-selection-button-3);\n    border: 0;\n    font-family: var(--font-screen-sm-regular-font-family);\n    font-size: var(--font-screen-sm-regular-font-size);\n    font-style: var(--font-screen-sm-regular-font-style);\n    font-weight: var(--font-screen-sm-regular-font-weight);\n    letter-spacing: var(--font-screen-sm-regular-letter-spacing);\n    line-height: var(--font-screen-sm-regular-line-height);\n}\n\n.J696mhz1BcPTlFKFnMhh {\n    margin-right: 4px;\n}\n", "",{"version":3,"sources":["webpack://./src/features/order/components/order-list/OrderSearchBar/DatePickers/DatePickersButton.module.css"],"names":[],"mappings":"AAAA;IACI,sDAAsD;IACtD,SAAS;IACT,sDAAsD;IACtD,kDAAkD;IAClD,oDAAoD;IACpD,sDAAsD;IACtD,4DAA4D;IAC5D,sDAAsD;AAC1D;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".root {\n    background-color: var(--violet-hue-selection-button-3);\n    border: 0;\n    font-family: var(--font-screen-sm-regular-font-family);\n    font-size: var(--font-screen-sm-regular-font-size);\n    font-style: var(--font-screen-sm-regular-font-style);\n    font-weight: var(--font-screen-sm-regular-font-weight);\n    letter-spacing: var(--font-screen-sm-regular-letter-spacing);\n    line-height: var(--font-screen-sm-regular-line-height);\n}\n\n.section {\n    margin-right: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "oxVTl6cqVN6fXhxsH8N3",
	"section": "J696mhz1BcPTlFKFnMhh"
};
export default ___CSS_LOADER_EXPORT___;
