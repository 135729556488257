import { plainToClass } from 'class-transformer';

import { globalAlertDataList } from 'state/globalAlertDataList';

import { Event } from 'models/Event';

import { decodeEvent } from 'features/notification/utils';

const createAlert = (messageEvent: MessageEvent) => {
  try {
    const data = JSON.parse(messageEvent.data);
    const event = plainToClass(Event, data);
    const decodedEvent = decodeEvent(event);

    if (decodedEvent.title) {
      globalAlertDataList.create(
        decodedEvent.title,
        decodedEvent.theme,
        decodedEvent.description,
      );
    }
  } catch (e) {
    console.error('Error decoding event', e);
  }
};

export { createAlert };
