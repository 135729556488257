import { infiniteQueryOptions } from '@tanstack/react-query';

import { fetchPrompts } from '../apis';

export const promptsOptions = () => infiniteQueryOptions({
  queryKey: ['prompts'],
  queryFn: ({ pageParam = '' }) => fetchPrompts({ cursor: pageParam }),
  getNextPageParam: (lastPage) => lastPage.cursor || null,
  initialPageParam: '',
  gcTime: 0,
  meta: {
    errorMessage: 'Error fetching prompts',
  },
});
