import { useRef } from 'react';
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import { Image as MantineImage } from '@mantine/core';

import { Message } from 'features/message/models/Message';

import { useImgUtils } from '../hooks/useImgUtils';
import { useKonvaUtils } from '../hooks/useKonvaUtils';
import { useTransformerUtils } from '../hooks/useTransformerUtils';

import { Buttons } from 'components/chat/Chat/ChatMessage/ImageMessage/ImageOverlay/Buttons';

import { KonvaStage } from './KonvaStage';
import { KonvaStageRef } from './KonvaStage/type';
import { useProcessOrderDraftsStore } from 'features/order/store/process-order-drafts';

interface Props {
  message: Message;

  imgUrl: string;
  imgName: string;
  docUrl: string;
}

const ImageWithAnnotations = ({
  message, imgUrl, imgName, docUrl,
}: Props) => {
  const transformWrapperRef = useRef<ReactZoomPanPinchRef>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const konvaStageRef = useRef<KonvaStageRef>(null);

  const selectedDocIndex = useProcessOrderDraftsStore((state) => state.selectedDocIndex);
  const selectedDocPageIndex = useProcessOrderDraftsStore((state) => state.selectedDocPageIndex);

  const {
    imageDimensions,
    naturalImageDimensions,
    scaleX,
    scaleY,
    handleImageLoad,
    imgLoadingStatus,
    setImgLoadingStatus,
  } = useImgUtils();

  const {
    wheelSmoothStep,
    onWheelStart,
  } = useTransformerUtils();

  const {
    sendScreenshot,
    selectedTool,
    setSelectedTool,
    onTransformed,
  } = useKonvaUtils({
    message,
    selectedDocIndex,
    selectedDocPageIndex,
    imageDimensions,
    naturalImageDimensions,
    scaleX,
    scaleY,
    transformWrapperRef,
    imageRef,
    konvaStageRef,
  });

  if (!imgUrl || imgLoadingStatus === 'error') {
    return (
      <div className="flex justify-center items-center w-full h-full">
        The attached image could not be loaded.
      </div>
    );
  }

  return (
    <TransformWrapper
      ref={transformWrapperRef}
      key={
        `${selectedDocIndex}-${selectedDocPageIndex}-${naturalImageDimensions.width}-${naturalImageDimensions.height}`
      } // To force rerender when page changes
      onWheelStart={onWheelStart}
      wheel={{
        smoothStep: wheelSmoothStep,
      }}
      minScale={0.5}
      panning={{ disabled: selectedTool !== 'Hand' }}
      onTransformed={onTransformed}
      initialPositionX={0}
      initialPositionY={0}
      // centerOnInit
    >
      {({ zoomIn, zoomOut }) => (
        <div className="h-full flex flex-col overflow-hidden">
          <div
            className="flex-1 overflow-hidden flex flex-col relative"
          >
            <TransformComponent
              contentClass="flex-1 flex flex-col w-full"
              wrapperClass="flex-1 flex flex-col w-full"
              wrapperStyle={{
                width: '100%',
                height: '100%',
              }}
            >
              <MantineImage
                ref={imageRef}
                onLoadStart={() => setImgLoadingStatus('loading')}
                onLoad={handleImageLoad}
                onError={() => setImgLoadingStatus('error')}
                alt=""
                src={imgUrl}
                crossOrigin="use-credentials"
                className="cursor-move object-contain"
                style={{
                  maxHeight: '100%',
                  maxWidth: '100%',
                }}
              />

              {imgLoadingStatus === 'loaded' && (
              <KonvaStage
                ref={konvaStageRef}
                annotationsKey={`${selectedDocIndex}-${selectedDocPageIndex}`}
                imageDimensions={imageDimensions}
                scaleX={scaleX}
                scaleY={scaleY}
                selectedTool={selectedTool}
                sendScreenshot={sendScreenshot}
              />
              )}
            </TransformComponent>
          </div>
          <Buttons
            defaultProps={{
              imageTitle: `${imgName} - ${selectedDocPageIndex + 1}`,
              docUrl,
              zoomIn,
              zoomOut,
            }}
            magicPenProps={{
              selectedTool,
              setSelectedTool,
            }}
          />
        </div>
      )}
    </TransformWrapper>
  );
};

export default ImageWithAnnotations;
