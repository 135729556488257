import { useEffect, useRef, useState } from 'react';

import { twMerge } from 'tailwind-merge';

import { MessageContextUtils } from 'features/message/models/Message';

import { useSelectedParentOrder } from '../../right-side/hooks/useSelectedParentOrder';
import { useDocsUtils } from '../hooks/useDocsUtils';

import { MailHeader } from 'components/ui';
import { RecordingMessage } from 'features/message/components/recording-message';

import { isImageTabSelected, isTextAndRecordingTabSelected } from '../utils/tab';

import FocusModeTab from './FocusModeTab';
import FocusModeText from './FocusModeText';
import ImageWithAnnotations from './ImageWithAnnotations';
import { useProcessOrderDraftsStore } from 'features/order/store/process-order-drafts';

interface Props {
  onManageSubjectButtonClick?: () => void;
}

const FocusMode = ({ onManageSubjectButtonClick }: Props) => {
  const message = useProcessOrderDraftsStore((state) => state.message);
  const playRecordingKeywordAndMessageId = useProcessOrderDraftsStore((state) => state.playRecordingKeywordAndMessageId);
  const setPlayRecordingKeywordAndMessageId = useProcessOrderDraftsStore(
    (state) => state.setPlayRecordingKeywordAndMessageId);
  const scrollToKeyword = useProcessOrderDraftsStore((state) => state.scrollToKeyword);

  const { order } = useSelectedParentOrder();

  const [selectedTab, setSelectedTab] = useState<'text-and-recording' | string>('text-and-recording');

  const firstRenderRef = useRef(true);

  const {
    docUrl,
    docs,
    docImages,
    imageLength,
    handleImageTabClick: handleImageTabClick_,
    selectedDocImgIndex,
    showImage,
    showRecording,
    handleAttachmentClick: handleAttachmentClick_,
  } = useDocsUtils({ message });

  const handleImageTabClick = (index: number) => {
    setSelectedTab(`image-${index}`);
    handleImageTabClick_(index);
  };

  const handleAttachmentClick = (index: number) => {
    handleAttachmentClick_(index);
    const imgIndex = MessageContextUtils.docAndPageIndexToImgIndex(docs, index, 0);
    setSelectedTab(`image-${imgIndex}`);
  };

  useEffect(() => {
    if (typeof selectedDocImgIndex === 'number' && !firstRenderRef.current) {
      setSelectedTab(`image-${selectedDocImgIndex}`);
    }
    firstRenderRef.current = false;
  }, [selectedDocImgIndex]);

  const imgTabSelected = showImage && isImageTabSelected(selectedTab);

  return (
    <div className={twMerge(
      'mx-6 mt-6 mb-2 space-y-2 4xl:space-y-4 bg-white flex-1 flex flex-col h-full relative',
      imgTabSelected ? 'overflow-hidden' : 'overflow-y-auto',
    )}
    >
      <MailHeader
        customer={order?.customer}
        subject={order?.createdByUserInfo}
        message={message}
        onManageSubjectButtonClick={onManageSubjectButtonClick}
      />

      {
        showImage && (
          <FocusModeTab
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            handleImageTabClick={handleImageTabClick}
            imageLength={imageLength}
          />
        )
      }

      {
        (!showImage || (showImage && isTextAndRecordingTabSelected(selectedTab))) && (
          <FocusModeText
            message={message}
            docs={docs}
            imageLength={imageLength}
            handleAttachmentClick={handleAttachmentClick}
          />
        )
      }

      {showRecording && (
        <div className="pt-3">
          {message && (
          <RecordingMessage
            message={message}
            playRecordingKeywordAndMessageId={playRecordingKeywordAndMessageId}
            setPlayRecordingKeywordAndMessageId={setPlayRecordingKeywordAndMessageId}
            scrollToKeyword={scrollToKeyword}
          />
          )}
        </div>
      )}

      {
        showImage && isImageTabSelected(selectedTab) && (
          <ImageWithAnnotations
            message={message}
            imgUrl={docImages?.[selectedDocImgIndex]?.url}
            imgName={docImages?.[selectedDocImgIndex]?.name}
            docUrl={docUrl}
          />
        )
      }
    </div>
  );
};

export default FocusMode;
