import { useEffect, useState } from 'react';

import { ProductWithQuantity } from '../models';

import { sortExtractedProducts } from '../utils/order';

import { useSettingsStore } from '../store/settingsStore';

interface Props {
  products: ProductWithQuantity[];
  errorsKeys: string[];
  warningsKeys: string[];
}

export const useSortProducts = ({ products, errorsKeys, warningsKeys }: Props) => {
  const [isSortLoading, setIsSortLoading] = useState(false);
  const [sortedProducts, setSortedProducts] = useState([]);

  const sortExtractedProductsBy = useSettingsStore((state) => state.sortExtractedProductsBy);

  useEffect(() => {
    setIsSortLoading(true);
    setSortedProducts(sortExtractedProducts(products, sortExtractedProductsBy, errorsKeys, warningsKeys) ?? []);
    setIsSortLoading(false);
  }, [products, sortExtractedProductsBy, errorsKeys, warningsKeys]);

  return {
    isSortLoading,
    sortedProducts,
  };
};
