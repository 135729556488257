import { TopTierCustomer } from 'models/Analytics';

import { SalesMetric } from '../types/metric';
import { TimePeriod } from '../types/timePeriod';

import { httpGetV1 } from 'utils/xhr';

interface FetchTopTierCustomersProps {
  date?: Date;
  period: string;
  metric: SalesMetric;
  limit?: number;
}

export const fetchTopTierCustomers = (
  date: Date,
  period: TimePeriod,
  metric: SalesMetric,
  limit: number = 10,
): Promise<TopTierCustomer[]> => {
  if (!period) return Promise.reject(new Error('Period is required'));

  const params: FetchTopTierCustomersProps = {
    period: period.toLowerCase(),
    metric,
    limit,
  };

  if (date) {
    params.date = date;
  }

  return httpGetV1('/businesses/me/customers/analytics/sales', {
    params,
    classType: TopTierCustomer,
  })
    .then((response) => response?.data?.result || []);
};
