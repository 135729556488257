import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { ExclamationCircleIcon, ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/24/outline';

type AlertStyle = {
  borderColor: string;
  icon: React.ReactNode;
  titleColor: string;
  descriptionColor: string;
  buttonColor: string;
};

export const getAlertStyle = (theme: string): AlertStyle => {
  switch (theme) {
    case 'error-alert':
      return {
        borderColor: 'border-red-400',
        icon: <ExclamationCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />,
        titleColor: 'text-red-800',
        descriptionColor: 'text-red-700',
        buttonColor: 'text-red-500',
      };
    case 'success':
      return {
        borderColor: 'border-green-400',
        icon: <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />,
        titleColor: 'text-green-800',
        descriptionColor: 'text-green-700',
        buttonColor: 'text-green-500',
      };
    case 'info':
      return {
        borderColor: 'border-blue-400',
        icon: <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />,
        titleColor: 'text-blue-800',
        descriptionColor: 'text-blue-700',
        buttonColor: 'text-blue-500',
      };
    case 'warning':
      return {
        borderColor: 'border-yellow-400',
        icon: <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />,
        titleColor: 'text-yellow-800',
        descriptionColor: 'text-yellow-700',
        buttonColor: 'text-yellow-500',
      };
    default:
      return {
        borderColor: 'border-gray-400',
        icon: <ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />,
        titleColor: 'text-gray-800',
        descriptionColor: 'text-gray-700',
        buttonColor: 'text-gray-500',
      };
  }
};
