import { twMerge } from 'tailwind-merge';

import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react';
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid';

interface Props {
  onOrderDetailsTitle?: string;
  onOrderDetailsButtonClick?: () => void;

  onCreateNewOrderButtonClick?: () => void;

  onShowHtmlPreviewButtonClick?: () => void;
}

const EllipsisButton = ({
  onOrderDetailsTitle,
  onOrderDetailsButtonClick,
  onCreateNewOrderButtonClick,
  onShowHtmlPreviewButtonClick,
}: Props) => (
  <div className="absolute right-0 top-0">
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <MenuButton className="w-full px-md pt-sm font-semibold">
          <EllipsisHorizontalIcon className="w-[20px]" />
        </MenuButton>
      </div>

      <Transition
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems
          anchor="bottom start"
          className="z-[50] w-[20ch] origin-top-right rounded-xl border bg-white text-sm/6 shadow-md [--anchor-gap:var(--spacing-1)]"
        >
          <div className="py-1">
            {onOrderDetailsButtonClick && (
              <MenuItem>
                {({ focus }) => (
                  <button
                    type="button"
                    className={twMerge(
                      focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block w-full px-4 py-2 text-start text-sm',
                    )}
                    onClick={onOrderDetailsButtonClick}
                  >
                    {onOrderDetailsTitle}
                  </button>
                )}
              </MenuItem>
            )}

            {onCreateNewOrderButtonClick && (
              <MenuItem>
                {({ focus }) => (
                  <button
                    type="button"
                    className={twMerge(
                      focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block w-full px-4 py-2 text-start text-sm',
                    )}
                    onClick={onCreateNewOrderButtonClick}
                  >
                    Create new order
                  </button>
                )}
              </MenuItem>
            )}

            {onShowHtmlPreviewButtonClick && (
              <MenuItem>
                {({ focus }) => (
                  <button
                    type="button"
                    className={twMerge(
                      focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block w-full px-4 py-2 text-start text-sm',
                    )}
                    onClick={onShowHtmlPreviewButtonClick}
                  >
                    Original email content
                  </button>
                )}
              </MenuItem>
            )}
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  </div>
);

export default EllipsisButton;
