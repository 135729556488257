import { twMerge } from 'tailwind-merge';

import { OverflowEllipsisParagraph } from 'components/ui/OverflowEllipsisParagraph';

interface Props {
  label: string;
  errorMsg?: string;
  children: React.ReactNode;
}

const LabelWrapper = ({ label, errorMsg, children }: Props) => (
  <>
    <OverflowEllipsisParagraph
      className={twMerge('font-medium w-[80px] lg:w-[120px] text-ellipsis', errorMsg ? 'mb-2.5' : '')}
      maxLines={1}
      isTooltipEnabled
    >
      {label}
    </OverflowEllipsisParagraph>
    <div className={twMerge(errorMsg ? 'mb-2.5' : '')}>
      {children}
    </div>
  </>
);

export default LabelWrapper;
