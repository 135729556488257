import { NavLink } from 'react-router-dom';

import { ROUTES } from 'config/routes';

import { User } from 'features/user/models';

import { getUserContact, isUserActivated } from 'features/user/utils';

import { TeamMemberIconWithActiveStatus } from '../team-member';

interface Props {
  user: User;
}

const getUserText = (user: User) => {
  if (user?.firstName && user?.lastName) {
    return `${user.firstName} ${user.lastName}`;
  }

  return getUserContact(user);
};

const TeamMemberListItem = ({ user }: Props) => (
  <NavLink
    className="inline-flex flex-col items-center gap-3 p-6 relative flex-[0_0_auto] bg-white rounded-[10px] border border-solid border-light-grey shadow-blue-light-tint-drop-shadow hover:shadow-blue-tint-drop-shadow"
    to={ROUTES.TEAM_MEMBER_BY_ID(user?.id)}
  >
    <TeamMemberIconWithActiveStatus user={user} />

    <div className="inline-flex flex-col items-center gap-1 relative flex-[0_0_auto]">
      <div
        className="relative w-fit mt-[-1.00px] font-font-screen-sm-regular font-[number:var(--font-screen-sm-regular-font-weight)] text-black text-[length:var(--font-screen-sm-regular-font-size)] text-center tracking-[var(--font-screen-sm-regular-letter-spacing)] leading-[var(--font-screen-sm-regular-line-height)] whitespace-nowrap [font-style:var(--font-screen-sm-regular-font-style)]"
      >
        {getUserText(user)}
      </div>

      <div
        className="relative w-fit font-font-screen-xs-regular font-[number:var(--font-screen-xs-regular-font-weight)] text-black-lighter-text text-[length:var(--font-screen-xs-regular-font-size)] text-center tracking-[var(--font-screen-xs-regular-letter-spacing)] leading-[var(--font-screen-xs-regular-line-height)] whitespace-nowrap [font-style:var(--font-screen-xs-regular-font-style)]"
      >
        {isUserActivated(user) && (
        <div className="hover:text-primary-active text-sm font-medium text-gray-600">
          {user?.username || user?.GetEmail() || user?.GetPhone()}
        </div>
        )}
      </div>
    </div>
  </NavLink>
);

export default TeamMemberListItem;
