import { useTranslation } from 'react-i18next';

import i18next from 'i18next';

import { Select } from '@mantine/core';

import { Card } from 'components/common/Card';
import { LanguageIcon } from 'components/icon/outline';

const LanguageSettings = () => {
  const { t } = useTranslation();

  const onSelectChange = (value: string) => {
    i18next.changeLanguage(value);
  };

  return (
    <Card id="lang_settings" className="">
      <Card.Header>
        <div className="flex items-start gap-1.5 relative self-stretch w-full flex-[0_0_auto]">
          <LanguageIcon
            className="!h-[18px] !w-[18px]"
          />
          <div className="relative w-fit mt-[-1.00px] font-font-screen-md-medium font-[number:var(--font-screen-md-medium-font-weight)] text-black text-[length:var(--font-screen-md-medium-font-size)] tracking-[var(--font-screen-md-medium-letter-spacing)] leading-[var(--font-screen-md-medium-line-height)] whitespace-nowrap [font-style:var(--font-screen-md-medium-font-style)]">
            {t('settings.card.languageSettings.title')}
          </div>
        </div>
      </Card.Header>
      <Card.Body className="text-sm text-gray-700">
        <div className="flex flex-col items-start gap-3 relative self-stretch w-full flex-[0_0_auto]">
          <div className="relative w-fit mt-[-1.00px] font-font-screen-xs-regular font-[number:var(--font-screen-xs-regular-font-weight)] text-black-lighter-text text-[length:var(--font-screen-xs-regular-font-size)] tracking-[var(--font-screen-xs-regular-letter-spacing)] leading-[var(--font-screen-xs-regular-line-height)] whitespace-nowrap [font-style:var(--font-screen-xs-regular-font-style)]">
            {t('settings.card.languageSettings.description')}
          </div>
        </div>

        <Select
          value={i18next.language}
          data={[
            { label: t('settings.card.languageSettings.english'), value: 'en' },
            { label: t('settings.card.languageSettings.german'), value: 'de' },
          ]}
          onChange={onSelectChange}
          className="w-full"
        />
      </Card.Body>
    </Card>
  );
};

export default LanguageSettings;
