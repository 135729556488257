import { ProtectedScreen } from 'components/ui/ProtectedScreen';

import { SyncLogListPage } from '../pages';

const SyncLogsScreen = () => (
  <ProtectedScreen>
    <SyncLogListPage />
  </ProtectedScreen>
);

export default SyncLogsScreen;
