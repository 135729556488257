import { useState } from 'react';

import {
  array, boolean, object, string,
} from 'yup';

import { LoadingOverlay } from '@mantine/core';

import { Business } from '../../../../models/Business';
import { PhoneType } from '../../../../models/Phone';

import { FormProvider } from '../../../../contexts/useFormContext';

import { phone } from 'utils/validations';

import { SidebarBase } from '../../SidebarBase';
import { _Body as Body } from './_Body';
import { _Header as Header } from './_Header';

const customerFormValidationSchema = object().shape({
  name: string().required(),
  email: string().email('Invalid email address'),
  address: string(),
  externalId: string(),
  phones: array(
    object({
      number: phone(),
      isDefault: boolean(),
      type: string().oneOf([PhoneType.Landline, PhoneType.Mobile]),
    }),
  ),
});

interface Props {
  customer: Business;
  _onComplete: (customer: Business) => void;
  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;
}

const _Sidebar = ({
  customer,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _onComplete,
  sidebarOpen,
  setSidebarOpen,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <FormProvider
      initialValues={{
        name: '',
        email: '',
        address: '',
        externalId: '',
        phones: [],
      }}
      validationSchema={customerFormValidationSchema}
      onSubmit={null}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
    >
      <LoadingOverlay
        visible={!customer && sidebarOpen}
        loaderProps={{ type: 'dots' }}
        overlayProps={{ blur: 2 }}
      />

      <SidebarBase
        header={<Header />}
        body={<Body customer={customer} />}
        sidebarWidth="max-w-[30vw]"
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
    </FormProvider>
  );
};

export { _Sidebar };
