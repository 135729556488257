import { forwardRef, Ref, SVGProps } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props extends SVGProps<SVGSVGElement> {
  className?: string;
}

const UnitBoxIcon = ({ className, ...props }: Props, ref: Ref<SVGSVGElement>) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    className={twMerge('w-6 h-6', className)}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8998 3.9266C12.6543 3.80384 12.3646 3.80721 12.122 3.93565L9.27821 5.44118L16.5086 9.26904L20.0467 7.50001L12.8998 3.9266ZM15.8501 11.0517V13.5C15.8501 13.859 16.1411 14.15 16.5001 14.15C16.8591 14.15 17.1501 13.859 17.1501 13.5V10.4017L20.8501 8.55173V15.6174C20.8501 15.9261 20.6827 16.2105 20.4129 16.3604L13.1501 20.3953V12.4017L15.8501 11.0517ZM15.0797 9.98349L7.88899 6.17666L5.38932 7.50001L12.5086 11.269L15.0797 9.98349ZM4.6501 15.6421V8.5796L11.8501 12.3914V20.3635L5.06913 16.3747C4.80951 16.222 4.6501 15.9433 4.6501 15.6421ZM11.5138 2.78672C12.1274 2.46185 12.8602 2.45333 13.4812 2.76385L20.9616 6.50404C21.69 6.86824 22.1501 7.6127 22.1501 8.42706V15.6174C22.1501 16.3982 21.7268 17.1176 21.0442 17.4968L13.5655 21.6517C12.8997 22.0216 12.0878 22.0116 11.4313 21.6254L4.41 17.4952C3.75331 17.109 3.3501 16.404 3.3501 15.6421V8.40313C3.3501 7.60675 3.79031 6.8756 4.49414 6.50299L11.5138 2.78672Z"
    />
  </svg>
);

export default forwardRef(UnitBoxIcon);
