import {
  forwardRef,
  useCallback, useEffect, useImperativeHandle, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { twMerge } from 'tailwind-merge';

import { Text, Textarea } from '@mantine/core';

import { Order } from 'features/order/models';

import { Button } from 'components/ui/Button';

import OrderProcessAssigneeSelect from './OrderProcessAssigneeSelect';

interface Props {
  order: Order;
  assignOrder: (teamMemberId: string, teamMemberName: string, comment: string) => void;

  assignedTeamMember: {
    value: string;
    label: string;
  };
  setAssignedTeamMember: (assignee: {
    value: string;
    label: string;
  }) => void;
}

const Assignee = forwardRef(({
  order,
  assignOrder,
  assignedTeamMember,
  setAssignedTeamMember,
}: Props, ref) => {
  const { t } = useTranslation();

  const [assignedComment, setAssignedComment] = useState<string>('');

  const isAssigneeChanged = order?.assigneeId !== assignedTeamMember?.value;

  const onAssignButtonClick = useCallback(() => {
    if (assignedTeamMember?.value) {
      assignOrder(
        assignedTeamMember.value,
        assignedTeamMember.label,
        assignedComment,
      );
    }
  }, [
    assignedTeamMember?.value,
    assignedTeamMember?.label,
    assignOrder,
    assignedComment,
  ]);

  useImperativeHandle(ref, () => ({
    isAssigneeChanged,
  }));

  // Set comment if present
  useEffect(() => {
    if (order?.draft?.comment) {
      setAssignedComment(order.draft.comment);
    } else {
      setAssignedComment('');
    }
  }, [order?.draft?.comment, setAssignedComment]);

  useEffect(() => {
    setAssignedTeamMember({
      value: order?.assigneeId,
      label: '',
    });
  }, [order?.assigneeId, setAssignedTeamMember]);

  return (
    <div className={twMerge(
      'border px-4 py-2 bg-gray-100',
      isAssigneeChanged ? 'rounded-l-md rounded-r-md' : 'rounded-l-full rounded-r-full',
    )}
    >
      <div className="flex items-center gap-4">
        <Text aria-required size="sm">
          {t('order.processOrderDrafts.rightSide.assignee')}
          *
        </Text>

        <div className="w-[180px]">
          <OrderProcessAssigneeSelect
            selectedOption={assignedTeamMember}
            onChange={(value, label) => setAssignedTeamMember({ value, label })}
          />
        </div>
      </div>

      {
        isAssigneeChanged && (
          <>
            <Textarea
              placeholder={t('order.processOrderDrafts.rightSide.addSomeComment')}
              label={t('order.processOrderDrafts.rightSide.comment')}
              className="pt-1"
              autosize
              minRows={2}
              maxRows={4}
              value={assignedComment}
              onChange={(event) => setAssignedComment(event.currentTarget.value)}
            />

            <div
              className="flex w-full justify-end pt-2"
            >
              <Button
                title={t('order.processOrderDrafts.rightSide.assign')}
                theme="primary"
                disabled={assignedTeamMember === null}
                onClick={onAssignButtonClick}
              />
            </div>
          </>
        )
      }
    </div>
  );
});

export default Assignee;
