import DOMPurify from 'dompurify';

import { Message } from 'features/message/models/Message';

import { MessageDirection } from 'features/message/types';

import { getMessageDirectionByUserId } from 'features/message/utils';

import AdamChatMessagePlot from './AdamChatMessagePlot';

interface Props {
  message: Message;
}

const formatText = (text: string): string => {
  // Replace '**...**' with '<strong>...</strong>'
  const boldReplaced = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

  // Replace '\n' with '<br />'
  const formattedText = boldReplaced.replace(/\n/g, '<br />');

  return formattedText;
};

const Content = ({ message }: Props) => {
  // NOTE: we assume that there is only one [PLOT_HERE] in the message
  const splittedMessages = message.message.split('[PLOT_HERE]');

  if (splittedMessages.length === 1 || !message.aiMetadata?.plotData) {
    const text = DOMPurify.sanitize(formatText(message.message));

    return React.createElement('div', { dangerouslySetInnerHTML: { __html: text } });
  }

  const firstPartText = DOMPurify.sanitize(formatText(splittedMessages[0]));
  const secondPartText = DOMPurify.sanitize(formatText(splittedMessages[1]));

  const firstPart = React.createElement('div', { dangerouslySetInnerHTML: { __html: firstPartText } });
  const secondPart = React.createElement('div', { dangerouslySetInnerHTML: { __html: secondPartText } });

  return (
    <div className="flex flex-col gap-2">
      {firstPart}
      <AdamChatMessagePlot plotData={message.aiMetadata.plotData} />
      {secondPart}
    </div>
  );
};

const AdamChatMessageBubble = ({ message }: Props) => {
  const messageDirection = getMessageDirectionByUserId(message);

  if (messageDirection === MessageDirection.Sent) {
    return (
      <div id={message.id} className="w-full flex justify-end">
        <div className="gap-1 px-4 py-3 w-fit max-w-[70%] bg-[#1e3d7a0f] rounded-[10px]">
          <Content message={message} />
        </div>
      </div>
    );
  }

  return (
    <div id={message.id} className="flex items-start gap-[13px] relative self-stretch w-full flex-[0_0_auto]">
      <div className="relative flex-1 mt-[-1.00px]">
        <Content message={message} />
      </div>
    </div>
  );
};

export default React.memo(AdamChatMessageBubble);
