import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PlusIcon } from '@heroicons/react/24/outline';
import { LoadingOverlay, Tooltip } from '@mantine/core';
import { useInfiniteQuery } from '@tanstack/react-query';

import { ROUTES } from 'config/routes';

import { Prompt } from '../models';

import { useDeletePrompt } from 'hooks/useDeletePrompt';

import { InstructionListItem } from '../components';
import { Page } from 'components/layout/Page/Page';

import { isEnvProdLike } from 'utils/environments';

import { promptsOptions } from '../queryOptions';

const InstructionListPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [prompts, setPrompts] = useState<Prompt[]>([]);

  const { data, isFetching } = useInfiniteQuery({
    ...promptsOptions(),
  });

  const { deletePrompt } = useDeletePrompt();

  const onCardClick = (promptId: string) => {
    navigate(ROUTES.EDIT_INSTRUCTION(promptId));
  };

  const onDeleteClick = (promptId: string) => {
    deletePrompt(promptId)
      .then(() => {
        setPrompts((_prompts) => _prompts.filter((prompt) => prompt.id !== promptId));
      });
  };

  const onCreateInstructionButtonPress = () => {
    navigate(ROUTES.CREATE_INSTRUCTION);
  };

  useEffect(() => {
    console.log(data);
    if (data) {
      setPrompts([...data.pages.flatMap((page) => page.result)]);
    }
  }, [data]);

  return (
    <Page
      pageTitle={t('instruction.list.title')}
      pageDescription={t('instruction.list.description')}
    >
      <LoadingOverlay
        visible={isFetching}
        loaderProps={{ type: 'dots' }}
        overlayProps={{ blur: 2 }}
      />

      <div className="grid grid-cols-5 desktop:grid-cols-6 gap-4 py-6 relative self-stretch w-full overflow-y-auto">
        {prompts.map((prompt) => (
          <InstructionListItem
            key={prompt.id}
            prompt={prompt}
            onCardClick={onCardClick}
            onDeleteClick={onDeleteClick}
          />
        ))}
      </div>

      <div className="inline-flex items-end justify-end gap-2 p-4 relative flex-1 grow">
        <Tooltip label={isEnvProdLike() ? 'Coming soon' : ''} hidden={isEnvProdLike()}>
          <button
            type="button"
            disabled={!isEnvProdLike()}
            onClick={onCreateInstructionButtonPress}
            className="inline-flex items-center justify-center gap-1 px-4 py-2 relative flex-[0_0_auto] bg-purple-highlight-2 rounded-full shadow-blue-light-tint-drop-shadow hover:shadow-blue-tint-drop-shadow"
          >
            <PlusIcon className="!relative !w-5 !h-5" color="white" />
            <div
              className="relative w-fit font-font-screen-md-regular font-[number:var(--font-screen-md-regular-font-weight)] text-white text-[length:var(--font-screen-md-regular-font-size)] tracking-[var(--font-screen-md-regular-letter-spacing)] leading-[var(--font-screen-md-regular-line-height)] whitespace-nowrap [font-style:var(--font-screen-md-regular-font-style)]"
            >
              {t('instruction.list.add.button')}
            </div>
          </button>
        </Tooltip>
      </div>
    </Page>
  );
};

export default InstructionListPage;
