import adam from 'assets/adam-face.png';

import { MessageComposer } from '../../MessageComposer';

const examplePrompts = [
  'What is the top selling product in the last 3 months?',
  'How many orders were placed in last year?',
  'Who is the customer that has ordered the most in December 2024?',
  'Who are the customers that have ordered less and less in the last 3 months?',
];

interface Props {
  onSendMessage: (message: string) => void;
  isLoading: boolean;
}

const LandingView = ({ onSendMessage, isLoading }: Props) => (
  <div className="w-full h-full flex justify-center items-center">
    <div className="flex flex-col items-center">
      <div className="flex flex-col items-center gap-3 desktop:gap-5">
        <img
          src={adam}
          alt="adam"
          className="w-28 h-28 rounded-full desktop:w-32 desktop:h-32"
        />
        <p className="text-3xl desktop:text-4xl font-medium text-gray-500">
          What can I help you with?
        </p>
      </div>
      <p className="text-sm desktop:text-base text-gray-400 mt-2 desktop:mt-3">
        Choose a prompt below or write your own to start chatting with Adam
      </p>

      <div className="mt-6 w-[700px] desktop:w-[1000px]">
        <MessageComposer onSendMessage={onSendMessage} isLoading={isLoading} />

        <div className="flex flex-wrap gap-2 mt-3">
          {examplePrompts.map((prompt) => (
            <button
              key={prompt}
              type="button"
              className="bg-gray-100 text-gray-400 rounded-md py-2 px-4 text-xs hover:bg-gray-200 hover:text-gray-500 transition-colors duration-200"
              onClick={() => onSendMessage(prompt)}
            >
              {prompt}
            </button>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default LandingView;
