import {
  useCallback, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Product } from 'features/product/models/Product';

import {
  BulletsIcon, DollarSignIcon, MenuParagraphIcon, QRCodeScanIcon, UnitBoxIcon,
} from 'components/icon/outline';
import { InfiniteScrollTable, InfiniteScrollTableContextProps } from 'components/ui';

import ProductListTableRow from './ProductListTableRow';

export interface Props extends InfiniteScrollTableContextProps {
  products: Product[];
  onDetailsButtonClick: (productId: string) => void;
}

interface SelectedProductIds {
  selectedProductIds: string[];
  setSelectedProductIds: (productIds: string[]) => void;
}

const ProductListTable = ({
  products,
  selectedProductIds,
  setSelectedProductIds,
  onDetailsButtonClick,
  ...props
}: Props & SelectedProductIds) => {
  const { t } = useTranslation();

  const checkbox = useRef<HTMLInputElement>();

  const toggleAll = useCallback(() => {
    if (selectedProductIds.length === products.length) {
      setSelectedProductIds([]);
    } else {
      setSelectedProductIds(products.map((product) => product.id));
    }
  }, [products, selectedProductIds, setSelectedProductIds]);

  const onSelectionChange = useCallback((product: Product) => {
    if (selectedProductIds.includes(product.id)) {
      setSelectedProductIds(selectedProductIds.filter((p) => p !== product.id));
    } else {
      setSelectedProductIds([...selectedProductIds, product.id]);
    }
  }, [selectedProductIds, setSelectedProductIds]);

  return (
    <InfiniteScrollTable
      {...props}
    >
      <InfiniteScrollTable.Thead>
        <tr>
          <InfiniteScrollTable.Th>
            <input
              type="checkbox"
              className="absolute left-1/2 -translate-x-1/2 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary-500"
              ref={checkbox}
              checked={selectedProductIds.length === products.length}
              onChange={toggleAll}
            />
          </InfiniteScrollTable.Th>
          <InfiniteScrollTable.Th
            text={t('product.list.header.idOrSku')}
            Icon={QRCodeScanIcon}
          />
          <InfiniteScrollTable.Th
            text={t('product.list.header.name')}
            Icon={MenuParagraphIcon}
          />
          <InfiniteScrollTable.Th
            text={t('product.list.header.category')}
            Icon={BulletsIcon}
          />
          <InfiniteScrollTable.Th
            text={t('product.list.header.price')}
            Icon={DollarSignIcon}
          />
          <InfiniteScrollTable.Th
            text={t('product.list.header.units')}
            Icon={UnitBoxIcon}
            isLast
          />
        </tr>
      </InfiniteScrollTable.Thead>
      <InfiniteScrollTable.Tbody>
        {products.map((product, idx) => (
          <ProductListTableRow
            key={product.id}
            idx={idx}
            product={product}
            isRowSelected={selectedProductIds.includes(product.id)}
            onRowSelected={onSelectionChange}
            onDetailsButtonClick={onDetailsButtonClick}
          />
        ))}
      </InfiniteScrollTable.Tbody>
    </InfiniteScrollTable>
  );
};

export default ProductListTable;
