import { SegmentedControl } from '@mantine/core';

import styles from './ExportedSegmentedControl.module.css';

import { GetOrdersParams } from 'features/order/api/useFetchOrders';

interface Props {
  params: GetOrdersParams;
  setParams: (params: GetOrdersParams) => void;
}

const ExportedSegmentedControl = ({ params, setParams }: Props) => (
  <SegmentedControl
    data={[
      { value: 'all', label: 'All' },
      { value: 'not_exported', label: 'Not exported' },
    ]}
    value={params.is_not_exported ? 'not_exported' : 'all'}
    onChange={(value) => {
      setParams({ ...params, is_not_exported: value === 'not_exported' });
    }}
    classNames={styles}
    size="xs"
    radius="xl"
  />
);

export default ExportedSegmentedControl;
