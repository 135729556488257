import { createContext, useContext } from 'react';

enum DetailPanelContentType {
  USER_INFO = 'user info',
  ORDER_DETAIL = 'order detail',
}

type DetailPanelContextType = {
  detailPanelContent: DetailPanelContentType; // Might add "collapsed" in the future.,
  setDetailPanelContent: React.Dispatch<
  React.SetStateAction<DetailPanelContentType>
  >;
};

const DetailPanelContentContext = createContext<
DetailPanelContextType | undefined
>(undefined);

const useDetailPanelContent = () => {
  const context = useContext(DetailPanelContentContext);

  if (context === undefined) {
    throw new Error('Must be wrapped by DetailPanelContentContext provider.');
  }

  return context;
};

export {
  DetailPanelContentContext,
  DetailPanelContentType,
  useDetailPanelContent,
};
