import { Model } from 'features/instruction/types';
import { Keyword } from 'features/order/types/keyword';

import { pathToModel } from 'features/instruction/utils';
import { isValidKeyword } from 'features/order/utils/keyword';

import { Annotation } from './type';

const isPointInSquare = (x: number, y: number, annotation: { x: number; y: number; width: number; height: number }) => (
  x >= annotation.x
      && x <= annotation.x + annotation.width
      && y >= annotation.y
      && y <= annotation.y + annotation.height
);

const _getBaseColor = (model: Model) => {
  switch (model) {
    case Model.PRODUCTS:
      return '165,114,234';
    case Model.CUSTOMER:
      return '213,65,28';
    default:
      return '28,213,86';
  }
};

const getTextBackgroundColor = (annotation: Annotation) => {
  const model = pathToModel(annotation.path);

  return `rgb(${_getBaseColor(model)})`;
};

export const isKeywordMatchAnnotation = (
  keyword: Keyword,
  annotation: Annotation,
  isLeft: boolean = false,
) => {
  if (!isValidKeyword(keyword)) return false;

  const isPathMatch = (keyword.fieldPath) ? keyword.fieldPath === annotation.path : true;
  const isFieldIdsMatch = keyword.fieldIds.length > 0 ? (
    keyword.fieldIds.some((fieldId) => (annotation?.productUiIds || []).includes(fieldId))
  ) : true;

  // If the keyword is on the left side and it is a keyword with fieldIds,
  // we highlight all the annotations that have the same fieldIds
  // (for instance, when hovering on product name, we highlight also id, quantity, etc.)
  if (isLeft && keyword.fieldIds.length) {
    return isFieldIdsMatch;
  }

  return isPathMatch && isFieldIdsMatch;
};

const getFillColor = (
  annotation: Annotation,
  focusKeyword: Keyword | null,
  isLeft: boolean = false,
) => {
  const model = pathToModel(annotation.path);

  let opacity = 0.1;

  if (isValidKeyword(focusKeyword)) {
    if (isKeywordMatchAnnotation(focusKeyword, annotation, isLeft)) {
      opacity = 0.4;
    } else {
      opacity = 0.15;
    }
  }

  return `rgba(${_getBaseColor(model)},${opacity})`;
};

const getStrokeColor = (
  annotation: Annotation,
  focusKeyword: Keyword | null,
  isLeft: boolean = false,
) => {
  const model = pathToModel(annotation.path);

  let opacity = 0.8;

  if (isValidKeyword(focusKeyword)) {
    if (isKeywordMatchAnnotation(focusKeyword, annotation, isLeft)) {
      opacity = 1;
    } else {
      opacity = 0;
    }
  }

  return `rgba(${_getBaseColor(model)},${opacity})`;
};

const findAnnotationInRecord = (
  annotationsRecord: Record<string, Annotation[]>,
  keyword: Keyword,
  isLeft: boolean = false,
) => (
  Object.values(annotationsRecord)
    .flat()
    .find((annotation_) => isKeywordMatchAnnotation(keyword, annotation_, isLeft))
);

export {
  findAnnotationInRecord,
  getFillColor, getStrokeColor, getTextBackgroundColor, isPointInSquare,
};
