import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { ProductSortingColumn, SortExtractedProductsBy } from 'features/product/types/product';

type SettingsSlice = {
  sortingColumn: ProductSortingColumn | null;
  setSortingColumn: (sortingColumn: ProductSortingColumn | null) => void;
  filterEnabledProducts: boolean;
  setFilterEnabledProducts: (filterEnabledProducts: boolean) => void;
  sortExtractedProductsBy: SortExtractedProductsBy | null;
  setSortExtractedProductsBy: (sortExtractedProductsBy: SortExtractedProductsBy | null) => void;
};

export const useSettingsStore = create<SettingsSlice>()(
  persist(
    (set) => ({
      sortingColumn: null,
      setSortingColumn: (sortingColumn) => set({ sortingColumn }),
      filterEnabledProducts: false,
      setFilterEnabledProducts: (filterEnabledProducts) => set({ filterEnabledProducts }),
      sortExtractedProductsBy: null,
      setSortExtractedProductsBy: (sortExtractedProductsBy) => set({ sortExtractedProductsBy }),
    }),
    {
      name: 'settings-storage', // key in localStorage
    },
  ),
);
