import { httpPostV1 } from 'utils/xhr';

type GroupOrderDraftsProps = {
  orderIds: string[];
  giveErrorFeedback?: boolean;
};

export const groupOrderDrafts = async ({
  orderIds,
}: GroupOrderDraftsProps): Promise<string> => {
  if (!orderIds.length) return Promise.reject(new Error('No order IDs provided'));

  return httpPostV1('/orders/drafts/group', {
    order_ids: orderIds,
  })
    .then((response) => Promise.resolve(response.data?.groupId))
    .catch((error) => Promise.reject(error));
};
