import { forwardRef, Ref, SVGProps } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props extends SVGProps<SVGSVGElement> {
  className?: string;
}

const TrashIcon = ({ className, ...props }: Props, ref: Ref<SVGSVGElement>) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 24"
    fill="currentColor"
    className={twMerge('w-6 h-6', className)}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2336 17.2628C14.2336 17.6218 13.9426 17.9128 13.5836 17.9128L11.0836 17.9128C10.7246 17.9128 10.4336 17.6218 10.4336 17.2628C10.4336 16.9038 10.7246 16.6128 11.0836 16.6128L13.5836 16.6128C13.9426 16.6128 14.2336 16.9038 14.2336 17.2628Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.01896 5.11533C6.37617 5.07961 6.6947 5.34023 6.73042 5.69743L7.98043 18.1976L7.98366 18.2299V18.2623C7.98366 18.6344 8.15434 19.0592 8.76185 19.4237C9.40014 19.8067 10.5154 20.1123 12.3337 20.1123C14.152 20.1123 15.2673 19.8067 15.9056 19.4237C16.5131 19.0592 16.6838 18.6344 16.6838 18.2623V18.2299L16.687 18.1976L17.937 5.69743C17.9728 5.34023 18.2913 5.07961 18.6485 5.11533C19.0057 5.15106 19.2663 5.46958 19.2306 5.82679L17.9836 18.2971C17.9717 19.162 17.5168 19.973 16.5745 20.5384C15.6502 21.093 14.2655 21.4123 12.3337 21.4123C10.402 21.4123 9.01723 21.093 8.093 20.5384C7.15061 19.973 6.69576 19.162 6.6839 18.2971L5.43687 5.82679C5.40115 5.46958 5.66176 5.15106 6.01896 5.11533Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.02138 5.26147C6.78608 5.484 6.73359 5.65347 6.73359 5.76234C6.73359 5.8712 6.78608 6.04068 7.02138 6.2632C7.25805 6.48703 7.63541 6.71854 8.15561 6.92662C9.1924 7.34134 10.6693 7.61237 12.3337 7.61237C13.9981 7.61237 15.475 7.34134 16.5118 6.92662C17.032 6.71854 17.4093 6.48703 17.646 6.2632C17.8813 6.04068 17.9338 5.8712 17.9338 5.76234C17.9338 5.65347 17.8813 5.484 17.646 5.26147C17.4093 5.03765 17.032 4.80614 16.5118 4.59806C15.475 4.18334 13.9981 3.9123 12.3337 3.9123C10.6693 3.9123 9.1924 4.18334 8.15561 4.59806C7.63541 4.80614 7.25805 5.03765 7.02138 5.26147ZM7.6728 3.39104C8.89809 2.90092 10.5462 2.6123 12.3337 2.6123C14.1211 2.6123 15.7693 2.90092 16.9946 3.39104C17.6054 3.63538 18.1434 3.94258 18.5392 4.31695C18.9365 4.69263 19.2338 5.18084 19.2338 5.76234C19.2338 6.34384 18.9365 6.83205 18.5392 7.20773C18.1434 7.5821 17.6054 7.8893 16.9946 8.13364C15.7693 8.62376 14.1211 8.91237 12.3337 8.91237C10.5462 8.91237 8.89809 8.62376 7.6728 8.13364C7.06195 7.8893 6.52401 7.5821 6.12814 7.20773C5.73089 6.83205 5.43359 6.34384 5.43359 5.76234C5.43359 5.18084 5.73089 4.69263 6.12814 4.31695C6.52401 3.94258 7.06195 3.63538 7.6728 3.39104Z"
    />
  </svg>
);

export default forwardRef(TrashIcon);
