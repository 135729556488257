import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useSidebarStore } from 'stores';

import { ProtectedScreen } from 'components/ui/ProtectedScreen';

import { ChatWithAdamPage } from '../pages';
import { useAiChatStore } from '../stores';

const ChatWithAdamScreen = () => {
  const { chatId } = useParams();
  const closeSidebar = useSidebarStore((state) => state.close);

  const setCurrentChat = useAiChatStore((state) => state.setCurrentChat);

  useEffect(() => {
    if (!chatId) {
      setCurrentChat(null);
    }
  }, [chatId, setCurrentChat]);

  useEffect(() => closeSidebar(), [closeSidebar]);

  return (
    <ProtectedScreen>
      <ChatWithAdamPage chatId={chatId} />
    </ProtectedScreen>
  );
};

export default ChatWithAdamScreen;
