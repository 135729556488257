import { action, makeObservable, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import { TOKEN_EXPIRY_ALERT_ID } from 'constants/alertTypes';

// error is for error modal, instead of error alert
export enum AlertTheme {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  ERROR_ALERT = 'error-alert',
  WARNING = 'warning',
}

export type AlertData = {
  id: string;
  title: string | null;
  theme: AlertTheme;
  description?: string;
};

export class AlertDataList {
  alerts: AlertData[] = [];

  constructor() {
    makeObservable(this, {
      alerts: observable,
      create: action,
      dismiss: action,
    });
  }

  create = (title: string, theme: AlertTheme, description?: string, alertId: string = uuidv4()) => {
    // If an alert with the token expiry error alert id exists, and the theme is error, return without creating a new alert
    if (this.alerts.some((alert) => alert.id === TOKEN_EXPIRY_ALERT_ID && alert.theme === AlertTheme.ERROR)) {
      return;
    }

    console.log('[AlertDataList] Creating alert:', title, theme, description);
    if (theme === AlertTheme.ERROR) {
      // Make sure there is only one error alert at a time
      this.alerts = this.alerts.filter((alert) => alert.theme !== AlertTheme.ERROR);
    }

    this.alerts.unshift({
      id: alertId, title, theme, description,
    });
  };

  dismiss = (id: string) => {
    this.alerts = this.alerts.filter((alert) => alert.id !== id);
  };
}
