import {
  useCallback,
  useEffect, useRef, useState,
} from 'react';

const useIntersectionObserver = (
  callback: () => void,
  options?: IntersectionObserverInit,
) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const observerCallback: IntersectionObserverCallback = useCallback(
    (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        setIsIntersecting(true);
        callback();
      } else {
        setIsIntersecting(false);
      }
    },
    [callback],
  );

  useEffect(() => {
    const node = ref.current;
    if (node) {
      const observer = new IntersectionObserver(observerCallback, options);
      observer.observe(node);

      return () => {
        observer.unobserve(node);
      };
    }

    return () => {};
  }, [ref, observerCallback, options]);

  return { ref, isIntersecting };
};

export { useIntersectionObserver };
