import { StateCreator } from 'zustand';

import { NavigationSlice, ProcessOrderDraftsStore } from './types';

export const createNavigationSlice: StateCreator<ProcessOrderDraftsStore, [], [], NavigationSlice> = (set, get) => ({
  currentIndex: 0,
  selectPrevOrderDraft: () => {
    const { currentIndex } = get();
    if (currentIndex > 0) {
      set({ currentIndex: currentIndex - 1 });
    }

    return currentIndex > 0;
  },
  selectNextOrderDraft: () => {
    const { currentIndex, globalOrders } = get();

    if (currentIndex < globalOrders.length - 2) {
      set({ currentIndex: currentIndex + 1 });
      return true;
    }

    const { endReached, loadOrderDrafts } = get();

    if (endReached) {
      if (currentIndex === globalOrders.length - 2) {
        set({ currentIndex: currentIndex + 1 });
        return true;
      }

      return false;
    }

    if (currentIndex < get().globalOrders.length - 1) {
      loadOrderDrafts()
        .then(() => {
          set({ currentIndex: currentIndex + 1 });
        });
    }

    return currentIndex < get().globalOrders.length - 1;
  },
});
