import { Order } from '../models';

export const updateAndCreateOrderDrafts = async (
  orderDrafts: Order[],
  deletedOrderIds: string[],
  deleteOrderDrafts: (orderIds: string[]) => Promise<void>,
  createAndGroupOrderDrafts: (
    createdOrderDrafts: Order[],
    nonCreatedOrderIds: string[],
    messageId?: string,
  ) => Promise<Order[]>,
  saveOrderDrafts: (orderDrafts: Order[]) => Promise<Order[]>,
  messageId?: string,
): Promise<Order[]> => {
  const filteredDeletedOrderIds = deletedOrderIds.filter((id) => id !== '' && !id.startsWith('new-'));

  // Delete to delete drafts
  if (filteredDeletedOrderIds.length > 0) {
    try {
      await deleteOrderDrafts(filteredDeletedOrderIds);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  // First separate the orderDrafts into two arrays: one for created orders and one for changed orders
  const { createdOrders, changedOrders } = orderDrafts.reduce(
    (acc, orderDraft) => {
      if (orderDraft.isCreated) {
        acc.createdOrders.push(orderDraft);
      } else {
        acc.changedOrders.push(orderDraft);
      }
      return acc;
    },
    { createdOrders: [], changedOrders: [] },
  );

  let groupId: string = '';

  // List of order drafts that are not created
  let _orderDrafts = orderDrafts.filter(
    (order) => !createdOrders.includes(order),
  );

  try {
    // Check if there are created orders, if yes, create them and group them
    if (createdOrders.length > 0) {
      // If only new orders then set messageId, otherwise set null
      const messageIdToUse = _orderDrafts.length === 0 ? messageId : null;

      const orders = (
        await createAndGroupOrderDrafts(
          createdOrders,
          // map all ids that are not present in createdOrders
          _orderDrafts.map((order) => order.id),
          messageIdToUse,
        )
      );

      // set the new order group id
      groupId = orders[0].groupId;
      _orderDrafts = [..._orderDrafts, ...orders];
    }

    // If there are changed orders, save them
    if (changedOrders.length > 0) {
      await saveOrderDrafts(
        changedOrders,
      );
    }

    return await Promise.resolve(
      _orderDrafts.map((_order) => ({
        ..._order,
        groupId: groupId || _order.groupId,
      })),
    );
  } catch (error) {
    return await Promise.reject(error);
  }
};
