import { ContentTheme } from './types';

export const contentThemeToTooltipTheme = (contentTheme: ContentTheme) => {
  switch (contentTheme) {
    case ContentTheme.AIInfo:
      return 'ai-info';
    case ContentTheme.AIWarning:
    case ContentTheme.WARNING:
      return 'warning';
    case ContentTheme.AIError:
      return 'error';
    case ContentTheme.AISafe:
      return 'success';
    default:
      return 'standard';
  }
};

export const contentThemeToTextColor = (contentTheme: ContentTheme) => {
  switch (contentTheme) {
    case ContentTheme.WARNING:
    case ContentTheme.AIWarning:
      return 'text-[#ff6f00]';
    case ContentTheme.AIInfo:
      return 'text-violet-500';
    case ContentTheme.AIImproved:
      return 'text-yellow-400';
    case ContentTheme.AIError:
      return 'text-red-500';
    case ContentTheme.AISafe:
      return 'text-green-500';
    default:
      return 'text-gray-700';
  }
};

export const contentThemeToSparkleFilter = (contentTheme: ContentTheme) => {
  switch (contentTheme) {
    case ContentTheme.AIImproved:
      return 'brightness(0) saturate(100%) invert(80%) sepia(70%) saturate(2000%) hue-rotate(0deg) brightness(100%) contrast(90%)';
    case ContentTheme.AIInfo:
      return 'invert(54%) sepia(62%) saturate(6540%) hue-rotate(237deg) brightness(99%) contrast(94%)';
    case ContentTheme.AIWarning:
      return 'invert(57%) sepia(100%) saturate(1415%) hue-rotate(341deg) brightness(100%) contrast(97%)';
    case ContentTheme.AIError:
      return 'invert(76%) sepia(72%) saturate(7040%) hue-rotate(332deg) brightness(110%) contrast(108%)';
    case ContentTheme.AISafe:
      return 'invert(56%) sepia(88%) saturate(423%) hue-rotate(89deg) brightness(94%) contrast(89%)';
    default:
      return '';
  }
};

export const contentThemeToBorderStyle = (contentTheme: ContentTheme) => {
  switch (contentTheme) {
    case ContentTheme.WARNING:
    case ContentTheme.AIWarning:
      return '1px solid #FFAD6E';
    case ContentTheme.AIImproved:
      return '2px solid #FFEB99';
    case ContentTheme.AISafe:
      return '1px solid #86efac';
    default:
      return '';
  }
};

export const contentThemeHasSparkle = (contentTheme: ContentTheme) => [
  ContentTheme.AIImproved,
  ContentTheme.AIInfo,
  ContentTheme.AIWarning,
  ContentTheme.AIError,
  ContentTheme.AISafe,
].includes(contentTheme);
