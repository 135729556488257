import { ProtectedScreen } from 'components/ui/ProtectedScreen';

import { InstructionListPage } from '../pages';

const InstructionListScreen = () => (
  <ProtectedScreen>
    <InstructionListPage />
  </ProtectedScreen>
);

export default InstructionListScreen;
