import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Group, ORDER_DRAFT_CREATED_BY_PATH } from 'features/instruction/constants';

import { useSchema } from '../../hooks/useSchema';
import { useSelectedGroupOrder } from '../../hooks/useSelectedGroupOrder';

import { DynamicField, ExpandableCard, FieldRenderer } from './components';
import { CustomerSelect } from 'features/customer/components';

import { FieldPathWrapper } from './components/utils';
import { getCustomerFields } from 'features/order/store/process-order-drafts/utils';

import { useProcessOrderDraftsStore } from 'features/order/store/process-order-drafts';

interface Props {
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
}

const WrappedCustomerSelect = FieldPathWrapper(CustomerSelect);
const WrappedAddressField = FieldPathWrapper(DynamicField);

const CustomerDetails = ({ isExpanded, setIsExpanded }: Props) => {
  const { t } = useTranslation();

  const { order, groupOrders, selectedOrderId } = useSelectedGroupOrder();
  const schema = useSchema({ groupOrders, selectedOrderId });

  const addError = useProcessOrderDraftsStore((state) => state.addError);

  const defaultCustomerFieldSpecs = useMemo(() => (
    getCustomerFields(groupOrders, selectedOrderId)
  ), [groupOrders, selectedOrderId]);

  const customerFieldSpecs = defaultCustomerFieldSpecs.filter((field) => !field.path.includes('address'));

  const defaultFieldSpecs = customerFieldSpecs.slice(0, 3);
  const expandedFieldSpecs = customerFieldSpecs.slice(3);

  const setCustomerSelectError = useCallback((error: string) => (
    addError(Group.Customer, ORDER_DRAFT_CREATED_BY_PATH, error)
  ), [addError]);

  return (
    <ExpandableCard
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      title={t('order.processOrderDrafts.rightSide.customerDetails')}
      group={Group.Customer}
      className="grid grid-cols-[auto_1fr] gap-2 items-center"
      isExpandable={expandedFieldSpecs.length > 0}
    >
      <ExpandableCard.DefaultContent>
        <>
          <div className="col-span-2">
            <WrappedCustomerSelect
              fieldPath=""
              setError={setCustomerSelectError}
              showLabel={false}
              width="max(600px, 100%)"
              position="bottom-end"
            />
          </div>
          {
            defaultFieldSpecs?.map((fieldSpec) => (
              <FieldRenderer
                key={fieldSpec.path}
                group={Group.Customer}
                fieldPath={fieldSpec.path}
                wrapperClassName="contents"
                schema={schema}
                fieldSpec={fieldSpec}
                checkMatchStatus
              />
            ))
          }
          {
            order?.customer?.address && (
              <WrappedAddressField
                fieldPath=""
                type="text"
                label="Delivery Address"
                wrapperClassName="contents"
                value={order?.customer?.address}
                readOnly
              />
            )
          }
        </>
      </ExpandableCard.DefaultContent>
      <ExpandableCard.ExpandedContent>
        {
          expandedFieldSpecs?.map((fieldSpec) => (
            <FieldRenderer
              key={fieldSpec.path}
              group={Group.Customer}
              fieldPath={fieldSpec.path}
              wrapperClassName="contents"
              schema={schema}
              fieldSpec={fieldSpec}
              checkMatchStatus
            />
          ))
        }
      </ExpandableCard.ExpandedContent>
    </ExpandableCard>
  );
};

export default CustomerDetails;
