import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'config/routes';

import { ProtectedScreen } from 'components/ui/ProtectedScreen';

import { OrderListPage } from 'features/order/pages';

const OrderListScreen = () => {
  const navigate = useNavigate();

  const navigateToOrder = useCallback(
    (orderId: string) => {
      navigate(ROUTES.ORDER_BY_ID(orderId));
    },
    [navigate],
  );

  const navigateToProcessDraftById = useCallback((orderId: string) => {
    navigate(ROUTES.PROCESS_ORDER_DRAFT_BY_ID(orderId));
  }, [navigate]);

  return (
    <ProtectedScreen>
      <OrderListPage
        navigateToOrder={navigateToOrder}
        navigateToProcessDraftById={navigateToProcessDraftById}
      />
    </ProtectedScreen>
  );
};

export default OrderListScreen;
