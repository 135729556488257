interface Props {
  onCancelClick: () => void;
  onApplyClick: () => void;
}

const DatePickersButtons = ({ onCancelClick, onApplyClick }: Props) => (
  <div className="flex items-end justify-end gap-2 pt-3 pb-0 px-0 relative self-stretch w-full flex-[0_0_auto] border-t [border-top-style:solid] border-light-grey">
    <button
      type="button"
      className="inline-flex items-center justify-center gap-[3px] px-3 py-2 relative flex-[0_0_auto] rounded-[10px] border border-solid border-black-lighter-text"
      onClick={onCancelClick}
    >
      <div className="relative w-fit mt-[-1.00px] font-font-screen-sm-regular font-[number:var(--font-screen-sm-regular-font-weight)] text-black-lighter-text text-[length:var(--font-screen-sm-regular-font-size)] text-right tracking-[var(--font-screen-sm-regular-letter-spacing)] leading-[var(--font-screen-sm-regular-line-height)] whitespace-nowrap [font-style:var(--font-screen-sm-regular-font-style)]">
        Cancel
      </div>
    </button>

    <button
      type="button"
      className="inline-flex items-center justify-center gap-[3px] px-3 py-2 relative flex-[0_0_auto] rounded-[10px] bg-purple-highlight-2 shadow-blue-light-tint-drop-shadow hover:shadow-blue-tint-drop-shadow"
      onClick={onApplyClick}
    >
      <div className="relative w-fit mt-[-1.00px] font-font-screen-sm-regular font-[number:var(--font-screen-sm-regular-font-weight)] text-white text-[length:var(--font-screen-sm-regular-font-size)] text-right tracking-[var(--font-screen-sm-regular-letter-spacing)] leading-[var(--font-screen-sm-regular-line-height)] whitespace-nowrap [font-style:var(--font-screen-sm-regular-font-style)]">
        Apply Dates
      </div>
    </button>
  </div>
);

export default DatePickersButtons;
