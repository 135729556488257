import { QuantityConversionPopupContent } from 'features/product/types/product';

import ContentLowQuantity from './ContentLowQuantity';
import QuantityConversionContentComponent from './ContentQuantityConversion';

interface Props {
  defaultQuantity: number;
  quantityPopupContent: QuantityConversionPopupContent | null;
  onQuantityPopupCanceled: () => void;
  onQuantityPopupConfirmed: () => void;
  updateProductQuantity: (quantity: number) => void;
}

const PopupContent = ({
  defaultQuantity, quantityPopupContent, onQuantityPopupCanceled, onQuantityPopupConfirmed, updateProductQuantity,
}: Props) => {
  if (quantityPopupContent?.alermLowQuantity) {
    return (
      <ContentLowQuantity
        defaultQuantity={defaultQuantity}
        onUpdateQuantityConfirmed={(quantity) => {
          updateProductQuantity(quantity);
          onQuantityPopupCanceled();
        }}
        onUpdateQuantityCanceled={onQuantityPopupCanceled}
      />
    );
  }

  return (
    <QuantityConversionContentComponent
      quantityPopupContent={quantityPopupContent}
      onQuantityPopupCanceled={onQuantityPopupCanceled}
      onQuantityPopupConfirmed={onQuantityPopupConfirmed}
    />
  );
};

export default PopupContent;
