import { forwardRef, Ref, SVGProps } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props extends SVGProps<SVGSVGElement> {
  className?: string;
}

const BulletsIcon = ({ className, ...props }: Props, ref: Ref<SVGSVGElement>) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    className={twMerge('w-6 h-6', className)}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 9C5.55228 9 6 8.55228 6 8C6 7.44772 5.55228 7 5 7C4.44772 7 4 7.44772 4 8C4 8.55228 4.44772 9 5 9ZM8 7.35C7.64102 7.35 7.35 7.64101 7.35 8C7.35 8.35899 7.64102 8.65 8 8.65H20C20.359 8.65 20.65 8.35899 20.65 8C20.65 7.64101 20.359 7.35 20 7.35H8ZM6 12C6 12.5523 5.55228 13 5 13C4.44772 13 4 12.5523 4 12C4 11.4477 4.44772 11 5 11C5.55228 11 6 11.4477 6 12ZM8 11.35C7.64102 11.35 7.35 11.641 7.35 12C7.35 12.359 7.64102 12.65 8 12.65H20C20.359 12.65 20.65 12.359 20.65 12C20.65 11.641 20.359 11.35 20 11.35H8ZM6 16C6 16.5523 5.55228 17 5 17C4.44772 17 4 16.5523 4 16C4 15.4477 4.44772 15 5 15C5.55228 15 6 15.4477 6 16ZM8 15.35C7.64102 15.35 7.35 15.641 7.35 16C7.35 16.359 7.64102 16.65 8 16.65H20C20.359 16.65 20.65 16.359 20.65 16C20.65 15.641 20.359 15.35 20 15.35H8Z"
    />
  </svg>
);

export default forwardRef(BulletsIcon);
