import { useCallback, useEffect, useState } from 'react';

import { AxiosRequestConfig } from 'axios';

import { CheckCircleIcon } from '@heroicons/react/24/outline';

import { globalAlertDataList } from '../../state/globalAlertDataList';
import { AlertTheme } from 'state/classes/AlertDataList';

import {
  MatchStatus,
  ModelConfig,
} from '../../models/matcher/BaseHeaderMatcher';
import { CustomerHeaderMatcher } from '../../models/matcher/CustomerHeaderMatcher';
import { ProductHeaderMatcher } from '../../models/matcher/ProductHeaderMatcher';

import { genericErrorFeedback } from 'utils/errors';
import { httpGetV1, httpPostV1 } from 'utils/xhr';

import { Page } from '../layout/Page/Page';
import { CreateMatchingDialog } from './CreateMatchingDialog';
import { FieldsMatching } from './FieldsMatching';
import { UploadConfirm } from './UploadConfirm';
import { UploadMatcherFile } from './UploadMatcherFile';

type Matcher = ProductHeaderMatcher | CustomerHeaderMatcher;

interface Props {
  matchers: Matcher[];
  setMatchers: React.Dispatch<React.SetStateAction<Matcher[]>>;
  selectedMatcher: Matcher;
  setSelectedMatcher: React.Dispatch<React.SetStateAction<Matcher>>;
  getMatchingStates: (
    matcher: Matcher,
    previewHeaders: string[],
  ) => MatchStatus[];

  model: 'product' | 'customer';
  modelConfig: ModelConfig;

  confirmedHeaders: string[];
  confirmedItems: string[][];
  setConfirmContent: (
    previewHeaders: string[],
    previewItems: string[][],
  ) => void;

  navigateToListPage: () => void;
}

type StepType = {
  name: string;
  status: string;
};

type TimeLineProp = {
  steps: StepType[];
  onClick: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    name: string,
  ) => void;
};

const TimeLine = ({ steps, onClick }: TimeLineProp) => (
  <nav className="flex w-52" aria-label="Progress">
    <ol className="space-y-6">
      {steps.map((step) => (
        <li key={step.name}>
          {step.status === 'complete' && (
            <div
              className="group"
              onClick={(e) => onClick(e, step.name)}
              onKeyDown={() => {}}
              role="button"
              tabIndex={0}
            >
              <span className="flex items-start">
                <span className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center">
                  <CheckCircleIcon
                    className="h-full w-full text-primary-500 group-hover:text-primary-600"
                    aria-hidden="true"
                  />
                </span>
                <span className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                  {step.name}
                </span>
              </span>
            </div>
          )}

          {step.status !== 'complete' && step.status === 'current' && (
            <div
              className="flex cursor-pointer items-start"
              aria-current="step"
            >
              <span
                className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center"
                aria-hidden="true"
              >
                <span className="absolute h-4 w-4 rounded-full bg-indigo-200" />
                <span className="relative block h-2 w-2 rounded-full bg-primary-500" />
              </span>
              <span className="ml-3 text-sm font-medium text-primary-500">
                {step.name}
              </span>
            </div>
          )}

          {step.status !== 'complete' && step.status !== 'current' && (
            <div className="group cursor-pointer">
              <div className="flex items-start">
                <div
                  className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center"
                  aria-hidden="true"
                >
                  <div className="h-2 w-2 rounded-full bg-gray-300 group-hover:bg-gray-400" />
                </div>
                <p className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                  {step.name}
                </p>
              </div>
            </div>
          )}
        </li>
      ))}
    </ol>
  </nav>
);

const defaultSteps = [
  { name: 'Upload Your File', status: 'current' },
  { name: 'Fields Matching', status: 'upcoming' },
  { name: 'Review & Confirm', status: 'upcoming' },
];

const ImportMain = ({
  matchers,
  setMatchers,
  selectedMatcher,
  setSelectedMatcher,
  getMatchingStates,
  model,
  modelConfig,
  confirmedHeaders,
  confirmedItems,
  setConfirmContent,
  navigateToListPage,
}: Props) => {
  const showMatchingPreviewText = false;

  const [isLoading, setIsLoading] = useState(false);
  const [animate, setAnimate] = useState(0);
  const [animateDelayed, setAnimateDelayed] = useState(0);
  const [steps, setSteps] = useState<StepType[]>(defaultSteps);
  const [currentStep, setCurrentStep] = useState<StepType>();

  const [file, setFile] = useState<File>(null);
  const [previewHeaders, setPreviewHeaders] = useState<string[]>([]);
  const [previewItems, setPreviewItems] = useState<string[][]>([]);

  const [openCreateMatching, setOpenCreateMatching] = useState(false);

  const [matchingStates, setMatchingStates] = useState<MatchStatus[]>([]);

  const getMatchers = async () => {
    setIsLoading(true);
    await httpGetV1(`/erp/header_matcher/${model}`)
      .then((response) => {
        const entries = response.data.result || [];

        const sortedEntries: Matcher[] = entries.map((matcher: Matcher) => {
          const temp = getMatchingStates(matcher, previewHeaders);
          const isMatched = temp.filter((item) => !item.matched).length === 0;
          return { ...matcher, isMatched };
        });

        sortedEntries.sort(
          (a, b) => (b.isMatched ? 1 : 0) - (a.isMatched ? 1 : 0),
        );

        if (sortedEntries.length) {
          setMatchers(sortedEntries);
          setSelectedMatcher(sortedEntries[0]);
        }
      })
      .catch((err) => {
        genericErrorFeedback('Error loading matchers')(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const resetSteps = () => {
    setSteps(defaultSteps);
    setFile(null);
    setPreviewHeaders([]);
    setPreviewItems([]);
  };

  const onStepClicked = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    name: string,
  ) => {
    e.preventDefault();
    let currentIdx = -1;

    for (let i = 0; i < steps.length; i += 1) if (steps[i].name === currentStep.name) currentIdx = i;

    if (currentStep.name === defaultSteps[0].name) await getMatchers();
    if (currentStep.name === defaultSteps[1].name) setConfirmContent(previewHeaders, previewItems);

    setSteps((s) => s.map((item, idx) => {
      if (item.name === name) return { ...item, status: 'current' };
      if (item.status === 'current') {
        if (idx <= currentIdx) return { ...item, status: 'complete' };
        return { ...item, status: 'upcoming' };
      }

      return item;
    }),
    );
  };

  const onStepBackClicked = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    name: string,
  ) => {
    e.preventDefault();
    let currentIdx = -1;

    for (let i = 0; i < steps.length; i += 1) if (steps[i].name === currentStep.name) currentIdx = i;

    setSteps((s) => s.map((item, idx) => {
      if (item.name === name) return { ...item, status: 'upcoming' };
      if (idx + 1 === currentIdx) return { ...item, status: 'current' };

      return item;
    }),
    );
  };

  const onUploadClicked = useCallback(
    (isChecked: boolean) => {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('upload[]', file);
      formData.append(
        'data',
        JSON.stringify({
          batch_size: 512,
          soft_delete_excluded_documents: isChecked,
        }),
      );

      const config: AxiosRequestConfig<any> = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        transformRequest: null,
      };

      httpPostV1(`/erp/import/${model}/${selectedMatcher?.id}`, formData, config)
        .then(() => {
          resetSteps();
          navigateToListPage();
          globalAlertDataList.create(
            `${model} imported successfully`,
            AlertTheme.SUCCESS,
          );
        })
        .catch((error) => {
          genericErrorFeedback(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [file, navigateToListPage, selectedMatcher?.id, model],
  );

  useEffect(() => {
    setCurrentStep(steps.filter((item) => item.status === 'current')[0]);
  }, [steps, setCurrentStep]);

  useEffect(() => {
    if (setSteps && setCurrentStep) {
      resetSteps();
    }
  }, [setSteps, setCurrentStep]);

  useEffect(() => {
    if (selectedMatcher && previewHeaders) {
      const temp: MatchStatus[] = getMatchingStates(
        selectedMatcher,
        previewHeaders,
      );
      setMatchingStates(temp);

      if (temp.filter((item) => !item.matched).length > 0) {
        setSteps((s) => s.map((item) => {
          if (item.name === defaultSteps[2].name) return { ...item, status: 'upcoming' };
          return item;
        }),
        );
      }

      setAnimate((a) => a + 1);
      setTimeout(() => {
        setAnimateDelayed((a) => a + 1);
      }, 750);
    }
  }, [previewHeaders, selectedMatcher, getMatchingStates]);

  return (
    <Page>
      <div className="w-full px-2xl" style={{ height: 'calc(100vh - 56px)' }}>
        <div className="flex pt-3">
          <div className="pr-lg pt-6">
            <TimeLine steps={steps} onClick={onStepClicked} />
          </div>
          <div className="w-full overflow-hidden" id="table_parent">
            {currentStep && currentStep.name === defaultSteps[0].name && (
              <UploadMatcherFile
                file={file}
                setFile={setFile}
                previewHeaders={previewHeaders}
                setPreviewHeaders={setPreviewHeaders}
                previewItems={previewItems}
                setPreviewItems={setPreviewItems}
                setIsLoading={setIsLoading}
                onStepClicked={onStepClicked}
                resetSteps={resetSteps}
              />
            )}

            {currentStep && currentStep.name === defaultSteps[1].name && (
              <FieldsMatching
                showMatchingPreviewText={showMatchingPreviewText}
                animate={animate}
                animateDelayed={animateDelayed}
                previewItems={previewItems}
                matchingStates={matchingStates}
                matchers={matchers}
                selectedMatcher={selectedMatcher}
                setSelectedMatcher={setSelectedMatcher}
                setOpenCreateMatching={setOpenCreateMatching}
                onStepClicked={onStepClicked}
                onStepBackClicked={onStepBackClicked}
              />
            )}

            {currentStep && currentStep.name === defaultSteps[2].name && (
              <UploadConfirm
                isLoading={isLoading}
                selectedMatcher={selectedMatcher}
                confirmedHeaders={confirmedHeaders}
                confirmedItems={confirmedItems}
                onStepBackClicked={onStepBackClicked}
                onUploadClicked={onUploadClicked}
              />
            )}
          </div>
        </div>

        <CreateMatchingDialog
          open={openCreateMatching}
          setOpen={setOpenCreateMatching}
          model={model}
          modelConfig={modelConfig}
          inputHeaders={previewHeaders}
          getMatchers={getMatchers}
        />
      </div>
    </Page>
  );
};

export { ImportMain };
