import { twMerge } from 'tailwind-merge';

interface Props extends React.HTMLAttributes<HTMLTableCellElement> {
  children: React.ReactNode;

  isLast?: boolean;
}

const InfiniteScrollTableTd = ({ children, isLast, ...props }: Props) => (
  <td
    {...props}
    className={twMerge(
      'table-cell text-left px-1.5 py-2 relative',
      !isLast && 'border-r-[0.5px] border-solid border-violet-hue-selection-button-1',
      props.className,
    )}
  >
    {children}
  </td>
);

export default InfiniteScrollTableTd;
