import { twMerge } from 'tailwind-merge';

import { InformationCircleIcon } from 'components/icon/outline';

import { Tooltip } from './Tooltip';

interface Props {
  label: string | React.ReactNode;

  iconClassName?: string;
}

const InformationIconWithTooltip = ({ label, iconClassName }: Props) => (
  <Tooltip
    label={label}
    withArrow
    arrowSize={10}
    tooltipStyles={{
      padding: '24px',
    }}
  >
    <InformationCircleIcon
      className={twMerge(
        'w-4 h-4 cursor-pointer',
        iconClassName,
      )}
    />
  </Tooltip>
);

export default InformationIconWithTooltip;
