import { forwardRef, Ref, SVGProps } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props extends SVGProps<SVGSVGElement> {
  className?: string;
}

const PlusIcon = ({ className, ...props }: Props, ref: Ref<SVGSVGElement>) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 24"
    fill="none"
    stroke="currentColor"
    className={twMerge('w-6 h-6', className)}
    {...props}
  >
    <path
      d="M12.3336 17.6569L12.3336 6.34315M6.67676 12H17.9905"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default forwardRef(PlusIcon);
