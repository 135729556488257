import { Expose, Type } from 'class-transformer';

import { TypeSpec } from './schema';

export class Prompt {
  @Expose({ name: 'id' })
    id: string;

  @Expose({ name: 'name' })
    name: string;

  @Expose({ name: 'description' })
    description?: string;

  @Expose({ name: 'boundTypeSpecs' })
  @Type(() => Array<TypeSpec>)
    boundTypeSpecs: TypeSpec[];

  @Expose({ name: 'customerIds' })
    customerIds?: string[];
}
