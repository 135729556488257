import { Message } from 'features/message/models/Message';

import { parsePaginatedApiResponse } from 'utils';
import { httpGetV2 } from 'utils/xhr';

export interface FetchChatMessagesParams {
  cursor?: string;
  limit?: number;
}

interface Props {
  chatId: string;
  params: FetchChatMessagesParams;
}

export const fetchChatMessages = ({
  chatId,
  params,
}: Props) => {
  if (!chatId) {
    return Promise.resolve({
      result: [],
      cursor: null,
    });
  }

  return httpGetV2(`/chat/${chatId}/messages`, {
    params: {
      ...params,
      limit: params.limit || 20,
    },
    classType: Message,
  }).then((res) => parsePaginatedApiResponse<Message>(res));
};
