import { StateCreator } from 'zustand';

import { BusinessSettingsSlice } from './types';

import { genericErrorFeedback } from 'utils/errors';

import { fetchBusinessSettings } from 'features/order/api/fetchBusinessSettings';

export const createBusinessSettingsSlice: StateCreator<BusinessSettingsSlice, [], [], BusinessSettingsSlice> = (set) => ({
  businessSettings: null,
  isBusinessSettingsLoading: false,
  loadBusinessSettings: async () => {
    set({ isBusinessSettingsLoading: true });
    fetchBusinessSettings()
      .then((businessSettings) => {
        set({ businessSettings });
      })
      .catch((error) => {
        genericErrorFeedback('Error loading business settings')(error);
      })
      .finally(() => {
        set({ isBusinessSettingsLoading: false });
      });
  },
});
