import {
  useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useInfiniteQuery } from '@tanstack/react-query';

import { Order } from 'features/order/models';

import { Page } from 'components/layout/Page/Page';
import { OrderListTableWithExportButton, OrderSearchBar } from 'features/order/components/order-list';

import { genericErrorFeedback } from 'utils/errors';

import { FetchOrdersParams } from '../api';
import { ordersOptions } from '../queryOptions';

interface Props {
  navigateToOrder: (orderId: string) => void;
  navigateToProcessDraftById: (orderId: string) => void;
}

const OrderListPage = ({ navigateToOrder, navigateToProcessDraftById }: Props) => {
  const { t } = useTranslation();

  const [orders, setOrders] = useState<Order[]>([]);
  const [params, setParams] = useState<FetchOrdersParams>({});

  const {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
    error,
  } = useInfiniteQuery({
    ...ordersOptions(params),
  });

  const onOrderDetailsButtonClick = useCallback((orderId: string, isDraft: boolean) => {
    if (isDraft) {
      navigateToProcessDraftById(orderId);
    } else {
      navigateToOrder(orderId);
    }
  }, [navigateToOrder, navigateToProcessDraftById]);

  useEffect(() => {
    setOrders(data?.pages.flatMap((page) => page.result) ?? []);
  }, [data]);

  useEffect(() => {
    if (error) {
      genericErrorFeedback(t('error.fetchingOrders'))(error);
    }
  }, [error, t]);

  return (
    <Page
      pageTitle={t('order.list.title')}
      pageDescription={t('order.list.description')}
      className="p-4"
      contentWithBorder
    >
      <div className="flex justify-between gap-lg">
        <OrderSearchBar
          params={params}
          setParams={setParams}
          onResetFilters={() => setParams({})}
        />
      </div>

      <OrderListTableWithExportButton
        orders={orders}
        setOrders={setOrders}
        onDetailsButtonClick={onOrderDetailsButtonClick}
        isLoading={isFetching}
        hasNextPage={hasNextPage}
        onScrolledToEnd={fetchNextPage}
      />
    </Page>
  );
};

export default OrderListPage;
