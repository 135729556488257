import { forwardRef, Ref, SVGProps } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props extends SVGProps<SVGSVGElement> {
  className?: string;
}

const ArrowRightIcon = ({ className, ...props }: Props, ref: Ref<SVGSVGElement>) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    className={twMerge('w-6 h-6', className)}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6283 6.57424C13.3931 6.34084 13.0132 6.34231 12.7798 6.57753C12.5464 6.81275 12.5478 7.19264 12.783 7.42605L16.5433 11.1574L6.00039 11.1574C5.66902 11.1574 5.40039 11.426 5.40039 11.7574C5.40039 12.0887 5.66902 12.3574 6.00039 12.3574L16.5432 12.3574L12.783 16.0886C12.5478 16.322 12.5464 16.7019 12.7798 16.9371C13.0132 17.1723 13.3931 17.1738 13.6283 16.9404L18.3866 12.2187C18.3914 12.214 18.3961 12.2091 18.4008 12.2043C18.5233 12.0944 18.6004 11.9349 18.6004 11.7574C18.6004 11.5802 18.5236 11.4209 18.4014 11.3111C18.3966 11.306 18.3917 11.3009 18.3866 11.2959L13.6283 6.57424Z"
    />
  </svg>
);

export default forwardRef(ArrowRightIcon);
