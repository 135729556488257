// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RDnW9XxREoOhMQIycVgk {\n    border: 1px solid var(--light-grey);\n    box-shadow: var(--blue-light-tint-drop-shadow);\n}\n\n.Zqy0v9MUdZhxPpbECA7I {\n    border-color: var(--light-grey);\n}", "",{"version":3,"sources":["webpack://./src/components/ui/Menu/Menu.module.css"],"names":[],"mappings":"AAAA;IACI,mCAAmC;IACnC,8CAA8C;AAClD;;AAEA;IACI,+BAA+B;AACnC","sourcesContent":[".dropdown {\n    border: 1px solid var(--light-grey);\n    box-shadow: var(--blue-light-tint-drop-shadow);\n}\n\n.arrow {\n    border-color: var(--light-grey);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": "RDnW9XxREoOhMQIycVgk",
	"arrow": "Zqy0v9MUdZhxPpbECA7I"
};
export default ___CSS_LOADER_EXPORT___;
