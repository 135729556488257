import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { twMerge } from 'tailwind-merge';

import LoadingOverlay from '../LoadingOverlay';
import { InfiniteScrollTableContext } from './InfiniteScrollTable.context';
import InfiniteScrollTableTbody from './InfiniteScrollTableTbody';
import InfiniteScrollTableTd from './InfiniteScrollTableTd';
import InfiniteScrollTableTh from './InfiniteScrollTableTh';
import InfiniteScrollTableThead from './InfiniteScrollTableThead';
import InfiniteScrollTableTr from './InfiniteScrollTableTr';

interface Props extends React.HTMLAttributes<HTMLTableElement> {
  children: React.ReactNode;
  onScrolledToEnd: () => void;
  isLoading: boolean;
  hasNextPage: boolean;
}

const InfiniteScrollTable = ({
  children,
  onScrolledToEnd,
  isLoading,
  hasNextPage,
  ...props
}: Props) => {
  const { t } = useTranslation();

  const value = useMemo(() => ({ onScrolledToEnd, isLoading, hasNextPage }), [onScrolledToEnd, isLoading, hasNextPage]);

  return (
    <InfiniteScrollTableContext.Provider value={value}>
      <div
        className="relative w-full h-full overflow-auto"
      >
        <div className="w-full h-fit overflow-hidden">
          <div className="w-full h-full overflow-auto border-x-[0.5px] border-t-[0.5px] border-solid border-violet-hue-selection-button-1 rounded-t-md">
            <table
              {...props}
              className={twMerge(
                'w-full',
                props.className,
              )}
            >
              {children}
            </table>
          </div>
        </div>

        {
        !hasNextPage && !isLoading && (
          <div className="w-full flex justify-center text-center text-sm text-gray-400 pt-4">
            {t('table.noMoreData')}
          </div>
        )
      }

        <div className="relative w-full h-10 flex justify-center">
          <LoadingOverlay visible={isLoading} />
        </div>
      </div>
    </InfiniteScrollTableContext.Provider>
  );
};

InfiniteScrollTable.Thead = InfiniteScrollTableThead;
InfiniteScrollTable.Tbody = InfiniteScrollTableTbody;
InfiniteScrollTable.Th = InfiniteScrollTableTh;
InfiniteScrollTable.Td = InfiniteScrollTableTd;
InfiniteScrollTable.Tr = InfiniteScrollTableTr;

export default InfiniteScrollTable;
