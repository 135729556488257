import { v4 as uuidv4 } from 'uuid';

import { Prompt } from '../models';

import { Pipeline } from '../types';

import { httpGetV1 } from 'utils/xhr';

export const fetchPromptByCustomerId = async (
  pipeline: Pipeline,
  customerId: string,
) => httpGetV1(`/llm/pipelines/${pipeline}/prompts`, {
  params: {
    customer_id: customerId,
  },
})
  .then((response) => {
    const prompt = response.data as Prompt;
    const promptWithUiId: Prompt = {
      ...prompt,
      boundTypeSpecs: prompt.boundTypeSpecs.map((typeSpec) => ({
        ...typeSpec,
        fields: typeSpec.fields.map((field) => ({
          ...field,
          uiId: uuidv4(),
        })),
      })),
    };
    return promptWithUiId;
  });
