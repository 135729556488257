import { StateCreator } from 'zustand';

import { AnnotationSlice, ProcessOrderDraftsStore } from './types';

import { Annotation } from 'components/chat/Chat/ChatMessage/ImageMessage/ImageOverlay/KonvaStage/type';

import { isPointInSquare } from 'components/chat/Chat/ChatMessage/ImageMessage/ImageOverlay/KonvaStage/utils';

export const createAnnotationSlice: StateCreator<
ProcessOrderDraftsStore,
[],
[],
AnnotationSlice
> = (set, get) => ({
  annotationsRecord: {},
  setAnnotations: (key: string, annotations: Annotation[]) => {
    set((state) => ({
      annotationsRecord: {
        ...state.annotationsRecord,
        [key]: annotations,
      },
    }));
  },
  setAnnotationIsLoading: (key: string, annotationKey: string, isLoading: boolean) => {
    set((state) => ({
      annotationsRecord: {
        ...state.annotationsRecord,
        [key]: state.annotationsRecord[key].map((annotation) => (annotation.key === annotationKey
          ? { ...annotation, isLoading } : annotation)),
      },
    }));
  },
  removeAnnotationByPoint: (key: string, x: number, y: number, orderId_?: string) => {
    const orderId = orderId_ || get().selectedOrderId;

    set((state) => {
      const newRecord = { ...state.annotationsRecord };

      let didRemove = false;

      newRecord[key] = newRecord[key].filter((annotation) => {
        const toErase = isPointInSquare(x, y, annotation);

        if (toErase) {
          (annotation.productUiIds || []).forEach((productUiId) => {
            // TODO(chihirokuya): rewrite this logic where uiID and position ID is just mixed...
            const p = get().products[orderId]?.find(
              (product) => product.uiId === productUiId || product.positionId === productUiId);
            if (p) {
              get().removeProductByUiIdAndPositionId(p.uiId, p.positionId, orderId);
            }
          });
          didRemove = true;
        }

        return !toErase;
      });

      if (!didRemove) {
        return {};
      }

      return { annotationsRecord: newRecord };
    });
  },
  addAnnotationProductUiIds: (key: string, annotationKey: string, productUiIds: string[]) => {
    set((state) => ({
      annotationsRecord: {
        ...state.annotationsRecord,
        [key]: state.annotationsRecord[key].map((annotation) => (annotation.key === annotationKey
          ? { ...annotation, productUiIds } : annotation)),
      },
    }));
  },
  addAnnotationPopupContent: (key: string, annotationKey: string, popupContent: string) => {
    set((state) => ({
      annotationsRecord: {
        ...state.annotationsRecord,
        [key]: state.annotationsRecord[key].map((annotation) => (annotation.key === annotationKey
          ? { ...annotation, popupContent } : annotation)),
      },
    }));
  },
  addAnnotationPath: (key: string, annotationKey: string, path: string) => {
    set((state) => ({
      annotationsRecord: {
        ...state.annotationsRecord,
        [key]: state.annotationsRecord[key].map((annotation) => (annotation.key === annotationKey
          ? { ...annotation, path } : annotation)),
      },
    }));
  },
});
