import { faArrowRight, faArrowRightArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mantine/core';

import { QuantityConversionPopupContent } from 'features/product/types/product';

interface Props {
  quantityPopupContent: QuantityConversionPopupContent | null;
  onQuantityPopupCanceled: () => void;
  onQuantityPopupConfirmed: () => void;
}

const ContentQuantityConversion = ({
  quantityPopupContent, onQuantityPopupCanceled, onQuantityPopupConfirmed,
}: Props) => (
  <>
    {/* Title */}
    <div className="flex items-center gap-1">
      <FontAwesomeIcon icon={faArrowRightArrowLeft} className="text-primary-500" />
      <h1 className="text-lg font-bold">
        Confirm Quantity Conversion
      </h1>
    </div>
    <div className="flex justify-center items-center gap-1 py-1">
      {quantityPopupContent?.prevQuantity}
      <FontAwesomeIcon icon={faArrowRight} />
      {quantityPopupContent?.newQuantity}
    </div>
    <p className="text-sm text-gray-700">
      Would you like to convert this quantity based on the unit change?
    </p>
    <div className="flex justify-end pt-1">
      <div className="flex items-center gap-2">
        <Button
          variant="outline"
          size="sm"
          onClick={onQuantityPopupCanceled}
        >
          Cancel
        </Button>
        <Button
          onClick={onQuantityPopupConfirmed}
          size="sm"
        >
          Confirm
        </Button>
      </div>
    </div>
  </>
);

export default ContentQuantityConversion;
