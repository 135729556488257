import { twMerge } from 'tailwind-merge';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mantine/core';

import { ROUTES } from 'config/routes';

import { Message, MessageRefType } from 'features/message/models/Message';

import { OverflowEllipsisParagraph } from 'components/ui/OverflowEllipsisParagraph';
import { MessageTags } from 'features/message/components';

import { formatEventTime } from 'utils/dateTime';

interface Props {
  message: Message;
  newMessageIds: Set<string>;
  navigate: (path: string) => void;
}

const getSource = (message: Message) => {
  if (message.businessSentByInfo?.name) return message.businessSentByInfo?.name;

  if (message.context?.from) return message.context.from.join(', ');

  return 'Unknown';
};

const DashboardInboxItem = ({ message, newMessageIds, navigate }: Props) => (
  <div
    key={message.id}
    role="button"
    tabIndex={0}
    onKeyDown={null}
    onClick={() => navigate(ROUTES.THREAD_BY_ID(message.threadId))}
    className={twMerge(
      'group inline-flex h-fit w-full items-start justify-start gap-2 border-b border-gray-100 p-3 last:border-b-0 hover:cursor-pointer hover:bg-gray-50',
      newMessageIds.has(message.id) && 'animate-slide-in-blink',
    )}
  >
    <div className="flex-1 space-y-1 w-full">
      <div className="flex items-center justify-between gap-2">
        <OverflowEllipsisParagraph
          className="text-base font-medium text-black flex-1"
          maxLines={1}
          isTooltipEnabled
        >
          {getSource(message)}
        </OverflowEllipsisParagraph>
        <OverflowEllipsisParagraph
          className="max-w-[30%] text-xs font-light text-[#232d42]/60"
          maxLines={1}
          isTooltipEnabled
        >
          {formatEventTime(new Date(message.createdAt))}
        </OverflowEllipsisParagraph>
      </div>
      <MessageTags message={message} />
      <OverflowEllipsisParagraph
        className="text-sm text-[#232d42]/60"
        maxLines={1}
      >
        {message.context?.subject || message.message}
      </OverflowEllipsisParagraph>

      {message.ref && (
        <div className="max-h-0 overflow-hidden w-full flex justify-end transition-all duration-300 ease-in-out group-hover:max-h-20">
          <Button
            radius="xl"
            size="xs"
            rightSection={<FontAwesomeIcon icon={faArrowRight} />}
            onClick={(e) => {
              e.stopPropagation();
              navigate(ROUTES.PROCESS_ORDER_DRAFT_BY_ID(message.ref, message.refType === MessageRefType.ORDER_GROUP));
            }}
          >
            Process Order
          </Button>
        </div>
      )}
    </div>
  </div>
);

export default DashboardInboxItem;
