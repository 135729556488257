interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
}

const DatePickersLeftSectionButton = ({ label, ...props }: Props) => (
  <button
    {...props}
    type="button"
    className="flex flex-col items-start gap-1 px-2 py-1 relative self-stretch w-full flex-[0_0_auto] rounded-[5px] hover:bg-[#1e3d7a0f]"
  >
    <div className="relative w-fit mt-[-1.00px] font-font-screen-xs-regular font-[number:var(--font-screen-xs-regular-font-weight)] text-black text-[length:var(--font-screen-xs-regular-font-size)] tracking-[var(--font-screen-xs-regular-letter-spacing)] leading-[var(--font-screen-xs-regular-line-height)] whitespace-nowrap [font-style:var(--font-screen-xs-regular-font-style)]">
      {label}
    </div>
  </button>
);

export default DatePickersLeftSectionButton;
