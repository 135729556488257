import {
  useCallback, useState,
} from 'react';

import { AlertTheme } from 'state/classes/AlertDataList';
import { globalAlertDataList } from 'state/globalAlertDataList';

import {
  BusinessSector, BusinessTime, ErpConnectionSettings,
} from 'features/settings/models';

import { SettingSection } from './Feed/types';

import { useFetchBusinessSettings } from 'hooks/fetch/useFetchBusinessSettings';

import { valueToDecimalComma } from 'features/settings/utils';
import { genericErrorFeedback } from 'utils/errors';
import { httpPutV1 } from 'utils/xhr';

import { Feed } from './Feed';
import { BasicSettings, ERPModels, ERPOverview } from './setting-card';

const settingSections: SettingSection[] = [
  {
    title: 'Basic Settings',
    sectionId: 'basic_settings',
  },
  {
    title: 'ERP Integration',
    sectionId: 'erp_integration',
    children: [
      {
        title: 'Overview',
        sectionId: 'erp_integration_overview',
      },
      {
        title: 'Models',
        sectionId: 'erp_integration_models',
      },
    ],
  },
];

  // TODO(chihirokuya): all these logics should be moved a hook
  type UpdateBusinessSettingsParams = {
    defaultCurrency?: string;

    businessTimes?: BusinessTime[];

    decimalComma?: ',' | '.' | '';

    defaultTranscriptionLanguageCode?: string;

    erpConnectionSettings?: ErpConnectionSettings;

    sector?: BusinessSector;
  };

const sectionIds = [
  ...settingSections
    .filter((s) => !s.children || s.children.length === 0)
    .map((s) => s.sectionId),
  ...settingSections
    .filter((s) => s.children && s.children.length > 0)
    .map((s) => s.children?.map((c) => c.sectionId))
    .flat(),
];

const BusinessSettings = () => {
  const {
    businessSettings,
    setBusinessSettings,
    isLoading: isLoadingSettings,
  } = useFetchBusinessSettings({});

  const [toUpdateFields, setToUpdateFields] = useState<UpdateBusinessSettingsParams>();

  const [currentSectionId, setCurrentSectionId] = useState<string>(
    settingSections[0].sectionId,
  );
  const [isLoadingSave, setIsLoadingSave] = useState(false);

  const isLoading = isLoadingSettings || isLoadingSave;

  const onDefaultCurrencyChange = useCallback((value: string) => {
    setToUpdateFields((prev) => ({
      ...prev,
      defaultCurrency: value,
    }));

    setBusinessSettings((bs) => ({
      ...bs,
      defaultCurrency: value,
    }));
  }, [setBusinessSettings]);

  const onBusinessSectorChange = useCallback((value: BusinessSector) => {
    setToUpdateFields((prev) => ({
      ...prev,
      sector: value,
    }));

    setBusinessSettings((bs) => ({
      ...bs,
      sector: value,
    }));
  }, [setBusinessSettings]);

  const onDecimalCommaChange = useCallback((value: 'Dot (0.1)' | 'Comma (0,1)') => {
    setToUpdateFields((prev) => ({
      ...prev,
      decimalComma: valueToDecimalComma(value),
    }));

    setBusinessSettings((bs) => ({
      ...bs,
      decimalComma: valueToDecimalComma(value),
    }));
  }, [setBusinessSettings]);

  const onDefaultTranscriptionLanguageChange = useCallback((value: string) => {
    setToUpdateFields((prev) => ({
      ...prev,
      defaultTranscriptionLanguageCode: value,
    }));

    setBusinessSettings((bs) => ({
      ...bs,
      defaultTranscriptionLanguageCode: value,
    }));
  }, [setBusinessSettings]);

  const onAddBusinessTimesIntervalButtonClick = useCallback(() => {
    setToUpdateFields((prev) => ({
      ...prev,
      businessTimes: [
        ...(prev?.businessTimes || []),
        {
          from: null,
          to: null,
        },
      ],
    }));

    setBusinessSettings((bs) => ({
      ...bs,
      businessTimes: [
        ...(bs.businessTimes || []),
        {
          from: null,
          to: null,
        },
      ],
    }));
  }, [setBusinessSettings]);

  const onBusinessTimesTimeChange = useCallback(
    (index: number, from: string, to: string) => {
      setToUpdateFields((prev) => ({
        ...prev,
        businessTimes: prev?.businessTimes?.map((bt, i) => {
          if (i === index) {
            return {
              ...bt,
              from,
              to,
            };
          }
          return bt;
        }),
      }));

      setBusinessSettings((bs) => ({
        ...bs,
        businessTimes: (bs.businessTimes || []).map((bt, i) => {
          if (i === index) {
            return {
              ...bt,
              from,
              to,
            };
          }
          return bt;
        }),
      }));
    },
    [setBusinessSettings],
  );

  const onRemoveBusinessTimesIntervalButtonClick = useCallback(
    (index: number) => {
      setToUpdateFields((prev) => ({
        ...prev,
        businessTimes: prev?.businessTimes?.filter((_, i) => i !== index),
      }));

      setBusinessSettings((bs) => ({
        ...bs,
        businessTimes: bs.businessTimes?.filter((_, i) => i !== index),
      }));
    },
    [setBusinessSettings],
  );

  const onSaveBusinessDetailsButtonClick = useCallback(() => {
    setIsLoadingSave(true);
    httpPutV1('/businesses/me/settings', {
      ...toUpdateFields,
      businessTimes: toUpdateFields?.businessTimes?.filter(
        (bt) => bt.from && bt.to,
      ),
    })
      .then(() => {
        globalAlertDataList.create('Business settings saved correctly', AlertTheme.SUCCESS);
      })
      .catch(genericErrorFeedback('Error saving business settings'))
      .finally(() => {
        setIsLoadingSave(false);
      });
  }, [toUpdateFields]);

  const handleScroll = () => {
    for (let i = 0; i < sectionIds.length; i += 1) {
      const section = document.getElementById(sectionIds[i]);
      if (!section) return;
      if (section.getBoundingClientRect().top >= 0) {
        setCurrentSectionId(sectionIds[i]);
        break;
      }
    }
  };
  return (
    <div className="flex h-full space-x-4 overflow-hidden">
      <Feed
        sections={settingSections}
        currentSectionId={currentSectionId}
      />
      <div
        className="h-full flex-1 overflow-y-auto"
        onScroll={handleScroll}
      >
        <div className="flex-1 space-y-4 pb-[200px]">
          <BasicSettings
            businessSettings={businessSettings}
            onBusinessTimesTimeChange={onBusinessTimesTimeChange}
            onRemoveBusinessTimesIntervalButtonClick={
                    onRemoveBusinessTimesIntervalButtonClick
                  }
            onAddBusinessTimesIntervalButtonClick={
                    onAddBusinessTimesIntervalButtonClick
                  }
            onDefaultCurrencyChange={onDefaultCurrencyChange}
            onBusinessSectorChange={onBusinessSectorChange}
            onDecimalCommaChange={onDecimalCommaChange}
            onDefaultTranscriptionLanguageChange={onDefaultTranscriptionLanguageChange}
            onSaveButtonClick={onSaveBusinessDetailsButtonClick}
            isLoading={isLoading}
          />

          <ERPOverview businessSettings={businessSettings} />

          <ERPModels businessSettings={businessSettings} />
        </div>
      </div>
    </div>
  );
};

export default BusinessSettings;
