import { AvgTimeToFinalize } from '../models';

import { httpGetV1 } from 'utils/xhr';

type GetFinalizedOrdersAverageTimeRequest = {
  from_time: Date;
  to_time?: Date;
  granularity?: string;
  normalized_time?: boolean; // Whether to normalize the time to finalize orders by the number of products in the orders
};

export const fetchAvgTimeToFinalize = (request: GetFinalizedOrdersAverageTimeRequest): Promise<AvgTimeToFinalize> => httpGetV1('/orders/metrics/processed_orders/time/average', {
  params: request,
  classType: AvgTimeToFinalize,
})
  .then((response) => response.data);
