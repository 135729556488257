import { startOfToday } from 'date-fns';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { SalesMetric } from '../types/metric';
import { TimePeriod } from '../types/timePeriod';

interface AnalyticsStore {
  finalizationTimeAndCorrectionsTimePeriod: TimePeriod;
  salesTrendsTimePeriod: TimePeriod;
  topSellingProductsTimePeriod: TimePeriod;
  topTierCustomersTimePeriod: TimePeriod;
  setFinalizationTimeAndCorrectionsTimePeriod: (timePeriod: TimePeriod) => void;
  setSalesTrendsTimePeriod: (timePeriod: TimePeriod) => void;
  setTopSellingProductsTimePeriod: (timePeriod: TimePeriod) => void;
  setTopTierCustomersTimePeriod: (timePeriod: TimePeriod) => void;

  salesTrendsMetric: SalesMetric;
  setSalesTrendsMetric: (salesMetric: SalesMetric) => void;
  topSellingProductsMetric: SalesMetric;
  setTopSellingProductsMetric: (salesMetric: SalesMetric) => void;
  topTierCustomersMetric: SalesMetric;
  setTopTierCustomersMetric: (salesMetric: SalesMetric) => void;

  topSellingProductsDate: Date | null;
  setTopSellingProductsDate: (date: Date | null) => void;
  topTierCustomersDate: Date | null;
  setTopTierCustomersDate: (date: Date | null) => void;
}

export const useAnalyticsStore = create<AnalyticsStore>()(
  persist(
    (set) => ({
      finalizationTimeAndCorrectionsTimePeriod: TimePeriod.Daily,
      salesTrendsTimePeriod: TimePeriod.Daily,
      topSellingProductsTimePeriod: TimePeriod.Daily,
      topTierCustomersTimePeriod: TimePeriod.Daily,

      setFinalizationTimeAndCorrectionsTimePeriod: (timePeriod: TimePeriod) => (
        set({ finalizationTimeAndCorrectionsTimePeriod: timePeriod })),
      setSalesTrendsTimePeriod: (timePeriod: TimePeriod) => set({ salesTrendsTimePeriod: timePeriod }),
      setTopSellingProductsTimePeriod: (timePeriod: TimePeriod) => set({ topSellingProductsTimePeriod: timePeriod }),
      setTopTierCustomersTimePeriod: (timePeriod: TimePeriod) => set({ topTierCustomersTimePeriod: timePeriod }),

      salesTrendsMetric: SalesMetric.TotalProductsSold,
      setSalesTrendsMetric: (salesMetric: SalesMetric) => set({ salesTrendsMetric: salesMetric }),
      topSellingProductsMetric: SalesMetric.TotalProductsSold,
      setTopSellingProductsMetric: (salesMetric: SalesMetric) => set({ topSellingProductsMetric: salesMetric }),
      topTierCustomersMetric: SalesMetric.TotalProductsSold,
      setTopTierCustomersMetric: (salesMetric: SalesMetric) => set({ topTierCustomersMetric: salesMetric }),

      topSellingProductsDate: startOfToday(),
      setTopSellingProductsDate: (date: Date | null) => set({ topSellingProductsDate: date }),
      topTierCustomersDate: startOfToday(),
      setTopTierCustomersDate: (date: Date | null) => set({ topTierCustomersDate: date }),
    }),
    {
      name: 'analytics-storage',
      partialize: (state) => ({
        finalizationTimeAndCorrectionsTimePeriod: state.finalizationTimeAndCorrectionsTimePeriod,
        salesTrendsTimePeriod: state.salesTrendsTimePeriod,
        topSellingProductsTimePeriod: state.topSellingProductsTimePeriod,
        topTierCustomersTimePeriod: state.topTierCustomersTimePeriod,

        salesTrendsMetric: state.salesTrendsMetric,
        topSellingProductsMetric: state.topSellingProductsMetric,
        topTierCustomersMetric: state.topTierCustomersMetric,
      }),
    },
  ),
);
