import { MessageIntents } from './classes/MessageIntents';

let globalMessageIntents: MessageIntents = null;

const initGlobalMessageIntents = async () => {
  globalMessageIntents = new MessageIntents();
  await globalMessageIntents.initPersistence();
};

export { globalMessageIntents, initGlobalMessageIntents };
