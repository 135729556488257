import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useInfiniteQuery } from '@tanstack/react-query';

import { FetchSubjectsParams } from '../apis';

import { Page } from 'components/layout/Page/Page';
import { SubjectSearchBar, SubjectsTable } from 'features/subject/components/subject-list';

import { subjectsOptions } from '../queryOptions';

const SubjectListPage = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState<FetchSubjectsParams>({});

  const {
    data,
    isFetching,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    ...subjectsOptions(params),
  });

  const onSearchClicked = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const query = formData.get('query');
    setParams({ query: query.toString() });
  };

  const onScrolledEnd = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  const subjects = data?.pages.flatMap((page) => page.result) ?? [];
  console.log('[subjects]', subjects);

  return (
    <Page
      pageTitle={t('subject.list.title')}
      pageDescription={t('subject.list.description')}
      className="p-4"
      contentWithBorder
    >
      <div className="flex justify-between gap-lg">
        <SubjectSearchBar onSubmit={onSearchClicked} />
      </div>

      <SubjectsTable
        subjects={subjects}
        hasNextPage={hasNextPage}
        isLoading={isFetching}
        onScrolledToEnd={onScrolledEnd}
      />
    </Page>
  );
};

export default SubjectListPage;
