import {
  Fragment, useCallback, useRef, useState,
} from 'react';

import { SyncLog } from 'features/erp/models';

import { InfiniteScrollTable, InfiniteScrollTableContextProps } from 'components/ui';

import SyncLogListTableRow from './SyncLogListTableRow';

interface Props extends InfiniteScrollTableContextProps {
  logs: SyncLog[];
}

const SyncLogListTable = ({
  logs,
  ...props
}: Props) => {
  const [selectedLogIds, setSelectedLogIds] = useState<string[]>([]);

  const checkbox = useRef<HTMLInputElement>();
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());

  const toggleAll = useCallback(() => {
    if (selectedLogIds.length === logs.length) {
      setSelectedLogIds([]);
    } else {
      setSelectedLogIds(logs.map((log) => log.id));
    }
  }, [logs, selectedLogIds]);

  const toggleRowExpansion = useCallback((logId: string) => {
    setExpandedRows((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(logId)) {
        newSet.delete(logId);
      } else {
        newSet.add(logId);
      }
      return newSet;
    });
  }, []);

  const onSelectionChange = useCallback((log: SyncLog) => {
    setSelectedLogIds((prev) => (prev.includes(log.id) ? prev.filter((p) => p !== log.id) : [...prev, log.id]));
  }, []);

  return (
    <InfiniteScrollTable
      className="min-w-full divide-y divide-gray-300"
      {...props}
    >
      <InfiniteScrollTable.Thead className="sticky top-0 z-10 bg-gray-50 text-xs font-medium text-gray-500 uppercase drop-shadow">
        <tr>
          <th scope="col" className="relative px-lg py-smd">
            <input
              type="checkbox"
              className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              ref={checkbox}
              checked={selectedLogIds.length === logs.length}
              onChange={toggleAll}
            />
          </th>
          <th
            scope="col"
            className="whitespace-nowrap px-lg py-smd text-left"
          >
            Timestamp
          </th>
          <th
            scope="col"
            className="whitespace-nowrap px-lg py-smd text-left"
          >
            Model
          </th>
          <th
            scope="col"
            className="whitespace-nowrap px-lg py-smd text-center"
          >
            Direction
          </th>
          <th
            scope="col"
            className="whitespace-nowrap px-lg py-smd text-center"
          >
            Status
          </th>
          <th
            scope="col"
            className="whitespace-nowrap px-lg py-smd text-left"
          >
            Details
          </th>
          <th
            scope="col"
            className="whitespace-nowrap px-lg py-smd text-left"
          >
            Operation ID
          </th>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <th scope="col" />
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <th scope="col" />
        </tr>
      </InfiniteScrollTable.Thead>
      <InfiniteScrollTable.Tbody>
        {logs.map((log) => (
          <Fragment key={log.id}>
            <SyncLogListTableRow
              log={log}
              isRowSelected={selectedLogIds.includes(log.id)}
              isRowExpanded={expandedRows.has(log.id)}
              toggleRowExpansion={toggleRowExpansion}
              onSelectionChange={onSelectionChange}
            />
          </Fragment>
        ))}
      </InfiniteScrollTable.Tbody>
    </InfiniteScrollTable>
  );
};

export default SyncLogListTable;
