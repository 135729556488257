import { twMerge } from 'tailwind-merge';

interface Props {
  label: string;
  isSelected: boolean;
  onClick: () => void;
}

const SettingsTab = ({ label, isSelected, onClick }: Props) => (
  <button
    type="button"
    className="inline-flex h-full py-2 px-2 flex-col items-start gap-1.5 relative flex-[0_0_auto] hover:bg-light-hue-box"
    onClick={onClick}
  >
    <p
      className="relative w-fit mt-[-1.00px] font-font-screen-md-regular font-[number:var(--font-screen-md-regular-font-weight)] text-black-text-70 text-[length:var(--font-screen-md-regular-font-size)] tracking-[var(--font-screen-md-regular-letter-spacing)] leading-[var(--font-screen-md-regular-line-height)] whitespace-nowrap [font-style:var(--font-screen-md-regular-font-style)]"
    >
      {label}
    </p>

    <div className={twMerge(
      'absolute bottom-0 left-0 w-full h-px bg-black-lighter-text',
      !isSelected && 'hidden',
    )}
    />
  </button>
);

export default SettingsTab;
