import { useCallback, useEffect, useState } from 'react';

import { GroupedFields } from 'features/instruction/types';

type UseUIStateReturnType = {
  selectedDocIndex: number;
  setSelectedDocIndex: (value: number) => void;
  selectedDocPageIndex: number;
  setSelectedDocPageIndex: (value: number) => void;
  selectedDocImgIndex: number;
  setSelectedDocImgIndex: (value: number) => void;

  accordionValue: string[]; // for group fields
  setAccordionValue: (value: string[]) => void;
  addAccordionValue: (id: string) => void;
  setFirstAccordionValue: (groupedFields: GroupedFields[]) => void;
  onAccordionItemClick: (groupKey: string) => void;

  productAccordionValue: string[]; // for product fields
  setProductAccordionValue: (value: string[]) => void;
  resetProductAccordionValue: () => void;
  addProductAccordionValue: (id: string) => void;
  removeProductAccordionValueById: (id: string) => void;
  addOrRemoveProductAccordionValue: (id: string) => void;
  confirmedFields: string[]; // for group fields
  setConfirmedFields: (value: string[]) => void;
  onConfirmField: (key: string, groupedFields: GroupedFields[]) => void;

  hoverId: string | null;
  triggerHoverEffect: (id: string) => void;
};

export const useUIState = ({
  resetTrigger,
}: {
  resetTrigger: number;
}): UseUIStateReturnType => {
  const [selectedDocIndex, setSelectedDocIndex] = useState(0);
  const [selectedDocPageIndex, setSelectedDocPageIndex] = useState(0);
  const [selectedDocImgIndex, setSelectedDocImgIndex] = useState(0);

  const [accordionValue, setAccordionValue] = useState<string[]>([]);
  const [productAccordionValue, setProductAccordionValue] = useState<string[]>([]);
  const [confirmedFields, setConfirmedFields] = useState<string[]>([]);
  const [hoverId, setHoverId] = useState<string | null>(null);

  const onAccordionItemClick = useCallback((groupKey: string) => {
    setAccordionValue((prev) => {
      if (prev.includes(groupKey)) {
        return prev.filter((item) => item !== groupKey);
      }

      return [...prev, groupKey];
    });
  }, [setAccordionValue]);

  const addAccordionValue = useCallback((id: string) => {
    setAccordionValue((prev) => {
      if (prev.includes(id)) return prev;

      return [...prev, id];
    });
  }, [setAccordionValue]);

  const setFirstAccordionValue = useCallback((_groupedFields: GroupedFields[]) => {
    setAccordionValue((prev) => {
      if (prev.length > 0 || _groupedFields.length === 0) return prev;

      const firstKey = _groupedFields[0]?.title;

      if (!firstKey) return [];

      return [firstKey];
    });
  }, []);

  const resetProductAccordionValue = useCallback(() => {
    setProductAccordionValue([]);
  }, []);

  const addProductAccordionValue = useCallback((id: string) => {
    setProductAccordionValue((prev) => [...prev, id]);
  }, []);

  const addOrRemoveProductAccordionValue = useCallback((id: string) => {
    setProductAccordionValue((prev) => (!prev.includes(
      id) ? [...prev, id] : prev.filter((id_) => id_ !== id)));
  }, []);

  const removeProductAccordionValueById = useCallback((id: string) => {
    setProductAccordionValue((prev) => prev.filter((item) => item !== id));
  }, []);

  const onConfirmField = useCallback((key: string, groupedFields: GroupedFields[]) => {
    setConfirmedFields((prevCFs) => {
      if (prevCFs.includes(key)) {
        return prevCFs.filter((item) => item !== key);
      }

      setAccordionValue((prevAV) => {
        let newAV = prevAV.filter((item) => item !== key);

        const keyIdx = groupedFields.findIndex((group) => group.title === key);
        if (keyIdx + 1 < groupedFields.length
          && !prevCFs.includes(groupedFields[keyIdx + 1].title)) {
          newAV = [...newAV, groupedFields[keyIdx + 1].title];
        }

        return newAV;
      });

      return [...prevCFs, key];
    });
  }, []);

  const triggerHoverEffect = useCallback((id: string) => {
    setHoverId(id);
  }, [setHoverId]);

  useEffect(() => {
    if (resetTrigger > 0) {
      setSelectedDocIndex(0);
      setSelectedDocPageIndex(0);
      setSelectedDocImgIndex(0);
    }
  }, [resetTrigger]);

  return {
    selectedDocIndex,
    selectedDocPageIndex,
    selectedDocImgIndex,
    setSelectedDocIndex,
    setSelectedDocPageIndex,
    setSelectedDocImgIndex,

    accordionValue,
    setAccordionValue,
    addAccordionValue,
    setFirstAccordionValue,
    onAccordionItemClick,

    productAccordionValue,
    setProductAccordionValue,
    resetProductAccordionValue,
    addProductAccordionValue,
    removeProductAccordionValueById,
    addOrRemoveProductAccordionValue,

    confirmedFields,
    setConfirmedFields,
    onConfirmField,

    hoverId,
    triggerHoverEffect,
  };
};
