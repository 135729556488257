//
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  isSameWeek, startOfToday, startOfWeek, subWeeks,
} from 'date-fns';
import { observer } from 'mobx-react-lite';

import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ShoppingCartIcon } from '@heroicons/react/24/outline';
import { Button } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';

import { ROUTES } from 'config/routes';

import { globalOrderDrafts } from 'state/globalOrderDrafts';
import { globalUser } from 'state/globalUser';

import { InformationIconWithTooltip } from 'components/ui';

import CircleBase from './CircleBase';
import { orderCountsOptions } from 'features/analytics/queryOptions/orderCountsOptions';

type DashboardOrderCounts = {
  today: number;
  yesterday: number;
  lastWeekAvg: number;
};

const DefaultOrderCounts: DashboardOrderCounts = {
  today: 0,
  yesterday: 0,
  lastWeekAvg: 0,
};

const UnprocessedOrders = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { data: orderCounts_, isLoading } = useQuery({
    ...orderCountsOptions(globalUser?.id),
    select: (data) => {
      const today = startOfToday();

      // set fromTime to monday of the last week
      const fromTime = startOfWeek(subWeeks(today, 1));
      // Get the avg of last week
      // Get all the data from last monday to sunday
      const lastWeekIndices = data.timeWindowsLowerBounds.map((time, index) => (
        isSameWeek(time, fromTime) ? index : null),
      ).filter((index) => index !== null);

      const lastWeekData = data.counts.filter((_, index) => lastWeekIndices.includes(index));

      const lastWeekAvg = Math.ceil(lastWeekData.reduce((acc, curr) => acc + curr, 0) / lastWeekData.length);

      return {
        today: data.counts[data.counts.length - 1],
        yesterday: data.counts[data.counts.length - 2],
        lastWeekAvg,
      };
    },
  });

  const orderCounts = orderCounts_ ?? DefaultOrderCounts;

  return (
    <div className="w-full h-full p-4 bg-[rgba(255,255,255,1.0)] rounded-[20px] bg-gradient-to-r from-[#8249F2] to-[#BE99F0] shadow-blue-tint-drop-shadow flex flex-col">
      <div className="h-6 justify-start items-center gap-2 inline-flex">
        <ShoppingCartIcon className="text-white w-5 h-5 3xl:w-6 3xl:h-6" />
        <div className="text-white text-sm 3xl:text-base font-semibold">
          {t('dashboard.unprocessedOrders.title')}
        </div>
        <InformationIconWithTooltip
          label={(
            <div className="w-[400px] whitespace-normal">
              <div className="text-[#191919]/75 text-sm">
                {t('dashboard.unprocessedOrders.description.main')}
              </div>

              <div className="space-y-2 mt-3.5">
                <div>
                  <div className="text-[#212020] text-sm font-semibold">{t('dashboard.unprocessedOrders.description.details.processedAvgLastWeek')}</div>
                  <div className="text-[#232d42]/60 text-[13px] font-normal font-['Inter']">{t('dashboard.unprocessedOrders.description.details.processedAvgLastWeek.description')}</div>
                </div>
                <div>
                  <div className="text-[#212020] text-sm font-semibold">{t('dashboard.unprocessedOrders.description.details.processedYesterday')}</div>
                  <div className="text-[#232d42]/60 text-[13px] font-normal font-['Inter']">{t('dashboard.unprocessedOrders.description.details.processedYesterday.description')}</div>
                </div>
                <div>
                  <div className="text-[#212020] text-sm font-semibold">{t('dashboard.unprocessedOrders.description.details.processedToday')}</div>
                  <div className="text-[#232d42]/60 text-[13px] font-normal font-['Inter']">{t('dashboard.unprocessedOrders.description.details.processedToday.description')}</div>
                </div>
              </div>
            </div>
          )}
          iconClassName="w-5 h-5 text-[rgba(255,255,255,0.6)] hover:text-white"
        />
      </div>

      <div className="flex-1 flex justify-start items-center gap-6">
        <div className="flex-1 flex justify-center items-center gap-6 relative">
          <CircleBase
            percentage={100}
            orderCount={orderCounts.lastWeekAvg}
            title="Last Week"
            subtitle="Processed avg."
            isLoading={isLoading}
          />

          <CircleBase
            percentage={orderCounts.lastWeekAvg ? (orderCounts.yesterday / orderCounts.lastWeekAvg) * 100 : 0}
            orderCount={orderCounts.yesterday}
            title="Yesterday"
            subtitle="Processed"
            opacity={0.6}
            isLoading={isLoading}
          />

          <CircleBase
            percentage={orderCounts.lastWeekAvg ? (orderCounts.today / orderCounts.lastWeekAvg) * 100 : 0}
            orderCount={orderCounts.today}
            title="Today"
            subtitle="Processed"
            opacity={1}
            isLoading={isLoading}
          />
        </div>

        <div className="w-[243px] pl-3 pr-12 3xl:pr-16 py-2 flex-col justify-start items-center gap-1 inline-flex">
          <div className="self-stretch text-center text-white text-sm 3xl:text-base">
            {t('dashboard.unprocessedOrders.count.title')}
          </div>
          <div className="text-center text-white text-5xl font-semibold">{globalOrderDrafts.count}</div>
          <div className="self-stretch text-center text-white text-sm 3xl:text-base">
            {t('dashboard.unprocessedOrders.count.description')}
          </div>
        </div>
      </div>

      <div className="flex justify-end pr-12 3xl:pr-16">
        <Button
          variant="white"
          radius="xl"
          rightSection={<FontAwesomeIcon icon={faChevronRight} />}
          onClick={() => navigate(ROUTES.PROCESS_ORDER_DRAFTS)}
          className="text-purple-highlight-2 hover:text-purple-highlight-1"
        >
          {t('dashboard.unprocessedOrders.count.button')}
        </Button>
      </div>
    </div>
  );
};

export default observer(UnprocessedOrders);
