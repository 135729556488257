import Dayjs from 'dayjs';
import { twMerge } from 'tailwind-merge';

import { User } from 'features/user/models';

interface Props {
  user: User;
}

const getRandomCircleColor = () => {
  const colors = [
    'text-blue-600 ring-blue-100 bg-blue-50',
    'text-orange-600 ring-orange-100 bg-orange-50',
    'text-red-600 ring-red-100 bg-red-50',
  ];
  return colors[Math.floor(Math.random() * colors.length)];
};

const getInitials = (user: User) => {
  if (!user) return '';

  let initials = '';

  if (user.firstName && user.lastName) {
    initials = `${user.firstName.substring(0, 1)}${user.lastName.substring(0, 1)}`;
  } else if (user.firstName) {
    initials = user.firstName.substring(0, 2);
  } else if (user.lastName) {
    initials = user.lastName.substring(0, 2);
  }

  return initials.toUpperCase();
};

const TeamMemberIcon = ({ user }: Props) => {
  const now = Dayjs();
  const userLastActivityAt = Dayjs(user?.updatedAt);
  const isRecentlyActive = !userLastActivityAt.isBefore(now.subtract(1, 'day'));

  return (
    <div
      className={twMerge(
        'relative flex size-20 items-center justify-center rounded-full text-2xl ring-1',
        getRandomCircleColor(),
      )}
    >
      {getInitials(user)}
      <div
        className={twMerge(
          'absolute bottom-0.5 left-16 flex size-2.5 -translate-y-1/2 transform rounded-full bg-success group',
          !isRecentlyActive ? 'bg-neutral-100' : '',
        )}
      >
        {isRecentlyActive && (
          <span className="absolute bottom-full left-1/2 mb-2 -translate-x-1/2 rounded bg-gray-800 px-2 py-1 text-xs text-white opacity-0 group-hover:opacity-100">
            User recently active
          </span>
        )}
      </div>
    </div>
  );
};

export default TeamMemberIcon;
