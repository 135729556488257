import { forwardRef, Ref, SVGProps } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props extends SVGProps<SVGSVGElement> {
  className?: string;
}

const DollarSignIcon = ({ className, ...props }: Props, ref: Ref<SVGSVGElement>) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 24"
    fill="currentColor"
    className={twMerge('w-6 h-6', className)}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.525 3C12.884 3.00001 13.175 3.29103 13.175 3.65001L13.1749 5.14165C14.1099 5.24981 15.0119 5.59251 15.7386 6.16976L16.3206 6.63211C16.6017 6.85541 16.6485 7.26429 16.4252 7.54538C16.2019 7.82646 15.7931 7.87331 15.512 7.65002L14.93 7.18767C14.4364 6.79558 13.8209 6.55067 13.1749 6.45293L13.1748 11.4506C14.1514 11.5524 15.1108 11.8863 15.8908 12.4713C16.8506 13.1912 17.3834 14.1808 17.3834 15.2232C17.3834 16.2655 16.8506 17.2552 15.8908 17.975C15.1108 18.56 14.1515 18.8939 13.1749 18.9957L13.175 20.4837C13.175 20.8427 12.884 21.1337 12.525 21.1337C12.166 21.1337 11.875 20.8427 11.875 20.4837L11.8749 18.9957C10.8985 18.8938 9.93934 18.56 9.15942 17.975L7.92653 17.0503C7.63935 16.8349 7.58115 16.4275 7.79655 16.1403C8.01195 15.8531 8.41937 15.7949 8.70655 16.0103L9.93944 16.935C10.4727 17.335 11.1509 17.591 11.8749 17.6869L11.8748 12.6795C10.951 12.5726 10.0463 12.2356 9.31096 11.6514C8.39707 10.9254 7.90154 9.9375 7.90154 8.91057C7.90154 7.88364 8.39707 6.89576 9.31097 6.16976C10.0377 5.59244 10.9399 5.24973 11.8749 5.14162L11.875 3.64999C11.875 3.291 12.166 2.99999 12.525 3ZM11.8749 6.45289C11.2289 6.55059 10.6132 6.79552 10.1196 7.18767C9.48184 7.69429 9.20154 8.32195 9.20154 8.91057C9.20154 9.49918 9.48184 10.1268 10.1196 10.6335C10.6057 11.0197 11.2193 11.2691 11.8748 11.3683L11.8749 6.45289ZM13.1748 12.7594L13.1749 17.6869C13.8991 17.5911 14.5774 17.3351 15.1108 16.935C15.7945 16.4223 16.0834 15.7963 16.0834 15.2232C16.0834 14.65 15.7945 14.0241 15.1108 13.5113C14.5774 13.1112 13.899 12.8552 13.1748 12.7594Z"
    />
  </svg>
);

export default forwardRef(DollarSignIcon);
