import { forwardRef, Ref, SVGProps } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props extends SVGProps<SVGSVGElement> {
  className?: string;
}

const MagnifyingGlassIcon = ({ className, ...props }: Props, ref: Ref<SVGSVGElement>) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 24"
    fill="currentColor"
    className={twMerge('w-6 h-6', className)}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.31663 11.0001C4.31663 6.94083 7.60733 3.65012 11.6666 3.65012C15.7259 3.65012 19.0166 6.94083 19.0166 11.0001C19.0166 12.9907 18.2253 14.7965 16.9401 16.12C16.9109 16.1408 16.8831 16.1642 16.8569 16.1904C16.8307 16.2166 16.8073 16.2444 16.7865 16.2736C15.463 17.5588 13.6572 18.3501 11.6666 18.3501C7.60733 18.3501 4.31663 15.0594 4.31663 11.0001ZM17.3063 17.559C15.792 18.8623 13.8213 19.6501 11.6666 19.6501C6.88936 19.6501 3.01663 15.7774 3.01663 11.0001C3.01663 6.22286 6.88936 2.35012 11.6666 2.35012C16.4439 2.35012 20.3166 6.22286 20.3166 11.0001C20.3166 13.1548 19.5288 15.1255 18.2255 16.6398L22.0188 20.433C22.2726 20.6869 22.2726 21.0984 22.0188 21.3523C21.7649 21.6061 21.3534 21.6061 21.0995 21.3523L17.3063 17.559Z"
    />
  </svg>
);

export default forwardRef(MagnifyingGlassIcon);
