import { forwardRef, Ref, SVGProps } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props extends SVGProps<SVGSVGElement> {
  className?: string;
}

const PhoneIcon = ({ className, ...props }: Props, ref: Ref<SVGSVGElement>) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    className={twMerge('w-6 h-6', className)}
    {...props}
  >
    <path
      d="M12.0755 6.93134C12.044 6.66687 11.9339 6.41791 11.7595 6.21656C11.5852 6.01521 11.3545 5.8707 11.0973 5.80165L9.32576 5.32697C9.00211 5.24026 8.65728 5.28565 8.36711 5.45318C8.07695 5.62071 7.86521 5.89665 7.77849 6.22029C6.24387 11.9476 9.64252 17.8352 15.3711 19.3701C15.6947 19.4567 16.0394 19.4111 16.3294 19.2436C16.6194 19.076 16.831 18.8002 16.9177 18.4767L17.3924 16.7051C17.4614 16.4478 17.4517 16.1756 17.3645 15.9238C17.2773 15.672 17.1167 15.4521 16.9034 15.2924L15.3878 14.1589C15.1953 14.0149 14.9676 13.9255 14.7285 13.9004C14.4895 13.8752 14.2481 13.9151 14.0299 14.0159L13.494 14.2643C13.1918 14.4042 12.8483 14.4259 12.5309 14.3253C12.2135 14.2247 11.9453 14.009 11.7789 13.7206L10.8762 12.1542C10.7099 11.8662 10.6572 11.5263 10.7286 11.2014C10.7999 10.8765 10.9901 10.5901 11.2619 10.3982L11.7443 10.0582C11.941 9.91971 12.0966 9.73064 12.1946 9.51097C12.2926 9.2913 12.3293 9.04921 12.3009 8.81036L12.0755 6.93134Z"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default forwardRef(PhoneIcon);
