import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { observer } from 'mobx-react-lite';

import { LRO_STATUSES } from 'state/classes/LROs';
import { globalLROs } from 'state/globalLROs';

import { Subject } from 'features/user/models';
import { WorkflowRun } from 'models/Workflow';

import { useSelectedParentOrder } from './right-side/hooks/useSelectedParentOrder';
import { useRetryWorkflow } from 'features/lro/hooks';

import LoadingOverlay from 'components/ui/LoadingOverlay';

import LeftSide from './left-side/LeftSide';
import RightSide from './right-side/RightSide';
import { useProcessOrderDraftsStore } from 'features/order/store/process-order-drafts';

interface Props {
  isNewOrderCreationPage?: boolean;
}

const MainContent = observer(({ isNewOrderCreationPage }: Props) => {
  const { t } = useTranslation();

  const { order } = useSelectedParentOrder();
  const isOrderRetrying = globalLROs.lros.find((lro) => lro.data === order?.id)?.status === LRO_STATUSES.PENDING;
  const isOrderDraftListLoading = useProcessOrderDraftsStore((state) => state.isOrderDraftListLoading);

  // Retry related
  const { retryWorkflow } = useRetryWorkflow();
  const message = useProcessOrderDraftsStore((state) => state.message);
  const isRecordingAvailable = useProcessOrderDraftsStore((state) => state.isRecordingAvailable);
  const onRetryButtonClick = useCallback((
    selectedWorkflow: string, workflowRun: WorkflowRun, transcriptionLanguage?: string,
  ) => {
    retryWorkflow(selectedWorkflow, workflowRun, order?.id, 'Retry Order', 'Order is being retried', transcriptionLanguage);
  }, [retryWorkflow, order?.id]);

  const onSubjectAssignmentDone_ = useProcessOrderDraftsStore((state) => state.onSubjectAssignmentDone);
  const onSubjectAssignmentDone = useCallback((subject: Subject) => {
    onSubjectAssignmentDone_(subject);
  }, [onSubjectAssignmentDone_]);

  if (isOrderDraftListLoading) {
    return (
      <div className="h-full w-full flex justify-center items-center relative">
        <LoadingOverlay visible displayText={t('loading.orderDrafts')} />
      </div>
    );
  }

  if (isOrderRetrying) {
    return (
      <div className="h-full w-full flex justify-center items-center relative">
        <LoadingOverlay visible displayText={t('loading.orderRetry')} />
      </div>
    );
  }

  // if the order is not yet loaded, show a loading overlay
  if (!order) {
    return (
      <div className="h-full w-full flex justify-center items-center relative">
        <LoadingOverlay visible displayText={t('loading.order')} />
      </div>
    );
  }

  return (
    <div className="h-full w-full flex overflow-hidden bg-white relative">
      <LeftSide
        workflowRunId={order?.createdByWorkflowRunId}
        isVoiceAttachment={isRecordingAvailable}
        transcriptionLanguage={message?.context?.workflowOrder?.audioLanguage}
        onRetryWorkflow={onRetryButtonClick}
        subject={order?.createdByUserInfo}
        onComplete={onSubjectAssignmentDone}
        isNewOrderCreationPage={isNewOrderCreationPage}
      />
      <RightSide />
    </div>
  );
});

export default MainContent;
