import { Message } from 'features/message/models/Message';

import { httpPostV2 } from 'utils/xhr';

interface CreateLLMChatMessageParams {
  message: string;
  pipeline: string;
}

interface Props {
  chatId: string;
  params: CreateLLMChatMessageParams;
}

export const createLLMChatMessage = ({
  chatId,
  params,
}: Props) => httpPostV2(`llm/chat/${chatId}/messages`, params, {
  classType: Message,
}).then((res) => res.data as Message);
