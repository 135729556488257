import { Component, ErrorInfo, ReactNode } from 'react';

import { faEnvelope, faRefresh, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mantine/core';

type Props = {
  children: ReactNode;
};

type State = {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
  showDetails: boolean;
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
      showDetails: false,
    };
  }

  public static getDerivedStateFromError(error: Error): Partial<State> {
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error, errorInfo });
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
  }

  toggleDetails = () => {
    this.setState((prevState) => ({ showDetails: !prevState.showDetails }));
  };

  handleReportError = () => {
    const supportEmail = 'support@hoshii.ai';
    const subject = 'Error Report: An issue occurred in the application';
    let body = 'Dear Support Team,\n\n';
    body += 'An error occurred in the application. Please find the details below:\n\n';

    if (this.state.error) {
      body += `Error: ${this.state.error.message}\n\n`;
    }
    if (this.state.error?.stack) {
      body += `Stack Trace:\n${this.state.error.stack}\n\n`;
    }
    if (this.state.errorInfo && this.state.errorInfo.componentStack) {
      body += `Component Stack:\n${this.state.errorInfo.componentStack}\n\n`;
    }

    window.location.href = `mailto:${supportEmail}?subject=${encodeURIComponent(
      subject,
    )}&body=${encodeURIComponent(body)}`;
  };

  public render() {
    if (this.state.hasError) {
      return (
        <div className="max-w-2xl mx-auto my-8 rounded-xl border border-red-200 bg-white shadow-lg overflow-hidden">
          <div className="bg-red-50 p-6 border-b border-red-100">
            <div className="flex items-center gap-3">
              <FontAwesomeIcon icon={faTriangleExclamation} className="h-6 w-6 text-red-500" />
              <h1 className="text-xl font-semibold text-red-700">
                Something went wrong
              </h1>
            </div>
            <p className="mt-3 text-gray-700">
              We apologize for the inconvenience. An unexpected error has occurred in the application.
            </p>
          </div>

          <div className="p-6">
            <div className="mb-4">
              <h2 className="font-medium text-gray-800 mb-2">Error message:</h2>
              <div className="px-4 py-3 bg-red-50 rounded-lg border border-red-100 text-red-800">
                {this.state.error?.message || 'Unknown error'}
              </div>
            </div>

            <div className="flex flex-col sm:flex-row gap-3 mb-6">
              <Button
                onClick={() => window.location.reload()}
                leftSection={<FontAwesomeIcon icon={faRefresh} className="h-4 w-4" />}
              >
                Reload Application
              </Button>

              <Button
                onClick={this.handleReportError}
                leftSection={<FontAwesomeIcon icon={faEnvelope} className="h-4 w-4" />}
              >
                Report Error
              </Button>
            </div>

            <div className="mt-6">
              <button
                type="button"
                onClick={this.toggleDetails}
                className="text-sm text-gray-600 hover:text-gray-900 underline focus:outline-none"
              >
                {this.state.showDetails ? 'Hide technical details' : 'Show technical details'}
              </button>

              {this.state.showDetails && (
                <div className="mt-3 space-y-4">
                  {this.state.error?.stack && (
                    <div className="error-details">
                      <h3 className="text-sm font-medium text-gray-700 mb-1">Stack Trace:</h3>
                      <pre className="whitespace-pre-wrap bg-gray-50 p-3 rounded-lg border border-gray-200 text-xs text-gray-800 overflow-auto max-h-60">
                        {this.state.error.stack}
                      </pre>
                    </div>
                  )}

                  {this.state.errorInfo?.componentStack && (
                    <div className="component-stack">
                      <h3 className="text-sm font-medium text-gray-700 mb-1">Component Stack:</h3>
                      <pre className="whitespace-pre-wrap bg-gray-50 p-3 rounded-lg border border-gray-200 text-xs text-gray-800 overflow-auto max-h-60">
                        {this.state.errorInfo.componentStack}
                      </pre>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export { ErrorBoundary };
