import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ROUTES } from 'config/routes';

import { ProtectedScreen } from 'components/ui/ProtectedScreen';

import { CustomerDetailsPage } from 'features/customer/pages';

const CustomerListScreen = () => {
  const { businessId } = useParams();
  const navigate = useNavigate();

  const navigateToCustomersList = useCallback(() => {
    navigate(ROUTES.CUSTOMERS);
  }, [navigate]);

  return (
    <ProtectedScreen>
      <CustomerDetailsPage
        businessId={businessId}
        navigateToCustomersList={navigateToCustomersList}
      />
    </ProtectedScreen>
  );
};

export default CustomerListScreen;
