import { infiniteQueryOptions } from '@tanstack/react-query';

import { fetchChatMessages, FetchChatMessagesParams } from '../apis';

export const chatMessagesOptions = (chatId: string, params?: FetchChatMessagesParams) => infiniteQueryOptions({
  queryKey: ['chatMessages', chatId, params],
  queryFn: ({ pageParam = '' }) => fetchChatMessages({
    chatId,
    params: {
      ...params,
      limit: 5,
      cursor: pageParam,
    },
  }),
  initialPageParam: '',
  getNextPageParam: (lastPage) => lastPage.cursor || null,
  gcTime: 0,
  meta: {
    errorMessage: 'Error fetching chat messages',
  },
});
