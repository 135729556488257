import { observer } from 'mobx-react-lite';

import {
  Modal, MultiSelect, TextInput,
} from '@mantine/core';

import { globalInboxes } from 'state/globalInboxes';
import { globalUser } from 'state/globalUser';

import { Inbox } from 'models/Inbox';

import { useUpdateInbox } from 'features/inbox/apis';

import { Button } from 'components/ui/Button';
import LoadingOverlay from 'components/ui/LoadingOverlay';

import ParticipantsSection from './ParticipantsSection';

interface Props {
  opened: boolean;
  close: () => void;
  editingInbox: Inbox | null;
  setEditingInbox: (inbox: Inbox) => void;
}

const EditInboxModal = observer(({
  opened, close, editingInbox, setEditingInbox,
}: Props) => {
  const { updateInbox, isLoading } = useUpdateInbox();

  const onSaveButtonClick = () => {
    updateInbox(editingInbox)
      .then(() => {
        globalInboxes.updateInbox(editingInbox);
      });

    close();
  };

  return (
    <Modal opened={opened} onClose={close} title="Inbox Participants" centered size="xl">
      {isLoading && <LoadingOverlay visible />}
      <div className="space-y-4">
        <TextInput
          label="Name"
          defaultValue={editingInbox?.name}
          onChange={(e) => {
            setEditingInbox({ ...editingInbox, name: e.target.value });
          }}
          required
        />
        <TextInput
          label="Description"
          onChange={(e) => {
            setEditingInbox({ ...editingInbox, description: e.target.value });
          }}
          defaultValue={editingInbox?.description}
        />

        <MultiSelect
          label="Emails"
          defaultValue={editingInbox?.boundEmailChannels}
          data={globalUser.business?.emails?.map((email) => ({
            value: email,
            label: email,
          }))}
          onChange={(value) => {
            setEditingInbox({ ...editingInbox, boundEmailChannels: value });
          }}
          required
        />

        <ParticipantsSection
          editingInbox={editingInbox}
          setEditingInbox={setEditingInbox}
        />
      </div>
      <div className="sticky bottom-0 bg-white py-2 flex justify-end gap-2">
        <Button onClick={close} title="Cancel" theme="red" />
        <Button onClick={onSaveButtonClick} title="Save" />
      </div>
    </Modal>
  );
});

export default EditInboxModal;
