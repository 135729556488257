import { TrashIcon } from '@heroicons/react/24/outline';

import { Prompt } from 'features/instruction/models';

import { Card } from 'components/common/Card';
import { OverflowEllipsisParagraph } from 'components/ui/OverflowEllipsisParagraph';

import cartLight from 'assets/utils/cart-light.svg';

interface Props {
  prompt: Prompt;
  onCardClick: (promptId: string) => void;
  onDeleteClick: (promptId: string) => void;
}

const InstructionListItem = ({ prompt, onCardClick, onDeleteClick }: Props) => (
  <Card
    key={prompt.id}
    className="cursor-pointer transition-all ease-linear duration-200 shadow-blue-light-tint-drop-shadow hover:shadow-blue-tint-drop-shadow"
    onClick={() => {
      onCardClick(prompt.id);
    }}
  >
    <Card.Body className="px-4 group">
      <div className="flex justify-between">
        <OverflowEllipsisParagraph
          maxLines={1}
          isTooltipEnabled
          className="cursor-pointer text-sm font-semibold"
        >
          {prompt.name}
        </OverflowEllipsisParagraph>
        <button className="group-hover:visible invisible" type="button" onClick={(e) => { onDeleteClick(prompt.id); e.stopPropagation(); }}>
          <TrashIcon className="h-4 w-4 text-red-500" />
        </button>
      </div>
      <img
        src={cartLight}
        alt="cart"
        className="aspect-square h-[100px]"
      />
    </Card.Body>
  </Card>
);

export default InstructionListItem;
