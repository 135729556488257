import { twMerge } from 'tailwind-merge';

import { faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useProcessOrderDraftsStore } from 'features/order/store/process-order-drafts';
// import { useMemo } from 'react';

const Tabs = () => {
  const groupOrders = useProcessOrderDraftsStore((state) => state.groupOrders);
  const selectedOrderId = useProcessOrderDraftsStore((state) => state.selectedOrderId);
  const createNewOrder = useProcessOrderDraftsStore((state) => state.createNewOrder);
  const removeOrderById = useProcessOrderDraftsStore((state) => state.removeOrderById);
  const onTabChange = useProcessOrderDraftsStore((state) => state.onTabChange);

  const sortedOrderIds = Object.entries(groupOrders)
    .sort((a, b) => new Date(a[1].createdAt).getTime() - new Date(b[1].createdAt).getTime())
    .map(([orderId]) => orderId);

  const handleTabClick = (orderId: string) => {
    onTabChange(orderId);
  };

  const handleDeleteButtonClick = (orderId: string) => {
    removeOrderById(orderId);
  };

  const onAddButtonClick = () => {
    createNewOrder();
  };

  return (
    <div className="flex gap-2">
      {
        sortedOrderIds.map((orderId, index) => (
          <div
            key={orderId}
            className={twMerge(
              'rounded-l-full rounded-r-full min-w-fit px-4 py-2 group flex items-center cursor-pointer',
              orderId === selectedOrderId ? 'bg-black text-white' : 'border-gray-300 border',
            )}
            role="button"
            tabIndex={0}
            onClick={() => handleTabClick(orderId)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                handleTabClick(orderId);
              }
            }}
          >
            {`Order-${index + 1}`}

            {
              Object.keys(groupOrders).length > 1 && (
                <button
                  type="button"
                  className="hidden ml-2 group-hover:block"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteButtonClick(orderId);
                  }}
                >
                  <FontAwesomeIcon icon={faXmark} />
                </button>
              )
            }
          </div>
        ))
      }
      <button
        type="button"
        className="border border-gray-300 px-4 py-2 aspect-square rounded-full"
        aria-label="Add new item"
        onClick={onAddButtonClick}
      >
        <FontAwesomeIcon icon={faPlus} />
      </button>
    </div>
  );
};

export default Tabs;
