import { queryOptions } from '@tanstack/react-query';

import { MINUTE } from 'constants/miliseconds';

import { TimePeriod } from '../types/timePeriod';

import { fetchBusinessSales } from '../apis';

export const businessSalesOptions = (period: TimePeriod) => queryOptions({
  queryKey: ['business-sales', period],
  queryFn: () => fetchBusinessSales(period),
  staleTime: 30 * MINUTE, // cache for 30 minutes
  meta: {
    errorMessage: 'Error fetching business sales',
  },
});
