import ReactJson from 'react-json-view';

import Dayjs from 'dayjs';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

import { SyncLog } from 'features/erp/models';

import { isZeroId } from 'utils/objectId';

import { DirectionBadge } from '../DirectionBadge';
import { StatusBadge } from '../StatusBadge';

interface Props {
  log: SyncLog;
  isRowSelected: boolean;
  isRowExpanded: boolean;
  toggleRowExpansion: (id: string) => void;
  onSelectionChange: (log: SyncLog) => void;
}

const getDetails = (log: SyncLog) => {
  const details = [
    log.failedId && !isZeroId(log.failedId) && `Failed ID: ${log.failedId}`,
    log.failedExternalId && `Failed External ID: ${log.failedExternalId}`,
    log.failedIndex && `Failed Index: ${log.failedIndex}`,
    log.failedMessage && `Reason: ${log.failedMessage}`,
    log.additionalFields && `Additional Context: ${JSON.stringify(log.additionalFields)}`,
  ].filter(Boolean).join('\n');

  return details;
};

const getOperationId = (log: SyncLog) => {
  if (!isZeroId(log.lroId)) {
    return log.lroId;
  }
  return log.runId;
};

const SyncLogListTableRow = ({
  log,
  isRowSelected,
  isRowExpanded,
  toggleRowExpansion,
  onSelectionChange,
}: Props) => (
  <>
    <tr
      className={twMerge(
        'hover:bg-gray-50',
        isRowSelected && 'bg-gray-50',
      )}
      onClick={() => toggleRowExpansion(log.id)}
    >
      <td className="relative px-7 sm:w-12 sm:px-xl">
        {isRowSelected && (
          <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
        )}
        <input
          type="checkbox"
          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
          value={log.id}
          checked={isRowSelected}
          onChange={() => onSelectionChange(log)}
          onClick={(e) => e.stopPropagation()}
        />
      </td>
      <td className="whitespace-nowrap px-lg py-smd text-sm text-gray-500 !font-mono">
        {Dayjs(log.createdAt).format('YYYY-MM-DD HH:mm:ss.SSS')}
      </td>
      <td className="px-lg py-smd text-sm text-gray-500">
        {log.model}
      </td>
      <td className="px-lg py-smd text-sm text-gray-500">
        <DirectionBadge direction={log.syncDirection} />
      </td>
      <td className="relative flex items-center justify-center px-lg py-smd">
        <StatusBadge status={log.status} />
      </td>
      <td className="px-lg py-smd text-sm text-gray-500 !font-mono whitespace-pre-line">
        {getDetails(log)}
      </td>
      <td className="px-lg py-smd text-sm whitespace-nowrap !font-mono text-gray-500">
        {getOperationId(log)}
      </td>
      <td />
      <td className="px-lg py-smd">
        {isRowExpanded ? (
          <ChevronUpIcon className="h-4 w-4" />
        ) : (
          <ChevronDownIcon className="h-4 w-4" />
        )}
      </td>
    </tr>
    {isRowExpanded && (
      <tr className="bg-gray-50 shadow-[inset_0_4px_6px_-1px_rgb(0,0,0,0.1)]">
        <td colSpan={9} className="px-lg py-md">
          <div className="text-sm text-gray-700">
            <ReactJson
              src={log}
              name={null}
              theme="rjv-default"
              collapsed={2}
              enableClipboard
              displayDataTypes={false}
            />
          </div>
        </td>
      </tr>
    )}
  </>
);

export default React.memo(SyncLogListTableRow);
