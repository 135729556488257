import {
  CartesianGrid, Line, LineChart, ResponsiveContainer,
  Tooltip, XAxis, YAxis,
} from 'recharts';
import { twMerge } from 'tailwind-merge';

import { Formatter, NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { BaseAxisProps } from 'recharts/types/util/types';

import LoadingOverlay from 'components/ui/LoadingOverlay';

import { Card, CardContent } from './ChartCard';
import ChartCardHeaderWithSelect, { ChartCardHeaderWithSelectProps } from './ChartCardHeaderWithSelect';

type Data = {
  [key: string]: any;
};

interface Props {
  data: Data[];
  xDataKey: string;
  yDataKey: string;

  isLoading?: boolean;

  chartDivClassName?: string;

  tickFormatter?: BaseAxisProps['tickFormatter'];
  toolTipFormatter?: Formatter<ValueType, NameType>;
}

const LineChartComponent = ({
  data, xDataKey, yDataKey, chartDivClassName,
  isLoading = false,
  tickFormatter = (value) => value.toLocaleString(),
  toolTipFormatter = (value) => [value.toLocaleString()],
  ...headerProps
}: Props & ChartCardHeaderWithSelectProps) => (
  <Card className="w-full">
    <ChartCardHeaderWithSelect
      {...headerProps}
    />
    <CardContent className="relative">
      <LoadingOverlay visible={isLoading} />

      {
        (data || []).length === 0 ? (
          <div
            className={twMerge(
              'pt-10 w-full flex justify-center',
              chartDivClassName,
            )}
          >
            <div className="text-sm text-gray-500">No data available</div>
          </div>
        ) : (
          <div className={twMerge(
            'h-96 w-full',
            chartDivClassName,
          )}
          >
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                data={data}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" className="stroke-gray-200" />
                <XAxis
                  dataKey={xDataKey}
                  tick={{ fill: 'var(--black-lighter-text)' }}
                  tickLine={{ stroke: 'var(--black-lighter-text)' }}
                  interval={Math.round((data?.length ?? 0) / 10)}
                />
                <YAxis
                  tick={{ fill: 'var(--black-lighter-text)' }}
                  tickLine={{ stroke: 'var(--black-lighter-text)' }}
                  tickFormatter={tickFormatter}
                />
                <Tooltip
                  contentStyle={{
                    backgroundColor: '#fff',
                    border: '1px solid #e5e7eb',
                    borderRadius: '0.5rem',
                  }}
                  formatter={toolTipFormatter}
                />
                <Line
                  type="monotone"
                  dataKey={yDataKey}
                  stroke="var(--purple-highlight-2)"
                  strokeWidth={2}
                  dot={{ fill: 'var(--purple-highlight-2)', strokeWidth: 2 }}
                  activeDot={{ r: 8 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        )
        }
    </CardContent>
  </Card>
);
export default LineChartComponent;
