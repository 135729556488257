import { useCallback, useState } from 'react';

import { Message } from '../../features/message/models/Message';

import { genericErrorFeedback } from 'utils/errors';
import { httpGetV1 } from 'utils/xhr';

const useFetchMessageById = () => {
  const [message, setMessage] = useState<Message | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const loadMessage = useCallback(
    (
      messageId: string,
      giveErrorFeedback: boolean = true,
    ): Promise<Message> => {
      if (!messageId) return Promise.reject();
      setIsLoading(true);
      return httpGetV1(`/chat/messages/${messageId}`)
        .then((response) => {
          setMessage(response.data);
          return response.data;
        })
        .catch((error) => {
          if (giveErrorFeedback) {
            genericErrorFeedback('An error has occured while fetching message')(
              error,
            );
          }
          return Promise.reject(error);
        })
        .finally(() => setIsLoading(false));
    },
    [],
  );

  return {
    message,
    loadMessage,
    isLoading,
  };
};

export { useFetchMessageById };
