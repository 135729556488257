import { create } from 'zustand';

import { Chat } from '../models';

interface AiChatStore {
  currentChat: Chat;
  setCurrentChat: (chat: Chat) => void;

  loadingChatById: Record<string, boolean>;
  setLoadingChatById: (id: string, loading: boolean) => void;

  chats: Chat[];
  setChats: (chats: Chat[]) => void;
  addChat: (chat: Chat) => void;
}

export const useAiChatStore = create<AiChatStore>()(
  (set) => ({
    currentChat: null,
    setCurrentChat: (chat: Chat) => set({ currentChat: chat }),

    loadingChatById: {},
    setLoadingChatById: (id: string, loading: boolean) => set((state) => ({
      loadingChatById: { ...state.loadingChatById, [id]: loading },
    })),

    chats: [],
    setChats: (chats: Chat[]) => set({ chats }),
    addChat: (chat: Chat) => set((state) => ({ chats: [chat, ...state.chats] })),
  }),
);
