import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelectedGroupOrder } from '../../hooks/useSelectedGroupOrder';

import LoadingOverlay from 'components/ui/LoadingOverlay';
import { ConfirmOrderModalWrapper } from 'components/wrapper/ConfirmOrderModalWrapper';

import { isZeroId } from 'utils/objectId';

import Buttons from '../Buttons';
import TabsAndAssignee from '../TabsAndAssignee';
import ProcessContent from './ProcessContent';
import { useProcessOrderDraftsStore } from 'features/order/store/process-order-drafts';

interface Props {
  stopStopwatch: () => void;
  onAssignButtonClick: (userId: string, userName: string, assignedComment: string) => void;
}

const ProcessView = ({ stopStopwatch, onAssignButtonClick }: Props) => {
  const { t } = useTranslation();

  const { order } = useSelectedGroupOrder();

  const isBusinessSettingsLoading = useProcessOrderDraftsStore((state) => state.isBusinessSettingsLoading);
  const isSchemasLoading = useProcessOrderDraftsStore((state) => state.isSchemasLoading);
  const isPromptLoading = useProcessOrderDraftsStore((state) => state.isPromptLoading);

  const loadBusinessSettings = useProcessOrderDraftsStore((state) => state.loadBusinessSettings);
  const loadSchema = useProcessOrderDraftsStore((state) => state.loadSchema);
  const loadPrompt = useProcessOrderDraftsStore((state) => state.loadPrompt);

  const declineOrder = useProcessOrderDraftsStore((state) => state.declineOrder);
  const saveOrder = useProcessOrderDraftsStore((state) => state.saveOrder);
  const confirmOrder = useProcessOrderDraftsStore((state) => state.confirmOrder);

  const confirmOrderModalWrapperRef = useRef(null);
  const onConfirm = () => {
    stopStopwatch();
    confirmOrder();
  };

  const onConfirmButtonClick = () => {
    // If it's a group order, ask for the confirmation. Otherwise, confirm the order
    if (order.groupId && !isZeroId(order.groupId)) {
      confirmOrderModalWrapperRef.current?.openModal();
    } else {
      onConfirm();
    }
  };

  const onDeclineButtonClick = () => {
    stopStopwatch();
    declineOrder();
  };

  useEffect(() => {
    loadBusinessSettings();
  }, [loadBusinessSettings]);

  useEffect(() => {
    order?.typeSpecs?.forEach((typeSpec) => {
      loadSchema(typeSpec.typeRef);
    });
  }, [order, loadSchema]);

  useEffect(() => {
    if (!order?.customer?.id) return;
    loadPrompt(order.customer.id);
  }, [order?.customer?.id, loadPrompt]);

  if (isBusinessSettingsLoading) {
    return (
      <LoadingOverlay visible displayText={t('loading.businessSettings')} />
    );
  }

  if (isSchemasLoading) {
    return (
      <LoadingOverlay visible displayText={t('loading.schemas')} />
    );
  }

  if (isPromptLoading) {
    return (
      <LoadingOverlay visible displayText={t('loading.prompt')} />
    );
  }

  return (
    <ConfirmOrderModalWrapper
      ref={confirmOrderModalWrapperRef}
      onConfirm={onConfirm}
    >
      <div className="h-full w-full flex flex-col overflow-hidden bg-gray-50">
        <div className="flex-1 overflow-hidden p-4">
          <div id="body-container" className="h-full overflow-x-hidden overflow-y-scroll hidden-scrollbar">
            <TabsAndAssignee
              onAssignButtonClick={onAssignButtonClick}
            />
            <ProcessContent />
          </div>
        </div>
        <div className="border-t border-t-gray-300">
          <Buttons
            onConfirmButtonClick={onConfirmButtonClick}
            saveOrder={saveOrder}
            onDeclineButtonClick={onDeclineButtonClick}
          />
        </div>
      </div>
    </ConfirmOrderModalWrapper>
  );
};

export default ProcessView;
