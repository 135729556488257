import { forwardRef, Ref, SVGProps } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props extends SVGProps<SVGSVGElement> {
  className?: string;
}

const ShoppingCartIcon = ({ className, ...props }: Props, ref: Ref<SVGSVGElement>) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 24"
    stroke="currentColor"
    fill="none"
    className={twMerge('w-6 h-6', className)}
    {...props}
  >
    <path
      d="M9.65127 5.5L17.3939 5.5C18.656 5.5 19.6026 6.65465 19.3551 7.89223L18.6551 11.3922C18.4681 12.3271 17.6473 13 16.6939 13L10.4351 13C9.49896 13 8.68806 12.3506 8.48347 11.4371L7.69961 7.93709C7.41969 6.6872 8.37042 5.5 9.65127 5.5Z"
      strokeWidth="1.3"
    />
    <path
      d="M6.3335 19C6.3335 18.4477 6.78121 18 7.3335 18V18C7.88578 18 8.3335 18.4477 8.3335 19V19C8.3335 19.5523 7.88578 20 7.3335 20V20C6.78121 20 6.3335 19.5523 6.3335 19V19Z"
      strokeWidth="1.3"
    />
    <path
      d="M17.3335 19C17.3335 18.4477 17.7812 18 18.3335 18V18C18.8858 18 19.3335 18.4477 19.3335 19V19C19.3335 19.5523 18.8858 20 18.3335 20V20C17.7812 20 17.3335 19.5523 17.3335 19V19Z"
      strokeWidth="1.3"
    />
    <path
      d="M4.3335 4L5.63024 4C6.33329 4 6.94201 4.4883 7.09452 5.1746L8.38757 10.9933C8.65633 12.2028 8.13804 13.4636 7.10717 14.1509V14.1509C6.28394 14.6997 6.64686 16 7.63627 16H18.8335"
      strokeWidth="1.3"
      strokeLinecap="round"
    />

  </svg>
);

export default forwardRef(ShoppingCartIcon);
