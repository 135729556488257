import { useTranslation } from 'react-i18next';

import { Tooltip } from 'components/ui';

interface Props {
  errors: string[];
  children: React.ReactNode;
}

const ErrorsTooltip = ({ errors, children }: Props) => {
  const { t } = useTranslation();

  // Group errors by text, if the same error text is found, we put "num x ~"
  const groupedErrors = Object.entries(errors.reduce((acc: Record<string, number>, error) => {
    const key = error.trim();
    if (!acc[key]) {
      acc[key] = 0;
    }
    acc[key] += 1;
    return acc;
  }, {})).map(([error, num]) => (num > 1 ? `${num} x ${error}` : error));

  const displayedErrors = groupedErrors.slice(0, 10);
  const hasMoreErrors = groupedErrors.length > 10;

  return (
    <Tooltip
      label={(
        <div className="p-2">
          <p className="text-red-500 font-medium">
            {errors.length}
            {' '}
            {t('order.processOrderDrafts.rightSide.errorsDetected')}
          </p>
          <p className="text-xxs">
            {t('order.processOrderDrafts.rightSide.errorsDetected.description')}
          </p>
          <ul className="list-disc pl-3 space-y-1 pt-2 text-xs">
            {displayedErrors.map((error) => (
              <li key={error}>{error}</li>
            ))}
            {hasMoreErrors && <li>...</li>}
          </ul>
        </div>
      )}
    >
      {children}
    </Tooltip>
  );
};

export default ErrorsTooltip;
