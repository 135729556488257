// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iV4hYxKvU2HDZqBQtGgQ {\n    border: 1px solid var(--light-grey);\n    box-shadow: var(--blue-light-tint-drop-shadow);\n}\n\n.VX2IWmrdSewhb5Zg7jUA {\n    border-top: 1px solid var(--light-grey);\n    border-left: 1px solid var(--light-grey);\n}", "",{"version":3,"sources":["webpack://./src/components/ui/Tooltip/Tooltip.module.css"],"names":[],"mappings":"AAAA;IACI,mCAAmC;IACnC,8CAA8C;AAClD;;AAEA;IACI,uCAAuC;IACvC,wCAAwC;AAC5C","sourcesContent":[".tooltip {\n    border: 1px solid var(--light-grey);\n    box-shadow: var(--blue-light-tint-drop-shadow);\n}\n\n.arrow {\n    border-top: 1px solid var(--light-grey);\n    border-left: 1px solid var(--light-grey);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "iV4hYxKvU2HDZqBQtGgQ",
	"arrow": "VX2IWmrdSewhb5Zg7jUA"
};
export default ___CSS_LOADER_EXPORT___;
