import {
  useCallback, useEffect,
  useRef, useState,
} from 'react';
import AudioPlayer from 'react-h5-audio-player';

import { PauseIcon, PlayIcon } from '@heroicons/react/24/outline';
import { LoadingOverlay } from '@mantine/core';

import { Message, MessageContextUtils } from 'features/message/models/Message';

import { usePlayRecordingContext } from 'contexts/usePlayRecordingIndex';
import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';

import { httpGetBlobV1 } from 'utils/xhr';

interface Props {
  message: Message;

  disableShortcut?: boolean;
}

const RecordingMessage = ({
  message,

  disableShortcut = false,
}: Props) => {
  const {
    playRecordingKeywordAndMessageId,
    setPlayRecordingKeywordAndMessageId,
  } = usePlayRecordingContext();
  const { scrollToKeyword } = useProcessOrderContext();

  const [isLoadingAudio, setIsLoadingAudio] = useState(true);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [recordingSrc, setRecordingUrl] = useState<string>();
  const audioPlayerRef = useRef<{ audio: { current: HTMLMediaElement } }>();

  // For shortcut key to move from one keyword to another
  // Should be disabled in the normal chat interface
  const [lastPlayedIndex, setLastPlayedIndex] = useState(-1);

  // shortcut key
  const handleKeydown = useCallback(
    (event: KeyboardEvent) => {
      if (disableShortcut) return;

      if (event.key === 'P' && event.ctrlKey && event.shiftKey) {
        event.preventDefault();
        if (audioPlayerRef.current.audio.current.paused) {
          audioPlayerRef.current.audio.current.play();
          setIsAudioPlaying(true);
        } else {
          audioPlayerRef.current.audio.current.pause();
          setIsAudioPlaying(false);
        }
        return;
      }

      const target = event.target as HTMLElement;
      const isInputFocused = target.tagName === 'INPUT'
        || target.tagName === 'TEXTAREA'
        || target.isContentEditable;
      const isCombinedKey = event.ctrlKey || event.metaKey || event.shiftKey || event.altKey;

      if (!audioPlayerRef.current || isInputFocused) return;

      switch (event.key) {
        case ' ':
          if (!isCombinedKey) {
            if (audioPlayerRef.current.audio.current.paused) {
              audioPlayerRef.current.audio.current.play();
              setIsAudioPlaying(true);
            } else {
              audioPlayerRef.current.audio.current.pause();
              setIsAudioPlaying(false);
            }
          }
          break;
        case 's':
          if (!isCombinedKey) {
            audioPlayerRef.current.audio.current.pause();
            audioPlayerRef.current.audio.current.currentTime = 0;
            setIsAudioPlaying(false);
            setLastPlayedIndex(-1);
          }
          break;
        case 'ArrowRight':
          if (event.shiftKey) {
            const index = Math.min(
              lastPlayedIndex + 1,
              (message.context?.workflowOrder?.audioKeywords.length || 0) - 1,
            );
            scrollToKeyword(
              message.context?.workflowOrder?.audioKeywords[index].word,
            );
            break;
          }

          if (!isCombinedKey) {
            audioPlayerRef.current.audio.current.currentTime += 5;
          }
          break;
        case 'ArrowLeft':
          if (event.shiftKey) {
            const index = Math.max(lastPlayedIndex - 1, 0);
            scrollToKeyword(
              message.context?.workflowOrder?.audioKeywords[index].word,
            );
            break;
          }

          if (!isCombinedKey) {
            audioPlayerRef.current.audio.current.currentTime -= 5;
          }
          break;
        default:
          break;
      }
    },
    [
      disableShortcut,
      lastPlayedIndex,
      message?.context?.workflowOrder?.audioKeywords,
      scrollToKeyword,
    ],
  );

  useEffect(() => {
    if (!disableShortcut) {
      document.addEventListener('keydown', handleKeydown);

      return () => {
        document.removeEventListener('keydown', handleKeydown);
      };
    }

    return () => {};
  }, [disableShortcut, handleKeydown]);

  const onPlayButtonClick = useCallback(() => {
    if (audioPlayerRef.current?.audio.current.paused) {
      setIsAudioPlaying(true);
      audioPlayerRef.current?.audio.current.play();
    } else {
      setIsAudioPlaying(false);
      audioPlayerRef.current?.audio.current.pause();
    }
  }, []);

  const loadRecording = useCallback(() => {
    const audioAttachments = MessageContextUtils.audioAttachments(message.context);
    if (audioAttachments.length > 0) {
      setIsLoadingAudio(true);
      const tokens = audioAttachments[0].url.split('/v1/');
      const recordingRelativeUrl = tokens[1];
      httpGetBlobV1(recordingRelativeUrl)
        .then((response) => {
          setRecordingUrl(URL.createObjectURL(response.data));
          // TODO: Find a way to URL.revokeObjectURL(url) when page is left
        })
        .finally(() => setIsLoadingAudio(false));
    }
  }, [message.context]);

  useEffect(() => {
    if (!playRecordingKeywordAndMessageId) return;

    const { messageId, keyword } = playRecordingKeywordAndMessageId;
    const index = message.context?.workflowOrder?.audioKeywords.findIndex(
      (kw) => kw.fieldId === keyword,
    );
    if (index !== -1 && audioPlayerRef.current && message.id === messageId) {
      const start = (message.context?.workflowOrder?.audioKeywords[index].start as number)
        - 0.5; // start 0.5 sec before the actual keyword
      const end = (message.context?.workflowOrder?.audioKeywords[index].end as number)
        + 0.5; // finish 0.5 sec after the actual keyword
      audioPlayerRef.current.audio.current.currentTime = start;
      audioPlayerRef.current.audio.current.play();
      setIsAudioPlaying(true);
      setLastPlayedIndex(index);

      setTimeout(
        () => {
          if (audioPlayerRef.current.audio.current) {
            audioPlayerRef.current.audio.current.pause();
            setIsAudioPlaying(false);
          }
        },
        (end - start) * 1000,
      );
    }

    // Reset
    setPlayRecordingKeywordAndMessageId(null);
  }, [
    playRecordingKeywordAndMessageId,
    message.id,
    message.context?.workflowOrder?.audioKeywords,
    setPlayRecordingKeywordAndMessageId,
  ]);

  useEffect(() => {
    if (!recordingSrc) {
      loadRecording();
    }
  }, [loadRecording, recordingSrc]);

  return (
    <div className="relative mb-sm flex w-full gap-lg rounded-lg border border-blue-gray-100 bg-white p-lg text-content-md">
      {isLoadingAudio && (
        <LoadingOverlay
          loaderProps={{ type: 'dots' }}
          overlayProps={{ blur: 2 }}
        />
      )}
      <button type="button" onClick={onPlayButtonClick}>
        {isAudioPlaying ? (
          <PauseIcon height={20} width={20} />
        ) : (
          <PlayIcon height={20} width={20} />
        )}
      </button>
      <AudioPlayer
        // @ts-ignore
        ref={audioPlayerRef}
        src={recordingSrc}
        onPlayError={console.error}
        autoPlayAfterSrcChange={false}
        customControlsSection={[null]}
      />
    </div>
  );
};

export default RecordingMessage;
