import { useMemo } from 'react';

import { Order } from 'features/order/models';

import { getSchema } from 'features/order/store/process-order-drafts/utils';

import { useProcessOrderDraftsStore } from 'features/order/store/process-order-drafts';

export const useSchema = ({
  groupOrders,
  selectedOrderId,
}: {
  groupOrders: Record<string, Order>;
  selectedOrderId: string;
}) => {
  const schemas = useProcessOrderDraftsStore((state) => state.schemas);
  const schema = useMemo(() => getSchema(groupOrders, selectedOrderId, schemas), [groupOrders, selectedOrderId, schemas]);

  return schema;
};
