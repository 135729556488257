import { FinalizedOrdersCounts } from '../models';

import { httpGetV1 } from 'utils/xhr';

interface FetchOrderCountsParams {
  from_time: string;
  to_time?: string;
  product_number_from?: number;
  product_number_to?: number;
  assignee_user_id?: string;
  customer_id?: string;
  granularity?: string;
}

export const fetchOrderCounts = async (params: FetchOrderCountsParams) => httpGetV1('orders/metrics/processed_orders', {
  params,
  classType: FinalizedOrdersCounts,
}).then((response) => response.data as FinalizedOrdersCounts);
