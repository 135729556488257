import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';

import { Menu } from '@mantine/core';

import { SidebarNavItemMultiple } from '../Sidebar.types';

import { ChevronDownIcon } from 'components/icon/outline';
import { Menu as MenuComponent } from 'components/ui';

import { isActive } from '../Sidebar.utils';

interface Props {
  item: SidebarNavItemMultiple;
  isSidebarExpanded: boolean;
  pathname: string;
}

const LinksGroup = ({ item, isSidebarExpanded, pathname }: Props) => {
  const navigate = useNavigate();

  const [opened, setOpened] = useState(false);

  const isItemActive = isActive(pathname, item.basePath);

  useEffect(() => {
    setOpened(isItemActive);
  }, [isItemActive]);

  if (isSidebarExpanded) {
    return (
      <div className="w-full">
        <button
          type="button"
          onClick={() => setOpened(!opened)}
          className={twMerge(
            'flex justify-between items-center cursor-pointer relative flex-[0_0_auto] rounded-full text-black-menu-80 hover:bg-light-blue-hue-box transition-all duration-300 ease-in-out',
            'pl-2 pr-3 desktop:pl-3 desktop:pr-4 py-1.5 desktop:py-2 self-stretch w-full flex',
            ((opened && !isItemActive) || (!opened && isItemActive)) && 'bg-light-blue-hue-box',
          )}
        >
          <div
            className={twMerge(
              'items-center gap-2 relative flex-[0_0_auto] flex',
            )}
          >
            <item.icon className="size-[22px] desktop:size-6" />
            <div
              className={twMerge(
                'relative w-fit font-font-screen-nav-bar-medium font-[number:var(--font-screen-nav-bar-medium-font-weight)] text-[length:var(--font-screen-nav-bar-medium-font-size)] tracking-[var(--font-screen-nav-bar-medium-letter-spacing)] leading-[var(--font-screen-nav-bar-medium-line-height)] whitespace-nowrap [font-style:var(--font-screen-nav-bar-medium-font-style)]',
                'desktop:font-font-screen-nav-bar-large-medium desktop:font-[number:var(--font-screen-nav-bar-large-medium-font-weight)] desktop:text-[length:var(--font-screen-nav-bar-large-medium-font-size)] desktop:tracking-[var(--font-screen-nav-bar-large-medium-letter-spacing)] desktop:leading-[var(--font-screen-nav-bar-large-medium-line-height)] desktop:[font-style:var(--font-screen-nav-bar-large-medium-font-style)]',
              )}
            >
              {item.label}
            </div>
          </div>

          <ChevronDownIcon className={twMerge(
            'w-[18px] h-[18px] transition-transform duration-300 ease-in-out',
            opened && 'rotate-180',
          )}
          />
        </button>

        <div className={twMerge(
          'space-y-2 ml-4 border-black-menu-80/50 transition-all duration-300 ease-in-out',
          opened && 'border-l py-1',
        )}
        >
          {
          item.children.map((child) => (
            <button
              key={child.href}
              type="button"
              onClick={() => navigate(child.href)}
              className={twMerge(
                'relative font-font-screen-nav-bar-medium font-[number:var(--font-screen-nav-bar-medium-font-weight)] text-[length:var(--font-screen-nav-bar-medium-font-size)] tracking-[var(--font-screen-nav-bar-medium-letter-spacing)] leading-[var(--font-screen-nav-bar-medium-line-height)] whitespace-nowrap [font-style:var(--font-screen-nav-bar-medium-font-style)]',
                'desktop:font-font-screen-nav-bar-large-medium desktop:font-[number:var(--font-screen-nav-bar-large-medium-font-weight)] desktop:text-[length:var(--font-screen-nav-bar-large-medium-font-size)] desktop:tracking-[var(--font-screen-nav-bar-large-medium-letter-spacing)] desktop:leading-[var(--font-screen-nav-bar-large-medium-line-height)] desktop:[font-style:var(--font-screen-nav-bar-large-medium-font-style)]',
                'text-black-menu-80 text-left',
                'hover:bg-light-blue-hue-box w-full block',
                'transition-all duration-300 ease-in-out',
                opened ? 'max-h-20 p-2' : 'max-h-0 overflow-hidden',
                isActive(pathname, child.href) && 'bg-light-blue-hue-box',
              )}
            >
              {child.label}
            </button>
          ))
      }
        </div>
      </div>
    );
  }

  return (
    <MenuComponent withArrow arrowSize={13} trigger="hover" position="right">
      <Menu.Target>
        <button
          type="button"
          onClick={() => setOpened(!opened)}
          className={twMerge(
            'flex justify-between items-center cursor-pointer relative flex-[0_0_auto] rounded-full text-black-menu-80 hover:bg-light-blue-hue-box transition-all duration-300 ease-in-out',
            'inline-flex p-1',
            ((opened && !isItemActive) || (!opened && isItemActive)) && 'bg-light-blue-hue-box',
          )}
        >
          <div
            className={twMerge(
              'items-center gap-2 relative flex-[0_0_auto] flex',
            )}
          >
            <item.icon className="!relative !w-5 !h-5" />
          </div>
        </button>
      </Menu.Target>

      <Menu.Dropdown>
        {
          item.children.map((child) => (
            <Menu.Item key={child.href} onClick={() => navigate(child.href)}>
              {child.label}
            </Menu.Item>
          ))
        }
      </Menu.Dropdown>
    </MenuComponent>
  );
};

export default LinksGroup;
