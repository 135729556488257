import { BaseHeaderMatcher, ModelConfig } from './BaseHeaderMatcher';

class CustomerHeaderMatcher extends BaseHeaderMatcher {
  additionalFeatures: string[];

  createdBy: string;

  externalId: string;

  name: string;

  emails: string[];

  phones: string[];

  addressComponents: string[];
}

const CustomerConfig: ModelConfig = {
  required: [
    { title: 'ID', key: 'external_id', type: 'string' },
    { title: 'Name', key: 'name', type: 'string' },
  ],
  optional: [
    { title: 'Phones', key: 'phones', type: 'list' },
    { title: 'Emails', key: 'emails', type: 'list' },
    { title: 'Adresses', key: 'address_components', type: 'list' },
  ],
};

export { CustomerConfig, CustomerHeaderMatcher };
