import { StateCreator } from 'zustand';

import { ProcessOrderDraftsStore, SchemaSlice } from './types';
import { Pipeline } from 'features/instruction/types';

import { fetchPromptByCustomerId, fetchSchemaByTypeRef } from 'features/instruction/apis';

import { genericErrorFeedback } from 'utils/errors';

export const createSchemaSlice: StateCreator<ProcessOrderDraftsStore, [], [], SchemaSlice> = (set, get) => ({
  schemas: {},
  isSchemasLoading: false,
  prompts: {},
  isPromptLoading: false,
  getOrderFields: () => {
    const { groupOrders, selectedOrderId } = get();
    const order = groupOrders[selectedOrderId];
    return order?.typeSpecs?.[0]?.fields;
  },
  loadSchema: (typeRef: string) => {
    const schemas = get().schemas;
    if (schemas[typeRef]) {
      return;
    }

    set({ isSchemasLoading: true });
    fetchSchemaByTypeRef(typeRef)
      .then((schema) => {
        set({ schemas: { ...schemas, [typeRef]: schema } });
      })
      .catch((error) => {
        genericErrorFeedback('Error loading schema')(error);
      })
      .finally(() => set({ isSchemasLoading: false }));
  },
  loadPrompt: (customerId: string) => {
    const prompts = get().prompts;
    if (prompts[customerId]) {
      get().updateOrderByFn((currentOrder) => ({
        ...currentOrder,
        typeSpecs: prompts[customerId].boundTypeSpecs,
        promptId: prompts[customerId].id,
      }), undefined, false);
      return;
    }

    set({ isPromptLoading: true });
    fetchPromptByCustomerId(Pipeline.ORDER, customerId)
      .then((prompt) => {
        get().updateOrderByFn((currentOrder) => ({
          ...currentOrder,
          typeSpecs: prompt.boundTypeSpecs,
          promptId: prompt.id,
        }), undefined, false);
        set({
          prompts: { ...prompts, [customerId]: prompt },
        });
      })
      .catch((error) => {
        genericErrorFeedback('Error loading prompt')(error);
      })
      .finally(() => set({ isPromptLoading: false }));
  },
});
