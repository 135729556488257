import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

import { AuthType, BusinessSettings } from 'features/settings/models';

import Input from '../../../ui/Input';

interface Props {
  businessSettings: BusinessSettings;
}

const sapByd = ({ businessSettings }: Props) => {
  const sapBydCfg = businessSettings.erpConnectionSettings.sapByd;

  return (
    <div className="grid gap-5 text-sm text-gray-700 lg:py-7.5">
      <Input label="Instance URL" value={sapBydCfg?.instanceUrl} readOnly />
      <Input label="Authentication Method" value={sapBydCfg?.authType} readOnly />
      {sapBydCfg?.authType === AuthType.BASIC && (
        <>
          <Input label="Username" value={sapBydCfg?.basicAuth?.username} readOnly />
          <Input label="Password" value={sapBydCfg?.basicAuth?.password} readOnly />
        </>
      )}
      <Disclosure as="div" key="Endpoints" className="">
        <DisclosureButton className="group flex w-full items-center justify-between">
          <span className="text-sm/6 font-medium text-gray-900 group-data-[hover]:text-gray-900/80">
            Endpoints
          </span>
          <ChevronDownIcon className="size-5 fill-gray-900/60 group-data-[open]:rotate-180 group-data-[hover]:fill-gray-900/50" />
        </DisclosureButton>
        <DisclosurePanel className="mt-2 ml-5 space-y-2 text-sm/5 text-gray-900/50">
          <Input label="Customers Endpoint" value={sapBydCfg?.endpoints?.customers} readOnly />
          <Input label="Materials Endpoint" value={sapBydCfg?.endpoints?.materials} readOnly />
          <Input label="Sales Orders Endpoint" value={sapBydCfg?.endpoints?.salesOrders} readOnly />
        </DisclosurePanel>
      </Disclosure>
      <Input label="Import Batch Size" value={sapBydCfg?.importBatchSize.toString()} readOnly />
    </div>
  );
};

export default sapByd;
