import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { v4 as uuidv4 } from 'uuid';

import { faArrowUp, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Group } from 'features/instruction/constants';

import { getErrorsOrWarnings } from 'features/order/store/process-order-drafts/utils';

import ProductDetailsSettingsPopover from './ProductDetailsSettingsPopover';
import { useProcessOrderDraftsStore } from 'features/order/store/process-order-drafts';

interface Props {
  productLength: number;
}

const ProductDetailsHeader = ({
  productLength,
}: Props) => {
  const { t } = useTranslation();

  const addNewProduct = useProcessOrderDraftsStore((state) => state.addNewProduct);
  const resetProducts = useProcessOrderDraftsStore((state) => state.resetProducts);
  const storeErros = useProcessOrderDraftsStore((state) => state.errors);
  const selectedOrderId = useProcessOrderDraftsStore((state) => state.selectedOrderId);

  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const errors = getErrorsOrWarnings(storeErros, selectedOrderId);

  const customerAndOrderDetailsErrorsNum = useMemo(() => (
    Object.values(errors[Group.Customer] || {}).filter((e) => e).length
    + Object.values(errors[Group.StandardFields] || {}).filter((e) => e).length
  ), [errors]);

  const onErrorVisibleClick = useCallback(() => {
    document.querySelector('[data-order-section="upper-section"]')?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const upperSection = document.querySelector('[data-order-section="upper-section"]');
      if (upperSection) {
        const rect = upperSection.getBoundingClientRect();
        setIsErrorVisible(rect.bottom < 0);
      }
    };

    document.getElementById('body-container')?.addEventListener('scroll', handleScroll);
    return () => document.getElementById('body-container')?.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="flex justify-between items-center sticky top-0 z-20 bg-gray-50 pb-2">
      {
        isErrorVisible && customerAndOrderDetailsErrorsNum > 0 && (
          <div className="absolute top-0 left-1/2 -translate-x-1/2 w-fit h-fit z-50">
            <button
              type="button"
              className="bg-red-500 flex items-center gap-2 text-white px-2 py-1 rounded-full animate-bounceTwice"
              onClick={onErrorVisibleClick}
            >
              <FontAwesomeIcon icon={faArrowUp} />
              <p className="text-sm">
                {customerAndOrderDetailsErrorsNum}
                {' '}
                errors
              </p>
            </button>
          </div>
        )
      }
      <ProductDetailsSettingsPopover />
      <div className="flex items-center gap-2">
        <button
          type="button"
          className="flex items-center rounded-l-full rounded-r-full gap-1 text-sm bg-gray-200 hover:bg-gray-300/80 px-3 py-1 rounded"
          onClick={() => resetProducts()}
        >
          {t('order.processOrderDrafts.rightSide.deleteAll')}
        </button>
        <button
          type="button"
          className="flex items-center rounded-l-full rounded-r-full gap-1 text-sm bg-gray-200 hover:bg-gray-300/80 px-3 py-1 rounded"
          onClick={() => {
            const newProductname = `New product ${productLength + 1}`;
            const uiId = uuidv4();
            addNewProduct?.({
              id: '',
              uiId,
              name: newProductname,
              quantity: null,
              product: null,
              unit: null,
              price: null,
              comment: '',
              score: 0,
              autoMatched: false,
              defaultUnitConversionFactor: null,
            });

            setTimeout(() => {
              document.getElementById(uiId)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }, 200);
          }}
        >
          <FontAwesomeIcon icon={faPlus} />
          {t('order.processOrderDrafts.rightSide.addProduct')}
        </button>
      </div>
    </div>
  );
};

export default React.memo(ProductDetailsHeader);
