import { Pipeline, TypeRef, Workflow } from '../types';

export const workflowToPipeline = (workflow: Workflow): Pipeline => {
  switch (workflow) {
    case Workflow.ORDER:
      return Pipeline.ORDER;
    case Workflow.SUPPLIER_NOTE:
      return Pipeline.SUPPLIER_NOTE;
    default:
      return Pipeline.ORDER;
  }
};

export const typeRefToWorkflow = (typeRef: TypeRef): Workflow => {
  switch (typeRef) {
    case TypeRef.ORDER_DRAFT:
      return Workflow.ORDER;
    default:
      return Workflow.ORDER;
  }
};
