import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { observer } from 'mobx-react-lite';

import {
  ArchiveBoxIcon,
  ArrowLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClockIcon,
  EllipsisVerticalIcon,
  FolderIcon,
  PrinterIcon,
  ShareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { LoadingOverlay, Tooltip } from '@mantine/core';

import { ROUTES } from 'config/routes';

import { globalSseSources } from 'state/globalSseSources';
import { globalUser } from 'state/globalUser';

import { Message } from 'features/message/models/Message';
import { newEventFromMessageEvent } from 'models/Event';
import { SMTPMessage } from 'models/Mail';
import { Thread as ThreadModel } from 'models/Thread';

import { ThreadByIdScreenState } from 'features/thread/types';

import { useFetchInboxById } from 'hooks/fetch/useFetchInboxById';
import { useFetchMessagesByThreadId } from 'hooks/fetch/useFetchMessagesByThreadId';
import { useCreateMessageV2 } from 'hooks/useCreateMessageV2';

import { Button } from 'components/ui/Button';

import { ThreadMessage } from './ThreadMessage';
import { ThreadReplyMessage } from './ThreadReplyMessage';

interface Props {
  state?: ThreadByIdScreenState;
  thread: ThreadModel;
  isThreadLoading: boolean;
  currentIndex?: number;
  threadsCount?: number;
  onNextThread?: () => void;
  onPreviousThread?: () => void;
  onBack?: () => void;
  onArchive?: () => void;
  onDelete?: () => void;
  onMoveToInbox?: () => void;
}

type MessageOrReply = {
  message?: Message;
  replyToMessageId?: string;
};

const Thread = observer(({
  state,
  thread,
  isThreadLoading,
  currentIndex = 0,
  threadsCount,
  onNextThread,
  onPreviousThread,
  onBack,
  onArchive,
  onDelete,
  onMoveToInbox,
}: Props) => {
  const navigate = useNavigate();

  const { createMailMessage } = useCreateMessageV2();
  const {
    messages, setMessages, isLoading: isMessagesLoading, loadMessages,
  } = useFetchMessagesByThreadId({});
  // DEV-744: TODO(ntauth): Move this into an InboxContext that allows us to fetch the inbox, its
  // threads, and its messages.
  const { inbox, isLoading: isInboxLoading, loadInbox } = useFetchInboxById();
  const isLoading = useMemo(
    () => isThreadLoading || isMessagesLoading || isInboxLoading,
    [isThreadLoading, isMessagesLoading, isInboxLoading],
  );
  const currentPosition = currentIndex + 1;
  const [messagesOrReply, setMessagesOrReply] = useState<MessageOrReply[]>([]);

  const [expandedMessages, setExpandedMessages] = useState<Record<string, boolean>>({});

  const toggleMessage = (messageId: string) => {
    setExpandedMessages((prev) => ({
      ...prev,
      [messageId]: !prev[messageId],
    }));
  };

  const getBusinessName = (businessId: string) => {
    const business = thread.participantBusinesses.find((b) => b.id === businessId);
    return business?.name || (business?.emails.length && business?.emails[0]) || 'Unknown Business';
  };

  enum getUserNameType {
    FROM = 'from',
    TO = 'to',
  }

  const getUserName = (
    message: Message,
    userId: string,
    bracketizeEmail: boolean = false,
    typ: getUserNameType = getUserNameType.FROM,
  ) => {
    const user = thread.participantUsers.find((u) => u.id === userId);
    if (user?.id === globalUser.id) {
      return 'Me';
    }
    const name = `${user?.firstName || ''} ${user?.lastName || ''}`.trim();
    let email = '';
    if (typ === getUserNameType.FROM) {
      if (message.context?.from) {
        email = message.context?.from[0] ?? (bracketizeEmail ? `<${message.context?.from[0]}>` : message.context?.from[0]);
      }
    } else if (typ === getUserNameType.TO) {
      if (message.context?.to) {
        email = message.context?.to[0] ?? (bracketizeEmail ? `<${message.context?.to[0]}>` : message.context?.to[0]);
      }
    }
    let phone = '';
    if (typ === getUserNameType.FROM) {
      if (message.context?.phoneFrom) {
        phone = message.context?.phoneFrom;
      }
    } else if (typ === getUserNameType.TO) {
      phone = user?.GetPhone();
    }
    return name || email || phone || 'Unknown User';
  };

  const getMessageTime = (message: Message) => {
    const date = new Date(message.createdAt);
    const original = new Date(message.originalCreatedAt);
    const now = new Date();
    const diffInMinutes = Math.floor((now.getTime() - date.getTime()) / (1000 * 60));

    let relative: string;
    if (diffInMinutes < 1) {
      relative = 'now';
    } else if (diffInMinutes < 60) {
      relative = `${diffInMinutes} minutes ago`;
    } else {
      const diffInHours = Math.floor(diffInMinutes / 60);
      if (diffInHours < 24) {
        relative = `${diffInHours} hours ago`;
      } else {
        const diffInDays = Math.floor(diffInHours / 24);
        if (diffInDays < 30) {
          relative = `${diffInDays} days ago`;
        } else {
          const diffInMonths = Math.floor(diffInDays / 30);
          if (diffInMonths < 12) {
            relative = `${diffInMonths} months ago`;
          } else {
            const diffInYears = Math.floor(diffInMonths / 12);
            relative = `${diffInYears} years ago`;
          }
        }
      }
    }

    return {
      current: date.toLocaleString(),
      original: original.toLocaleString(),
      relative,
    };
  };

  const _onBack = () => {
    if (onBack) {
      onBack();
    } else {
      navigate(ROUTES.INBOX({}));
    }
  };

  const _onReply = (index: number) => {
    const newMessagesOrReply = [
      ...messagesOrReply.slice(0, index + 1),
      { replyToMessageId: messagesOrReply[index].message?.id },
      ...messagesOrReply.slice(index + 1),
    ];
    setMessagesOrReply(newMessagesOrReply);
  };

  const _onCloseReply = (index: number) => {
    const newMessagesOrReply = [
      ...messagesOrReply.slice(0, index),
      ...messagesOrReply.slice(index + 1),
    ];
    setMessagesOrReply(newMessagesOrReply);
  };

  const _onDiscardReply = (index: number) => {
    _onCloseReply(index);
  };

  const _onSendReply = async (index: number, smtpMessage: SMTPMessage) => {
    const _smtpMessage = { ...smtpMessage };
    _smtpMessage.inReplyToID = messagesOrReply[index].replyToMessageId;
    _onCloseReply(index);
    createMailMessage(_smtpMessage).then(() => {
      loadMessages(thread.id, true, true);
    });
  };

  const _onSMTPMessageStatusUpdated = useCallback((e: MessageEvent) => {
    const event = newEventFromMessageEvent(e);
    const eventMsg = event.getDataInstanceOf(Message);
    if (eventMsg) {
      const updatedMessages = messages.map((msg) => {
        if (msg.id === eventMsg.id) {
          return {
            ...msg,
            smtpMessage: {
              ...msg.smtpMessage,
              status: eventMsg.smtpMessage.status,
            },
          };
        }
        return msg;
      });
      setMessages(updatedMessages);
    }
  }, [messages, setMessages]);

  useEffect(() => {
    // Add the SSE handlers when component mounts
    globalSseSources.addSourcesHandler(
      'thread:messages/smtp_message_sent',
      ['smtp_message_sent'],
      _onSMTPMessageStatusUpdated,
    );
    globalSseSources.addSourcesHandler(
      'thread:messages/smtp_message_failed',
      ['smtp_message_failed'],
      _onSMTPMessageStatusUpdated,
    );

    // Remove the SSE handler when component unmounts
    return () => {
      globalSseSources.removeSourcesHandler('thread:messages/smtp_message_sent');
      globalSseSources.removeSourcesHandler('thread:messages/smtp_message_failed');
    };
  }, [_onSMTPMessageStatusUpdated]);

  useEffect(() => {
    if (thread) {
      loadMessages(thread.id, true, true);
      loadInbox(thread.inboxId);
    }
  }, [thread, loadMessages, loadInbox]);

  useEffect(() => {
    if (messages.length > 0) {
      // Expand the last message by default
      setExpandedMessages((prev) => ({
        ...prev,
        [messages[messages.length - 1].id]: true,
      }));

      setMessagesOrReply(messages.map((message) => ({ message })));
    }
  }, [messages]);

  if (isLoading || !thread) {
    return (
      <LoadingOverlay visible />
    );
  }

  return (
    <div className="w-full bg-white">
      {/* Top Navigation Bar */}
      <div className="flex items-center px-4 py-2 border-b">
        <div className="flex items-center flex-1 space-x-2">
          <Tooltip position="bottom" label="Back to inbox">
            <Button
              className="p-2 hover:bg-gray-100 rounded-full"
              theme="secondary"
              variant="small"
              icon={<ArrowLeftIcon className="aspect-square w-4" />}
              onClick={_onBack}
            />
          </Tooltip>
          <Tooltip position="bottom" label="Archive conversation">
            <Button
              disabled
              theme="secondary"
              variant="small"
              icon={<ArchiveBoxIcon className="aspect-square w-4" />}
              onClick={onArchive}
            />
          </Tooltip>
          <Tooltip position="bottom" label="Delete conversation">
            <Button
              disabled
              theme="secondary"
              variant="small"
              icon={<TrashIcon className="aspect-square w-4" />}
              onClick={onDelete}
            />
          </Tooltip>
          <Tooltip position="bottom" label="Snooze conversation">
            <Button
              disabled
              theme="secondary"
              variant="small"
              icon={<ClockIcon className="aspect-square w-4" />}
              onClick={() => {}}
            />
          </Tooltip>
          <Tooltip position="bottom" label="Move to inbox">
            <Button
              disabled
              theme="secondary"
              variant="small"
              icon={<FolderIcon className="aspect-square w-4" />}
              onClick={onMoveToInbox}
            />
          </Tooltip>
        </div>

        {state && (
        <div className="flex items-center space-x-4">
          <span className="text-sm text-gray-600">
            {currentPosition}
            {' '}
            of
            {' '}
            {`${threadsCount}`}
          </span>
          <Button
            disabled={currentPosition === 1}
            theme="secondary"
            variant="small"
            icon={<ChevronLeftIcon className="aspect-square w-4" />}
            onClick={onPreviousThread}
          />
          <Button
            disabled={currentPosition === threadsCount}
            theme="secondary"
            variant="small"
            icon={<ChevronRightIcon className="aspect-square w-4" />}
            onClick={onNextThread}
          />
        </div>
        )}
      </div>

      <div className="overflow-y-auto flex-1" style={{ maxHeight: 'calc(100vh - 120px)' }}>
        {/* Thread Title */}
        <div className="px-4 py-3 flex items-center justify-between">
          <div className="flex-1 px-12">
            <h1 className="text-xl text-gray-900">
              {thread.firstMessageSubject}
            </h1>
          </div>
          <div className="flex items-center space-x-2">
            <Tooltip position="bottom" label="Print">
              <Button
                disabled
                theme="secondary"
                variant="small"
                icon={<PrinterIcon className="aspect-square w-4" />}
                onClick={_onBack}
              />
            </Tooltip>
            <Tooltip position="bottom" label="Share">
              <Button
                disabled
                theme="secondary"
                variant="small"
                icon={<ShareIcon className="aspect-square w-4" />}
                onClick={_onBack}
              />
            </Tooltip>
            <Tooltip position="bottom" label="More actions">
              <Button
                disabled
                theme="secondary"
                variant="small"
                icon={<EllipsisVerticalIcon className="aspect-square w-4" />}
                onClick={_onBack}
              />
            </Tooltip>
          </div>
        </div>

        {/* Messages */}
        {messagesOrReply.map((messageOrReply, index) => {
          const { message, replyToMessageId } = messageOrReply;
          if (message) {
            const time = getMessageTime(message);
            const senderName = getUserName(message, message.userSentBy, true, getUserNameType.FROM);
            const senderBusinessName = getBusinessName(message.businessSentBy);
            const recipientNames = message.userSentToIds.map(
              (userId) => getUserName(message, userId, false, getUserNameType.TO),
            );
            const recipientBusinessName = getBusinessName(globalUser.business?.id);
            const isExpanded = expandedMessages[message.id];
            const isLastMessage = index === messages.length - 1;
            const hasReplyAfter = messagesOrReply[index + 1]?.replyToMessageId != null;

            return (
              <div key={message.id} className={`border-b border-gray-200 last:border-b-0 ${hasReplyAfter ? 'border-b-0' : ''}`}>
                <ThreadMessage
                  message={message}
                  isExpanded={isExpanded}
                  senderName={senderName}
                  senderBusinessName={senderBusinessName}
                  recipientNames={recipientNames}
                  recipientBusinessName={recipientBusinessName}
                  time={time}
                  canToggleExpanded={!isLastMessage}
                  onToggle={() => !isLastMessage && toggleMessage(message.id)}
                  onReply={() => _onReply(index)}
                />
              </div>
            );
          }

          const replyToMessage = messages.find((m) => m.id === replyToMessageId);
          return (
            <ThreadReplyMessage
              key={`reply-${replyToMessageId}`}
              subject={thread.firstMessageSubject && `Re: ${thread.firstMessageSubject}`}
              fromValues={inbox.boundEmailChannels}
              from={globalUser.email}
              to={[thread.participantUsers.find((u) => u.id === replyToMessage?.userSentBy)?.GetEmail()]}
              senderName={getUserName(replyToMessage, replyToMessage?.userSentBy, true, getUserNameType.FROM)}
              businessName={getBusinessName(globalUser.business?.id)}
              onDiscard={() => _onDiscardReply(index)}
              onSend={async (smtpMessage: SMTPMessage) => _onSendReply(index, smtpMessage)}
            />
          );
        })}
      </div>
    </div>
  );
});

export default Thread;
