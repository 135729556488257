import { StateCreator } from 'zustand';

import { KeywordAndMessageIdType, KeywordSlice, ProcessOrderDraftsStore } from './types';
import { Keyword } from 'features/order/types/keyword';

const getUpdatedKeyword = (keyword: Keyword) => ({
  ...keyword,
  fieldIds: (keyword?.fieldIds || []).filter((id) => !!id),
  fieldPath: keyword?.fieldPath || '',
});

export const createKeywordSlice: StateCreator<ProcessOrderDraftsStore, [], [], KeywordSlice> = (set, get) => ({
  hoverId: null,
  triggerHoverEffect: (hoverId: string) => set({ hoverId }),
  scrollToKeyword: (scrollToId: string) => {
    setTimeout(() => {
      let element = document.getElementById(scrollToId);
      if (!element) {
        element = document.querySelector(`[data-name="${scrollToId}"]`);
      }
      if (!element) {
        element = document.querySelector(`[data-field-path="${scrollToId}"]`);
      }
      element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 200);
  },
  zoomToId: (zoomId: string, fieldId?: string) => {
    if (!get().message) return;

    const context = get().message.context;
    const ocrKeywords = context?.workflowOrder?.ocrKeywords || [];
    const keyword = ocrKeywords.find((keyword_) => (
      keyword_.path === zoomId && (fieldId ? keyword_.fieldId === fieldId : true
      )));
    if (!keyword) return;

    get().setSelectedDocIndex(keyword.docIndex);
    get().setSelectedDocPageIndex(keyword.pageIndex);
  },

  toZoomKeyword: null,
  leftHoveredKeyword: null,
  rightHoveredKeyword: null,
  setLeftHoveredKeyword: (keyword: Keyword) => {
    set({ leftHoveredKeyword: getUpdatedKeyword(keyword) });
  },
  setRightHoveredKeyword: (keyword: Keyword) => {
    set({ rightHoveredKeyword: getUpdatedKeyword(keyword) });
  },
  setToZoomKeyword: (keyword: Keyword) => {
    set({ toZoomKeyword: getUpdatedKeyword(keyword) });
  },

  isRecordingAvailable: false,
  setIsRecordingAvailable: (isRecordingAvailable: boolean) => set({ isRecordingAvailable }),
  playRecordingKeywordAndMessageId: null,
  setPlayRecordingKeywordAndMessageId: (playRecordingKeywordAndMessageId: KeywordAndMessageIdType) => (
    set({ playRecordingKeywordAndMessageId })),
});
