import { useParams, useSearchParams } from 'react-router-dom';

import { ProtectedScreen } from 'components/ui/ProtectedScreen';

import { ProcessOrderDraftByIdPage } from 'features/order/pages';

const ProcessOrderDraftByIdScreen = () => {
  const { orderId } = useParams();

  const [searchParams] = useSearchParams();
  const isGroupOrder = searchParams.get('is_group_order') === 'true';

  return (
    <ProtectedScreen>
      <ProcessOrderDraftByIdPage orderId={orderId} isGroupOrder={isGroupOrder} />
    </ProtectedScreen>
  );
};

export default ProcessOrderDraftByIdScreen;
