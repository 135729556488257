import { ProtectedScreen } from 'components/ui/ProtectedScreen';

import { ProductListPage } from '../pages';

const ProductListScreen = () => (
  <ProtectedScreen>
    <ProductListPage />
  </ProtectedScreen>
);

export default ProductListScreen;
