import { infiniteQueryOptions } from '@tanstack/react-query';

import { fetchChats, FetchChatsParams } from '../apis';

export const chatsOptions = (params?: FetchChatsParams) => infiniteQueryOptions({
  queryKey: ['chats', params],
  queryFn: ({ pageParam = '' }) => fetchChats({
    ...params,
    cursor: pageParam,
  }),
  initialPageParam: '',
  getNextPageParam: (lastPage) => lastPage.cursor || null,
  gcTime: 0,
  meta: {
    errorMessage: 'Error fetching chats',
  },
});
