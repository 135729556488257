import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { globalUser } from 'state/globalUser';

import { AssignableUserRole } from 'features/user/models';

import { AssignedStatus } from 'features/order/types/order';

import { useSelectedGroupOrder } from './hooks/useSelectedGroupOrder';
import useStopWatch from './hooks/useStopWatch';

import InitialAssignee from './components/InitialAssignee';
import { ProcessView } from './components/ProcessView';
import LoadingOverlay from 'components/ui/LoadingOverlay';

import { getAssignedStatus } from 'features/order/utils/order';

import { useProcessOrderDraftsStore } from 'features/order/store/process-order-drafts';

const RightSide = () => {
  const { t } = useTranslation();

  const assignOrder = useProcessOrderDraftsStore((state) => state.assignOrder);

  // Loading states
  const isGroupOrdersLoading = useProcessOrderDraftsStore((state) => state.isGroupOrdersLoading);
  const isAssigningOrder = useProcessOrderDraftsStore((state) => state.isAssigningOrder);
  const isSavingOrder = useProcessOrderDraftsStore((state) => state.isSavingOrder);
  const isDecliningOrder = useProcessOrderDraftsStore((state) => state.isDecliningOrder);
  const isConfirmingOrder = useProcessOrderDraftsStore((state) => state.isConfirmingOrder);

  const { order } = useSelectedGroupOrder();

  const { startStopwatch, stopStopwatch } = useStopWatch({ order });

  const assignedStatus: AssignedStatus = getAssignedStatus(order?.assigneeId);

  const onAssignButtonClick = useCallback((userId: string, userName: string, assignedComment: string) => {
    assignOrder(
      userId,
      userName,
      assignedComment,
    ).then(() => {
      startStopwatch();
    });
  }, [assignOrder, startStopwatch]);

  if (assignedStatus === AssignedStatus.OTHER && !globalUser.hasRole(AssignableUserRole.BusinessAdmin)) {
    return (
      <div className="relative flex h-full flex-1 items-center justify-center">
        <div className="flex flex-col space-y-2">
          <h1 className="text-center text-title-md">Oops!</h1>
          <p>{t('order.processOrderDrafts.rightSide.alreadyAssigned')}</p>
        </div>
      </div>
    );
  }

  if (assignedStatus === AssignedStatus.UNASSIGNED) {
    return (
      <div className="flex-1 p-4">
        <InitialAssignee
          order={order}
          assignOrder={onAssignButtonClick}
        />
      </div>
    );
  }

  if (isAssigningOrder) {
    return (
      <LoadingOverlay visible displayText={t('loading.assigningOrder')} />
    );
  }

  if (isConfirmingOrder) {
    return (
      <LoadingOverlay visible displayText={t('loading.confirmingOrder')} />
    );
  }

  if (isDecliningOrder) {
    return (
      <LoadingOverlay visible displayText={t('loading.decliningOrder')} />
    );
  }

  if (isSavingOrder) {
    return (
      <LoadingOverlay visible displayText={t('loading.savingOrder')} />
    );
  }

  return (
    <div className="flex-1 bg-white overflow-hidden">
      <div className="h-full w-full overflow-y-auto overflow-x-hidden relative">
        {
          isGroupOrdersLoading && (
            <LoadingOverlay visible displayText={t('loading.orderDetails')} />
          )
        }
        <ProcessView
          stopStopwatch={stopStopwatch}
          onAssignButtonClick={onAssignButtonClick}
        />
      </div>
    </div>
  );
};

export default RightSide;
