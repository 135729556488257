import { StateCreator } from 'zustand';

import { Message, MessageContextUtils } from 'features/message/models/Message';

import { MessageSlice, ProcessOrderDraftsStore } from './types';

import { genericErrorFeedback } from 'utils/errors';
import { isZeroId } from 'utils/objectId';
import { httpGetV1 } from 'utils/xhr';

import { fetchMessageById } from 'features/message/api';

export const createMessageSlice: StateCreator<ProcessOrderDraftsStore, [], [], MessageSlice> = (set, get) => ({
  message: null,
  resetMessageStore: () => {
    set({
      message: null,
      isMessageLoading: false,
    });
  },
  isMessageLoading: false,
  loadMessageById: async (messageId: string) => {
    set({ isMessageLoading: true });
    return fetchMessageById(messageId)
      .then((message) => {
        set({ message });
      })
      .catch((error) => {
        genericErrorFeedback('Failed to load message')(error);
      })
      .finally(() => set({ isMessageLoading: false }));
  },
  loadMessage: async () => {
    const { groupOrders } = get();
    const currentParentOrder = Object.values(groupOrders).find((order) => !order.isGroupSibling);
    if (!currentParentOrder) {
      return Promise.reject(new Error('No order selected'));
    }

    const refId = (!isZeroId(currentParentOrder.groupId)) ? currentParentOrder.groupId : currentParentOrder.id;

    set({ isMessageLoading: true });

    return httpGetV1(
      `/chat/messages?reference_id=${refId}`,
    )
      .then((response) => {
        const messages = (response.data.result || []) as Message[];
        const message = messages[0];

        let isRecordingAvailable = false;
        if (message) {
          isRecordingAvailable = MessageContextUtils.audioAttachments(message.context).length > 0;
        }

        set({
          message,
          isRecordingAvailable,
        });
      })
      .catch((error) => {
        genericErrorFeedback('Failed to load message')(error);
      })
      .finally(() => {
        set({ isMessageLoading: false });
      });
  },
  removeKeywordsByFieldId: (fieldId: string) => {
    set((state) => {
      const newMessage = { ...state.message };
      if (newMessage?.context?.workflowOrder?.ocrKeywords) {
        newMessage.context.workflowOrder.ocrKeywords = (
          newMessage.context.workflowOrder.ocrKeywords.filter((keyword) => keyword.fieldId !== fieldId)
        );
      }
      if (newMessage?.context?.workflowOrder?.audioKeywords) {
        newMessage.context.workflowOrder.audioKeywords = (
          newMessage?.context?.workflowOrder?.audioKeywords.filter((keyword) => keyword.fieldId !== fieldId)
        );
      }
      if (newMessage?.context?.workflowOrder?.keywords) {
        newMessage.context.workflowOrder.keywords = (
          newMessage?.context?.workflowOrder?.keywords.filter((keyword) => keyword.fieldId !== fieldId)
        );
      }

      return { message: newMessage };
    });
  },
});
