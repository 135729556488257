import { useEffect, useMemo } from 'react';

import { Group } from 'features/instruction/constants';

import { ProductWithQuantity } from '../models';

import { getErrorsOrWarnings } from '../store/process-order-drafts/utils';

import { useProcessOrderDraftsStore } from '../store/process-order-drafts';

interface Props {
  products: ProductWithQuantity[];
  selectedOrderId: string;
}

export const useProductsErrorsAndWarnings = ({ products, selectedOrderId }: Props) => {
  const storeErrors = useProcessOrderDraftsStore((state) => state.errors);
  const storeWarnings = useProcessOrderDraftsStore((state) => state.warnings);
  const resetGroupErrorsAndWarnings = useProcessOrderDraftsStore((state) => state.resetGroupErrorsAndWarnings);

  const errors = getErrorsOrWarnings(storeErrors, selectedOrderId);
  const warnings = getErrorsOrWarnings(storeWarnings, selectedOrderId);

  const errorsKeys = useMemo(() => Object.keys(errors[Group.Product] || {}), [errors]);
  const productErrors = Object.values(errors[Group.Product] || {}).filter((error) => error !== '');
  const warningsKeys = useMemo(() => Object.keys(warnings[Group.Product] || {}), [warnings]);

  useEffect(() => {
    // TODO(chihirokuya): fix this dirty hack
    if (!products || products?.length === 0) {
      resetGroupErrorsAndWarnings(Group.Product);
    }
  }, [products, resetGroupErrorsAndWarnings]);

  return {
    errorsKeys,
    warningsKeys,

    productErrors,
  };
};
