import { useTranslation } from 'react-i18next';

import { TFunction } from 'i18next';

import { useQuery } from '@tanstack/react-query';

import { TopTierCustomer } from 'models/Analytics';

import { SalesMetric } from 'features/analytics/types/metric';
import { TimePeriod } from 'features/analytics/types/timePeriod';

import { BarChart } from 'components/charts';
import { InformationIconWithTooltip } from 'components/ui';

import { DateOrMonthPickerInput } from '../utils';

import { topTierCustomersOptions } from 'features/analytics/queryOptions';
import { useAnalyticsStore } from 'features/analytics/stores';

const formatData = (data: TopTierCustomer[], metric: SalesMetric) => {
  if (!data) return [];

  const formattedData = data.map((item) => ({
    x: item.customerName,
    y: metric === SalesMetric.TotalProductsSold ? item.totalQuantitySum : item.totalRevenueSum,
  }));

  return formattedData;
};

const title = (metric: SalesMetric, t: TFunction) => (
  <div className="flex items-center gap-2">
    <p>
      {t('dashboard.topTierCustomers.title')}
    </p>
    <InformationIconWithTooltip
      iconClassName="text-gray-400 hover:text-gray-700"
      label={(
        <div className="max-w-[300px] whitespace-normal">
          {metric === SalesMetric.TotalProductsSold ? (
            <div className="space-y-3">
              <div className="grow shrink basis-0 text-[#191919]/75 text-sm font-normal font-['Inter']">
                {t('dashboard.topTierCustomers.productsSold.description')}
              </div>
              <div className="self-stretch text-[#232d42]/60 text-[13px] font-normal font-['Inter']">
                {t('dashboard.topTierCustomers.productsSold.note')}
              </div>
            </div>
          ) : (
            <div className="space-y-3">
              <div className="grow shrink basis-0 text-[#191919]/75 text-sm font-normal font-['Inter']">
                {t('dashboard.topTierCustomers.revenue.description')}
              </div>
              <div className="self-stretch text-[#232d42]/60 text-[13px] font-normal font-['Inter']">
                {t('dashboard.topTierCustomers.revenue.note')}
              </div>
            </div>
          )}
        </div>
      )}
    />
  </div>
);
const TopTierCustomerBarChart = () => {
  const { t } = useTranslation();

  const date = useAnalyticsStore((state) => state.topTierCustomersDate);
  const setDate = useAnalyticsStore((state) => state.setTopTierCustomersDate);
  const metric = useAnalyticsStore((state) => state.topTierCustomersMetric);
  const period = useAnalyticsStore((state) => state.topTierCustomersTimePeriod);
  const setMetric = useAnalyticsStore((state) => state.setTopTierCustomersMetric);
  const setPeriod = useAnalyticsStore((state) => state.setTopTierCustomersTimePeriod);

  const {
    data: topTierCustomers,
    isFetching,
  } = useQuery({
    ...topTierCustomersOptions(date, period, metric, 10),
    select: (data) => formatData(data, metric),
  });

  const tooltipFormatter = (value: any) => [value.toLocaleString(), metric === SalesMetric.TotalProductsSold ? 'Products Sold' : 'CHF'];

  return (
    <BarChart
      isLoading={isFetching}
      title={title(metric, t)}
      subtitle={`${t('dashboard.topTierCustomers.description')} ${metric === SalesMetric.TotalProductsSold ? t('dashboard.topTierCustomers.metric.productsSold') : t('dashboard.topTierCustomers.metric.revenue')}`}
      data={topTierCustomers}
      xDataKey="x"
      yDataKey="y"
      chartDivClassName="h-[370px]"
      rightSelectOptions={[
        { value: TimePeriod.Daily, label: t('timePeriod.daily') },
        { value: TimePeriod.Weekly, label: t('timePeriod.weekly') },
        { value: TimePeriod.Monthly, label: t('timePeriod.monthly') },
      ]}
      selectedRightOptionValue={period}
      setRightOption={(value) => setPeriod(value as TimePeriod)}
      leftSelectOptions={[
        { value: SalesMetric.TotalProductsSold, label: t('dashboard.topTierCustomers.metric.productsSold') },
        { value: SalesMetric.TotalRevenue, label: t('dashboard.topTierCustomers.metric.revenue') },
      ]}
      selectedLeftOptionValue={metric}
      setLeftOption={setMetric}
      tooltipFormatter={tooltipFormatter}
      horizontal={false}
      rightSection={<DateOrMonthPickerInput date={date} setDate={setDate} timePeriod={period} />}
    />
  );
};

export default TopTierCustomerBarChart;
