import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingOverlay } from '@mantine/core';

import { Action, Workflow } from 'features/instruction/types';

import { InstructionContext } from 'contexts/useInstructionContext';

import { useFetchPromptById } from 'hooks/fetch/useFetchPromptById';
import { useUpdatePrompt } from 'hooks/useUpdatePrompt';

import { Page } from 'components/layout/Page/Page';
import { InstructionDetail } from 'components/steps/instructions';

interface Props {
  instructionId: string;
}

const EditInstructionPage = ({ instructionId }: Props) => {
  const { t } = useTranslation();

  const [selectedWorkflow, setSelectedWorkflow] = useState<Workflow>(
    Workflow.ORDER,
  );
  const [selectedAction, setSelectedAction] = useState<Action>(Action.CREATE);
  const [instructionName, setInstructionName] = useState<string>('');

  const {
    prompt,
    setPrompt,
    isLoading: isPromptLoading,
    loadPrompt,
  } = useFetchPromptById();

  const { updatePrompt, isLoading: isUpdatePromptLoading } = useUpdatePrompt();

  const instructionContextValue = useMemo(
    () => ({
      selectedWorkflow,
      setSelectedWorkflow,
      selectedAction,
      setSelectedAction,
      instructionName,
      setInstructionName,
      prompt,
      setPrompt,
    }),
    [selectedWorkflow, selectedAction, instructionName, prompt, setPrompt],
  );

  useEffect(() => {
    loadPrompt(instructionId).then((_prompt) => {
      setInstructionName(_prompt.name);
    });
  }, [instructionId, loadPrompt]);

  return (
    <Page
      pageTitle={t('instruction.edit.title')}
      pageDescription={t('instruction.edit.description')}
    >
      <InstructionContext.Provider value={instructionContextValue}>
        {isPromptLoading || !prompt ? (
          <LoadingOverlay />
        ) : (
          <InstructionDetail
            buttonTitle="Update"
            onButtonClick={() => {
              updatePrompt(prompt);
            }}
            isOuterLoading={isUpdatePromptLoading}
          />
        )}
      </InstructionContext.Provider>
    </Page>
  );
};

export default EditInstructionPage;
