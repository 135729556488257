import { forwardRef, Ref, SVGProps } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props extends SVGProps<SVGSVGElement> {
  className?: string;
}

const TeamsIcon = ({ className, ...props }: Props, ref: Ref<SVGSVGElement>) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    className={twMerge('w-6 h-6', className)}
    {...props}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M17.0599 7.6501C17.0599 6.82167 16.3884 6.1501 15.5599 6.1501C14.7315 6.1501 14.0599 6.82167 14.0599 7.6501C14.0599 8.47852 14.7315 9.1501 15.5599 9.1501C16.3884 9.1501 17.0599 8.47852 17.0599 7.6501ZM15.5599 4.8501C17.1063 4.8501 18.3599 6.1037 18.3599 7.6501C18.3599 9.1965 17.1063 10.4501 15.5599 10.4501C14.0135 10.4501 12.7599 9.1965 12.7599 7.6501C12.7599 6.1037 14.0135 4.8501 15.5599 4.8501ZM10.16 8.54986C10.16 7.50604 9.31386 6.65986 8.27005 6.65986C7.22623 6.65986 6.38004 7.50605 6.38004 8.54986C6.38004 9.59368 7.22623 10.4399 8.27005 10.4399C9.31386 10.4399 10.16 9.59368 10.16 8.54986ZM8.27005 5.35986C10.0318 5.35986 11.46 6.78807 11.46 8.54986C11.46 10.3117 10.0318 11.7399 8.27005 11.7399C6.50826 11.7399 5.08004 10.3117 5.08004 8.54986C5.08004 6.78807 6.50826 5.35986 8.27005 5.35986ZM12.1519 15.7341C12.499 15.1071 12.0106 14.2399 11.2 14.2399H6.53399L5.35553 14.2499L5.35553 14.2499H5.35003C4.53514 14.2499 4.0527 15.1112 4.39848 15.7347L4.39926 15.7361C5.14879 17.0957 6.60474 18.0199 8.28003 18.0199C9.9516 18.0199 11.3904 17.099 12.1519 15.7341ZM13.07 11.1401C11.9743 11.1401 11.1283 11.9649 10.9883 12.9399H6.53124H6.52573V12.94L5.34706 12.9499C3.64372 12.9521 2.38825 14.7891 3.26119 16.3645C4.23178 18.1245 6.11561 19.3199 8.28003 19.3199C10.4482 19.3199 12.3091 18.1213 13.2877 16.3665L13.2885 16.3652C13.3141 16.319 13.3379 16.2725 13.3599 16.2259C14.0218 16.5557 14.769 16.7401 15.56 16.7401C17.4153 16.7401 19.048 15.704 19.8881 14.186C20.6742 12.7777 19.543 11.1401 18.03 11.1401H13.07ZM13.5238 14.819C13.3752 14.1306 12.9061 13.5258 12.2682 13.1985L12.269 13.1862C12.2907 12.7951 12.6303 12.4401 13.07 12.4401H18.03C18.6565 12.4401 19.005 13.1015 18.7527 13.5529L18.7512 13.5556C18.1311 14.6769 16.9242 15.4401 15.56 15.4401C14.7999 15.4401 14.101 15.2111 13.5238 14.819Z" />
  </svg>
);

export default forwardRef(TeamsIcon);
