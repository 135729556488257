import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { observer } from 'mobx-react-lite';

import { globalUser } from '../../state/globalUser';

import LoadingOverlay from './LoadingOverlay';

interface Props {
  children: ReactNode;
}

const ProtectedScreen = observer(({ children }: Props) => {
  if (globalUser.isTokenRefreshing) {
    return <LoadingOverlay visible />;
  }

  if (!globalUser.isSignedIn) {
    return <Navigate to="/signin" />;
  }
  if (!globalUser.activated) {
    return <Navigate to="/activate-user" />;
  }
  return <section>{children}</section>;
});

export { ProtectedScreen };
