import { plainToClass } from 'class-transformer';
import { debounce } from 'lodash';
import {
  action, makeObservable, observable, reaction, runInAction,
} from 'mobx';

import { globalSseSources } from 'state/globalSseSources';

import { Message } from 'features/message/models/Message';
import { Event } from 'models/Event';
import { Inbox } from 'models/Inbox';

import { genericErrorFeedback } from 'utils/errors';
import { isZeroId } from 'utils/objectId';
import { httpGetV1 } from 'utils/xhr';

class Inboxes {
  inboxes: Inbox[] = [];

  selectedInboxId?: string | null = null;

  inboxMessages: Message[] = [];

  isLoading?: boolean = false;

  isInboxMessagesLoading?: boolean = false;

  constructor() {
    makeObservable(this, {
      inboxes: observable,
      isLoading: observable,
      selectedInboxId: observable,
      inboxMessages: observable,
      isInboxMessagesLoading: observable,
      loadInboxes: action,
    });

    reaction(
      () => this.selectedInboxId,
      () => this.loadInboxMessages(),
    );
  }

  _reset = () => {
    runInAction(() => {
      this.inboxes = [];
      this.selectedInboxId = null;
    });
  };

  refresh = () => {
    this._reset();

    this.loadInboxes()
      .catch(genericErrorFeedback('Error refreshing inboxes'));
  };

  setSelectedInboxId = (id: string) => {
    if (this.inboxes.find((inbox) => inbox.id === id)) {
      this.selectedInboxId = id;
    }
  };

  updateInbox = (inbox: Inbox) => {
    this.inboxes = this.inboxes.map((inbox_) => {
      if (inbox_.id === inbox.id) {
        return { ...inbox_, ...inbox };
      }
      return inbox_;
    });
  };

  loadInboxes = async () => {
    runInAction(() => {
      this.isLoading = true;
    });

    const dataResponse = await httpGetV1('/inboxes', {
      params: {
        limit: 20,
      },
      classType: Inbox,
    });

    runInAction(() => {
      this.inboxes = dataResponse.data || [];
      this.isLoading = false;

      if (this.selectedInboxId === null && this.inboxes.length > 0) {
        // If no inbox is selected, select the first inbox
        this.selectedInboxId = this.inboxes[0].id;
      }
    });
  };

  loadInboxMessages = async (onlyUpdate: boolean = false) => {
    if (isZeroId(this.selectedInboxId)) {
      return;
    }

    if (!onlyUpdate) {
      runInAction(() => {
        this.isInboxMessagesLoading = true;
        this.inboxMessages = [];
      });
    }

    const dataResponse = await httpGetV1(`/inboxes/${this.selectedInboxId}/messages`, {
      params: {
        limit: 5,
        include_business_sent_by: true,
      },
      classType: Message,
    });

    const messages = (dataResponse.data.result || []) as Message[];

    runInAction(() => {
      this.inboxMessages = messages;
      this.isInboxMessagesLoading = false;
    });
  };

  _debouncedLoadInboxMessages = debounce(this.loadInboxMessages, 1000);

  addSseSourcesHandler = () => {
    globalSseSources.addSourcesHandler(
      'messages',
      ['new_message'],
      this._onNewMessageSseReceived,
    );

    globalSseSources.addSourcesHandler(
      'messages',
      ['classified_message', 'processed_message', 'failed_message'],
      this._onMessageStatusUpdateSseReceived,
    );
  };

  // eslint-disable-next-line class-methods-use-this
  removeSseSourcesHandler = () => {
    globalSseSources.removeSourcesHandler('messages');
  };

  _onNewMessageSseReceived = () => {
    this._debouncedLoadInboxMessages(true);
  };

  // eslint-disable-next-line class-methods-use-this
  _onMessageStatusUpdateSseReceived = (messageEvent: MessageEvent) => {
    let message: Message;

    try {
      const data = JSON.parse(messageEvent.data);
      const event = plainToClass(Event, data);
      message = event.getDataInstanceOf(Message);
    } catch (e) {
      return;
    }

    const inboxMessage = this.inboxMessages.find((msg) => msg.id === message.id);

    if (inboxMessage) {
      this.inboxMessages = this.inboxMessages.map((msg) => {
        if (msg.id === message.id) {
          return message;
        }
        return msg;
      });
    }
  };
}

export { Inboxes };
