import { useState } from 'react';

import { SettingSection } from '../BusinessSettings/Feed/types';

import { Feed } from '../BusinessSettings/Feed';
import LanguageSettings from './setting-card/LanguageSettings';

const settingSections: SettingSection[] = [
  {
    title: 'Language Settings',
    sectionId: 'lang_settings',
  },
];

const sectionIds = [
  ...settingSections
    .filter((s) => !s.children || s.children.length === 0)
    .map((s) => s.sectionId),
  ...settingSections
    .filter((s) => s.children && s.children.length > 0)
    .map((s) => s.children?.map((c) => c.sectionId))
    .flat(),
];

const AccountSettings = () => {
  const [currentSectionId, setCurrentSectionId] = useState<string>('lang_settings');

  const handleScroll = () => {
    for (let i = 0; i < sectionIds.length; i += 1) {
      const section = document.getElementById(sectionIds[i]);
      if (!section) return;
      if (section.getBoundingClientRect().top >= 0) {
        setCurrentSectionId(sectionIds[i]);
        break;
      }
    }
  };

  return (
    <div className="flex h-full space-x-4 overflow-hidden">
      <Feed
        currentSectionId={currentSectionId}
        sections={settingSections}
      />
      <div
        className="h-full flex-1 overflow-y-auto"
        onScroll={handleScroll}
      >
        <LanguageSettings />
      </div>
    </div>
  );
};

export default AccountSettings;
