import { useCallback } from 'react';

import DatePickersLeftSectionButton from './DatePickersLeftSectionButton';

interface Props {
  setFromDate: (date: Date) => void;
  setToDate: (date: Date) => void;
}

const DatePickersLeftSection = ({ setFromDate, setToDate }: Props) => {
  const handleYesterdayClick = useCallback(() => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    setFromDate(yesterday);
    setToDate(yesterday);
  }, [setFromDate, setToDate]);

  const handleSelectPast7DaysClick = useCallback(() => {
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
    setFromDate(sevenDaysAgo);
    setToDate(new Date());
  }, [setFromDate, setToDate]);

  const handlePrevious30DaysClick = useCallback(() => {
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    setFromDate(thirtyDaysAgo);
    setToDate(new Date());
  }, [setFromDate, setToDate]);

  const handlePrevious6MonthsClick = useCallback(() => {
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
    setFromDate(sixMonthsAgo);
    setToDate(new Date());
  }, [setFromDate, setToDate]);

  const handlePreviousYearClick = useCallback(() => {
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
    setFromDate(oneYearAgo);
    setToDate(new Date());
  }, [setFromDate, setToDate]);

  return (
    <div className="inline-flex flex-col items-start gap-1 pl-0 pr-6 py-2 relative self-stretch flex-[0_0_auto] border-r [border-right-style:solid] border-light-grey">
      <DatePickersLeftSectionButton label="yesterday" onClick={handleYesterdayClick} />
      <DatePickersLeftSectionButton label="Select past 7 days" onClick={handleSelectPast7DaysClick} />
      <DatePickersLeftSectionButton label="Previous 30 days" onClick={handlePrevious30DaysClick} />
      <DatePickersLeftSectionButton label="Previous 6 months" onClick={handlePrevious6MonthsClick} />
      <DatePickersLeftSectionButton label="Previous year" onClick={handlePreviousYearClick} />
    </div>
  );
};

export default DatePickersLeftSection;
