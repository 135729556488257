import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Dayjs from 'dayjs';

import { DateTimePicker } from '@mantine/dates';

import { Order } from '../../models';

import { TextWithLabel } from 'components/ui/TextWithLabel';

import { isZeroTime } from 'utils/dateTime';

interface Props {
  order: Order;
}

const Details = ({ order }: Props) => {
  const { t } = useTranslation();

  const requestedDeliveryDatetime: Date | null = useMemo(() => {
    if (order?.requestedDeliveryTime) return Dayjs(order?.requestedDeliveryTime).toDate();

    return null;
  }, [order?.requestedDeliveryTime]);

  const createdDatetime: Date | null = useMemo(() => {
    if (order?.createdAt) return Dayjs(order?.createdAt).toDate();

    return null;
  }, [order?.createdAt]);

  const lastErpSynchDatetime: Date | null = useMemo(() => {
    if (order?.lastErpSyncAt && !isZeroTime(order?.lastErpSyncAt)) return Dayjs(order?.lastErpSyncAt).toDate();

    return null;
  }, [order?.lastErpSyncAt]);

  if (!order) return null;
  return (
    <div className="grid grid-cols-2 gap-sm">
      <DateTimePicker
        label={t('order.details.card.details.placedAt')}
        value={createdDatetime}
        readOnly
        variant="unstyled"
        classNames={{
          label: 'text-gray-400',
          input: 'text-gray-900',
        }}
      />
      <DateTimePicker
        label={t('order.details.card.details.requestedDeliveryTime')}
        value={requestedDeliveryDatetime}
        variant="unstyled"
        readOnly
        classNames={{
          label: 'text-gray-400',
          input: 'text-gray-900',
        }}
      />
      <TextWithLabel
        label={t('order.details.card.details.orderIdInHoshii')}
        value={order?.id}
      />
      <TextWithLabel
        label={t('order.details.card.details.orderIdInYourERP')}
        value={order?.externalId}
        className={!order?.externalId && 'invisible'}
      />
      <TextWithLabel
        label={t('order.details.card.details.comment')}
        value={order?.draft?.comment || '-'}
      />
      <DateTimePicker
        label={t('order.details.card.details.lastERPSynchronizationAt')}
        value={lastErpSynchDatetime}
        variant="unstyled"
        readOnly
        className={`col-span-2 ${!lastErpSynchDatetime && 'hidden'}`}
        classNames={{
          label: 'text-gray-400',
          input: 'text-gray-900',
        }}
      />
    </div>
  );
};

export default Details;
