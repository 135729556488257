import { twMerge } from 'tailwind-merge';

import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loader } from '@mantine/core';

import { Message, MessageIntent, MessageStateType } from 'features/message/models/Message';

import { TagVariant } from 'types/tag';

import { Tag } from 'components/common/Tag';

import { hasFailedWorkflow, hasPendingWorkflow } from '../utils/messageState';
import { intentToTagVariant, sortIntents } from 'utils/enums';

interface Props {
  message: Message;
  className?: string;
  hideReading?: boolean;
}

const loaderColorMap = {
  [TagVariant.BLUE]: 'rgb(63 131 248)',
  [TagVariant.GREEN]: 'green',
  [TagVariant.RED]: 'red',
  [TagVariant.GRAY]: 'gray',
  [TagVariant.WHITE]: 'white',
};

const MessageTags = ({ message, className, hideReading = false }: Props) => {
  if (!hideReading && message.state?.type === MessageStateType.NEW) {
    return <div className="text-xs text-gray-500 animate-blink">Reading message contents...</div>;
  }

  return (
    <div className={twMerge(
      'inline-flex items-start justify-start gap-0.5',
      className,
    )}
    >
      {sortIntents(message.intents, MessageIntent.ORDER).map((intent) => {
        const tagVariant = intentToTagVariant(intent);
        return (
          <Tag
            key={intent}
            tagTitle={intent}
            leftSection={(
              <>
                {hasPendingWorkflow(message, intent) && (
                  <span className="flex items-center">
                    <Loader size="0.7rem" className="-mr-0.5" color={loaderColorMap[tagVariant]} />
                  </span>
                )}

                {hasFailedWorkflow(message, intent) && (
                <FontAwesomeIcon className="-mr-1.5 h-3 w-3" icon={faXmark} />
                )}
              </>
                        )}
            className="static"
            tagVariant={tagVariant}
            hideCircle
          />
        );
      })}
    </div>
  );
};

export default MessageTags;
