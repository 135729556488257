import { useParams } from 'react-router-dom';

import { ProtectedScreen } from 'components/ui/ProtectedScreen';

import { EditInstructionPage } from '../pages';

const EditInstructionScreen = () => {
  const { instructionId } = useParams();

  return (
    <ProtectedScreen>
      <EditInstructionPage instructionId={instructionId} />
    </ProtectedScreen>
  );
};

export default EditInstructionScreen;
