import { forwardRef, Ref, SVGProps } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props extends SVGProps<SVGSVGElement> {
  className?: string;
}

const ArrowExportIcon = ({ className, ...props }: Props, ref: Ref<SVGSVGElement>) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    className={twMerge('w-6 h-6', className)}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.05 4.04854L10.05 12.6C10.05 12.9313 10.3187 13.2 10.65 13.2C10.9814 13.2 11.25 12.9313 11.25 12.6V4.04854L12.2258 5.02428C12.4601 5.25859 12.84 5.25859 13.0743 5.02428C13.3086 4.78996 13.3086 4.41006 13.0743 4.17575L11.1096 2.2111C11.1037 2.20511 11.0976 2.19926 11.0914 2.19355C10.9818 2.07454 10.8246 1.99998 10.65 1.99998C10.4754 1.99998 10.3183 2.07454 10.2086 2.19357C10.2025 2.19927 10.1964 2.20511 10.1904 2.2111L8.22576 4.17575C7.99145 4.41006 7.99145 4.78996 8.22576 5.02427C8.46008 5.25859 8.83997 5.25859 9.07429 5.02427L10.05 4.04854ZM7.65002 7.24998C6.35216 7.24998 5.30002 8.30211 5.30002 9.59998V15.6C5.30002 16.8978 6.35216 17.95 7.65002 17.95H13.65C14.9479 17.95 16 16.8978 16 15.6V9.59998C16 8.30211 14.9479 7.24998 13.65 7.24998C13.291 7.24998 13 6.95896 13 6.59998C13 6.24099 13.291 5.94998 13.65 5.94998C15.4612 5.94998 16.9643 7.2692 17.2508 8.99919C18.9808 9.28569 20.3 10.7888 20.3 12.6V18.6C20.3 20.6158 18.6659 22.25 16.65 22.25H10.65C8.83882 22.25 7.33573 20.9307 7.04923 19.2008C5.31925 18.9143 4.00002 17.4112 4.00002 15.6V9.59998C4.00002 7.58414 5.63419 5.94998 7.65002 5.94998C8.00901 5.94998 8.30002 6.24099 8.30002 6.59998C8.30002 6.95896 8.00901 7.24998 7.65002 7.24998ZM8.39108 19.25C8.67304 20.2317 9.57765 20.95 10.65 20.95H16.65C17.9479 20.95 19 19.8978 19 18.6V12.6C19 11.5276 18.2817 10.623 17.3 10.341V15.6C17.3 17.6158 15.6659 19.25 13.65 19.25H8.39108Z"
    />
  </svg>
);

export default forwardRef(ArrowExportIcon);
