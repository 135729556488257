import { useSearchParams } from 'react-router-dom';

import { MessageIntent } from 'features/message/models/Message';

import { ThreadProviderType, ThreadsProvider } from 'contexts/useThreadsContext';

import { ProtectedScreen } from 'components/ui/ProtectedScreen';

import { InboxPage } from '../pages';

const InboxScreen = () => {
  const [searchParams] = useSearchParams();

  const unreadOnly = searchParams.get('unread_only') === 'true';
  const intent = searchParams.get('intent') as MessageIntent | null || null;

  return (
    <ProtectedScreen>
      <ThreadsProvider
        providerType={ThreadProviderType.DEFAULT}
        defaultFilter={{
          unreadOnly,
          intents: intent ? [intent] : [],
        }}
      >
        <InboxPage />
      </ThreadsProvider>
    </ProtectedScreen>
  );
};

export default InboxScreen;
