import { forwardRef, Ref, SVGProps } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props extends SVGProps<SVGSVGElement> {
  className?: string;
}

const CogIcon = ({ className, ...props }: Props, ref: Ref<SVGSVGElement>) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    className={twMerge('w-6 h-6', className)}
    {...props}
  >
    <path
      d="M9.18703 3.94005C9.27744 3.39759 9.74678 3 10.2967 3H12.8907C13.4406 3 13.91 3.39759 14.0004 3.94005L14.2139 5.2211C14.2762 5.59514 14.5262 5.90671 14.858 6.09036C14.9321 6.13142 15.0055 6.17383 15.0779 6.21757C15.4029 6.41384 15.7983 6.47486 16.1538 6.34166L17.3707 5.88578C17.8857 5.69284 18.4647 5.90051 18.7397 6.37677L20.0366 8.62321C20.3116 9.09948 20.202 9.70473 19.7774 10.0543L18.773 10.8811C18.4805 11.1219 18.3355 11.4937 18.3425 11.8725C18.3433 11.9149 18.3437 11.9574 18.3437 12C18.3437 12.0426 18.3433 12.0851 18.3425 12.1275C18.3355 12.5063 18.4805 12.8781 18.773 13.1189L19.7774 13.9457C20.202 14.2953 20.3116 14.9005 20.0366 15.3768L18.7397 17.6232C18.4647 18.0995 17.8857 18.3071 17.3707 18.1142L16.1538 17.6583C15.7983 17.5251 15.4029 17.5862 15.0779 17.7824C15.0055 17.8262 14.9322 17.8686 14.858 17.9096C14.5262 18.0933 14.2762 18.4049 14.2139 18.7789L14.0004 20.0599C13.91 20.6024 13.4406 21 12.8907 21H10.2967C9.74678 21 9.27744 20.6024 9.18703 20.0599L8.97353 18.7789C8.91118 18.4049 8.66121 18.0933 8.32944 17.9096C8.25527 17.8686 8.18195 17.8262 8.10952 17.7824C7.78448 17.5862 7.38915 17.5251 7.03359 17.6583L5.81672 18.1142C5.30173 18.3072 4.72274 18.0995 4.44777 17.6232L3.15078 15.3768C2.87581 14.9005 2.98547 14.2953 3.41005 13.9457L4.4144 13.1189C4.70691 12.8781 4.8519 12.5063 4.84489 12.1275C4.8441 12.0851 4.84371 12.0426 4.84371 12C4.84371 11.9574 4.8441 11.9149 4.84489 11.8725C4.8519 11.4937 4.7069 11.1219 4.4144 10.8811L3.41005 10.0543C2.98546 9.70475 2.87581 9.09949 3.15078 8.62323L4.44777 6.37679C4.72274 5.90052 5.30173 5.69286 5.81672 5.88579L7.03357 6.34167C7.38914 6.47487 7.78447 6.41385 8.1095 6.21758C8.18194 6.17384 8.25526 6.13142 8.32944 6.09036C8.66121 5.90671 8.91118 5.59514 8.97352 5.2211L9.18703 3.94005Z"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5935 11.9999C14.5935 13.6568 13.2503 14.9999 11.5935 14.9999C9.93662 14.9999 8.59348 13.6568 8.59348 11.9999C8.59348 10.3431 9.93662 8.99992 11.5935 8.99992C13.2503 8.99992 14.5935 10.3431 14.5935 11.9999Z"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default forwardRef(CogIcon);
